import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router';
import Layout from '../layouts/claire';
import { generateRandomId, getBase64, getReadableFileSizeString } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import BlackOverlay from '../partials/black_overlay';
import fakeAuth from '../../fakeAuth';
import _, { slice } from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LineLoader from '../partials/line_loader';
import MaskedInput from 'react-text-mask';
import request from 'superagent';
import config from '../../config';

const baseStyle = {
    flex: 1,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 10,
    borderColor: '#343f4b',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onDrop && props.onDrop(file);
            props.drop && props.drop(file);
        });

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'image/*', onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container" style={{ position: 'relative' }}>

            <div {...getRootProps({ style })} className="pointer bg-gray-dark">
                {/*<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    className=" d-flex flex-row justify-content-center w-100 h-100">
                    <div style={{ width: 420, height: 420, borderRadius: 210, backgroundColor: '#e5e5e5', opacity: 0.3 }} className="align-self-center" />
    </div>*/}
                <input {...getInputProps()} />
                <div style={{ height: 50 }} />
                <i className="fas fa-upload white font-60" />
                <div style={{ height: 20 }} />
                <p className="text-center font-18">Arrastrar y soltar archivos, o <span className="blue">buscar</span></p>
                <p className="text-center font-14 gray">Soporta: JPEG, PNG. • Max: 2Mb c/u.</p>
                {/*<div style={{ border: '3px solid #2cad3c', borderRadius: 20, width: 200 }} className="p-2 text-center mt-4"><span className="green">UPLOAD VIDEOS</span></div>*/}
                <div style={{ height: 50 }} />
            </div>

        </div>
    );
}


class AddRoom extends React.Component {

    state = {
        page: 1,
        toggle: true,
        menu_toggled: false,
        validations: {},
        comodities: config.room_comodities,
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        editorMode: 1, // [1: es, 2: en]
        editorStage: 1, // [1: description, 2: rates, 3: terms]
        period: {
            WeekDays: 0,
            SalesCommission: {},
            RemainingInventory: {},
            MinOccupancy: {},
            MaxOccupancy: {},
            BlockedAllowance: 0,
            CloseAtArrival: 0,
            CloseAtDeparture: 0,
            MinDaysStay: {},
        },
        files: [],
        room: {
            BedTypes: [{
                id: generateRandomId(10)
            }],
            IdProperty: this.props.params.id,
            Comodities: [],
            Name: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
            Description: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
        },
        week_day_flags: config.week_day_flags,
    }

    // lifecycle
    componentWillMount() {
        const { params } = this.props;
        this.setState({ bootstrapped: true }, () => { this.onLoadEditorState(1, 1); });
    }
    componentWillReceiveProps = nextProps => {

    }
    componentWillUnmount = () => {
        //this.props.properties_actions && this.props.properties_actions.clean();
    }
    //

    onLoadEditorState = (mode, stage) => {
        const { room } = this.state;
        let html = '';
        switch (mode) {
            case 1: // Description
                html = stage === 1 ? room.Description.es.Value : room.Description.en.Value; break;
            default: break;
        }
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            this.setState({
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)),
                bootstrapped: true,
                editorMode: mode,
                editorStage: stage,
            });
        }
    }
    onEditorStateChange = (editorState) => this.setState({ editorState }, () => {
        const { room, editorMode, editorStage } = this.state;
        switch (editorMode) {
            case 1: // Description
                room.Description[editorStage === 1 ? 'es' : 'en'].Value = draftToHtml(convertToRaw(editorState.getCurrentContent())); break;
            default: break;
        }
    });
    onNameChange = () => {
        const { editorStage, room } = this.state;
        room.Name[editorStage === 1 ? 'es' : 'en'].Value = this.txtRoomName.value;
    }
    onComodity = (com) => {
        const { room } = this.state;
        if (_.find(room.Comodities, c => c === com))
            room.Comodities = _.reject(room.Comodities, c => c === com);
        else room.Comodities.push(com);
        this.setState({ room });
    }
    onDrop = (file) => {
        const { files } = this.state;
        files.push({ file, eye: false });
        this.setState({ files });

        /*getBase64(file).then(data => {
            request.post(`${config.apiEndpoint}/api/v4/rooms/image/we`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .send({ data })
                .then();
        });*/
    }

    // Saving    
    doSaveRoom = () => {
        const { room, doContinue } = this.state;
        // doContnue means that we get the IdRoom and then try to create the 1st period
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
            }, () => {
                // Call API
                request[room.IdRoom ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/rooms${room.IdRoom ? `/${room.IdRoom}` : ''}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({
                        Name: this.txtRoomName.value,
                        Availability: parseInt(this.txtAvailability.value, 10),
                        MinOccupancy: parseInt(this.txtMinOccupancy.value, 10),
                        MaxOccupancy: parseInt(this.txtMaxOccupancy.value, 10),
                        MinAdults: parseInt(this.txtMinAdults.value, 10),
                        MaxAdults: parseInt(this.txtMaxAdults.value, 10),
                        MinChildren: parseInt(this.txtMinChildren.value, 10),
                        MaxChildren: parseInt(this.txtMaxChildren.value, 10),
                        MinCribs: parseInt(this.txtMinCribs.value, 10),
                        MaxCribs: parseInt(this.txtMaxCribs.value, 10),
                        LocalTax: parseFloat(this.txtLocalTax.value),
                        IdAccount: fakeAuth.getUser().IdAccount,
                        IdProperty: this.props.params.id,
                        ..._.pick(room, 'Description', 'Name', 'BedTypes', 'Comodities', 'ExtraBed')
                    })
                    .then((data) => {

                        // The workflow is as follows: 
                        // If we're in step 1, save and out
                        // If we're in step 2, create the first period as well

                        if (data.body.success) {
                            room.IdRoom = data.body.IdRoom || room.IdRoom;
                            // Upload all files
                            this.state.files.forEach(f => {
                                // Only save those files that are 2mb or less
                                if (f.file.size <= 1000000 * 2)
                                    getBase64(f.file).then(data => {
                                        request.post(`${config.apiEndpoint}/api/v4/rooms/image/${room.IdRoom}`)
                                            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                                            .send({ data })
                                            .then();
                                    });
                            });
                            if (doContinue) {
                                this.setState({ room, page: 2, confirm: false, microTransac: false });
                            } else {
                                this.props.router.goBack();
                            }
                        } else {
                            // TODO: show server error?
                            this.setState({ microTransac: false });
                        }
                    }, (error) => {
                        this.setState({ microTransac: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });
        }
    }

    validate = (doContinue) => {
        this.setState({ validations: {} }, () => {
            const { validations, room } = this.state;

            if (!room.Name.es.Value) {
                validations.txtRoomName = 'Por favor, introduce (al menos) el nombre en Español de la habitación';
                this.txtRoomNameWrapper.scrollIntoView({ behavior: 'smooth' });
                this.setState({ validations });
                return;
            }
            if (_.isNaN(parseInt(this.txtAvailability.value, 10))) {
                validations.txtAvailability = 'Por favor introduzca un número válido aqui';
                this.txtAvailabilityWrapper.scrollIntoView({ behavior: 'smooth' });
                this.setState({ validations });
                return;
            }           

            this.setState({ confirm: true });

        });
    }
    //

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_rooms blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Nueva habitación</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
                <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>
            </div>
            <TopRightMenu {...this.props} />
        </div>
    }
    renderImages = () => {
        const { room, files } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Imágenes de la habitación</h4>
            <hr />
            <div className="d-flex w3-content justify-content-center">
                <div className="w-100">
                    <StyledDropzone onDrop={this.onDrop} />
                </div>
                {files.length > 0 && <div className="w-100">
                    {/* Files */}
                    {files.map((file, i) => <div key={i} className="p-3 bg-gray-dark d-flex mt-1" style={{
                        borderWidth: 2,
                        borderRadius: 10,
                        borderColor: file.file.size <= 1000000 * 2 ? '#343f4b' : 'red',
                        borderStyle: 'dashed'
                    }}>

                        <span className="align-self-center font-10 white">{file.file.name} (<span className={`${file.file.size <= 1000000 * 2 ? 'white' : 'red'}`}>{getReadableFileSizeString(file.file.size)}</span>)</span>
                        <i onClick={() => {
                            const { files } = this.state;
                            files[i].eye = !files[i].eye;
                            this.setState({ files });
                        }} id={`file_${i}`} className="ms-auto align-self-center far fa-eye blue" />
                        <i className="fas align-self-center fa-trash gray pointer ms-2" onClick={() => {
                            const { files } = this.state;
                            this.setState({ files: [...files.slice(0, i), ...files.slice(i + 1)] });
                        }} />
                        <Popover placement="bottom" isOpen={file.eye ? true : false} target={`file_${i}`} toggle={() => {
                            const { files } = this.state;
                            files[i].eye = !files[i].eye;
                            this.setState({ files });
                        }} className="bg-gray-2">
                            <PopoverBody className="d-flex flex-row justify-content-center">
                                <img src={URL.createObjectURL(file.file)} style={{ maxHeight: 200 }} />
                            </PopoverBody>
                        </Popover>

                    </div>
                    )}

                </div>}
            </div>
        </section>
    }
    renderComodities = () => {
        const { comodities, room } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Comodidades</h4>
            <hr />
            <div className="d-flex flex-wrap w3-content justify-content-center">
                {comodities.map((s, i) => <button onClick={() => this.onComodity(s)} key={i} type="button" className={`btn btn-lg ${_.find(room.Comodities, c => c === s) ? 'btn-blue' : 'btn-gray-2'} white m-2`}>
                    {s}
                </button>)}
            </div>
        </section>
    }
    renderOccupancy = () => {
        const { room, validations } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Ocupación</h4>
            <hr />

            <div className="d-flex w3-content">
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Mínimo de ocupación <i className="blue">*</i></label>
                    <div id="txtMinOccupancy" className={`black-input ${validations.txtMinOccupancy ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Mínimo de ocupación" ref={(input) => this.txtMinOccupancy = input} defaultValue={room.MinOccupancy} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMinOccupancy ? true : false} target={`txtMinOccupancy`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMinOccupancy = !validations.txtMinOccupancy;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMinOccupancy}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Máximo de ocupación <i className="blue">*</i></label>
                    <div id="txtMaxOccupancy" className={`black-input ${validations.txtMaxOccupancy ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Máximo de ocupación" ref={(input) => this.txtMaxOccupancy = input} defaultValue={room.MaxOccupancy} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMaxOccupancy ? true : false} target={`txtMaxOccupancy`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMaxOccupancy = !validations.txtMaxOccupancy;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMaxOccupancy}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>

            <div className="d-flex w3-content">
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Mínimo de adultos <i className="blue">*</i></label>
                    <div id="txtMinAdults" className={`black-input ${validations.txtMinAdults ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Mínimo de niños" ref={(input) => this.txtMinAdults = input} defaultValue={room.MinAdults} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMinAdults ? true : false} target={`txtMinAdults`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMinAdults = !validations.txtMinAdults;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMinAdults}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Máximo de adultos <i className="blue">*</i></label>
                    <div id="txtMaxAdults" className={`black-input ${validations.txtMaxAdults ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Máximo de niños" ref={(input) => this.txtMaxAdults = input} defaultValue={room.MaxAdults} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMaxAdults ? true : false} target={`txtMaxAdults`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMaxAdults = !validations.txtMaxAdults;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMaxAdults}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>

            <div className="d-flex w3-content">
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Mínimo de niños <i className="blue">*</i></label>
                    <div id="txtMinChildren" className={`black-input ${validations.txtMinChildren ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Mínimo de niños" ref={(input) => this.txtMinChildren = input} defaultValue={room.MinChildren} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMinChildren ? true : false} target={`txtMinChildren`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMinChildren = !validations.txtMinChildren;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMinChildren}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Máximo de niños <i className="blue">*</i></label>
                    <div id="txtMaxChildren" className={`black-input ${validations.txtMaxChildren ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Máximo de niños" ref={(input) => this.txtMaxChildren = input} defaultValue={room.MaxChildren} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMaxChildren ? true : false} target={`txtMaxChildren`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMaxChildren = !validations.txtMaxChildren;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMaxChildren}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>

            <div className="d-flex w3-content">
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Mínimo de infantes <i className="blue">*</i></label>
                    <div id="txtMinCribs" className={`black-input ${validations.txtMinCribs ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Mínimo de niños" ref={(input) => this.txtMinCribs = input} defaultValue={room.MinCribs} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMinCribs ? true : false} target={`txtMinCribs`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMinCribs = !validations.txtMinCribs;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMinCribs}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Máximo de infantes <i className="blue">*</i></label>
                    <div id="txtMaxCribs" className={`black-input ${validations.txtMaxCribs ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Máximo de niños" ref={(input) => this.txtMaxCribs = input} defaultValue={room.MaxCribs} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMaxCribs ? true : false} target={`txtMaxCribs`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMaxCribs = !validations.txtMaxCribs;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMaxCribs}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>

            <div className="d-flex w3-content">
                {/* LOCAL TAX */}
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Impuesto local</label>
                    <div id="txtLocalTax" className={`black-input ${validations.txtLocalTax ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Mínimo de niños" ref={(input) => this.txtLocalTax = input} defaultValue={room.LocalTax} />
                    </div>
                </div>
                <div className="px-1" />
                {/* EXTRA BED */}
                <div className="w-100 mt-4 d-flex flex-row justify-content-center">
                    <button onClick={() => {
                        const { room } = this.state;
                        room.ExtraBed = !room.ExtraBed;
                        this.setState({ room });
                    }} className="btn btn-link align-self-center d-flex font-18">
                        <i className={`far ${room.ExtraBed ? 'fa-check-square blue' : 'fa-square gray'} me-3 align-self-center`} />
                        <span className={`align-self-center`}>Cama extra</span>
                    </button>
                </div>
            </div>

        </section>
    }
    renderInventory = () => {
        const { validations, room } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Información de inventario</h4>
            <hr />
            <div className="w3-content">
                <h5 className="white poppins mt-4 text-center">Inventario disponible</h5>
                <h6 className="white poppins text-center">(Recuerda que debes garantizar este número para evitar reservas de piezas no-disponibles)</h6>
            </div>
            <div className="d-flex w3-content">
                {/* Availability */}
                <div className="w-50 mx-auto mt-4" ref={(input) => this.txtAvailabilityWrapper = input}>
                    <label className="white poppins font-12">Inventario <i className="blue">*</i></label>
                    <div id="txtAvailability" className={`black-input ${validations.txtAvailability ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Agregar valor" ref={(input) => this.txtAvailability = input} defaultValue={room.Availability} />
                        <span className="ms-auto me-3 align-self-center white">Unidades</span>
                    </div>
                    <Popover placement="right" isOpen={validations.txtAvailability ? true : false} target={`txtAvailability`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtAvailability = !validations.txtAvailability;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtAvailability}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                {/*<div className="px-1" />
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Dias mínimos de liberación</label>
                    <div id="txtMinFreeUpDays" className={`black-input ${validations.txtMinFreeUpDays ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="city" placeholder="Ciudad" ref={(input) => this.txtMinFreeUpDays = input} defaultValue={room.MinFreeUpDays} />
                    </div>
                </div>*/}
            </div>
            {/* Bed Types */}
            <div className="w3-content">
                <h5 className="white poppins mt-4 text-center">Tipos de cama</h5>
            </div>
            <div className="d-flex flex-column justify-content-center w3-content">
                {room.BedTypes.map((bt, i) => <div key={bt.id} className="w-50 mt-2 mx-auto">
                    <div className={`black-input ${false ? 'error-input' : ''} d-flex mt-2`}>
                        <input onChange={(e) => {
                            const { room } = this.state;
                            room.BedTypes[i].name = e.target.value;
                            this.setState({ room });
                        }} className="w-100 p-3 white" type="text" placeholder="Tipo de cama" defaultValue={bt.name} />
                        {i > 0 && <i className="fas fa-minus font-16 white align-self-center px-3 pointer " onClick={() => {
                            const { room } = this.state;
                            room.BedTypes = [...room.BedTypes.slice(0, i), ...room.BedTypes.slice(i + 1)];
                            this.setState({ room });
                        }} />}
                    </div>
                </div>)}
                <button onClick={() => {
                    const { room } = this.state;
                    room.BedTypes.push({
                        name: '',
                        id: generateRandomId(10)
                    });
                    this.setState({ room });
                }} type="button" className="btn btn-lg btn-blue white mx-auto mt-3 w-50">
                    <i className="fas fa-plus white font-12" /> Agregar
                </button>
            </div>
        </section>
    }
    renderBasic = () => {
        const { editorState, editorStage, editorMode, validations, room } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Información general</h4>
            <hr />
            <div className="w3-content">
                {/* Room Name */}
                <div className="w-100 mt-4" ref={(input) => this.txtRoomNameWrapper = input}>
                    <div className="d-flex">
                        <label className="white poppins font-12">Nombre <i className="blue">*</i></label>
                        <div className="d-flex flex-row ms-auto">
                            <button onClick={() => this.setState({ editorStage: 1 }, () => this.onLoadEditorState(editorMode, 1))} className={`ms-auto btn btn-sm btn-link  ${editorStage === 1 ? 'blue' : 'gray'}`}>ES</button>
                            <button onClick={() => this.setState({ editorStage: 2 }, () => this.onLoadEditorState(editorMode, 2))} className={`btn btn-sm btn-link ${editorStage === 2 ? 'blue' : 'gray'}`}>EN</button>
                        </div>
                    </div>
                    <div id="txtRoomName" className={`black-input ${validations.txtRoomName ? 'error-input' : ''} d-flex mt-2`}>
                        {editorStage === 1 && <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre de la habitación en español" ref={(input) => this.txtRoomName = input} defaultValue={room.Name.es.Value} onChange={this.onNameChange} />}
                        {editorStage === 2 && <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre de la habitación en ingles" ref={(input) => this.txtRoomName = input} defaultValue={room.Name.en.Value} onChange={this.onNameChange} />}
                    </div>
                    <Popover placement="right" isOpen={validations.txtRoomName ? true : false} target={`txtRoomName`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtRoomName = !validations.txtRoomName;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtRoomName}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <label className="white poppins font-12 mt-4 mb-2">Descripción <i className="blue">*</i></label>
                <Editor
                    wrapperClassName="demo-wrapper"
                    editorState={editorState}
                    editorClassName="demo-editor px-4"
                    onEditorStateChange={this.onEditorStateChange}
                />
            </div>
        </section>
    }
    renderCommon = () => {
        return (
            <div>
                {this.renderHeader()}
                {this.state.bootstrapped && <section>

                    {/* Room details */}
                    <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.toggle} animationOut="fadeOutRight" animationIn="fadeInRight" className="mt-4">
                        {this.renderBasic()}
                        {this.renderInventory()}
                        {this.renderComodities()}
                        {this.renderImages()}
                        {/* TODO: images */}
                        {this.renderOccupancy()}
                        {/* Buttons */}
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white align-self-center" >
                                <i className="icon-corner-down-left white font-12" />
                            </button>
                            <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className="far fa-save white font-12" />
                            </button>
                        </div>
                    </Animated>
                </section>
                }
                <div style={{ height: 100 }} />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Confirm Room */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Nueva habitación</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de crear esta nueva habitación?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doSaveRoom} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default AddRoom;
