function payment_methods_Reducer(state = [], action) {
    switch (action.type) {
        case 'PAYMENT_METHODS/SET':
            return action.payment_methods;
        case 'PAYMENT_METHODS/CLEAR':
            return null;
        case 'BOOK/CLEAR':
            return null;
        default:
            return state;
    }
}

export default payment_methods_Reducer;
