import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { formatPrice, padWithZeros } from '../../helpers';
import { Animated } from 'react-animated-css';

import Menu from '../partials/menu';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import moment from 'moment';
import _ from 'lodash';
import GuestForm from './partials/guest_form';
import RoomsResume from './partials/rooms_resume';
import request from 'superagent';
import config from '../../config';
import Pieces from './partials/pieces';
import BlackOverlay from '../partials/black_overlay';
import Accounting from './partials/accounting';
import TransactionForm from './partials/transaction_form';
import PaymentMethods from './partials/payment_methods';
import PaymentMethodForm from './partials/payment_method_form';
import Documents from './partials/documents';
import Coorp from './partials/coorp';
import Notes from './partials/notes';
import Modify from './partials/modify';
import ChangeDates from './partials/change_dates';
import Companions from './partials/companions';
import CoorpForm from './partials/coorp_form';

class Booking extends React.Component {

    /*
    
    TODO: If the reservation is set to 'No Show', then we should liberate all the pieces of it

    */

    state = {
        tab: 1,
        menu_toggled: false,
        toggle: 1,
        sort: 'Name',
        filters: {
            CheckIn: moment().utc()
        },
        validations: {
            guest: false,
        },
        book: {
            IdStatus: 1,
            Guest: {
                FirstName: '',
                LastName: ''
            }
        },
        sources: [{ id: 1, value: 'Source 1' }, { id: 2, value: 'Source 2' }],
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        IdProperty: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).IdProperty : null,
    }

    // Lifecycle
    componentWillMount() {
        this.props.form_actions.fetch_countries();
        this.props.form_actions.fetch_country_codes();
        this.props.form_actions.fetch_nationalities();
        const { params } = this.props, { id } = params;
        if (id) {
            this.setup();
            this.props.floors_actions && this.props.floors_actions.fetch(this.state.IdProperty);
            this.props.coorps_actions && this.props.coorps_actions.fetch_all(fakeAuth.getUser().IdAccount);
            this.props.properties_actions && this.props.properties_actions.fetch_one(this.state.IdProperty);
        } else this.props.router.goBack();
    }
    componentWillReceiveProps = nextProps => {
        const { book, bookings, booking_actions, property } = nextProps, { id } = this.props.params;
        if (book && book.IdBook) this.setState({ book }, () => {
            // NEW: here we need to get the 'occupancy of pieces of the day the reservation starts (or today if that day has already passed). 
            // Then we use that occupancy to rule out pieces for assigning due to -well- they are taken
            if (!this.state.bootstrapped) {
                this.setState({ bootstrapped: true }, () => {
                    booking_actions && booking_actions.fetch_range({
                        IdProperty: this.state.IdProperty,
                        CheckIn: moment(book.CheckIn).isBefore(moment()) ? moment().format('YYYY-MM-DD') : moment(book.CheckIn).format('YYYY-MM-DD'),
                        CheckOut: moment(book.CheckOut).isBefore(moment()) ? moment().add(1, 'day').format('YYYY-MM-DD') : moment(book.CheckOut).format('YYYY-MM-DD')
                    });
                });
            }
        });
    }
    componentWillUnmount = () => {
        this.props.booking_actions.clear();
    }
    //

    setup = () => {
        const { params } = this.props, { id } = params;
        this.props.booking_actions && this.props.booking_actions.fetch_one(id);
        this.props.accounting_actions && this.props.accounting_actions.fetch(id);
        this.props.accounting_actions && this.props.accounting_actions.fetch_payment_methods(id);
    }

    download = () => {
        const { downloading, book } = this.state;
        if (downloading) return;
        this.setState({
            downloading: true
        }, () => {
            request.get(`${config.apiEndpoint}/api/v4/pdf/book/${book.IdBook}/voucher.pdf`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .responseType('arraybuffer')
                .then((data) => {
                    let file = new Blob([data.body], { type: 'application/pdf' });
                    require("downloadjs")(file, 'VOUCHER.pdf');
                    this.setState({ downloading: false, });
                });
        });
    }
    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    onStatus = () => {
        const { book } = this.state;
        request.patch(`${config.apiEndpoint}/api/v4/bookings/status/${this.props.params.id}`)
            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
            .send({ ..._.pick(book, 'IdStatus') })
            .then((data) => {

            }, (error) => {
                this.setState({ microTransact: false, error: 'Something wrong' });
                //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
            });
    }
    onFreePieces = () => {
        // CALL API to free up all the reservation pieces
        const { id } = this.props.params;
        this.props.booking_actions && this.props.booking_actions.free_up_pieces({
            IdBook: id,
            IdProperty: this.state.IdProperty
        });
    }
    onNoShow = () => {
        const { book } = this.state;
        book.IdStatus = 5;
        this.setState({ book, no_showing: false }, () => {
            this.onStatus();
            this.onFreePieces();
        });
    }
    onVoid = () => {
        const { book } = this.state;
        book.IdStatus = 6;
        this.setState({ book, voiding: false }, () => {
            this.onStatus();
            this.onFreePieces();
        });
    }
    onCheckOut = () => {
        const { book } = this.state;
        book.IdStatus = 4;
        this.setState({ book, checking_out: false }, () => {
            this.onStatus();
            this.onFreePieces();
        });
    }
    onDeleteBook = () => {
        const { book } = this.state;
        if (this.state.deleting) return;
        this.setState({ deleting: true }, () => {
            request.delete(`${config.apiEndpoint}/api/v4/bookings/${book.IdBook}`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .then((data) => {
                    this.props.router.goBack();
                }, (error) => {
                    this.setState({ microTransact: false, error: 'Something wrong' });
                    //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                });
        });

    }

    // RENDERS

    renderHeader = () => {
        const { booking_status } = config, { book, bootstrapped } = this.state, { Guest } = book;
        let class_name = (_.find(booking_status, t => parseInt(t.id, 10) === book.IdStatus) || { color_class: 'Invalid' }).color_class;
        return <div className="d-flex" style={{ position: 'relative', zIndex: 200 }}>
            <div className="w-100 d-flex">
                <i className="icon-lb_reservaciones blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Reservación</span>
                    {book && <span className="white ms-3 font-10 poppins">{(Guest && Guest.FirstName) ? `${Guest.FirstName} ${Guest.LastName ? Guest.LastName.substring(0, 1) : ''}` : ''} • {`${padWithZeros(book.PNR || 0)}`}</span>}
                </div>
                {bootstrapped && <Animated animationIn='fadeInDown' id="txtState" className={`black-input d-flex ms-3 align-self-center`}>
                    <Dropdown isOpen={this.state.pickState} toggle={() => this.setState({ pickState: !this.state.pickState })} className="w-100">
                        <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent', borderRadius: 10 }} className={`w-100 p-3 d-flex ${class_name}`}>
                            <span className="align-self-center">{(_.find(booking_status, t => parseInt(t.id, 10) === book.IdStatus) || { name: 'Invalid' }).name}</span>
                            <i className="fas fa-chevron-down font-16 align-self-center ps-3 ms-auto" />
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                            {booking_status && booking_status.map((c, i) => <DropdownItem key={i} onClick={() => {

                                if (parseInt(c.id, 10) === 5) {
                                    this.setState({ no_showing: true });
                                } else if (parseInt(c.id, 10) === 4) {
                                    this.setState({ checking_out: true });
                                } else if (parseInt(c.id, 10) === 6) {
                                    this.setState({ voiding: true });
                                } else if (parseInt(c.id, 10) === 3) {
                                    // TODO: here we check if the pieces are assigned
                                    if (_.find(book.Rooms, r => !r.PieceLabel)) {
                                        this.setState({ notify_missing_pieces: true });
                                    } else {
                                        const { book } = this.state;
                                        book.IdStatus = parseInt(c.id, 10);
                                        this.setState({ book }, this.onStatus);
                                    }
                                } else {
                                    const { book } = this.state;
                                    book.IdStatus = parseInt(c.id, 10);
                                    this.setState({ book }, this.onStatus);
                                }
                            }}>{c.name}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </Animated>}
            </div>
            <div className="w-75 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderTotals = () => {
        const { book } = this.state, { accounting, property } = this.props;

        let SUB_TOTAL = _.chain(accounting).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value(),
            TAXES = _.chain(accounting).reject(a => a.IsTaxFree).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value() * (property ? (property.Tax || 0) : 0) / 100,
            BALANCE = (SUB_TOTAL + TAXES) - _.chain(accounting).map(a => parseFloat(a.Credit)).reduce((a, b) => a + b, 0).value(),
            PROMO = 0;

        return <Animated animationInDelay={200} animationIn="fadeIn" className="d-flex flex-column w-100 rounded bg-gray-3" style={{ zIndex: 100, position: 'relative' }}>
            <div className="px-3">
                <table className="table poppins white w-100 m-0">
                    <thead>
                        <tr>
                            <th className="blue bg-gray-3">Concepto</th>
                            <th className="blue bg-gray-3 text-right">Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="bg-gray-3 white">Sub-Total</td>
                            <td className="bg-gray-3 white text-right">{formatPrice(SUB_TOTAL * 100)}</td>
                        </tr>
                        {property && <tr>
                            <td className="bg-gray-3 white">Impuestos ({property.Tax}%)</td>
                            <td className="bg-gray-3 white text-right">{formatPrice(TAXES * 100)}</td>
                        </tr>}
                        <tr>
                            <td className="bg-gray-3 white">Promoción</td>
                            <td className="bg-gray-3 white text-right">{formatPrice(PROMO * 100)}</td>
                        </tr>
                        <tr>
                            <td className="bg-gray-3 white">Total</td>
                            <td className="bg-gray-3 white text-right">{formatPrice((SUB_TOTAL + TAXES + PROMO) * 100)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="px-3 bg-gray-2">
                <table className="table poppins white w-100 m-0">
                    <tbody>
                        <tr>
                            <td className="bg-gray-2 white font-20">Saldo por cobrar</td>
                            <td className={`bg-gray-2 ${BALANCE ? 'blue' : 'green'} text-right font-20`}>{formatPrice(BALANCE * 100)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Animated>
    }
    renderCheckInOut = () => {
        const { book } = this.state;

        return <Animated animationInDelay={200} animationIn="fadeIn" className="d-flex flex-column w-100 rounded bg-gray-3" style={{ zIndex: 100, position: 'relative' }}>
            <div className="px-3">
                <table className="w-100 table m-0 poppins bg-gray-3 white">
                    <thead>
                        <tr>
                            <th className="bg-gray-3 white">Check-in</th>
                            <th className="bg-gray-3 white">Check-out</th>
                            <th className="bg-gray-3 white text-center">Noches</th>
                            <th className="bg-gray-3 white">Fecha</th>
                            <th className="bg-gray-3 white">Fuente</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="bg-gray-3 white font-10">{moment(book.CheckIn).format('DD/MM/YYYY @ HH:mmA')} {[5, 6].indexOf(book.IdStatus) === -1 && <i className="fas fa-edit ms-2 pointer blue" onClick={() => this.setState({ changing_dates: true })} />}</td>
                            <td className="bg-gray-3 white font-10">{moment(book.CheckOut).format('DD/MM/YYYY @ HH:mmA')} {[5, 6].indexOf(book.IdStatus) === -1 && <i className="fas fa-edit ms-2 pointer blue" onClick={() => this.setState({ changing_dates: true })} />}</td>
                            <td className="bg-gray-3 white font-10 text-center">{Math.abs(moment(book.CheckOut).utc().diff(moment(book.CheckIn).utc(), 'days')) + 1}</td>
                            <td className="bg-gray-3 white font-10">{moment(book.CreationDate).format('DD/MM/YYYY')}</td>
                            <td className="bg-gray-3 white font-10">{book.Source}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Animated>
    }
    renderGuest = () => {
        const { book } = this.state, { Guest } = book, { countries } = this.props;

        let selected_country = { name: 'N/A' }, selected_state = { name: 'N/A' };
        if (countries && countries.length && Guest && Guest.IdCountry) {
            selected_country = _.find(countries, c => parseInt(c.id, 10) === Guest.IdCountry);
            if (Guest.IdState && selected_country.states) {
                selected_state = _.find(selected_country.states, t => parseInt(t.id, 10) === Guest.IdState);
            }
        }
        return <Animated animationInDelay={200} animationInDuration={200} animationIn="fadeInLeft" className="d-flex flex-column w-100 rounded bg-gray-2 mt-2" style={{ zIndex: 100, position: 'relative' }}>
            <div className="px-3">
                <table className="w-100 table m-0 poppins bg-gray-2 white">
                    <thead>
                        <tr>
                            <th className="bg-gray-2 white">Huesped</th>
                            <th className="bg-gray-2 white">Correo electrónico</th>
                            <th className="bg-gray-2 white">Teléfono</th>
                            <th className="bg-gray-2 white">Cédula|Pasaporte</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={`bg-gray-2 ${(Guest && Guest.FirstName) ? 'blue' : 'red'} font-10`}>{(Guest && Guest.FirstName) ? `${Guest.FirstName} ${Guest.LastName}` : ''}</td>
                            <td className="bg-gray-2 blue font-10">{Guest ? `${Guest.EmailAddress || Guest.Email || 'N/A'}` : 'N/A'}</td>
                            <td className="bg-gray-2 blue font-10">{Guest ? `${Guest.Phone ? `${Guest.PhoneCountryCode} ${Guest.Phone}` : 'N/A'}` : 'N/A'}</td>
                            <td className="bg-gray-2 blue font-10">{Guest ? `${Guest.IdOrPassport || 'N/A'}` : 'N/A'}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th className="bg-gray-2 white">Pais</th>
                            <th className="bg-gray-2 white">Estado</th>
                            <th className="bg-gray-2 white">Ciudad</th>
                            <th className="bg-gray-2 white">Dirección</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="bg-gray-2 blue font-10">{selected_country.name}</td>
                            <td className="bg-gray-2 blue font-10">{Guest ? Guest.State : 'N/A'}</td>
                            <td className="bg-gray-2 blue font-10">{Guest ? Guest.City : 'N/A'}</td>
                            <td className="bg-gray-2 blue font-10">{Guest ? Guest.Address : 'N/A'}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colSpan={4} className="bg-gray-2 white">Llegada estimada</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="bg-gray-2 blue font-10">{Guest ? (Guest.EstimatedArrivalTime || '-') : 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Animated>
    }
    renderTabs = () => {
        const { tab, validations, book } = this.state, { payment_methods = [] } = this.props;
        return <Animated animationInDelay={200} animationIn="fadeIn" className="d-flex flex-column w-100 rounded" style={{ zIndex: 100, position: 'relative' }}>
            <table className="w-100 table mx-0 mt-4 poppins white">
                <thead>
                    <tr>
                        <th className={`px-3 bg-transparent white pointer ${tab === 1 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 1 })}>Piezas ({book.Rooms.length}){(_.find(book.Rooms, r => !r.IdPiece)) && <i className="fas fa-warning red ms-1" />}</th>
                        <th className={`px-3 bg-transparent white pointer ${tab === 2 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 2 })}>Cuenta Maestra {tab === 2 && <button className="btn btn-blue btn-sm white font-10 poppins ms-2" onClick={() => this.setState({ assigning_charge: true })}><i className="fas fa-plus" />Agregar</button>}</th>
                        {book.IsGroup !== 1 && <th className={`px-3 bg-transparent white pointer ${tab === 3 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 3 })}>Huésped Principal{(!book.Guest || !book.Guest.IdGuest) && <i className="fas fa-warning red ms-1" />}</th>}
                        {book.IsGroup === 1 && <th className={`px-3 bg-transparent white pointer ${tab === 10 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 10 })}>Empresa {(!book.Coorp || !book.Coorp.IdCoorp) && <i className="fas fa-warning red ms-1" />}</th>}
                        <th className={`px-3 bg-transparent white pointer ${tab === 9 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 9 })}>Acompañantes ({book.Companions.length})</th>
                        <th className={`px-3 bg-transparent white pointer ${tab === 4 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 4 })}>Métodos de pago {tab === 4 && <button className="btn btn-blue btn-sm white font-10 poppins ms-2" onClick={() => this.setState({ assigning_payment_method: true })}><i className="fas fa-plus" />Agregar</button>}</th>
                        <th className={`px-3 bg-transparent white pointer ${tab === 6 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 6 })}>Documentos ({book.Attachments.length})</th>
                        <th className={`px-3 bg-transparent white pointer ${tab === 7 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 7 })}>Observaciones</th>
                        <th className={`bg-transparent white w-100`} />
                        <th className={`ms-auto px-3 bg-transparent red pointer ${tab === 8 ? 'border-blue' : ''}`}
                            onClick={() => this.setState({ tab: 8 })}><i className="fas fa-trash me-2" />Eliminar</th>

                        {/*<th className={`px-3 bg-transparent white pointer ${tab === 5 ? 'border-blue' : ''}`} onClick={() => this.setState({ tab: 5 })}>Notas</th>*/}
                    </tr>
                </thead>
            </table>
        </Animated>
    }

    renderCommon = () => {
        const { filters, sources, tab, bootstrapped, downloading, book } = this.state;

        let available_voucher = book.IsGroup ? !(_.find(book.Rooms, r => !r.IdPiece) || (!book.Coorp || !book.Coorp.IdCoorp)) : !(_.find(book.Rooms, r => !r.IdPiece) || (!book.Guest || !book.Guest.IdGuest));
        return (
            <div className="">
                {this.renderHeader()}
                <div className="d-flex">
                    <h4 className="white poppins align-self-center">Resumen de la reserva</h4>
                    {!downloading && available_voucher && <a href={`/print/voucher/${book.IdBook}`} target='_blank' className="ms-auto d-flex flex-row pointer">
                        <i className="fas fa-file-pdf white font-12 white align-self-center" /><span className="mx-2 underlined align-self-center white font-12">Descargar comprobante</span>
                    </a>}
                    {!downloading && !available_voucher && <section className="ms-auto">
                        <div onClick={() => this.setState({ not_available_voucher: !this.state.not_available_voucher })} id="no_voucher" className="ms-auto d-flex flex-row pointer" >
                            <i className="fas fa-warning red font-12 white align-self-center" /><span className="mx-2 underlined align-self-center white font-12">Descargar comprobante</span>
                        </div>
                        <Popover placement="bottom" isOpen={this.state.not_available_voucher ? true : false} target={`no_voucher`} toggle={() => {
                            this.setState({ not_available_voucher: !this.state.not_available_voucher });
                        }} className="bg-gray-2">
                            <PopoverBody className="d-flex flex-row justify-content-center">
                                <i className="fas fa-times red font-8 align-self-center" />
                                <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{'Complete primero los campos requeridos'}</span>
                            </PopoverBody>
                        </Popover>
                    </section>}

                    {/*downloading && <div className="d-flex flex-row ms-auto">
                        <i className="fas fa-file-pdf white font-12 white align-self-center" /><span className="mx-2 underlined align-self-center white font-12">Descargando...</span>
                    </div>*/}
                </div>
                <hr />
                {bootstrapped && <section>
                    <div className="d-flex">
                        <div className="w-100">
                            {this.renderCheckInOut()}
                            {this.renderGuest()}
                        </div>
                        <div className="p-1"></div>
                        <div className="w-75">
                            {this.renderTotals()}
                        </div>
                    </div>
                    <hr />
                    {this.renderTabs()}
                    {tab === 1 && <RoomsResume {...this.props}
                        onPiece={(room, i) => {
                            this.setState({ assigning_piece: true, room_being_assigned: { room, i } });
                        }}
                        onChange={(room, i) => {
                            this.setState({ changing_room: { room, index: i } });
                        }}
                        fnUpdate={this.setup} />}
                    {tab === 2 && <Accounting {...this.props} />}
                    {tab === 3 && <GuestForm ref={i => this.guest_form = i} {...this.props}
                        fnCoorp={(state) => {
                            this.setState({ create_coorp: true })
                        }}
                        fnDone={() => {
                            this.vwTop.scrollIntoView({ behavior: 'smooth' });
                        }}
                        fnUpdate={(state) => {
                            const { book } = this.state;
                            book.Guest = state.guest;
                            this.setState({ book });
                        }}
                        fnPrevious={(guest) => {
                            this.setState({ previous_client: guest });
                        }} />}
                    {tab === 10 && <CoorpForm ref={i => this.guest_form = i} {...this.props} book={this.state.book}
                        fnDone={() => {
                            this.vwTop.scrollIntoView({ behavior: 'smooth' });
                        }}
                        fnUpdate={(state) => {
                            const { book } = this.state;
                            book.Coorp = state.coorp;
                            this.setState({ book });
                        }}
                        fnPrevious={(guest) => {
                            this.setState({ previous_client: guest });
                        }} />}
                    {tab === 9 && <Companions ref={i => this.companions_form = i} {...this.props}
                        fnCoorp={(state) => {
                            this.setState({ create_coorp: true })
                        }}
                        fnUpdate={this.setup}
                        fnPrevious={(guest) => {
                            this.setState({ previous_companion: guest });
                        }} />}
                    {tab === 4 && <PaymentMethods {...this.props} />}
                    {tab === 6 && <Documents {...this.props} />}
                    {tab === 7 && <Notes {...this.props} />}
                    {tab === 8 && <section>
                        <div className="w3-content mt-2">
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="red text-center poppins">ELIMINAR RESERVA</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro que desea eliminar por completo esta reserva?<br />Todas las piezas previamente asignadas seran liberadas. Este cambio no se puede revertir.</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    {!this.state.deleting && <button onClick={() => this.setState({ tab: 1 })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>}
                                    <button onClick={this.onDeleteBook} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className={`fas fa-${this.state.deleting ? 'cog fa-spin' : 'check'} white font-12`} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>}
                    <div style={{ height: 200 }} />
                </section>}
            </div>
        )
    }
    render() {

        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {this.state.microTransac && <LineLoader />}
                    <div ref={i => this.vwTop = i} className="w3-container w3-hide-small" style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {!this.state.assigning_piece && this.renderCommon()}
                    </div>

                    <Menu {...this.state} {...this.props} />

                    {/* ASSIGN PIECES TO THE ROOMS OF THIS RESERVATION */}
                    <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.assigning_piece ? true : false} animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, left: 100, right: 0, overflowY: 'auto' }} className="bg-gray-2">
                            <div className="d-flex mx-4 mt-4">
                                <span className="poppins white font-16">Asigne una pieza de habitación Suite</span>
                                <button onClick={() => this.setState({ assigning_piece: false })} className="btn btn-link rounded ms-auto"><i className="fas fa-times-circle gray font-18" /></button>
                            </div>

                            {this.props.floors && <Pieces  {...this.props} room={(this.state.room_being_assigned || {}).room} fn={(piece) => {
                                // Call API to assign the piece to a room
                                const { room_being_assigned, book } = this.state, { id } = this.props.params;
                                this.props.booking_actions && this.props.booking_actions.assign_piece_to_book({
                                    IdBookRoom: room_being_assigned.room.IdBookRoom,
                                    IdPiece: piece.IdPiece,
                                    IdBook: id,
                                    IdProperty: this.state.IdProperty,
                                    CheckIn: moment(book.CheckIn).isBefore(moment()) ? moment().format('YYYY-MM-DD') : moment(book.CheckIn).format('YYYY-MM-DD'),
                                    CheckOut: moment(book.CheckOut).isBefore(moment()) ? moment().add(1, 'day').format('YYYY-MM-DD') : moment(book.CheckOut).format('YYYY-MM-DD')
                                });
                                this.setState({ assigning_piece: false });
                            }} />}
                        </div>
                    </Animated>

                    {/* CHANGE A ROOM */}
                    <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.changing_room ? true : false} animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        <Modify {...this.props} {...this.state}
                            onCancel={() => this.setState({ changing_room: null })}
                            onDone={() => this.setState({ changing_room: null }, () => {
                                this.setup();
                            })} />
                    </Animated>

                    {/* ADD A QUICK AGENCY */}
                    <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.create_coorp ? true : false} animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, left: 100, right: 0, overflowY: 'auto' }} className="bg-gray-2">
                            <div className="d-flex mx-4 mt-4">
                                <span className="poppins white font-16">Nueva agencia</span>
                                <button onClick={() => this.setState({ create_coorp: false })} className="btn btn-link rounded ms-auto"><i className="fas fa-times-circle gray font-18" /></button>
                            </div>
                            <Coorp {...this.props} fn={(IdCoorp) => {
                                // TODO: assign the id to the user
                                // refresh everything                                
                                this.setState({ create_coorp: false });
                                this.guest_form && this.guest_form.external_add(IdCoorp);
                                this.companions_form && this.companions_form.external_add(IdCoorp);

                            }} toggle={() => this.setState({ create_coorp: false })} />
                        </div>
                    </Animated>

                    {/* ADD CHARGE */}
                    <Animated animationInDuration={200} animationOutDuration={200}
                        isVisible={this.state.assigning_charge ? true : false} animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        {this.state.assigning_charge && <TransactionForm {...this.props} toggle={() => this.setState({ assigning_charge: false })} />}
                    </Animated>

                    {/* ADD PAYMENT METHOD */}
                    <Animated animationInDuration={200} animationOutDuration={200}
                        isVisible={this.state.assigning_payment_method ? true : false} animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        {this.state.assigning_payment_method && <PaymentMethodForm {...this.props} toggle={() => this.setState({ assigning_payment_method: false })} />}
                    </Animated>

                    {/* NO SHOW CONFIRMATION */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.no_showing ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="red text-center poppins">NO SHOW</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de asignar este estatus a la reserva?<br />Todas las piezas previamente asignadas seran liberadas</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ no_showing: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.onNoShow} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    {/* VOID CONFIRMATION */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.voiding ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="red text-center poppins">ANULAR</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de asignar este estatus a la reserva?<br />Todas las piezas previamente asignadas seran liberadas</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ voiding: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.onVoid} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    {/* CHECK OUT CONFIRMATION */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.checking_out ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="red text-center poppins">CHECK OUT</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de asignar este estatus a la reserva?<br />Todas las piezas previamente asignadas seran liberadas</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ checking_out: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.onCheckOut} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    {/* MISSING PIECES / CANT CHECK IN */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.notify_missing_pieces ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Atención</h3>
                                <h6 className="yellow text-center poppins">OPERACIÓN INVALIDA</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">Es necesario asignar piezas a todas las habitaciones de la reserva para cambiar de estatus</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => {
                                        this.setState({ notify_missing_pieces: false, tab: 1 });
                                    }} type="button" className="btn btn-lg btn-blue white align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    {/* CHANGE DATES */}
                    <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.changing_dates ? true : false} animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        {this.state.changing_dates && <ChangeDates {...this.props} {...this.state}
                            onCancel={() => this.setState({ changing_dates: null })}
                            onDone={() => this.setState({ changing_dates: null }, () => {
                                this.setup();
                            })} />}
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default Booking;