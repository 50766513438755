import React from 'react';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _, { slice } from 'lodash';
import request from 'superagent';
import config from '../../../config';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../partials/custom_date_picker';
import { Animated } from 'react-animated-css';
import BlackOverlay from '../../partials/black_overlay';


class Modify extends React.Component {
    state = {
        mode: parseInt((localStorage.getItem('lb.rt') || 1), 10),
        files: [],
        validations: {},
        menu_toggled: false,
        filters: {
            CheckIn: moment().utc()
        },
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        bootstrapped: false,
        sources: [{ id: 1, value: 'Source 1' }, { id: 2, value: 'Source 2' }],
        cart: []
    }

    componentWillReceiveProps = nextProps => {
        const { book } = nextProps;        
        this.setState({ CheckIn: moment(book.CheckIn) });
    }

    onSearch = () => {
        const { CheckIn, CheckOut, validations, mode } = this.state, { book, changing_room } = this.props;
        let current_array = 'items';
        switch (mode) {
            case 1: current_array = 'items'; break;
            case 2: current_array = 'WALKIN'; break;
            case 3: current_array = 'COORP'; break;
            default: break;
        }
        
        this.setState({ validations: {}, cart: [] }, () => {           

            let Nights = this.txtNights.value ? (parseInt(this.txtNights.value, 10) || 1) : 1;

            this.setState({ microTransac: true, CheckOut: moment(book.CheckIn).add(Nights, 'days') }, () => {
                request.post(`${config.apiEndpoint}/api/v4/logic/availability/${this.props.book.IdProperty}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({ CheckIn, CheckOut: this.state.CheckOut })
                    .then((data) => {                        
                        if (data.body.success) {
                            this.setState({ rooms: data.body.rooms, microTransac: false, bootstrapped: true }, () => {
                                this.state.rooms.forEach((room, index) => {
                                    request.post(`${config.apiEndpoint}/api/v4/logic/rates/${room.IdRoom}`)
                                        .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                                        .send({ CheckIn, CheckOut: this.state.CheckOut })
                                        .then((data) => {
                                            const { rooms } = this.state;
                                            rooms[index].RATE = data.body.rates;
                                            rooms[index].MIN_RATE = 0;
                                            rooms[index].SELECTED_ADULTS = changing_room.room.Adults;
                                            rooms[index].SELECTED_CHILDREN = changing_room.room.Children;
                                            rooms[index].SELECTED_CRIBS = changing_room.room.Cribs;
                                            rooms[index].RATE.forEach(rate => {
                                                if (rate.DAY_RATE) {
                                                    rooms[index].MIN_RATE += parseFloat((rate.DAY_RATE[current_array] || rate.DAY_RATE.items)[0].Adults);
                                                }
                                            });
                                            this.setState({ rooms });
                                        });
                                });
                            });
                        } else {
                            // TODO: show server error?
                            this.setState({ microTransac: false });
                        }
                    }, (error) => {
                        this.setState({ microTransac: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });

        });
    }

    doChange = () => {
        this.setState({ modifying: true });
        const { changing_room, book } = this.props, { mode } = this.state;

        let current_array = 'items';
        switch (mode) {
            case 1: current_array = 'items'; break;
            case 2: current_array = 'WALKIN'; break;
            case 3: current_array = 'COORP'; break;
            default: break;
        }

        const { room_to_add } = this.state;

        // RULE: at least one (1) adult per room
        if (!room_to_add.SELECTED_ADULTS) room_to_add.SELECTED_ADULTS = 1;
        //

        let ADULT_RATE = 0, CHILDREN_RATE = 0, CRIBS_RATE = 0;
        try {
            // Adding up adults                                        
            if (room_to_add.SELECTED_ADULTS) {
                ADULT_RATE = _.chain(room_to_add.RATE)
                    .map(day => {
                        return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_ADULTS) || { Adults: 0 }).Adults);
                    })
                    .reduce((a, b) => a + b, 0)
                    .value();
            }
            //

            // Adding up children                                        
            if (room_to_add.SELECTED_CHILDREN) {
                CHILDREN_RATE = _.chain(room_to_add.RATE)
                    .map(day => {
                        return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_CHILDREN) || { Children: 0 }).Children);
                    })
                    .reduce((a, b) => a + b, 0)
                    .value();
            }
            //

            // Adding up cribs                                        
            if (room_to_add.SELECTED_CRIBS) {
                CRIBS_RATE = _.chain(room_to_add.RATE)
                    .map(day => {
                        return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_CRIBS) || { Cribs: 0 }).Cribs);
                    })
                    .reduce((a, b) => a + b, 0)
                    .value();
            }
            //
        } catch (e) { }

        room_to_add.ADULT_RATE = ADULT_RATE; // * room_to_add.SELECTED_QUANTITY;
        room_to_add.CHILDREN_RATE = CHILDREN_RATE; // * room_to_add.SELECTED_QUANTITY;
        room_to_add.CRIBS_RATE = CRIBS_RATE; // * room_to_add.SELECTED_QUANTITY;
        room_to_add.CALCULATED_RATE = ADULT_RATE + CHILDREN_RATE + CRIBS_RATE;

        // Call API
        request.patch(`${config.apiEndpoint}/api/v4/bookings/room`)
            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
            .send({
                IdBook: book.IdBook,
                Room: changing_room.room,
                NewRoom: room_to_add
            })
            .then((data) => {

                this.setState({ modifying: false, bootstrapped: false, rooms: [], confirm: false });
                this.props.onDone && this.props.onDone();

            }, (error) => {
                this.setState({ microTransac: false, error: 'Something wrong' });
                //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
            });

    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Tipo <i className={`icon-lb_sort ${sort === 'Name' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('Name')} /></th>
                <th className="bg-gray-2 white">Desde <i className={`icon-lb_sort ${sort === 'IdBooking' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('IdBooking')} /></th>
                <th className="bg-gray-2 white">Disp. <i className={`icon-lb_sort ${sort === 'Rooms' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('Rooms')} /></th>
                <th className="bg-gray-2 white text-center">Adultos </th>
                <th className="bg-gray-2 white text-center">Niños   </th>
                <th className="bg-gray-2 white text-center">Infantes</th>
                <th className="bg-gray-2 white text-center">Cama<br />extra</th>
                {/*<th className="bg-gray-2 white text-center">Cantidad</th>*/}
                <th className="bg-gray-2 white text-right"></th>
            </tr>
        </thead>
    }

    renderAvailability = () => {
        const { bootstrapped, rooms, mode } = this.state;
        let current_array = 'items';
        switch (mode) {
            case 1: current_array = 'items'; break;
            case 2: current_array = 'WALKIN'; break;
            case 3: current_array = 'COORP'; break;
            default: break;
        }

        return <Animated animationInDelay={200} animationIn="fadeInRight" className="mt-4" style={{ zIndex: 0, position: 'relative' }}>
            <hr />
            <h4 className="white poppins ms-4"><i className="far fa-calendar blue me-2" />Disponibilidad</h4>
            <div className="w-100 bg-gray-2 px-3 py-2 rounded d-flex">
                <table className="w-100 table m-0 poppins bg-gray-2 white">
                    {this.renderTH()}
                    {bootstrapped && rooms && rooms.length > 0 && <tbody>
                        {rooms.map((room, i) => <tr key={room.IdRoom}>
                            <td className="bg-gray-2 white">{room.GivenName}</td>
                            <td className={`bg-gray-2 ${room.MIN_RATE ? 'white' : 'orange'}`}>{room.RATE ? `USD ${room.MIN_RATE}` : 'Calculating...'}</td>
                            <td className={`bg-gray-2 ${room.BOOKABLE ? 'white' : 'red'}`}>{room.BOOKABLE}/{room.Availability}</td>
                            <td className="bg-gray-2 white">
                                <div className={`black-input mx-auto d-flex`} style={{ minWidth: 80, maxWidth: 80 }}>
                                    <input defaultValue={room.SELECTED_ADULTS} onChange={e => {
                                        const { rooms } = this.state;
                                        rooms[i].SELECTED_ADULTS = parseInt(e.target.value, 10) || 0;
                                        this.setState({ rooms });
                                    }} className="px-3 py-1 white w-100" type="text" placeholder="0" />
                                </div>
                            </td>
                            <td className="bg-gray-2 white">
                                <div className={`black-input mx-auto d-flex`} style={{ minWidth: 80, maxWidth: 80 }}>
                                    <input defaultValue={room.SELECTED_CHILDREN} onChange={e => {
                                        const { rooms } = this.state;
                                        rooms[i].SELECTED_CHILDREN = parseInt(e.target.value, 10) || 0;
                                        this.setState({ rooms });
                                    }} className="px-3 py-1 white w-100" type="text" placeholder="0" />
                                </div>
                            </td>
                            <td className="bg-gray-2 white">
                                <div className={`black-input mx-auto d-flex`} style={{ minWidth: 80, maxWidth: 80 }}>
                                    <input defaultValue={room.SELECTED_CRIBS} onChange={e => {
                                        const { rooms } = this.state;
                                        rooms[i].SELECTED_CRIBS = parseInt(e.target.value, 10) || 0;
                                        this.setState({ rooms });
                                    }} className="px-3 py-1 white w-100" type="text" placeholder="0" />
                                </div>
                            </td>
                            <td className="bg-gray-2 white text-center">
                                <i className="far fa-square gray font-16" />
                            </td>
                            {/*<td className="bg-gray-2 white">
                                <div className={`black-input mx-auto d-flex`} style={{ minWidth: 80, maxWidth: 80 }}>
                                    <input onChange={e => {
                                        const { rooms } = this.state;
                                        rooms[i].SELECTED_QUANTITY = parseInt(e.target.value, 10) || 0;
                                        this.setState({ rooms });
                                    }} className="px-3 py-1 white w-100" type="text" placeholder="0" />
                                </div>
                                </td>*/}
                            <td className="bg-gray-2 white font-8 text-right">
                                {room.BOOKABLE > 0 && room.MIN_RATE >= 0 && <button onClick={() => {
                                    this.setState({ confirm: true, room_to_add: room });
                                    /*const { cart, rooms } = this.state;
                                    let room_to_add = { ...rooms[i] };

                                    // RULE: at least one (1) adult per room
                                    if (!room_to_add.SELECTED_ADULTS) room_to_add.SELECTED_ADULTS = 1;
                                    //

                                    let ADULT_RATE = 0, CHILDREN_RATE = 0, CRIBS_RATE = 0;
                                    try {
                                        // Adding up adults                                        
                                        if (room_to_add.SELECTED_ADULTS) {
                                            ADULT_RATE = _.chain(room_to_add.RATE)
                                                .map(day => {
                                                    return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_ADULTS) || { Adults: 0 }).Adults);
                                                })
                                                .reduce((a, b) => a + b, 0)
                                                .value();
                                        }
                                        //

                                        // Adding up children                                        
                                        if (room_to_add.SELECTED_CHILDREN) {
                                            CHILDREN_RATE = _.chain(room_to_add.RATE)
                                                .map(day => {
                                                    return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_CHILDREN) || { Children: 0 }).Children);
                                                })
                                                .reduce((a, b) => a + b, 0)
                                                .value();
                                        }
                                        //

                                        // Adding up cribs                                        
                                        if (room_to_add.SELECTED_CRIBS) {
                                            CRIBS_RATE = _.chain(room_to_add.RATE)
                                                .map(day => {
                                                    return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_CRIBS) || { Cribs: 0 }).Cribs);
                                                })
                                                .reduce((a, b) => a + b, 0)
                                                .value();
                                        }
                                        //
                                    } catch (e) { }

                                    room_to_add.ADULT_RATE = ADULT_RATE; // * room_to_add.SELECTED_QUANTITY;
                                    room_to_add.CHILDREN_RATE = CHILDREN_RATE; // * room_to_add.SELECTED_QUANTITY;
                                    room_to_add.CRIBS_RATE = CRIBS_RATE; // * room_to_add.SELECTED_QUANTITY;
                                    room_to_add.CALCULATED_RATE = ADULT_RATE + CHILDREN_RATE + CRIBS_RATE;

                                    for (let j = 1; j <= parseInt({ ...room_to_add }.SELECTED_QUANTITY || 1, 10); j++) {
                                        cart.push({ ...room_to_add, SELECTED_QUANTITY: 1, local_id: generateRandomId(5) });
                                    }
                                    this.setState({ cart });*/

                                }} className="btn btn-blue btn-sm white">Seleccionar</button>}
                            </td>
                        </tr>)}
                    </tbody>}
                    {!bootstrapped && <tbody>
                        <tr>
                            <td colSpan={9} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">Filtra tu búsqueda para<br />ver la disponibilidad.</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>}
                    {bootstrapped && rooms && rooms.length === 0 && <tbody>
                        <tr>
                            <td colSpan={9} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">Lo sentimos, no encontramos habitaciones disponibles para las fechas seleccionadas.</h3>
                                </div>
                            </td>
                        </tr></tbody>}
                </table>
            </div>
        </Animated>
    }

    render() {
        const { changing_room, book } = this.props, { filters, sources, validations, mode } = this.state;;
        return <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, left: 100, right: 0, overflowY: 'auto' }} className="bg-gray-2">
            {changing_room && book && <section>
                <div className="d-flex mx-4 mt-4">
                    <span className="poppins white font-16">Modifique una habitación de tipo {changing_room ? changing_room.room.GivenName : ''}</span>
                    <button onClick={this.props.onCancel} className="btn btn-link rounded ms-auto"><i className="fas fa-times-circle gray font-18" /></button>
                </div>
                <hr />
                <div className="w-100 d-flex flex-wrap mt-4 px-4">
                    {/* Start */}
                    <div style={{ width: 150 }}>
                        <label className="white poppins font-12">Check-in</label>
                        <div id="txtCheckIn">
                            <DatePicker highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" hint="Check-In" />} selected={moment(book.CheckIn)} showYearDropdown scrollableYearDropdown
                                yearDropdownItemNumber={15} onChange={date => {
                                    this.setState({ CheckIn: moment(date).utc() });
                                }} className={`${validations.txtCheckIn ? 'error-input' : ''} white font-12`}
                            />
                            {/*<Popover placement="right" isOpen={validations.txtCheckIn ? true : false} target={`txtCheckIn`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtCheckIn = !validations.txtCheckIn;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCheckIn}</span>
                                </PopoverBody>
                        </Popover>*/}
                        </div>
                    </div>
                    <div className="p-1" />
                    {/* Nights */}
                    <div style={{ width: 150 }}>
                        <label className="white poppins font-12">Noches</label>
                        <div id="txtNights" className={`black-input d-flex mt-2`}>
                            <input defaultValue={moment(book.CheckOut).diff(moment(book.CheckIn), 'days') + 1} className="w-100 p-3 white" type="text" name="nights" placeholder="Noches" ref={(input) => this.txtNights = input} />
                        </div>
                    </div>
                    <div className="p-1" />
                    <button onClick={this.onSearch} className="btn btn-lg btn-blue align-self-end d-flex">
                        <i className={`fas fa-${this.state.microTransac ? 'clock fa-spin' : 'search'} white font-18 my-2 align-self-center`} />
                    </button>

                    {/* Rate type */}
                    <div className="ms-auto" style={{ width: 200 }}>
                        <label className="white poppins font-12">Tipo</label>
                        <div id="txtType" className={`black-input  d-flex mt-2`}>
                            <Dropdown isOpen={this.state.pickType} toggle={() => this.setState({ pickType: !this.state.pickType })} className="w-100">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                    <span className="align-self-center">{(_.find(config.rate_types, t => t.id === mode)).name}</span>
                                    <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {config.rate_types.map((c, i) => <DropdownItem key={i} onClick={() => {
                                        this.setState({ mode: c.id }, () => {
                                            localStorage.setItem('lb.rt', c.id);
                                            this.onSearch();
                                        });
                                    }}>{c.name}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {this.renderAvailability()}
            </section>}

            {/* Confirm */}
            <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                <BlackOverlay />
                <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                    <div className="black-input bg-gray-2 p-4 align-self-center">
                        <h3 className="white poppins text-center">Confirmar operación</h3>
                        <h6 className="red text-center poppins">Modioficar Reserva</h6>
                        <hr className="w-50 mx-auto" />
                        <h6 className="white text-center poppins" style={{ maxWidth: 400 }}>¿Está seguro de modificar esta reserva? Las tarifas seran re-calculadas. Las piezas asignadas con anterioridad seran liberadas. Esta operación no se puede revertir</h6>
                        <div className="d-flex justify-content-center mt-4">
                            {!this.state.modifying && <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                <i className="fas fa-times white font-12" />
                            </button>}
                            <button onClick={this.doChange} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className={`fas fa-${this.state.modifying ? 'cog fa-spin' : 'check'} white font-12`} />
                            </button>
                        </div>
                    </div>
                </div>
            </Animated>
        </div>
    }
}

export default Modify;