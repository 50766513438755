import React from 'react';
import moment from 'moment';

class CustomDatePicker extends React.Component {

    render() {
        const { value, className, hint, format = 'MM/DD/YYYY', clear } = this.props;        
        return (
            <div className={`black-input ${className} d-flex mt-2`} onClick={this.props.onClick}>
                <span className={`p-3 w-100 ${value ? 'white' : 'whiteish'}`} >{value ? moment(value).locale('es').format(format) : hint}</span>
                {value && clear && <i onClick={(e) => {
                    e.stopPropagation && e.stopPropagation();
                    this.props.clear && this.props.clear();
                }} className="ms-auto me-3 align-self-center gray fas fa-times" />}
                {(!value || !clear) && <i className="ms-auto me-3 align-self-center gray far fa-calendar" />}
            </div>
        )
    }
}

export default CustomDatePicker;
