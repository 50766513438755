import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { getReadableFileSizeString, getBase64 } from '../../../helpers';
import { Popover, PopoverBody, } from 'reactstrap';
import _, { slice } from 'lodash';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import request from 'superagent';
import config from '../../../config';

const baseStyle = {
    flex: 1,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 10,
    borderColor: '#343f4b',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onDrop && props.onDrop(file);
            props.drop && props.drop(file);
        });

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'image/*', onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container" style={{ position: 'relative' }}>

            <div {...getRootProps({ style })} className="pointer bg-gray-dark">
                {/*<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    className=" d-flex flex-row justify-content-center w-100 h-100">
                    <div style={{ width: 420, height: 420, borderRadius: 210, backgroundColor: '#e5e5e5', opacity: 0.3 }} className="align-self-center" />
    </div>*/}
                <input {...getInputProps()} />
                <div style={{ height: 50 }} />
                <i className="fas fa-upload white font-60" />
                <div style={{ height: 20 }} />
                <p className="text-center font-18">Arrastrar y soltar archivos, o <span className="blue">buscar</span></p>
                <p className="text-center font-14 gray">Soporta: JPEG, PNG, PDF. • Max: 2Mb c/u.</p>
                {/*<div style={{ border: '3px solid #2cad3c', borderRadius: 20, width: 200 }} className="p-2 text-center mt-4"><span className="green">UPLOAD VIDEOS</span></div>*/}
                <div style={{ height: 50 }} />
            </div>

        </div>
    );
}

class Documents extends React.Component {
    state = {
        files: []
    }

    /*
    files: _.chain(staff.Attachments || []).map((file, i) => {
                return { file: { name: file.Name || `File #${i + 1}`, IdAlbum: file.IdAlbum, URL: file.URL, size: file.FileSize || file.Size, type: file.MimeType }, eye: false }
            }).value()
    */
    componentWillMount = () => {
        const { book } = this.props;
        this.setState({
            files: _.chain(book.Attachments || []).map((file, i) => {
                return { file: { name: file.Name || `File #${i + 1}`, IdAlbum: file.IdAlbum, URL: file.URL, size: file.FileSize || file.Size, type: file.MimeType }, eye: false, valid: true }
            }).value()
        });
    }
    componentWillReceiveProps = nextProps => {
        const { book } = nextProps;
        this.setState({
            files: _.chain(book.Attachments || []).map((file, i) => {
                return { file: { name: file.Name || `File #${i + 1}`, IdAlbum: file.IdAlbum, URL: file.URL, size: file.FileSize || file.Size, type: file.MimeType }, eye: false, valid: true }
            }).value()
        });
    }
    onDeleteImage = (IdAlbum) => {
        request.delete(`${config.apiEndpoint}/api/v4/bookings/document/${IdAlbum}`)
            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
            .send({}).then(() => { });
    }
    onDrop = (file) => {

        // application/pdf -> image/png -> image/jpeg        
        const { files } = this.state;

        let valid = true;
        if (file.size > 1000000 * 5) valid = false;
        if (['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) === -1) valid = false;

        files.push({ file, eye: false, valid });
        this.setState({ files });

        if (valid)
            getBase64(file).then(data => {
                request.post(`${config.apiEndpoint}/api/v4/bookings/document/${this.props.params.id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({ data, ..._.pick(file, 'size', 'type', 'name') })
                    .then(() => {
                        this.props.booking_actions && this.props.booking_actions.fetch_one(this.props.params.id);
                    });
            });
    }
    render() {
        const { files } = this.state;
        return <section>

            <section className="mt-2">
                <div className="d-flex w3-content justify-content-center">
                    <div className="w-100">
                        <StyledDropzone onDrop={this.onDrop} />
                    </div>
                    {files.length > 0 && <div className="w-100">
                        {/* Files */}
                        {files.map((file, i) => {

                            let valid = file.valid;

                            return <div key={i} className="p-3 bg-gray-dark d-flex mt-1" style={{
                                borderWidth: 2,
                                borderRadius: 10,
                                borderColor: valid ? '#343f4b' : 'red',
                                borderStyle: 'dashed'
                            }}>

                                <span className="align-self-center font-10 white">{file.file.name} (<span className={`${valid ? 'white' : 'red'}`}>{file.file.type}-{getReadableFileSizeString(file.file.size)}</span>)</span>

                                <div className="ms-auto d-flex">
                                    {/* Image */}
                                    {file.file.type && file.file.type.indexOf('image') !== -1 && <i onClick={() => {
                                        const { files } = this.state;
                                        files[i].eye = !files[i].eye;
                                        this.setState({ files });
                                    }} id={`file_${i}`} className="align-self-center far fa-eye blue" />}

                                    {/* Non-Image */}
                                    {file.file.type && file.file.type.indexOf('image') === -1 && file.file.IdAlbum && <a className="align-self-center" href={file.file.URL} target="_blank"><i onClick={() => {
                                        const { files } = this.state;
                                        files[i].eye = !files[i].eye;
                                        this.setState({ files });
                                    }} id={`file_${i}`} className="fas fa-download blue" /></a>}

                                    {/* Delete */}
                                    {(!file.valid || file.file.IdAlbum) && <i className={`fas align-self-center fa-trash ${file.file.IdAlbum ? 'gray' : 'gray'} pointer ms-2`} onClick={() => {
                                        const { files } = this.state;
                                        if (file.file.IdAlbum) { this.onDeleteImage(file.file.IdAlbum); }
                                        this.setState({ files: [...files.slice(0, i), ...files.slice(i + 1)] });
                                    }} />}

                                    {/* Popover Image */}
                                    {file.file.type && file.file.type.indexOf('image') !== -1 && <Popover placement="bottom" isOpen={file.eye ? true : false} target={`file_${i}`} toggle={() => {
                                        const { files } = this.state;
                                        files[i].eye = !files[i].eye;
                                        this.setState({ files });
                                    }} className="bg-gray-2">
                                        <PopoverBody className="d-flex flex-row justify-content-center">
                                            {file.file.type && file.file.type.indexOf('image') !== -1 && <img src={file.file.IdAlbum ? file.file.URL : URL.createObjectURL(file.file)} style={{ maxHeight: 200 }} />}
                                        </PopoverBody>
                                    </Popover>}
                                </div>

                            </div>
                        })}

                    </div>}
                </div>
            </section>

        </section>
    }
}

export default Documents;