function occupancy_summary_Reducer(state = [], action) {
    switch (action.type) {
        case 'OCCUPANCY/SUMMARY/SET':            
            return action.occupancy_summary;
        case 'OCCUPANCY/SUMMARY/CLEAR':
            return null;
        default:
            return state;
    }
}

export default occupancy_summary_Reducer;
