import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as accounting_actions from '../redux-sagas/actions/accounting.js';
import * as booking_actions from '../redux-sagas/actions/bookings.js';
import * as coorps_actions from '../redux-sagas/actions/coorps.js';
import * as floors_actions from '../redux-sagas/actions/floors.js';
import * as form_actions from '../redux-sagas/actions/form';
import * as guests_actions from '../redux-sagas/actions/guests.js';
import * as logic_actions from '../redux-sagas/actions/logic.js';
import * as offers_actions from '../redux-sagas/actions/offers.js';
import * as periods_actions from '../redux-sagas/actions/periods.js';
import * as plans_actions from '../redux-sagas/actions/plans.js';
import * as properties_actions from '../redux-sagas/actions/properties';
import * as rooms_actions from '../redux-sagas/actions/rooms';
import * as staffs_actions from '../redux-sagas/actions/staffs.js';

import Main from '../components/layouts/base.claire.js';

function mapStateToProps(state) {
    return { ...state }
}

function mapDispatchToProps(dispatch) {
    return {
        form_actions: bindActionCreators(form_actions, dispatch),   
        properties_actions: bindActionCreators(properties_actions, dispatch),
        rooms_actions: bindActionCreators(rooms_actions, dispatch),
        periods_actions: bindActionCreators(periods_actions, dispatch),
        offers_actions: bindActionCreators(offers_actions, dispatch),
        plans_actions: bindActionCreators(plans_actions, dispatch),
        staffs_actions: bindActionCreators(staffs_actions, dispatch),
        booking_actions: bindActionCreators(booking_actions, dispatch),
        floors_actions: bindActionCreators(floors_actions, dispatch),
        accounting_actions: bindActionCreators(accounting_actions, dispatch),
        guests_actions: bindActionCreators(guests_actions, dispatch),
        logic_actions: bindActionCreators(logic_actions, dispatch),
        coorps_actions: bindActionCreators(coorps_actions, dispatch),
    }
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
