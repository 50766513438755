function offers_Reducer(state = [], action) {
    switch (action.type) {
        case 'OFFERS/SET':            
            return action.offers;
        case 'OFFERS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default offers_Reducer;
