import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router';
import Layout from '../layouts/claire';
import { generateRandomId, getBase64, getReadableFileSizeString } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import BlackOverlay from '../partials/black_overlay';
import moment from 'moment';
import fakeAuth from '../../fakeAuth';
import _, { slice } from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LineLoader from '../partials/line_loader';
import MaskedInput from 'react-text-mask';
import request from 'superagent';
import config from '../../config';

const baseStyle = {
    flex: 1,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 10,
    borderColor: '#343f4b',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onDrop && props.onDrop(file);
            props.drop && props.drop(file);
        });

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'image/*', onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container" style={{ position: 'relative' }}>

            <div {...getRootProps({ style })} className="pointer bg-gray-dark">
                {/*<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    className=" d-flex flex-row justify-content-center w-100 h-100">
                    <div style={{ width: 420, height: 420, borderRadius: 210, backgroundColor: '#e5e5e5', opacity: 0.3 }} className="align-self-center" />
    </div>*/}
                <input {...getInputProps()} />
                <div style={{ height: 50 }} />
                <i className="fas fa-upload white font-60" />
                <div style={{ height: 20 }} />
                <p className="text-center font-18">Arrastrar y soltar archivos, o <span className="blue">buscar</span></p>
                <p className="text-center font-14 gray">Soporta: JPEG, PNG. • Max: 2Mb c/u.</p>
                {/*<div style={{ border: '3px solid #2cad3c', borderRadius: 20, width: 200 }} className="p-2 text-center mt-4"><span className="green">UPLOAD VIDEOS</span></div>*/}
                <div style={{ height: 50 }} />
            </div>

        </div>
    );
}

class AddStaff extends React.Component {
    state = {
        files: [],
        validations: {},
        menu_toggled: false,
        staff: {
            IdProperty: this.props.params.id,
            PhoneCountryCode: '+58',
            CellphoneCountryCode: '+58',
        },
        shifts: config.shifts
    }

    // lifecycle
    componentWillMount() {        
        this.props.form_actions.fetch_countries();
    }
    componentWillReceiveProps = nextProps => {

    }
    componentWillUnmount = () => {
        //this.props.properties_actions && this.props.properties_actions.clean();
    }
    //

    onLogo = e => {
        const { staff } = this.state, { } = this.props;
        getBase64(e.target.files[0]).then(data => {
            staff.LogoUrl = data;
            this.setState({ staff, doUpdateLogo: true });
        });
    }

    doSave = () => {
        const { staff, validations } = this.state;
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
            }, () => {

                // Call API
                request.post(`${config.apiEndpoint}/api/v4/staff`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({
                        FirstName: this.txtFirstName.value,
                        LastName: this.txtLastName.value,
                        Email: this.txtEmail.value,
                        Phone: this.txtPhone.value,
                        Cellphone: this.txtCellphone.value,
                        DOB: ReactDOM.findDOMNode(this.txtDOB).value,
                        IdOrPassport: this.txtIdOrPassport.value,
                        City: this.txtCity.value,
                        IdAccount: fakeAuth.getUser().IdAccount,
                        Address: this.txtAddress.value,
                        CarPlate: this.txtPlate.value,
                        Profession: this.txtProfession.value,
                        Logo: this.state.doUpdateLogo ? staff.LogoUrl : null,
                        ..._.pick(staff, 'IdProperty', 'PhoneCountryCode', 'CellphoneCountryCode',
                            'IdCountry', 'IdState', 'IdNationality', 'JobTitle', 'Shift', 'IdLogo')
                    })
                    .then((data) => {

                        if (data.body.success) {
                            staff.IdStaff = data.body.IdStaff || staff.IdStaff;
                            this.state.files.forEach(f => {
                                if (!f.file.IdAlbum) {
                                    let valid = true;
                                    if (f.file.size > 1000000 * 5) valid = false;
                                    if (['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(f.file.type) === -1) valid = false;
                                    if (valid)
                                        getBase64(f.file).then(data => {
                                            request.post(`${config.apiEndpoint}/api/v4/staff/image/${staff.IdStaff}`)
                                                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                                                .send({ data, ..._.pick(f.file, 'size', 'type', 'name') })
                                                .then();
                                        });
                                }
                            });

                            this.props.router.goBack();
                        } else
                            this.setState({ microTransac: false });

                    }, (error) => {
                        this.setState({ microTransac: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });

            });
        }
    }
    validate = (e) => {
        e && e.preventDefault && e.preventDefault();
        this.setState({ validations: {} }, () => {
            const { validations, staff } = this.state;

            if (!this.txtFirstName.value) {
                validations.txtFirstName = 'Por favor, introduce el nombre del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtLastName.value) {
                validations.txtLastName = 'Por favor, introduce el apellido del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtEmail.value) {
                validations.txtEmail = 'Por favor, introduce el correo electrónico del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!ReactDOM.findDOMNode(this.txtDOB).value) {
                validations.txtDOB = 'Por favor, introduce la fecha de nacimiento del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!moment(ReactDOM.findDOMNode(this.txtDOB).value, 'DD/MM/YYYY').isValid()) {
                validations.txtDOB = 'Por favor, introduzca una fecha valida siguiendo el formato DD/MM/YYYY';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtIdOrPassport.value) {
                validations.txtIdOrPassport = 'Por favor, introduce el documento de identificación del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!staff.IdCountry) {
                validations.txtCountry = 'Por favor, selecciona el pais de residencia del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!staff.IdState) {
                validations.txtState = 'Por favor, selecciona el estado de residencia del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtCity.value) {
                validations.txtCity = 'Por favor, introduce la ciudad de residencia del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtCity.value) {
                validations.txtCity = 'Por favor, introduce la ciudad de residencia del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtAddress.value) {
                validations.txtAddress = 'Por favor, introduce la direccion del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!staff.IdNationality) {
                validations.txtNationality = 'Por favor, selecciona la nacionalidad del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!staff.JobTitle) {
                validations.txtJobTitle = 'Por favor, selecciona el cargo del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!staff.Shift) {
                validations.txtShift = 'Por favor, selecciona el turno del empleado';
                this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }

            // Check if the email is not already used
            request.post(`${config.apiEndpoint}/api/v4/membership/check_email`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .send({ Email: this.txtEmail.value })
                .then((data) => {                    
                    if (data.body.available) {
                        this.setState({ confirm: true });
                    } else {
                        validations.txtEmail = 'Esta dirección de correo no esta disponible en el sistema. Quizas ya existe un usuario ocupandola.';
                        this.setState({ validations, microTransac: false, confirm: false }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                        return;
                    }
                });
        });
    }
    onDrop = (file) => {

        // application/pdf -> image/png -> image/jpeg        
        const { files } = this.state;
        files.push({ file, eye: false });
        this.setState({ files });

        /*getBase64(file).then(data => {
            request.post(`${config.apiEndpoint}/api/v4/rooms/image/we`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .send({ data })
                .then();
        });*/
    }
    onDeleteImage = (IdAlbum) => {
        request.delete(`${config.apiEndpoint}/api/v4/staff/image/${IdAlbum}`)
            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
            .send({}).then(() => { });
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_user white font-40 align-self-center" />
                <span className="white ms-2 font-20 poppins align-self-center">Nuevo empleado</span>
                <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>
            </div>
            <TopRightMenu {...this.props} />
        </div>
    }
    renderAttachments = () => {
        const { files } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Adjuntos</h4>
            <hr />
            <div className="d-flex w3-content justify-content-center">
                <div className="w-100">
                    <StyledDropzone onDrop={this.onDrop} />
                </div>
                {files.length > 0 && <div className="w-100">
                    {/* Files */}
                    {files.map((file, i) => {

                        let valid = true;
                        if (file.file.size > 1000000 * 5) valid = false;
                        if (['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.file.type) === -1) valid = false;

                        return <div key={i} className="p-3 bg-gray-dark d-flex mt-1" style={{
                            borderWidth: 2,
                            borderRadius: 10,
                            borderColor: file.file.size <= 1000000 * 2 ? '#343f4b' : 'red',
                            borderStyle: 'dashed'
                        }}>

                            <span className="align-self-center font-10 white">{file.file.name} (<span className={`${valid ? 'white' : 'red'}`}>{file.file.type}-{getReadableFileSizeString(file.file.size)}</span>)</span>

                            <div className="ms-auto d-flex">
                                {/* Image */}
                                {file.file.type && file.file.type.indexOf('image') !== -1 && <i onClick={() => {
                                    const { files } = this.state;
                                    files[i].eye = !files[i].eye;
                                    this.setState({ files });
                                }} id={`file_${i}`} className="align-self-center far fa-eye blue" />}

                                {/* Non-Image */}
                                {file.file.type && file.file.type.indexOf('image') === -1 && file.file.IdAlbum && <a className="align-self-center" href={file.file.URL} target="_blank"><i onClick={() => {
                                    const { files } = this.state;
                                    files[i].eye = !files[i].eye;
                                    this.setState({ files });
                                }} id={`file_${i}`} className="fas fa-download blue" /></a>}

                                {/* Delete */}
                                <i className={`fas align-self-center fa-trash ${file.file.IdAlbum ? 'gray' : 'gray'} pointer ms-2`} onClick={() => {
                                    const { files } = this.state;
                                    if (file.file.IdAlbum) { this.onDeleteImage(file.file.IdAlbum); }
                                    this.setState({ files: [...files.slice(0, i), ...files.slice(i + 1)] });
                                }} />

                                {/* Popover Image */}
                                {file.file.type && file.file.type.indexOf('image') !== -1 && <Popover placement="bottom" isOpen={file.eye ? true : false} target={`file_${i}`} toggle={() => {
                                    const { files } = this.state;
                                    files[i].eye = !files[i].eye;
                                    this.setState({ files });
                                }} className="bg-gray-2">
                                    <PopoverBody className="d-flex flex-row justify-content-center">
                                        {file.file.type && file.file.type.indexOf('image') !== -1 && <img src={file.file.IdAlbum ? file.file.URL : URL.createObjectURL(file.file)} style={{ maxHeight: 200 }} />}
                                    </PopoverBody>
                                </Popover>}
                            </div>

                        </div>
                    })}

                </div>}
            </div>
        </section>
    }
    renderBasic = () => {
        const { validations, staff, timezones, shifts } = this.state, { countries } = this.props;
        let selected_country = null;
        if (countries && countries.length && staff.IdCountry) {
            selected_country = _.find(countries, c => parseInt(c.id, 10) === staff.IdCountry);
        }
        let job_titles = config.job_titles;        

        return <section className="mt-4">
            <h4 className="white poppins">Información Básica</h4>
            <hr />
            <div className="w3-content d-flex">
                {/* Avatar */}
                <div className="me-4 mt-4">
                    <input type="file" id="fi" onChange={this.onLogo} className="hide" ref={i => this.avatarFU = i} />
                    <label htmlFor="fi" className="align-self-center">
                        {(staff.LogoUrl) && <div className="cover pointer bg-gray-2 mx-auto d-flex justify-content-center" style={{
                            width: 200, height: 200, borderRadius: '50%', border: '1px solid black',
                            background: `url(${staff.LogoUrl}) no-repeat center center white`,
                        }} />}
                        {!(staff.LogoUrl) && <div style={{ width: 200, height: 200, borderRadius: '50%', }} className="bg-gray-2 pointer mx-auto d-flex justify-content-center">
                            <i className="fas fa-camera font-60 gray align-self-center" />
                        </div>}
                    </label>
                    {/* Shift */}
                    <div className="mt-4" style={{ width: 200 }}>
                        <label className="white poppins font-12">Turno <i className="blue">*</i></label>
                        <div id="txtShift" className={`black-input ${validations.txtShift ? 'error-input' : ''} d-flex mt-2`}>
                            <Dropdown isOpen={this.state.pickShift} toggle={() => this.setState({ pickShift: !this.state.pickShift })} className="w-100">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                    <span className="align-self-center">{(_.find(shifts, t => t.value === staff.Shift) || { value: 'Turno' }).value}</span>
                                    <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {shifts && shifts.map((c, i) => <DropdownItem key={i} onClick={() => {
                                        const { staff } = this.state;
                                        staff.Shift = c.value;
                                        this.setState({ staff });
                                        const { validations } = this.state;
                                        validations.txtShift = false;
                                        this.setState({ validations });
                                    }}>{c.value}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <Popover placement="right" isOpen={validations.txtShift ? true : false} target={`txtShift`} toggle={() => {
                            const { validations } = this.state;
                            validations.txtShift = !validations.txtShift;
                            this.setState({ validations });
                        }} className="bg-gray-2">
                            <PopoverBody className="d-flex flex-row justify-content-center">
                                <i className="fas fa-times red font-8 align-self-center" />
                                <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtShift}</span>
                            </PopoverBody>
                        </Popover>
                    </div>
                </div>
                {/* Rest */}
                <div>
                    <div className="d-flex w3-content" ref={input => this.txtFirstNameWrapper = input}>
                        {/* First Name */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Nombre <i className="blue">*</i></label>
                            <div id="txtFirstName" className={`black-input ${validations.txtFirstName ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre del empleado" ref={(input) => this.txtFirstName = input} defaultValue={staff.FirstName} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtFirstName ? true : false} target={`txtFirstName`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtFirstName = !validations.txtFirstName;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtFirstName}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Last Name */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Apellido <i className="blue">*</i></label>
                            <div id="txtLastName" className={`black-input ${validations.txtLastName ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Apellido del empleado" ref={(input) => this.txtLastName = input} defaultValue={staff.LastName} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtLastName ? true : false} target={`txtLastName`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtLastName = !validations.txtLastName;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtLastName}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Email */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Correo electrónico <i className="blue">*</i></label>
                            <div id="txtEmail" className={`black-input ${validations.txtEmail ? 'error-input' : ''} d-flex mt-2`}>
                                <i className="fas fa-at font-16 white align-self-center ps-3" />
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Correo del empleado" ref={(input) => this.txtEmail = input} defaultValue={staff.Email} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtEmail ? true : false} target={`txtEmail`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtEmail = !validations.txtEmail;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtEmail}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                    </div>
                    <div className="d-flex w3-content">
                        {/* Phone */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Teléfono</label>
                            <div className="black-input d-flex mt-2">
                                <span className="align-self-center ms-3 white">+58</span>
                                <i className="fas fa-chevron-down font-16 white align-self-center px-3 " />
                                <input className="w-100 p-3 white" type="text" name="phone" placeholder="Teléfono" ref={(input) => this.txtPhone = input} defaultValue={staff.Phone} />
                            </div>
                        </div>
                        <div className="px-1" />
                        {/* Cellphone */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Celular</label>
                            <div className="black-input d-flex mt-2">
                                <span className="align-self-center ms-3 white">+58</span>
                                <i className="fas fa-chevron-down font-16 white align-self-center px-3 " />
                                <input className="w-100 p-3 white" type="text" name="phone" placeholder="Celular" ref={(input) => this.txtCellphone = input} defaultValue={staff.Cellphone} />
                            </div>
                        </div>
                        <div className="px-1" />
                        {/* DOB */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Fecha de nacimiento <i className="blue">*</i></label>
                            <div id="txtDOB" className={`black-input ${validations.txtDOB ? 'error-input' : ''} d-flex mt-2`}>
                                <i className="fas fa-calendar font-16 white align-self-center ps-3" />
                                <MaskedInput guide={true} ref={(i) => this.txtDOB = i} className="w-100 p-3 white" placeholder="DD/MM/YYYY" defaultValue={staff.DOB}
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtDOB ? true : false} target={`txtDOB`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtDOB = !validations.txtDOB;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtDOB}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                    </div>
                    <div className="d-flex w3-content">
                        {/* Id Or Passport */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Cédula o pasaporte <i className="blue">*</i></label>
                            <div id="txtIdOrPassport" className={`black-input ${validations.txtIdOrPassport ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Cédula o pasaporte" ref={(input) => this.txtIdOrPassport = input} defaultValue={staff.IdOrPassport} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtIdOrPassport ? true : false} target={`txtIdOrPassport`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtIdOrPassport = !validations.txtIdOrPassport;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtIdOrPassport}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Country */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Pais <i className="blue">*</i></label>
                            <div id="txtCountry" className={`black-input ${validations.txtCountry ? 'error-input' : ''} d-flex mt-2`}>
                                <Dropdown isOpen={this.state.pickCountry} toggle={() => this.setState({ pickCountry: !this.state.pickCountry })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{(_.find(countries, t => parseInt(t.id, 10) === staff.IdCountry) || { name: 'Pais' }).name}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {countries && countries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                            const { staff } = this.state;
                                            staff.IdCountry = parseInt(c.id, 10);
                                            this.setState({ staff });
                                            const { validations } = this.state;
                                            validations.txtCountry = false;
                                            this.setState({ validations });
                                        }}>{c.name}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Popover placement="right" isOpen={validations.txtCountry ? true : false} target={`txtCountry`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtCountry = !validations.txtCountry;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCountry}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        {selected_country && <div className="px-1" />}
                        {/* State */}
                        {selected_country && <div className="w-100 mt-4 me-1">
                            <label className="white poppins font-12">Estado <i className="blue">*</i></label>
                            <div id="txtState" className={`black-input ${validations.txtState ? 'error-input' : ''} d-flex mt-2`}>
                                <Dropdown isOpen={this.state.pickState} toggle={() => this.setState({ pickState: !this.state.pickState })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{(_.find(selected_country.states, t => parseInt(t.id, 10) === staff.IdState) || { name: 'Estado' }).name}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {selected_country.states && selected_country.states.map((c, i) => <DropdownItem key={i} onClick={() => {
                                            const { staff } = this.state;
                                            staff.IdState = parseInt(c.id, 10);
                                            this.setState({ staff });
                                            const { validations } = this.state;
                                            validations.txtState = false;
                                            this.setState({ validations });
                                        }}>{c.name}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Popover placement="right" isOpen={validations.txtState ? true : false} target={`txtState`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtState = !validations.txtState;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtState}</span>
                                </PopoverBody>
                            </Popover>
                        </div>}
                    </div>
                    <div className="d-flex w3-content">
                        {/* City */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Ciudad <i className="blue">*</i></label>
                            <div id="txtCity" className={`black-input ${validations.txtCity ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Ciudad" ref={(input) => this.txtCity = input} defaultValue={staff.City} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtCity ? true : false} target={`txtCity`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtCity = !validations.txtCity;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCity}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Address */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Dirección <i className="blue">*</i></label>
                            <div id="txtAddress" className={`black-input ${validations.txtAddress ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Direccion" ref={(input) => this.txtAddress = input} defaultValue={staff.Address} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtAddress ? true : false} target={`txtAddress`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtAddress = !validations.txtAddress;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtAddress}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Placa vehicular</label>
                            <div className="black-input d-flex mt-2">
                                <input className="w-100 p-3 white" type="text" name="plate" placeholder="Placa vehicular" ref={(input) => this.txtPlate = input} defaultValue={staff.CarPlate} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex w3-content">
                        {/* Country */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Nacionalidad <i className="blue">*</i></label>
                            <div id="txtNationality" className={`black-input ${validations.txtNationality ? 'error-input' : ''} d-flex mt-2`}>
                                <Dropdown isOpen={this.state.pickNationality} toggle={() => this.setState({ pickNationality: !this.state.pickNationality })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{(_.find(countries, t => parseInt(t.id, 10) === staff.IdNationality) || { name: 'Nacionalidad' }).name}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {countries && countries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                            const { staff } = this.state;
                                            staff.IdNationality = parseInt(c.id, 10);
                                            this.setState({ staff });
                                            const { validations } = this.state;
                                            validations.txtNationality = false;
                                            this.setState({ validations });
                                        }}>{c.name}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Popover placement="right" isOpen={validations.txtNationality ? true : false} target={`txtNationality`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtNationality = !validations.txtNationality;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtNationality}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Profesión</label>
                            <div className="black-input d-flex mt-2">
                                <input className="w-100 p-3 white" type="text" name="profession" placeholder="Profesión" ref={(input) => this.txtProfession = input} defaultValue={staff.Profession} />
                            </div>
                        </div>
                        <div className="px-1" />
                        {/* Cargo */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Cargo <i className="blue">*</i></label>
                            <div id="txtJobTitle" className={`black-input ${validations.txtJobTitle ? 'error-input' : ''} d-flex mt-2`}>
                                <Dropdown isOpen={this.state.pickJobTitle} toggle={() => this.setState({ pickJobTitle: !this.state.pickJobTitle })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{(_.find(job_titles, t => t.value === staff.JobTitle) || { value: 'Cargo' }).value}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {job_titles && job_titles.map((c, i) => <DropdownItem key={i} onClick={() => {
                                            const { staff } = this.state;
                                            staff.JobTitle = c.value;
                                            this.setState({ staff });
                                            const { validations } = this.state;
                                            validations.txtJobTitle = false;
                                            this.setState({ validations });
                                        }}>{c.value}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Popover placement="right" isOpen={validations.txtJobTitle ? true : false} target={`txtJobTitle`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtJobTitle = !validations.txtJobTitle;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtJobTitle}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }

    renderCommon = () => {
        return (
            <div>
                {this.renderHeader()}
                <section>
                    <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.toggle} animationOut="fadeOutRight" animationIn="fadeInRight" className="mt-4">
                        {this.renderBasic()}
                        {this.renderAttachments()}
                        {/* Buttons */}
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white align-self-center" >
                                <i className="icon-corner-down-left white font-12" />
                            </button>
                            <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className="far fa-save white font-12" />
                            </button>
                        </div>
                    </Animated>
                </section>
                <div style={{ height: 100 }} />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Nuevo empleado</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de crear este nuevo empleado?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doSave} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default AddStaff;
