import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import fakeAuth from '../../fakeAuth';


export function* fetch_properties() {
    yield* takeEvery("PROPERTIES/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ }) => {
                return request.get(`${config.apiEndpoint}/api/v4/properties`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { properties } = result;
                yield put({ type: 'PROPERTIES/SET', properties });
            } else {

                yield put({
                    type: 'PROPERTIES/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({ type: 'PROPERTIES/FETCH_FAILED', e });
        }
    });
}

export function* fetch_property() {
    yield* takeEvery("PROPERTY/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/properties/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { property } = result;
                yield put({ type: 'PROPERTY/SET', property });
            } else {
                yield put({
                    type: 'PROPERTY/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({ type: 'PROPERTY/FETCH_FAILED', e });

            if (e.status === 403) {
                // Forbidden
                yield put({ type: 'AUTH/LOGOUT' });
                fakeAuth.signout(() => { });
            }
        }
    });
}
