import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';


export function* fetch_staffs() {
    yield* takeEvery("STAFFS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/properties/staffs/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { staffs } = result;
                yield put({ type: 'STAFFS/SET', staffs });
            } else {
                yield put({
                    type: 'STAFFS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'STAFFS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_staff() {
    yield* takeEvery("STAFF/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/staff/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { staff } = result;                
                yield put({ type: 'STAFF/SET', staff });
            } else {
                yield put({
                    type: 'STAFF/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'STAFF/FETCH_FAILED',
                e
            });
        }
    });
}

export function* archive_staff() {
    yield* takeEvery("STAFF/ARCHIVE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/staff/archive/${id}`)
                    .send({})
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { IdProperty } = action
                yield put({ type: 'STAFFS/FETCH', id: IdProperty });
            } else {
                yield put({
                    type: 'STAFF/ARCHIVE/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'STAFF/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}

export function* toggle_staff() {
    yield* takeEvery("STAFF/TOGGLE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id, IsActive }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/staff/toggle/${id}`)
                    .send({ IsActive })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);            
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'STAFF/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}
