const fakeAuth = {
  getCredentials() {
    return {
      token: localStorage.getItem('legendbeds.authtoken'),
      isAdmin: localStorage.getItem('legendbeds.isAdmin') === 'true',
      email: localStorage.getItem('email'),
    }
  },
  isAdmin() {
    return localStorage.getItem('legendbeds.isAdmin') === 'true' ? true : false;
  },
  isAuthenticated() {
    return localStorage.getItem('legendbeds.isAuthenticated') || false;
  }, //|| false, // this should be persisted ?
  authenticate(cb, token, isAdmin, user, family) {
    localStorage.setItem('legendbeds.isAuthenticated', '1'); // put here the auth token ?
    localStorage.setItem('legendbeds.authtoken', token);
    localStorage.setItem('authtoken', token);
    localStorage.setItem('legendbeds.isAdmin', isAdmin);
    if (user)
      localStorage.setItem('legendbeds.user', JSON.stringify(user))
    if (family)
      localStorage.setItem('legendbeds.family', JSON.stringify(family))
    cb && cb();
  },
  signout(cb) {
    localStorage.removeItem('legendbeds.isAuthenticated');
    localStorage.removeItem('legendbeds.authtoken');
    localStorage.removeItem('authtoken');
    localStorage.removeItem('legendbeds.isAdmin');
    localStorage.removeItem('legendbeds.user');
    cb && cb();
  },
  getUser() {
    var s = localStorage.getItem('legendbeds.user');
    if (s) {
      return JSON.parse(s);
    } else {
      return null;
    }
  },  
}

export default fakeAuth;
