import React from 'react';
import ReactDOM from 'react-dom';
import Layout from '../layouts/claire';
import { getBase64 } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import BlackOverlay from '../partials/black_overlay';
import fakeAuth from '../../fakeAuth';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LineLoader from '../partials/line_loader';
import MaskedInput from 'react-text-mask';
import request from 'superagent';
import config from '../../config';

class AddProperty extends React.Component {

    state = {
        menu_toggled: false,
        validations: {},
        comodities: config.comodities,
        //editorState: EditorState.createEmpty(),
        editorMode: 1, // [1: es, 2: en]
        editorStage: 1, // [1: description, 2: rates, 3: terms]
        property: {
            PhoneCountryCode: '+58',
            /*Name: 'Midpark at Carrasquilla',
            City: 'Caracas',
            CheckIn: '13:00',
            CheckOut: '10:00',            
            Website: 'https://www.google.com',
            Phone: '6819109',
            TotalRooms: 101,*/
            TotalFloors: 1,
            Stars: 1,
            Comodities: [],
            /*CribsMaxAge: 2,
            ChildrenMaxAge: 12,*/
            IdAccount: fakeAuth.getUser().IdAccount,
            DaysWithoutCheckin: 0,
            RegimeType: 0,
            AdultsOnly: 0,
            /*DescriptionCollation: 'An Id',
            RatesCollation: 'An Id',
            TermsAndConditionsCollation: 'An Id',*/
            Description: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
            Rates: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
            TermsAndConditions: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
            Tax: 0
        },
        week_day_flags: config.week_day_flags,
        regime_types: config.regime_types,
        timezones: config.timezones
    }

    // lifecycle
    componentWillMount() {
        const { params } = this.props;
        this.props.form_actions.fetch_countries();
        if (params.id) {
            this.props.properties_actions && this.props.properties_actions.fetch_one(params.id);
        } else {
            this.setState({ bootstrapped: true }, () => { this.onLoadEditorState(1, 1); });
        }
    }
    componentWillReceiveProps = nextProps => {
        const { params } = this.props;
        if (params.id && !this.state.bootstrapped) {
            if (nextProps.property) this.setState({ property: nextProps.property }, () => { this.onLoadEditorState(1, 1); });
        } else {
            this.setState({ bootstrapped: true }, () => { this.onLoadEditorState(1, 1); });
        }
    }
    componentWillUnmount = () => {
        this.props.properties_actions && this.props.properties_actions.clean();
    }
    //

    onLoadEditorState = (mode, stage) => {
        const { property } = this.state;
        let html = '';
        switch (mode) {
            case 1: // Description
                html = stage === 1 ? property.Description.es.Value : property.Description.en.Value; break;
            case 2: // Rates
                html = stage === 1 ? property.Rates.es.Value : property.Rates.en.Value; break;
            case 3: // Terms
                html = stage === 1 ? property.TermsAndConditions.es.Value : property.TermsAndConditions.en.Value; break;
            default: break;
        }
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            this.setState({
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)),
                bootstrapped: true,
                editorMode: mode,
                editorStage: stage,
            });
        }
    }
    onEditorStateChange = (editorState) => this.setState({ editorState }, () => {
        const { property, editorMode, editorStage } = this.state;
        switch (editorMode) {
            case 1: // Description
                property.Description[editorStage === 1 ? 'es' : 'en'].Value = draftToHtml(convertToRaw(editorState.getCurrentContent())); break;
            case 2: // Rates
                property.Rates[editorStage === 1 ? 'es' : 'en'].Value = draftToHtml(convertToRaw(editorState.getCurrentContent())); break;
            case 3: // Terms
                property.TermsAndConditions[editorStage === 1 ? 'es' : 'en'].Value = draftToHtml(convertToRaw(editorState.getCurrentContent())); break;
            default: break;
        }
    });
    onLogo = e => {
        const { property } = this.state, { } = this.props;
        getBase64(e.target.files[0]).then(data => {
            property.LogoUrl = data;
            this.setState({ property, doUpdateLogo: true });
        });
    }
    onComodity = (com) => {
        const { property } = this.state;
        if (_.find(property.Comodities, c => c === com))
            property.Comodities = _.reject(property.Comodities, c => c === com);
        else property.Comodities.push(com);
        this.setState({ property });
    }

    // Saving
    doSave = () => {
        const { property } = this.state;
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
            }, () => {

                // Call API
                request[property.IdProperty ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/properties${property.IdProperty ? `/${property.IdProperty}` : ''}`)
                    .send({
                        Name: this.txtPropertyName.value,
                        City: this.txtCity.value,
                        CheckIn: ReactDOM.findDOMNode(this.txtCheckIn).value,
                        CheckOut: ReactDOM.findDOMNode(this.txtCheckOut).value,
                        PhoneCountryCode: '+58',
                        Website: this.txtWebsite.value,
                        GoogleMaps: this.txtGoogleMaps.value,
                        Phone: this.txtPhone.value,
                        TotalRooms: parseInt(this.txtRooms.value, 10),
                        TotalFloors: parseInt(this.txtFloors.value, 10),
                        CribsMaxAge: this.txtCribMaxAge.value,
                        ChildrenMaxAge: this.txtChildrenMaxAge.value,
                        IdAccount: fakeAuth.getUser().IdAccount,
                        Logo: this.state.doUpdateLogo ? property.LogoUrl : null,
                        IdLogo: property.IdLogo,
                        Tax: this.txtTaxes.value || 0,
                        ..._.pick(property, 'DescriptionCollation', 'RatesCollation', 'TermsAndConditionsCollation',
                            'Description', 'Rates', 'TermsAndConditions', 'RegimeType', 'AdultsOnly', 'DaysWithoutCheckin',
                            'Comodities', 'Stars', 'TimeZone', 'IdCountry', 'IdState')
                    })
                    .then((data) => {
                        if (data.body.success) {
                            this.props.router.goBack();
                        } else
                            this.setState({ microTransac: false });

                    }, (error) => {
                        this.setState({ microTransac: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });
        }
    }
    validate = (e) => {
        e && e.preventDefault && e.preventDefault();

        this.setState({ validations: {} }, () => {
            const { validations, property } = this.state;

            if (!this.txtPropertyName.value) {
                validations.txtPropertyName = 'Por favor, introduce el nombre de la propiedad';
                this.setState({ validations });
                return;
            }
            if (!property.IdCountry) {
                validations.txtCountry = 'Por favor, selecciona el pais de la propiedad';
                this.setState({ validations });
                return;
            }
            if (!property.IdState) {
                validations.txtState = 'Por favor, selecciona el estado donde se ubica la propiedad';
                this.setState({ validations });
                return;
            }
            if (!this.txtCity.value) {
                validations.txtCity = 'Por favor, selecciona la ciudad donde se ubica la propiedad';
                this.setState({ validations });
                return;
            }
            if (!ReactDOM.findDOMNode(this.txtCheckIn).value) {
                validations.txtCheckIn = 'Por favor, selecciona la hora de check-in';
                this.setState({ validations });
                return;
            }
            if (!ReactDOM.findDOMNode(this.txtCheckOut).value) {
                validations.txtCheckOut = 'Por favor, selecciona la hora de check-out';
                this.setState({ validations });
                return;
            }
            if (!this.txtPhone.value) {
                validations.txtPhone = 'Por favor, coloque un número de telefono';
                this.setState({ validations });
                return;
            }
            if (!this.txtRooms.value) {
                validations.txtRooms = 'Por favor, coloque la cantidad de habitaciones de la propiedad';
                this.setState({ validations });
                return;
            }
            if (!this.txtFloors.value) {
                validations.txtFloors = 'Por favor, coloque numero de pisos de la propiedad';
                this.setState({ validations });
                return;
            }
            if (!this.txtCribMaxAge.value) {
                validations.txtCribMaxAge = 'Por favor, coloque la edad maxima para infantes';
                this.setState({ validations });
                return;
            }
            if (!this.txtChildrenMaxAge.value) {
                validations.txtChildrenMaxAge = 'Por favor, coloque la edad maxima para niñ@s';
                this.setState({ validations });
                return;
            }
            if (this.txtTaxes.value === null || this.txtTaxes.value === undefined) {
                validations.txtTaxes = 'Por favor, coloque el valor general de impuesto';
                this.setState({ validations });
                return;
            }

            this.setState({ confirm: true });
        });
    }
    //

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex">
                <i className="icon-lb_suitcase white font-40 align-self-center" />
                <span className="white ms-2 font-20 poppins align-self-center">{this.props.params.id ? 'Editar' : 'Nueva'} propiedad</span>
                <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>
            </div>
            <div className="w-75 align-self-center d-flex">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderBasic = () => {
        const { validations, property, timezones } = this.state, { countries } = this.props;
        let selected_country = null;
        if (countries && countries.length && property.IdCountry) {
            selected_country = _.find(countries, c => parseInt(c.id, 10) === property.IdCountry);
        }
        return <section>
            <h4 className="white poppins">Información Básica</h4>
            <hr />
            {/* Logo */}
            <div className="d-flex justify-content-center" style={{ height: 200, position: 'relative' }}>
                <div className=" d-flex flex-row justify-content-center w-25 pointer" style={{ zIndex: 1, position: 'relative' }}>
                    <input type="file" id="fi" onChange={this.onLogo} className="hide" ref={i => this.avatarFU = i} />
                    <label htmlFor="fi" className="align-self-center">
                        {(property.LogoUrl) && <div className="contains pointer bg-gray-2 mx-auto d-flex justify-content-center" style={{
                            width: 200, height: 200, borderRadius: '50%', border: '1px solid black',
                            background: `url(${property.LogoUrl}) no-repeat center center white`,
                        }} />}
                        {!(property.LogoUrl) && <div style={{ width: 200, height: 200, borderRadius: 100 }} className="bg-gray-2 pointer mx-auto d-flex justify-content-center">
                            <i className="fas fa-camera font-60 gray align-self-center" />
                        </div>}
                    </label>
                </div>
            </div>

            <div className="d-flex w3-content">
                {/* Property Name */}
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Nombre <i className="blue">*</i></label>
                    <div id="txtPropertyName" className={`black-input ${validations.txtPropertyName ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre de la propiedad" ref={(input) => this.txtPropertyName = input} defaultValue={property.Name} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtPropertyName ? true : false} target={`txtPropertyName`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtPropertyName = !validations.txtPropertyName;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtPropertyName}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                {/* Country */}
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Pais <i className="blue">*</i></label>
                    <div id="txtCountry" className={`black-input ${validations.txtCountry ? 'error-input' : ''} d-flex mt-2`}>
                        <Dropdown isOpen={this.state.pickCountry} toggle={() => this.setState({ pickCountry: !this.state.pickCountry })} className="w-100">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                <span className="align-self-center">{(_.find(countries, t => parseInt(t.id, 10) === property.IdCountry) || { name: 'Pais' }).name}</span>
                                <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {countries && countries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                    const { property } = this.state;
                                    property.IdCountry = parseInt(c.id, 10);
                                    this.setState({ property });
                                    const { validations } = this.state;
                                    validations.txtCountry = false;
                                    this.setState({ validations });
                                }}>{c.name}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <Popover placement="right" isOpen={validations.txtCountry ? true : false} target={`txtCountry`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtCountry = !validations.txtCountry;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCountry}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                {/* State */}
                {selected_country && <div className="w-100 mt-4 me-1">
                    <label className="white poppins font-12">Estado <i className="blue">*</i></label>
                    <div id="txtState" className={`black-input ${validations.txtState ? 'error-input' : ''} d-flex mt-2`}>
                        <Dropdown isOpen={this.state.pickState} toggle={() => this.setState({ pickState: !this.state.pickState })} className="w-100">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                <span className="align-self-center">{(_.find(selected_country.states, t => parseInt(t.id, 10) === property.IdState) || { name: 'Estado' }).name}</span>
                                <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {selected_country.states && selected_country.states.map((c, i) => <DropdownItem key={i} onClick={() => {
                                    const { property } = this.state;
                                    property.IdState = parseInt(c.id, 10);
                                    this.setState({ property });
                                    const { validations } = this.state;
                                    validations.txtState = false;
                                    this.setState({ validations });
                                }}>{c.name}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <Popover placement="right" isOpen={validations.txtState ? true : false} target={`txtState`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtState = !validations.txtState;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtState}</span>
                        </PopoverBody>
                    </Popover>
                </div>}
                {/* City */}
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Ciudad <i className="blue">*</i></label>
                    <div id="txtCity" className={`black-input ${validations.txtCity ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="city" placeholder="Ciudad" ref={(input) => this.txtCity = input} defaultValue={property.City} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtCity ? true : false} target={`txtCity`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtCity = !validations.txtCity;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCity}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
            <div className="d-flex w3-content">
                {/* Check-in */}
                <div className="w-50 mt-4">
                    <label className="white poppins font-12">Check-in <i className="blue">*</i></label>
                    <div id="txtCheckIn" className={`black-input ${validations.txtCheckIn ? 'error-input' : ''} d-flex mt-2`}>
                        <i className="fas fa-clock font-16 white align-self-center px-3 " />
                        <MaskedInput guide={true} ref={(i) => this.txtCheckIn = i} className="w-100 p-3 white" placeholder="HH:mm" defaultValue={property.CheckIn}
                            mask={[/\d/, /\d/, ':', /\d/, /\d/]} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtCheckIn ? true : false} target={`txtCheckIn`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtCheckIn = !validations.txtCheckIn;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCheckIn}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                {/* Google Maps */}
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Dirección Google Maps</label>
                    <div className="black-input d-flex mt-2">
                        <i className="fas fa-clock font-16 white align-self-center px-3 " />
                        <input className="w-100 p-3 white" type="text" name="googlemaps" placeholder="Dirección Google Maps" ref={(input) => this.txtGoogleMaps = input} defaultValue={property.GoogleMaps} />
                    </div>
                </div>
                <div className="px-1" />
                {/* Website */}
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Sitio Web</label>
                    <div className="black-input d-flex mt-2">
                        <i className="fas fa-clock font-16 white align-self-center px-3 " />
                        <input className="w-100 p-3 white" type="text" name="googlemaps" placeholder="Sitio Web" ref={(input) => this.txtWebsite = input} defaultValue={property.Website} />
                    </div>
                </div>
            </div>
            <div className="d-flex w3-content">
                {/* CheckOut */}
                <div className="w-50 mt-4">
                    <label className="white poppins font-12">Check-out <i className="blue">*</i></label>
                    <div id="txtCheckOut" className={`black-input ${validations.txtCheckOut ? 'error-input' : ''} d-flex mt-2`}>
                        <i className="fas fa-clock font-16 white align-self-center px-3 " />
                        <MaskedInput guide={true} ref={(i) => this.txtCheckOut = i} className="w-100 p-3 white" placeholder="HH:mm" defaultValue={property.CheckOut}
                            mask={[/\d/, /\d/, ':', /\d/, /\d/]} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtCheckOut ? true : false} target={`txtCheckOut`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtCheckOut = !validations.txtCheckOut;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCheckOut}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                {/* Stars */}
                <div className="mt-4">
                    <label className="white poppins font-12">Rating</label>
                    <div className="black-input d-flex mt-2">
                        <button className="btn btn-link"><i className={`fas fa-star font-16 ${property.Stars > 0 ? 'yellow' : 'white'} align-self-center`} onClick={() => {
                            const { property } = this.state;
                            property.Stars = 1;
                            this.setState({ property });
                        }} /></button>
                        <button className="btn btn-link"><i className={`fas fa-star font-16 ${property.Stars > 1 ? 'yellow' : 'white'} align-self-center`} onClick={() => {
                            const { property } = this.state;
                            property.Stars = 2;
                            this.setState({ property });
                        }} /></button>
                        <button className="btn btn-link"><i className={`fas fa-star font-16 ${property.Stars > 2 ? 'yellow' : 'white'} align-self-center`} onClick={() => {
                            const { property } = this.state;
                            property.Stars = 3;
                            this.setState({ property });
                        }} /></button>
                        <button className="btn btn-link"><i className={`fas fa-star font-16 ${property.Stars > 3 ? 'yellow' : 'white'} align-self-center`} onClick={() => {
                            const { property } = this.state;
                            property.Stars = 4;
                            this.setState({ property });
                        }} /></button>
                        <button className="btn btn-link"><i className={`fas fa-star font-16 ${property.Stars > 4 ? 'yellow' : 'white'} align-self-center`} onClick={() => {
                            const { property } = this.state;
                            property.Stars = 5;
                            this.setState({ property });
                        }} /></button>
                        <input className="py-3 px-0" style={{ width: 0 }} type="text" name="stars" disabled={true} placeholder="" />
                    </div>
                </div>
                <div className="px-1" />
                {/* # Rooms */}
                <div className="w-50 mt-4">
                    <label className="white poppins font-12"># Habitaciones <i className="blue">*</i></label>
                    <div id="txtRooms" className={`black-input ${validations.txtRooms ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="rooms" placeholder="# Habitaciones" ref={(input) => this.txtRooms = input} defaultValue={property.TotalRooms} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtRooms ? true : false} target={`txtRooms`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtRooms = !validations.txtRooms;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtRooms}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                {/* # Floors */}
                <div className="w-50 mt-4">
                    <label className="white poppins font-12"># Pisos <i className="blue">*</i></label>
                    <div id="txtFloors" className={`black-input ${validations.txtFloors ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="floors" placeholder="# Pisos" ref={(input) => this.txtFloors = input} defaultValue={property.TotalFloors} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtFloors ? true : false} target={`txtFloors`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtFloors = !validations.txtFloors;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtFloors}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
            <div className="d-flex  w3-content">
                {/* Phone */}
                <div className="mt-4">
                    <label className="white poppins font-12">Teléfono</label>
                    <div id="txtPhone" className={`black-input ${validations.txtPhone ? 'error-input' : ''} d-flex mt-2`}>
                        <span className="align-self-center ms-3 white">{property.PhoneCountryCode || '+58+'}</span>
                        <i className="fas fa-chevron-down font-16 white align-self-center px-3 " />
                        <input className="w-100 p-3 white" type="text" name="phone" placeholder="Teléfono" ref={(input) => this.txtPhone = input} defaultValue={property.Phone} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtPhone ? true : false} target={`txtPhone`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtPhone = !validations.txtPhone;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtPhone}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                {/* Time Zone */}
                <div className="w-50 mt-4">
                    <label className="white poppins font-12">Zona Horaria</label>
                    <div id="txtTimeZone" className={`black-input ${validations.txtTimeZone ? 'error-input' : ''} d-flex mt-2`}>
                        <Dropdown isOpen={this.state.pickCommunity} toggle={() => this.setState({ pickCommunity: !this.state.pickCommunity })} className="w-100">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                <span className="align-self-center">{(_.find(timezones, t => t.id === property.TimeZone) || { description: 'Zona horaria' }).description} (GMT {(_.find(timezones, t => t.id === property.TimeZone) || { utcOffset: 0 }).utcOffset})</span>
                                <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {timezones && timezones.map((c, i) => <DropdownItem key={i} onClick={() => {
                                    const { property } = this.state;
                                    property.TimeZone = c.id;
                                    this.setState({ property });
                                }}>{c.description} (GMT {c.utcOffset})</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <Popover placement="right" isOpen={validations.txtTimeZone ? true : false} target={`txtTimeZone`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtTimeZone = !validations.txtTimeZone;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtTimeZone}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
        </section>
    }
    renderComodities = () => {
        const { comodities, property } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Comodidades</h4>
            <hr />
            <div className="d-flex flex-wrap w3-content justify-content-center">
                {comodities.map((s, i) => <button onClick={() => this.onComodity(s)} key={i} type="button" className={`btn btn-lg ${_.find(property.Comodities, c => c === s) ? 'btn-blue' : 'btn-gray-2'} white m-2`}>
                    {s}
                </button>)}
            </div>
        </section>
    }
    renderRestrictions = () => {
        const { validations, property, week_day_flags, regime_types } = this.state, { DaysWithoutCheckin, RegimeType, AdultsOnly } = property;

        return <section className="mt-4">
            <h4 className="white poppins mt-4">Restricciones de edad e información avanzada</h4>
            <hr />
            <div className="d-flex w3-content">

                {/* Cribs max age */}
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Edad máxima Infantes <i className="blue">*</i></label>
                    <div id="txtCribMaxAge" className={`black-input ${validations.txtCribMaxAge ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="cribmaxage" placeholder="Edad máxima Infantes" ref={(input) => this.txtCribMaxAge = input} defaultValue={property.CribsMaxAge} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtCribMaxAge ? true : false} target={`txtCribMaxAge`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtCribMaxAge = !validations.txtCribMaxAge;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCribMaxAge}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                {/* Children Max Age */}
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Edad máxima Niños <i className="blue">*</i></label>
                    <div id="txtChildrenMaxAge" className={`black-input ${validations.txtChildrenMaxAge ? 'error-input' : ''} d-flex mt-2`}>
                        <input onChange={() => {
                            const { property } = this.state;
                            property.ChildrenMaxAge = parseInt(this.txtChildrenMaxAge.value || 0, 10);
                            this.setState({ property });
                        }} className="w-100 p-3 white" type="text" name="children" placeholder="Edad máxima Niños" ref={(input) => this.txtChildrenMaxAge = input} defaultValue={property.ChildrenMaxAge} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtChildrenMaxAge ? true : false} target={`txtChildrenMaxAge`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtChildrenMaxAge = !validations.txtChildrenMaxAge;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtChildrenMaxAge}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Edad minima Adultos <i className="blue">*</i></label>
                    <div className="black-input d-flex mt-2">
                        <input value={(property.ChildrenMaxAge || 0) + 1} disabled={true} className="w-100 p-3 white" type="text" name="minadultsage" placeholder="Edad mínima Adultos" ref={(input) => this.txtAdultMinAge = input} />
                    </div>
                </div>
            </div>
            <hr className="w-50 mx-auto" />
            <div className="d-flex w3-content">
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Dias sin check-in</label>
                    <div className="d-flex">
                        {/* property.DaysWithoutCheckIn */}
                        <div className="w-100 d-flex flex-column">

                            <button onClick={() => {
                                if (DaysWithoutCheckin & week_day_flags.mon) property.DaysWithoutCheckin -= week_day_flags.mon;
                                else property.DaysWithoutCheckin += week_day_flags.mon;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(DaysWithoutCheckin & week_day_flags.mon) ? 'white' : 'gray'}`}><i className={`far ${(DaysWithoutCheckin & week_day_flags.mon) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Lunes</span></button>

                            <button onClick={() => {
                                if (DaysWithoutCheckin & week_day_flags.tue) property.DaysWithoutCheckin -= week_day_flags.tue;
                                else property.DaysWithoutCheckin += week_day_flags.tue;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(DaysWithoutCheckin & week_day_flags.tue) ? 'white' : 'gray'}`}><i className={`far ${(DaysWithoutCheckin & week_day_flags.tue) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Martes</span></button>

                            <button onClick={() => {
                                if (DaysWithoutCheckin & week_day_flags.wed) property.DaysWithoutCheckin -= week_day_flags.wed;
                                else property.DaysWithoutCheckin += week_day_flags.wed;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(DaysWithoutCheckin & week_day_flags.wed) ? 'white' : 'gray'}`}><i className={`far ${(DaysWithoutCheckin & week_day_flags.wed) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Miercoles</span></button>

                            <button onClick={() => {
                                if (DaysWithoutCheckin & week_day_flags.thu) property.DaysWithoutCheckin -= week_day_flags.thu;
                                else property.DaysWithoutCheckin += week_day_flags.thu;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(DaysWithoutCheckin & week_day_flags.thu) ? 'white' : 'gray'}`}><i className={`far ${(DaysWithoutCheckin & week_day_flags.thu) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Jueves</span></button>

                        </div>
                        <div className="w-100 d-flex flex-column">

                            <button onClick={() => {
                                if (DaysWithoutCheckin & week_day_flags.fri) property.DaysWithoutCheckin -= week_day_flags.fri;
                                else property.DaysWithoutCheckin += week_day_flags.fri;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(DaysWithoutCheckin & week_day_flags.fri) ? 'white' : 'gray'}`}><i className={`far ${(DaysWithoutCheckin & week_day_flags.fri) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Viernes</span></button>

                            <button onClick={() => {
                                if (DaysWithoutCheckin & week_day_flags.sat) property.DaysWithoutCheckin -= week_day_flags.sat;
                                else property.DaysWithoutCheckin += week_day_flags.sat;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(DaysWithoutCheckin & week_day_flags.sat) ? 'white' : 'gray'}`}><i className={`far ${(DaysWithoutCheckin & week_day_flags.sat) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Sábado</span></button>


                            <button onClick={() => {
                                if (DaysWithoutCheckin & week_day_flags.sun) property.DaysWithoutCheckin -= week_day_flags.sun;
                                else property.DaysWithoutCheckin += week_day_flags.sun;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(DaysWithoutCheckin & week_day_flags.sun) ? 'white' : 'gray'}`}><i className={`far ${(DaysWithoutCheckin & week_day_flags.sun) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Domingo</span></button>

                        </div>
                    </div>
                </div>
                <div className="w-100 mt-4">
                    {/* property.RegimeType */}
                    <label className="white poppins font-12">Tipo de regimen</label>
                    <div className="d-flex">
                        <div className="w-100 d-flex flex-column">

                            <button onClick={() => {
                                if (RegimeType & regime_types.solo_alojamiento) property.RegimeType = 0;
                                else property.RegimeType = regime_types.solo_alojamiento;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(RegimeType & regime_types.solo_alojamiento) ? 'white' : 'gray'}`}><i className={`far ${(RegimeType & regime_types.solo_alojamiento) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Solo alojamiento</span></button>

                            <button onClick={() => {
                                if (RegimeType & regime_types.bandb) property.RegimeType = 0;
                                else property.RegimeType = regime_types.bandb;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(RegimeType & regime_types.bandb) ? 'white' : 'gray'}`}><i className={`far ${(RegimeType & regime_types.bandb) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">B&B</span></button>

                            <button onClick={() => {
                                if (RegimeType & regime_types.media_pension) property.RegimeType = 0;
                                else property.RegimeType = regime_types.media_pension;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(RegimeType & regime_types.media_pension) ? 'white' : 'gray'}`}><i className={`far ${(RegimeType & regime_types.media_pension) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Media pensión</span></button>

                            <button onClick={() => {
                                if (RegimeType & regime_types.pension_completa) property.RegimeType = 0;
                                else property.RegimeType = regime_types.pension_completa;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(RegimeType & regime_types.pension_completa) ? 'white' : 'gray'}`}><i className={`far ${(RegimeType & regime_types.pension_completa) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Pensión completa</span></button>

                        </div>
                        <div className="w-75 d-flex flex-column">

                            <button onClick={() => {
                                if (RegimeType & regime_types.todo_incluido) property.RegimeType = 0;
                                else property.RegimeType = regime_types.todo_incluido;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(RegimeType & regime_types.todo_incluido) ? 'white' : 'gray'}`}><i className={`far ${(RegimeType & regime_types.todo_incluido) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Todo incluido</span></button>

                            <button onClick={() => {
                                if (RegimeType & regime_types.servicios) property.RegimeType = 0;
                                else property.RegimeType = regime_types.servicios;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(RegimeType & regime_types.servicios) ? 'white' : 'gray'}`}><i className={`far ${(RegimeType & regime_types.servicios) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Servicios</span></button>

                        </div>
                    </div>
                </div>
                <div className="w-75 mt-4">
                    <label className="white poppins font-12">Solo adultos</label>
                    <div className="d-flex">
                        <div className="w-100 d-flex flex-column">

                            <button onClick={() => {
                                if (AdultsOnly) property.AdultsOnly = 0;
                                else property.AdultsOnly = 1;
                                this.setState({ property });
                            }} className={`btn btn-link d-flex ${(AdultsOnly) ? 'white' : 'gray'}`}><i className={`far ${(AdultsOnly) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Solo adultos</span></button>

                        </div>
                    </div>
                </div>
            </div>
            <hr className="w-50 mx-auto" />
            <div className="d-flex w3-content">
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Impuesto <i className="blue">*</i></label>
                    <div id="txtTaxes" className={`black-input ${validations.txtTaxes ? 'error-input' : ''} d-flex mt-2`}>
                        <i className="fas fa-percent font-16 white align-self-center px-3 " />
                        <input className="w-100 p-3 white" type="text" name="taxes" placeholder="Impuesto" ref={(input) => this.txtTaxes = input} defaultValue={property.Tax} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtTaxes ? true : false} target={`txtTaxes`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtTaxes = !validations.txtTaxes;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtTaxes}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="px-1" />
                <div className="w-100 mt-4">
                    <label className="white poppins font-12">Margen</label>
                    <div className="black-input d-flex mt-2">
                        <input className="w-100 p-3 white" type="text" name="margin" placeholder="Margen" ref={(input) => this.txtMargin = input} />
                    </div>
                </div>
            </div>
        </section>
    }
    renderAdditional = () => {

        const { editorState, editorStage, editorMode } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Información adicional</h4>
            <hr />
            <div className="w3-content">
                <div className="d-flex flex-wrap w3-content justify-content-center">
                    <button onClick={() => this.setState({ editorMode: 1 }, () => this.onLoadEditorState(1, editorStage))} type="button" className={`btn btn-lg ${editorMode === 1 ? 'btn-blue' : 'btn-gray-2'} white m-2`}>
                        Descripción de la propiedad
                    </button>
                    <button onClick={() => this.setState({ editorMode: 2 }, () => this.onLoadEditorState(2, editorStage))} type="button" className={`btn btn-lg ${editorMode === 2 ? 'btn-blue' : 'btn-gray-2'} white m-2`}>
                        Notificacion y tarifas
                    </button>
                    <button onClick={() => this.setState({ editorMode: 3 }, () => this.onLoadEditorState(3, editorStage))} type="button" className={`btn btn-lg ${editorMode === 3 ? 'btn-blue' : 'btn-gray-2'} white m-2`}>
                        Políticas y aviso legal
                    </button>
                </div>
                <hr className="w-50 mx-auto" />
                <div className="d-flex flex-row w-100">
                    <button onClick={() => this.setState({ editorStage: 1 }, () => this.onLoadEditorState(editorMode, 1))} className={`ms-auto btn btn-sm btn-link  ${editorStage === 1 ? 'blue' : 'gray'}`}>ES</button>
                    <button onClick={() => this.setState({ editorStage: 2 }, () => this.onLoadEditorState(editorMode, 2))} className={`btn btn-sm btn-link ${editorStage === 2 ? 'blue' : 'gray'}`}>EN</button>
                </div>
                <Editor
                    wrapperClassName="demo-wrapper"
                    editorState={editorState}
                    editorClassName="demo-editor px-4"
                    onEditorStateChange={this.onEditorStateChange}
                />
            </div>
        </section>
    }
    renderCommon = () => {

        return (
            <div>
                {this.renderHeader()}
                {this.state.bootstrapped && <Animated animationIn="fadeInRight" className="mt-4">
                    {this.renderBasic()}
                    {this.renderComodities()}
                    {this.renderRestrictions()}
                    {this.renderAdditional()}
                    <div className="d-flex justify-content-center mt-4">
                        <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                            <i className="icon-corner-down-left white font-12" />
                        </button>
                        <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                            <i className="far fa-save white font-12" />
                        </button>
                    </div>
                </Animated>}
                <div style={{ height: 100 }} />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">{this.props.params.id ? 'Editar' : 'Nueva'} propiedad</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de guardar estos cambios?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doSave} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default AddProperty;
