import React from 'react';
import Layout from '../layouts/base.dark';
import { Link } from 'react-router';
import { humanize_user } from '../../helpers';
import BlackOverlay from '../partials/black_overlay';
import { Animated } from 'react-animated-css';;
import fakeAuth from '../../fakeAuth';
import request from 'superagent';
import config from '../../config';
import Logo from '../partials/logo';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

class Home extends React.Component {
    state = {
        redirectToReferrer: false,
        mode: 1,
        invalid: false,
        selectedBg: null,
        message: null,
        email: null,
        toggle: true,
        code: ''
    }

    componentWillMount() {
        const { params } = this.props;
        if (fakeAuth.isAuthenticated()) {
            let user = fakeAuth.getUser();
            if (user.IdProperty && user.IdStaff) {
                localStorage.setItem('lb.ip', JSON.stringify({
                    IdProperty: user.IdProperty,
                    Name: user.PropertyName,
                    CheckIn: user.PropertyCheckIn,
                    CheckOut: user.PropertyCheckOut
                }));

                // TODO: Here we need to evaluate the user's permissions, to seee
                // where do we move it                
                this.props.router.push(`/dashboard/${user.IdProperty}`);
            } else this.props.router.push('/properties');
        } else {
            // Clear some things
            localStorage.removeItem('lb.ip');
            this.setState({ email: localStorage.getItem('email') });
        }
    }

    doLogin = (e) => {
        e && e.preventDefault && e.preventDefault();

        this.setState({ wrong_email: false, wrong_password: false }, () => {

            if (!this.txtEmail.value) {
                this.setState({ wrong_email: 'Por favor, introduce tu dirección de correo electrónico.' });
                return;
            }
            if (!this.txtPassword.value) {
                this.setState({ wrong_password: 'Por favor, introduce tu contraseña.' });
                return;
            }

            const { microTransac } = this.state;
            if (!microTransac) {
                this.setState({
                    microTransac: true,
                    invalid: false,
                    email: this.txtEmail.value
                }, () => {

                    // Save the email on the localstorage for future usage
                    localStorage.setItem('email', this.txtEmail.value);

                    // Call API
                    request.post(`${config.apiEndpoint}/api/v4/membership/login`)
                        .send({
                            email: this.txtEmail.value,
                            pass: this.txtPassword.value
                        })
                        .then((data) => {
                            if (data.body.success) {

                                // Decrypt User Info
                                data.body.family.forEach(humanize_user);
                                //                               

                                if (data.body.family.length > 1) {
                                    // I dont' think this will happen on this model
                                } else {
                                    let user = _.first(data.body.family);
                                    fakeAuth.authenticate(() => {
                                        this.props.form_actions.login({
                                            token: user.Token,
                                            isAdmin: true, //user.IsLeagueAdmin
                                        });
                                        if (user.IdProperty && user.IdStaff) {
                                            localStorage.setItem('lb.ip', JSON.stringify({
                                                IdProperty: user.IdProperty,
                                                Name: user.PropertyName,
                                                CheckIn: user.PropertyCheckIn,
                                                CheckOut: user.PropertyCheckOut,                                                
                                            }));

                                            // TODO: Here we need to evaluate the user's permissions, to seee
                                            // where do we move it                                            
                                            this.props.router.push(`/dashboard/${user.IdProperty}`);
                                        } else this.props.router.push('/properties');

                                    }, user.Token,
                                        true, user);
                                }
                            } else {
                                this.setState({ microTransac: false, wrong_email: 'Correo electrónico o contraseña invalidos', });
                            }

                        }, ({ response }) => {
                            const { body } = response;
                            this.setState({ microTransac: false, wrong_email: response.body.error.message || 'Correo electrónico o contraseña invalidos' });
                            this.props.setDeveloperMessage && this.props.setDeveloperMessage(response);
                        });
                });
            }



        });
    }

    render() {
        const { mode, microTransac, email, toggle, } = this.state;
        return (

            <section className="fullscreen abs" style={{ background: `url(images/backgrounds/waves.png) no-repeat center bottom` }}>
                <div className="h-100 w-100 d-flex justify-content-center" style={{ position: 'relative' }}>
                    <BlackOverlay />
                    <Animated
                        animateOnMount={true}
                        animationIn="fadeInRight"
                        animationInDelay={200}
                        style={{ width: 500, zIndex: 1, borderRadius: 6 }}
                        className="align-self-center d-flex px-4">

                        <form key="login-form" className='align-self-center w-100 d-flex flex-column'>
                            <Logo className="font-80 align-self-center mt-4" />
                            <h1 className="poppins white mt-4 font-20 align-self-center">Iniciar Sesión</h1>

                            {/* EMAIL */}
                            <div className={`w-100 mt-4`} >
                                <label className="white poppins font-12">Correo electrónico <i className="blue">*</i></label>
                                <div id="email_wrapper" className={`black-input ${this.state.wrong_email ? 'error-input' : ''} d-flex mt-2`}>
                                    <i className="fas fa-user font-16 white align-self-center ps-3" />
                                    <input className="w-100 p-3 white poppins" defaultValue={email} type="text" name="email" placeholder="Correo electrónico" ref={(input) => this.txtEmail = input} />
                                </div>
                                <Popover placement="right" isOpen={this.state.wrong_email ? true : false} target={`email_wrapper`} toggle={() => this.setState({ wrong_email: !this.state.wrong_email })} className="bg-gray-2">
                                    <PopoverBody className="d-flex flex-row justify-content-center">
                                        <i className="fas fa-times red font-8 align-self-center" />
                                        <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{this.state.wrong_email}</span>
                                    </PopoverBody>
                                </Popover>
                            </div>

                            {/* PASSWORD */}
                            <div className={`w-100 mt-4`} >
                                <label className="white poppins font-12">Contraseña <i className="blue">*</i></label>
                                <div id="password_wrapper" className={`black-input ${this.state.wrong_password ? 'error-input' : ''} d-flex mt-2`}>
                                    <i className="fas fa-lock font-16 white align-self-center ps-3" />
                                    <input className="w-100 p-3 white poppins" type={`${this.state.password_visible ? 'text' : 'password'}`} name="pass" placeholder="Contraseña" ref={(input) => this.txtPassword = input} />
                                    <i className={`fas fa-eye${this.state.password_visible ? '-slash' : ''} font-16 white align-self-center pe-3`} onClick={() => this.setState({ password_visible: !this.state.password_visible })} />
                                </div>
                                <Popover placement="right" isOpen={this.state.wrong_password ? true : false} target={`password_wrapper`} toggle={() => this.setState({ wrong_email: !this.state.wrong_password })} className="bg-gray-2">
                                    <PopoverBody className="d-flex flex-row justify-content-center">
                                        <i className="fas fa-times red font-8 align-self-center" />
                                        <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{this.state.wrong_password}</span>
                                    </PopoverBody>
                                </Popover>
                            </div>

                            {/* RECOVER */}
                            <Link to="/recover" key="recover-password" className="w-full text-center mt-4" onClick={() => this.setState({ recovering: true, recovering_step: 1 })}>
                                <span className="txt1 white poppins" style={{ marginRight: '5px' }}>Olvidé mi</span>
                                <span className="txt2 underlined blue poppins">contraseña</span>
                            </Link>

                            {/* GO */}
                            <button type="submit" className="btn btn-lg btn-blue white w-100 mt-4 poppins" onClick={this.doLogin}>
                                {!microTransac && <span>Entrar</span>}
                                {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                            </button>

                            {/* CREATE ACCOUNT */}
                            <Link to="/create_account" className="w-100 btn btn-link text-center mt-4">
                                <span className="txt1 white poppins" style={{ marginRight: '5px' }}>Quiero</span>
                                <span className="txt2 underlined blue poppins">ser parte</span>
                                <span className="txt1 white poppins" style={{ marginLeft: '5px' }}>de legendbeds</span>
                            </Link>

                            <div style={{ height: 70 }} />
                            <span className="white font-8 mx-auto poppins">©Legendbeds 2023. Powered by Legendsoft. Todos los derechos reservados</span>
                        </form>

                    </Animated>

                </div>
            </section>

        )
    }
}

export default Home;
