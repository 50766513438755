function floors_Reducer(state = [], action) {
    switch (action.type) {
        case 'FLOORS/SET':            
            return action.floors;
        case 'FLOORS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default floors_Reducer;
