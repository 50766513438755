function periods_Reducer(state = [], action) {
    switch (action.type) {
        case 'PERIODS/SET':            
            return action.periods;
        case 'PERIODS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default periods_Reducer;
