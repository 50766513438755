import React from 'react';
import ReactDOM from 'react-dom';
import Layout from '../layouts/claire';
import { generateRandomId, getBase64 } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import BlackOverlay from '../partials/black_overlay';
import fakeAuth from '../../fakeAuth';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LineLoader from '../partials/line_loader';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../partials/custom_date_picker';
import moment from 'moment';
import request from 'superagent';
import config from '../../config';
import Rates from './partials/rates';

import "react-datepicker/dist/react-datepicker.css";

class EditPeriod extends React.Component {

    state = {
        page: 1,
        toggle: true,
        menu_toggled: false,
        validations: {},
        defaultDate: moment(),
        comodities: config.room_comodities,
        editorMode: 1, // [1: es, 2: en]
        editorStage: 1, // [1: description, 2: rates, 3: terms]
        period: {
            WeekDays: 0,
            SalesCommission: {
                // mon, tue....
            },
            RemainingInventory: {},
            MinOccupancy: {},
            MaxOccupancy: {},
            BlockedAllowance: 0,
            CloseAtArrival: 0,
            CloseAtDeparture: 0,
            MinDaysStay: {},
            Start: moment(),
            End: moment().add(2, 'months'),
            CurrencyCode: 'USD'
        },
        room: {
            BedTypes: [{
                id: generateRandomId(10)
            }],
            IdProperty: this.props.params.id,
            Comodities: [],
            Name: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
            Description: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
        },
        week_day_flags: config.week_day_flags,
    }

    // lifecycle
    componentWillMount() {
        const { params } = this.props;
        if (params.id) {
            this.props.periods_actions && this.props.periods_actions.fetch_one(params.id);
        } else {
            this.props.router.goBack();
        }
    }
    componentWillReceiveProps = nextProps => {
        const { period } = nextProps;
        if (period && period.IdPeriod) this.setState({ bootstrapped: true, period });
    }
    componentWillUnmount = () => {
        //this.props.properties_actions && this.props.properties_actions.clean();
    }
    //    

    // Saving
    doSavePeriod = () => {
        const { period, microTransac } = this.state, { id } = this.props.params;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
            }, () => {
                // Call API
                request[period.IdPeriod ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/periods${period.IdPeriod ? `/${period.IdPeriod}` : ''}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({
                        Name: this.txtPeriodName.value,
                        Start: moment(period.Start).utc().format(),
                        End: moment(period.End).utc().format(),
                        CurrencyCode: this.txtCurrency.value,
                        ..._.pick(period, 'WeekDays', 'SalesCommission',
                            'RemainingInventory',
                            'MinOccupancy',
                            'MaxOccupancy',
                            'MinDaysStay',
                            'BlockedAllowance',
                            'CloseAtArrival',
                            'CloseAtDeparture'),
                        Rate: this.rates_comp.state.rate
                    }).then((data) => {

                        if (data.body.success) {
                            this.props.router.goBack();
                        } else {
                            // TODO: show server error?
                            this.setState({ microTransac: false });
                        }
                    }, (error) => {
                        this.setState({ microTransac: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });
        }
    }
    validatePeriod = () => {
        this.setState({ validations: {} }, () => {
            const { validations, period } = this.state;

            if (!this.txtPeriodName.value) {
                validations.txtPeriodName = 'Por favor, introduce el nombre del período';
                this.setState({ validations });
                return;
            }
            if (!this.txtCurrency.value) {
                validations.txtCurrency = 'Por favor, introduce el tipo de moneda';
                this.setState({ validations });
                return;
            }
            if (moment(period.Start).isAfter(moment(period.End))) {
                validations.txtPeriodEnd = 'La fecha Fin no puede ser antes de la fecha Inicio';
                this.setState({ validations });
                return;
            }
            /*if (_.isNaN(parseInt(this.txtAvailability.value, 10))) {
                validations.txtAvailability = 'Por favor introduzca un número válido aqui';
                this.setState({ validations });
                return;
            }*/
            // TODO
            /*           
            if (!this.txtCity.value) {
                validations.txtCity = 'Por favor, selecciona la ciudad donde se ubica la propiedad';
                this.setState({ validations });
                return;
            }
            if (!ReactDOM.findDOMNode(this.txtCheckIn).value) {
                validations.txtCheckIn = 'Por favor, selecciona la hora de check-in';
                this.setState({ validations });
                return;
            }
            if (!ReactDOM.findDOMNode(this.txtCheckOut).value) {
                validations.txtCheckOut = 'Por favor, selecciona la hora de check-out';
                this.setState({ validations });
                return;
            }
            if (!this.txtPhone.value) {
                validations.txtPhone = 'Por favor, coloque un número de telefono';
                this.setState({ validations });
                return;
            }
            if (!this.txtRooms.value) {
                validations.txtRooms = 'Por favor, coloque la cantidad de habitaciones de la propiedad';
                this.setState({ validations });
                return;
            }
            if (!this.txtFloors.value) {
                validations.txtFloors = 'Por favor, coloque numero de pisos de la propiedad';
                this.setState({ validations });
                return;
            }
            if (!this.txtCribMaxAge.value) {
                validations.txtCribMaxAge = 'Por favor, coloque la edad maxima para infantes';
                this.setState({ validations });
                return;
            }
            if (!this.txtChildrenMaxAge.value) {
                validations.txtChildrenMaxAge = 'Por favor, coloque la edad maxima para niñ@s';
                this.setState({ validations });
                return;
            }*/

            this.setState({ confirm_period: true });

        });
    }
    //

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="far fa-clock white font-40 align-self-center" />
                <span className="white ms-2 font-20 poppins align-self-center">Editar periodo</span>
                <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>
            </div>
            <div className="w-100">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderPeriod = () => {
        const { validations, period, week_day_flags } = this.state, { WeekDays } = period;
        return <section>
            <h4 className="white poppins mt-4">Periodos y tarifas</h4>
            <hr />
            <div className="w3-content">
                <div className="w3-content">
                    <h5 className="white poppins mt-4 text-center">Periodo de la habitación</h5>
                </div>
            </div>
            {/* Periodo */}
            <div className="d-flex w3-content">
                {/* Periodo */}
                <div className="w-100 mx-auto mt-4">
                    <label className="white poppins font-12">Nombre del periodo <i className="blue">*</i></label>
                    <div id="txtPeriodName" className={`black-input ${validations.txtPeriodName ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Agregar valor" ref={(input) => this.txtPeriodName = input} defaultValue={period.Name} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtPeriodName ? true : false} target={`txtPeriodName`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtPeriodName = !validations.txtPeriodName;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtPeriodName}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
            {/* Dates */}
            <div className="d-flex w3-content">
                {/* Start */}
                <div className="w-100 mx-auto mt-4">
                    <label className="white poppins font-12">Inicio <i className="blue">*</i></label>
                    <div id="txtPeriodStart">
                        <DatePicker highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" />} selected={moment(period.Start).utc()} showYearDropdown scrollableYearDropdown
                            yearDropdownItemNumber={15} onChange={date => {
                                const { period } = this.state;
                                period.Start = moment(date).utc();
                                this.setState({ period });
                            }} className="white font-12"
                        />
                    </div>
                    <Popover placement="right" isOpen={validations.txtPeriodStart ? true : false} target={`txtPeriodStart`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtPeriodStart = !validations.txtPeriodStart;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtPeriodStart}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="p-1" />
                {/* End */}
                <div className="w-100 mx-auto mt-4">
                    <label className="white poppins font-12">Fin <i className="blue">*</i></label>
                    <div id="txtPeriodEnd">
                        <DatePicker highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" />} selected={moment(period.End).utc()} showYearDropdown scrollableYearDropdown
                            yearDropdownItemNumber={15} onChange={date => {
                                const { period } = this.state;
                                period.End = moment(date).utc();
                                this.setState({ period });
                            }} className={`${validations.txtPeriodEnd ? 'error-input' : ''} white font-12`}
                        />
                    </div>
                    <Popover placement="right" isOpen={validations.txtPeriodEnd ? true : false} target={`txtPeriodEnd`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtPeriodEnd = !validations.txtPeriodEnd;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtPeriodEnd}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="p-1" />
                {/* Currency */}
                <div className="w-100 mx-auto mt-4">
                    <label className="white poppins font-12">Moneda <i className="blue">*</i></label>
                    <div id="txtCurrency" className={`black-input ${validations.txtCurrency ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Agregar valor" ref={(input) => this.txtCurrency = input} defaultValue={period.CurrencyCode} />
                        {/*<i className="ms-auto me-3 align-self-center gray fa fa-chevron-down" />*/}
                    </div>
                    <Popover placement="right" isOpen={validations.txtCurrency ? true : false} target={`txtCurrency`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtCurrency = !validations.txtCurrency;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCurrency}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>

        </section>
    }
    renderPeriodMatrix = () => {
        const { validations, period, week_day_flags } = this.state, { WeekDays, BlockedAllowance, CloseAtArrival, CloseAtDeparture } = period;
        return <section>
            {/* Days of the week */}
            <div className="mt-4 w3-content">
                <hr />
                <h5 className="white poppins mt-4 text-center">Seleccione los dias de la semana que aplican</h5>
            </div>
            <div className="d-flex w3-content">
                <div className="w-100 mt-4">
                    <div className="d-flex w-75 mx-auto">
                        <div className="w-100 d-flex">

                            <button onClick={() => {
                                if (WeekDays & week_day_flags.mon) period.WeekDays -= week_day_flags.mon;
                                else period.WeekDays += week_day_flags.mon;
                                this.setState({ period });
                            }} className={`btn btn-link d-flex w-100  ${(WeekDays & week_day_flags.mon) ? 'white' : 'gray'}`}><i className={`far ${(WeekDays & week_day_flags.mon) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Lunes</span></button>

                            <button onClick={() => {
                                if (WeekDays & week_day_flags.tue) period.WeekDays -= week_day_flags.tue;
                                else period.WeekDays += week_day_flags.tue;
                                this.setState({ period });
                            }} className={`btn btn-link d-flex w-100  ${(WeekDays & week_day_flags.tue) ? 'white' : 'gray'}`}><i className={`far ${(WeekDays & week_day_flags.tue) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Martes</span></button>

                            <button onClick={() => {
                                if (WeekDays & week_day_flags.wed) period.WeekDays -= week_day_flags.wed;
                                else period.WeekDays += week_day_flags.wed;
                                this.setState({ period });
                            }} className={`btn btn-link d-flex w-100  ${(WeekDays & week_day_flags.wed) ? 'white' : 'gray'}`}><i className={`far ${(WeekDays & week_day_flags.wed) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Miercoles</span></button>

                            <button onClick={() => {
                                if (WeekDays & week_day_flags.thu) period.WeekDays -= week_day_flags.thu;
                                else period.WeekDays += week_day_flags.thu;
                                this.setState({ period });
                            }} className={`btn btn-link d-flex w-100  ${(WeekDays & week_day_flags.thu) ? 'white' : 'gray'}`}><i className={`far ${(WeekDays & week_day_flags.thu) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Jueves</span></button>

                            <button onClick={() => {
                                if (WeekDays & week_day_flags.fri) period.WeekDays -= week_day_flags.fri;
                                else period.WeekDays += week_day_flags.fri;
                                this.setState({ period });
                            }} className={`btn btn-link d-flex w-100  ${(WeekDays & week_day_flags.fri) ? 'white' : 'gray'}`}><i className={`far ${(WeekDays & week_day_flags.fri) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Viernes</span></button>

                            <button onClick={() => {
                                if (WeekDays & week_day_flags.sat) period.WeekDays -= week_day_flags.sat;
                                else period.WeekDays += week_day_flags.sat;
                                this.setState({ period });
                            }} className={`btn btn-link d-flex w-100  ${(WeekDays & week_day_flags.sat) ? 'white' : 'gray'}`}><i className={`far ${(WeekDays & week_day_flags.sat) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Sábado</span></button>

                            <button onClick={() => {
                                if (WeekDays & week_day_flags.sun) period.WeekDays -= week_day_flags.sun;
                                else period.WeekDays += week_day_flags.sun;
                                this.setState({ period });
                            }} className={`btn btn-link d-flex w-100  ${(WeekDays & week_day_flags.sun) ? 'white' : 'gray'}`}><i className={`far ${(WeekDays & week_day_flags.sun) ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Domingo</span></button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 w3-content">
                <div className="w-100 bg-gray-2 px-0 py-0 rounded no-overflow d-flex">
                    <table className="w-100 table m-0 poppins bg-gray-2 white">
                        <thead>
                            <tr>
                                <th className="bg-gray-2 white p-4" style={{ width: 120 }}>Opciones del periodo</th>
                                <th className={`bg-gray-3 ${(WeekDays & week_day_flags.mon) ? 'white' : 'gray'} text-center`}>LUN</th>
                                <th className={`bg-gray-3 ${(WeekDays & week_day_flags.tue) ? 'white' : 'gray'} text-center`}>MAR</th>
                                <th className={`bg-gray-3 ${(WeekDays & week_day_flags.wed) ? 'white' : 'gray'} text-center`}>MIE</th>
                                <th className={`bg-gray-3 ${(WeekDays & week_day_flags.thu) ? 'white' : 'gray'} text-center`}>JUE</th>
                                <th className={`bg-gray-3 ${(WeekDays & week_day_flags.fri) ? 'white' : 'gray'} text-center`}>VIE</th>
                                <th className={`bg-gray-3 ${(WeekDays & week_day_flags.sat) ? 'white' : 'gray'} text-center`}>SAB</th>
                                <th className={`bg-gray-3 ${(WeekDays & week_day_flags.sun) ? 'white' : 'gray'} text-center`}>DOM</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Comission */}
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td className="bg-gray-2 px-4 whiteish font-10">Comisión asignada<br />por venta</td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.mon) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.SalesCommission.mon} onChange={(e) => {
                                            const { period } = this.state;
                                            period.SalesCommission.mon = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.tue) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.SalesCommission.tue} onChange={(e) => {
                                            const { period } = this.state;
                                            period.SalesCommission.tue = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.wed) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.SalesCommission.wed} onChange={(e) => {
                                            const { period } = this.state;
                                            period.SalesCommission.wed = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.thu) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.SalesCommission.thu} onChange={(e) => {
                                            const { period } = this.state;
                                            period.SalesCommission.thu = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.fri) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.SalesCommission.fri} onChange={(e) => {
                                            const { period } = this.state;
                                            period.SalesCommission.fri = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sat) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.SalesCommission.sat} onChange={(e) => {
                                            const { period } = this.state;
                                            period.SalesCommission.sat = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sun) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.SalesCommission.sun} onChange={(e) => {
                                            const { period } = this.state;
                                            period.SalesCommission.sun = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                            </tr>
                            {/* Inventory */}
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td className="bg-gray-2 px-4 whiteish font-10">Inventario restante</td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.mon) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.RemainingInventory.mon} onChange={(e) => {
                                            const { period } = this.state;
                                            period.RemainingInventory.mon = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.tue) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.RemainingInventory.tue} onChange={(e) => {
                                            const { period } = this.state;
                                            period.RemainingInventory.tue = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.wed) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.RemainingInventory.wed} onChange={(e) => {
                                            const { period } = this.state;
                                            period.RemainingInventory.wed = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.thu) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.RemainingInventory.thu} onChange={(e) => {
                                            const { period } = this.state;
                                            period.RemainingInventory.thu = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.fri) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.RemainingInventory.fri} onChange={(e) => {
                                            const { period } = this.state;
                                            period.RemainingInventory.fri = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sat) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.RemainingInventory.sat} onChange={(e) => {
                                            const { period } = this.state;
                                            period.RemainingInventory.sat = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sun) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.RemainingInventory.sun} onChange={(e) => {
                                            const { period } = this.state;
                                            period.RemainingInventory.sun = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                            </tr>
                            {/* Min Occ */}
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td className="bg-gray-2 px-4 whiteish font-10">Ocupación mínima</td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.mon) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinOccupancy.mon} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinOccupancy.mon = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.tue) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinOccupancy.tue} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinOccupancy.tue = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.wed) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinOccupancy.wed} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinOccupancy.wed = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.thu) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinOccupancy.thu} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinOccupancy.thu = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.fri) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinOccupancy.fri} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinOccupancy.fri = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sat) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinOccupancy.sat} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinOccupancy.sat = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sun) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinOccupancy.sun} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinOccupancy.sun = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                            </tr>
                            {/* Max Occ */}
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td className="bg-gray-2 px-4 whiteish font-10">Ocupación máxima</td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.mon) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MaxOccupancy.mon} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MaxOccupancy.mon = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.tue) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MaxOccupancy.tue} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MaxOccupancy.tue = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.wed) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MaxOccupancy.wed} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MaxOccupancy.wed = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.thu) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MaxOccupancy.thu} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MaxOccupancy.thu = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.fri) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MaxOccupancy.fri} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MaxOccupancy.fri = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sat) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MaxOccupancy.sat} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MaxOccupancy.sat = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sun) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MaxOccupancy.sun} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MaxOccupancy.sun = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                            </tr>
                            {/* Bloqueo */}
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td className="bg-gray-2 px-4 whiteish font-10">Alojamiento bloqueado</td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.mon)) return;
                                        if (BlockedAllowance & week_day_flags.mon) period.BlockedAllowance -= week_day_flags.mon;
                                        else period.BlockedAllowance += week_day_flags.mon;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(BlockedAllowance & week_day_flags.mon) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${BlockedAllowance & week_day_flags.mon ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.tue)) return;
                                        if (BlockedAllowance & week_day_flags.tue) period.BlockedAllowance -= week_day_flags.tue;
                                        else period.BlockedAllowance += week_day_flags.tue;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(BlockedAllowance & week_day_flags.tue) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${BlockedAllowance & week_day_flags.tue ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.wed)) return;
                                        if (BlockedAllowance & week_day_flags.wed) period.BlockedAllowance -= week_day_flags.wed;
                                        else period.BlockedAllowance += week_day_flags.wed;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(BlockedAllowance & week_day_flags.wed) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${BlockedAllowance & week_day_flags.wed ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.thu)) return;
                                        if (BlockedAllowance & week_day_flags.thu) period.BlockedAllowance -= week_day_flags.thu;
                                        else period.BlockedAllowance += week_day_flags.thu;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(BlockedAllowance & week_day_flags.thu) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${BlockedAllowance & week_day_flags.thu ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.fri)) return;
                                        if (BlockedAllowance & week_day_flags.fri) period.BlockedAllowance -= week_day_flags.fri;
                                        else period.BlockedAllowance += week_day_flags.fri;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(BlockedAllowance & week_day_flags.fri) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${BlockedAllowance & week_day_flags.fri ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.sat)) return;
                                        if (BlockedAllowance & week_day_flags.sat) period.BlockedAllowance -= week_day_flags.sat;
                                        else period.BlockedAllowance += week_day_flags.sat;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(BlockedAllowance & week_day_flags.sat) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${BlockedAllowance & week_day_flags.sat ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.sun)) return;
                                        if (BlockedAllowance & week_day_flags.sun) period.BlockedAllowance -= week_day_flags.sun;
                                        else period.BlockedAllowance += week_day_flags.sun;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(BlockedAllowance & week_day_flags.sun) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${BlockedAllowance & week_day_flags.sun ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                            </tr>
                            {/* Departure */}
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td className="bg-gray-2 px-4 whiteish font-10">Cerrado a la llegada</td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.mon)) return;
                                        if (CloseAtDeparture & week_day_flags.mon) period.CloseAtDeparture -= week_day_flags.mon;
                                        else period.CloseAtDeparture += week_day_flags.mon;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtDeparture & week_day_flags.mon) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtDeparture & week_day_flags.mon ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.tue)) return;
                                        if (CloseAtDeparture & week_day_flags.tue) period.CloseAtDeparture -= week_day_flags.tue;
                                        else period.CloseAtDeparture += week_day_flags.tue;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtDeparture & week_day_flags.tue) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtDeparture & week_day_flags.tue ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.wed)) return;
                                        if (CloseAtDeparture & week_day_flags.wed) period.CloseAtDeparture -= week_day_flags.wed;
                                        else period.CloseAtDeparture += week_day_flags.wed;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtDeparture & week_day_flags.wed) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtDeparture & week_day_flags.wed ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.thu)) return;
                                        if (CloseAtDeparture & week_day_flags.thu) period.CloseAtDeparture -= week_day_flags.thu;
                                        else period.CloseAtDeparture += week_day_flags.thu;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtDeparture & week_day_flags.thu) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtDeparture & week_day_flags.thu ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.fri)) return;
                                        if (CloseAtDeparture & week_day_flags.fri) period.CloseAtDeparture -= week_day_flags.fri;
                                        else period.CloseAtDeparture += week_day_flags.fri;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtDeparture & week_day_flags.fri) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtDeparture & week_day_flags.fri ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.sat)) return;
                                        if (CloseAtDeparture & week_day_flags.sat) period.CloseAtDeparture -= week_day_flags.sat;
                                        else period.CloseAtDeparture += week_day_flags.sat;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtDeparture & week_day_flags.sat) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtDeparture & week_day_flags.sat ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.sun)) return;
                                        if (CloseAtDeparture & week_day_flags.sun) period.CloseAtDeparture -= week_day_flags.sun;
                                        else period.CloseAtDeparture += week_day_flags.sun;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtDeparture & week_day_flags.sun) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtDeparture & week_day_flags.sun ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                            </tr>
                            {/* Arrival */}
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td className="bg-gray-2 px-4 whiteish font-10">Cerrado a la salida</td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.mon)) return;
                                        if (CloseAtArrival & week_day_flags.mon) period.CloseAtArrival -= week_day_flags.mon;
                                        else period.CloseAtArrival += week_day_flags.mon;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtArrival & week_day_flags.mon) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtArrival & week_day_flags.mon ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.tue)) return;
                                        if (CloseAtArrival & week_day_flags.tue) period.CloseAtArrival -= week_day_flags.tue;
                                        else period.CloseAtArrival += week_day_flags.tue;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtArrival & week_day_flags.tue) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtArrival & week_day_flags.tue ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.wed)) return;
                                        if (CloseAtArrival & week_day_flags.wed) period.CloseAtArrival -= week_day_flags.wed;
                                        else period.CloseAtArrival += week_day_flags.wed;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtArrival & week_day_flags.wed) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtArrival & week_day_flags.wed ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.thu)) return;
                                        if (CloseAtArrival & week_day_flags.thu) period.CloseAtArrival -= week_day_flags.thu;
                                        else period.CloseAtArrival += week_day_flags.thu;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtArrival & week_day_flags.thu) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtArrival & week_day_flags.thu ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.fri)) return;
                                        if (CloseAtArrival & week_day_flags.fri) period.CloseAtArrival -= week_day_flags.fri;
                                        else period.CloseAtArrival += week_day_flags.fri;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtArrival & week_day_flags.fri) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtArrival & week_day_flags.fri ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.sat)) return;
                                        if (CloseAtArrival & week_day_flags.sat) period.CloseAtArrival -= week_day_flags.sat;
                                        else period.CloseAtArrival += week_day_flags.sat;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtArrival & week_day_flags.sat) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtArrival & week_day_flags.sat ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                                <td className="bg-gray-3 text-center">
                                    <button onClick={() => {
                                        if (!(WeekDays & week_day_flags.sun)) return;
                                        if (CloseAtArrival & week_day_flags.sun) period.CloseAtArrival -= week_day_flags.sun;
                                        else period.CloseAtArrival += week_day_flags.sun;
                                        this.setState({ period });
                                    }} className={`btn btn-link ${(CloseAtArrival & week_day_flags.sun) ? 'blue' : 'gray'}`}>
                                        <i className={`far ${CloseAtArrival & week_day_flags.sun ? 'fa-check-square' : 'fa-square'} font-20`} /></button>
                                </td>
                            </tr>
                            {/* Min Stay */}
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td className="bg-gray-2 px-4 whiteish font-10">Tiempo mínimo de estadia</td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.mon) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinDaysStay.mon} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinDaysStay.mon = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.tue) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinDaysStay.tue} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinDaysStay.tue = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.wed) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinDaysStay.wed} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinDaysStay.wed = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.thu) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinDaysStay.thu} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinDaysStay.thu = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.fri) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinDaysStay.fri} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinDaysStay.fri = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sat) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinDaysStay.sat} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinDaysStay.sat = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                                <td className="bg-gray-3">
                                    <div className={`black-input`} style={{ maxWidth: 100 }}>
                                        <input disabled={(WeekDays & week_day_flags.sun) ? false : true} className="p-3 white debug w-100 text-center" type="text" placeholder="" defaultValue={period.MinDaysStay.sun} onChange={(e) => {
                                            const { period } = this.state;
                                            period.MinDaysStay.sun = parseFloat(e.target.value);
                                            this.setState({ period });
                                        }} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    }
    renderRoomRates = () => {
        const { period } = this.state;
        return <Rates ref={(i) => this.rates_comp = i} period={period} />
    }
    renderRates = () => {
        const { validations, period, week_day_flags } = this.state, { } = period;
        return <section className="mt-4">
            {this.renderPeriod()}
            {this.renderPeriodMatrix()}
            <div style={{ height: 80 }} />
            {this.renderRoomRates()}
        </section>
    }
    renderCommon = () => {
        return (
            <div>
                {this.renderHeader()}
                {this.state.bootstrapped && <section>

                    {/* Rate details */}
                    {this.state.page === 1 && <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.toggle} animationOut="fadeOutRight" animationIn="fadeInRight" className="mt-4">
                        {this.renderRates()}
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white align-self-center" >
                                <i className="icon-corner-down-left white font-12" />
                            </button>
                            <button onClick={this.validatePeriod} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className="far fa-save white font-12" />
                            </button>
                        </div>
                    </Animated>}

                </section>
                }
                <div style={{ height: 100 }} />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Confirm Period */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm_period ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Editar período</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro que desea guardar los cambios?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ confirm_period: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doSavePeriod} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default EditPeriod;
