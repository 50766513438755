import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import moment from 'moment';
import BlackOverlay from '../partials/black_overlay';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import See from './partials/see';

class Housekeep extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 0,
        rooms_selected: [],
        assigned_tasks: [],
        floors: [],
        status: config.room_status,
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
    }
    componentWillMount() {
        const { params, booking_actions } = this.props;
        let user = fakeAuth.getUser();
        if (user.IdStaff && user.IdProperty !== params.id) this.props.router.goBack();
        else {
            this.props.properties_actions && this.props.properties_actions.fetch_one(params.id);
            this.props.floors_actions && this.props.floors_actions.fetch(params.id);
            this.props.staffs_actions && this.props.staffs_actions.fetch(params.id);
            booking_actions && booking_actions.fetch_range({
                IdProperty: params.id,
                CheckIn: moment().format('YYYY-MM-DD'),
                CheckOut: moment().add(1, 'day').format('YYYY-MM-DD')
            });
        }
    }
    componentWillReceiveProps = nextProps => {
        const { floors = [] } = nextProps;
        let user = fakeAuth.getUser();
        this.setState({ floors }, () => {
            // Here we count how many 'tasks' do I have assigned. 
            // IDEA: reduce the pieces on floors, and filter by those that contains my IdUser in the Staff array
            let my_rooms = _.chain(floors)
                .map(f => f.Pieces)
                .flattenDeep()
                .filter(piece => _.find(piece.Staff, s => s.IdStaff === user.IdStaff))
                .value();
            this.setState({ assigned_tasks: my_rooms });
        });
        // Setting auto-complete
        const { staffs } = nextProps;
        this.setState({
            staffs: _.chain(staffs || []).map(u => {
                return {
                    id: u.IdStaff,
                    name: `${u.FirstName} ${u.LastName}`
                }
            }).value()
        });

        // Bookings to set up occupied or not
        const { bookings } = nextProps;
        if (bookings) {            
            this.setState({
                pieces_taken: _.chain(bookings)
                    .map(b => b.Rooms || [])
                    .flattenDeep()
                    .filter(r => r.IdPiece)
                    .map(p => p.IdPiece)
                    .value()
            });
        }
    }

    onAssignStaff = () => {
        const { selected_staff, rooms_selected, floors } = this.state, { params } = this.props;

        // TODO: not in love with this n3 alg
        floors.forEach(floor => {
            floor.Pieces.forEach(piece => {
                if (!piece.Staff) piece.Staff = [];
                if (_.chain(rooms_selected).map(r => r.IdPiece).value().indexOf(piece.IdPiece) !== -1) {
                    if (!_.find(piece.Staff, s => s.IdStaff === selected_staff.IdStaff)) {
                        piece.Staff.push(selected_staff);

                        // call API
                        this.props.floors_actions && this.props.floors_actions.assign_staff({
                            IdPiece: piece.IdPiece,
                            IdStaff: selected_staff.IdStaff,
                            IdProperty: params.id
                        });
                    }
                }
            });
        });

        this.setState({ selected_staff: null, assigning: false, floors });
    }
    onBounce = (i) => {
        const { status, rooms_selected } = this.state;

        _.chain(status).reject(s => s.macro).value()[i].bounce = true;
        _.chain(status).reject(s => s.macro).value()[i].selected = !_.chain(status).reject(s => s.macro).value()[i].selected;

        // TURN ON
        if (_.chain(status).reject(s => s.macro).value()[i].selected) {
            rooms_selected.forEach(piece => {
                if (!(piece.Status & Math.pow(2, _.chain(status).reject(s => s.macro).value()[i].id - 1))) {
                    piece.Status += Math.pow(2, _.chain(status).reject(s => s.macro).value()[i].id - 1);
                    this.props.floors_actions && this.props.floors_actions.update_piece_status(piece);
                }
            });

        } else {
            // TURN OFF
            rooms_selected.forEach(piece => {
                if ((piece.Status & Math.pow(2, _.chain(status).reject(s => s.macro).value()[i].id - 1))) {
                    piece.Status -= Math.pow(2, _.chain(status).reject(s => s.macro).value()[i].id - 1);
                    this.props.floors_actions && this.props.floors_actions.update_piece_status(piece);
                }
            });
        }

        this.setState({ status, rooms_selected }, () => {
            setTimeout(() => {
                _.chain(status).reject(s => s.macro).value()[i].bounce = false;
                this.setState({ status });
            }, 1000);
        });
    }


    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_housekeep blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Housekeeping</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            <div className="d-flex align-self-center w-100">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderControls = () => {
        const { status, assigned_tasks } = this.state;
        return <div className="px-4 py-3 d-flex" style={{ background: '#15191E' }}>
            <i className="ms-2 blue far font-20 fa-calendar align-self-center" />
            <span className="poppins white font-30 ms-2 align-self-center">{moment().locale('es').format('DD')}</span>
            <div className="d-flex flex-column ms-2 align-self-center">
                <span className="poppins white font-8 toUpper">{moment().locale('es').format('MMMM')}</span>
                <span className="poppins white font-8">{moment().locale('es').format('YYYY')}</span>
            </div>
            <i className="ms-4 white fas font-20 fa-chevron-right align-self-center" />
            <div onClick={() => {
                this.setState({ filter_assigned_tasks: !this.state.filter_assigned_tasks });
            }} className={`ms-4 bg-${this.state.filter_assigned_tasks ? 'green' : 'blue'} d-flex flex-row justify-content-center pointer zoomable`} style={{ width: 50, height: 50, borderRadius: 25, border: '6px solid black' }}>
                <span className="white font-14 poppins align-self-center">{assigned_tasks.length}</span>
            </div>
            <div className="d-flex flex-column ms-2 align-self-center">
                <span className="poppins white font-8">Tareas</span>
                <span className="poppins white font-8">Pendientes</span>
            </div>
            <button className="btn btn-link ms-auto d-flex" id="btn-estados" onClick={() => this.setState({ states_menu: !this.state.states_menu })}>
                <i className="fas fa-circle green font-8 me-2 align-self-center" />
                <span className="gray align-self-center font-16 poppins">Estados</span>
                <i className="ms-2 gray fas font-12 fa-chevron-down align-self-center" />
            </button>
            <Popover placement="bottom" isOpen={this.state.states_menu} target={`btn-estados`} toggle={() => this.setState({ states_menu: !this.state.states_menu })} className="bg-gray-2">
                <PopoverBody className="d-flex flex-column justify-content-center">
                    {status.map((s, i) => <button key={i} className="btn btn-link btn-sm white text-left"><i className="fas fa-circle font-8 me-2" style={{ color: s.color }} />{s.name}</button>)}
                </PopoverBody>
            </Popover>
        </div>
    }
    renderAccordion = () => {
        const { toggle, floors, status, assigned_tasks } = this.state;
        let user = fakeAuth.getUser();
        let OCUPADA = config.room_status[3];
        return <section>
            <div className="bg-gray-2 d-flex black-input mb-3 mt-4" style={{ overflow: 'hidden' }}>
                <div className="w-100 debug">
                    {this.renderControls()}
                    {floors && floors.length > 0 && _.sortBy(floors, 'Label').map((floor, floor_index) => {
                        return (<section key={floor.IdFloor || floor.TemporalId}>

                            {/* FLOOR HEADER */}
                            <div className="px-4 py-3 d-flex pointer" onClick={() => this.setState({ toggle: floor_index }, () => {
                                // NEW: if we change floors, we clean up the selection and the status
                                const { status } = this.state;
                                status.forEach(s => s.selected = false);
                                this.setState({ status, rooms_selected: [] });
                            })}>
                                <span className="poppins white align-self-center">Piso {floor.Label}</span>
                                <i className={`ms-2 blue fas fa-chevron-${toggle === floor_index ? 'down' : 'right'} align-self-center`} />


                                {this.state.filter_assigned_tasks && _.find(assigned_tasks, at => at.IdFloor === floor.IdFloor) && <div className="ms-auto d-flex">
                                    <div className={`ms-4 bg-${this.state.filter_assigned_tasks ? 'green' : 'blue'} d-flex flex-row justify-content-center pointer zoomable`} style={{ width: 40, height: 40, borderRadius: 20, border: '6px solid black' }}>
                                        <span className="white font-12 poppins align-self-center">{_.filter(assigned_tasks, at => at.IdFloor === floor.IdFloor).length}</span>
                                    </div>
                                    <div className="d-flex flex-column ms-2 align-self-center">
                                        <span className="poppins white font-8">Tareas</span>
                                        <span className="poppins white font-8">Pendientes</span>
                                    </div>
                                </div>}

                            </div>
                            {/* PIECES */}
                            {toggle === floor_index && <div className="bg-black p-4 d-flex flex-wrap justify-content-center">

                                {_.chain(floor.Pieces)
                                    .filter(piece => {
                                        if (this.state.filter_assigned_tasks) {
                                            return _.find(piece.Staff, s => s.IdStaff === user.IdStaff) ? true : false
                                        }
                                        return true;
                                    })
                                    .value().map((piece, b) => <figure className={`pointer bg-gray-2 d-flex flex-column black-input m-1 p-2 ${_.find(this.state.rooms_selected, r => r.IdPiece === piece.IdPiece) ? 'white-border' : ''}`} style={{ width: 235 }} key={b} onClick={() => {
                                        /* 
                                        IF ADMIN -> SET THE ROOM AS SELECTED
                                        IF STAFF -> SEE
                                        */
                                        const user = fakeAuth.getUser();
                                        if (user.IdStaff) {
                                            this.setState({ watch_room: piece, seeing: true }, () => {
                                                this.props.floors_actions.fetch_comments(this.state.watch_room.IdPiece);
                                            });
                                        } else {
                                            const { rooms_selected, status, pieces_taken } = this.state;
                                            // Set the array of status pre-selected to the current status of this piece?
                                            status.forEach(s => {
                                                if (!status.selected && Math.pow(2, s.id - 1) & piece.Status) s.selected = true;
                                            });
                                            if (_.find(rooms_selected, r => r.IdPiece === piece.IdPiece)) {
                                                this.setState({
                                                    status,
                                                    rooms_selected: _.reject(rooms_selected, r => r.IdPiece === piece.IdPiece)
                                                }, () => {
                                                    // If there are no more pieces selected, clean up the status
                                                    if (!this.state.rooms_selected.length) {
                                                        status.forEach(s => {
                                                            s.selected = false;
                                                        });
                                                        this.setState({ status });
                                                    }
                                                });
                                            } else {
                                                this.setState({
                                                    status,
                                                    rooms_selected: [...rooms_selected, piece]
                                                });
                                            }
                                        }
                                    }}>
                                        <div className="d-flex w-100">

                                            {/* VER */}
                                            <div className="d-flex" onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.setState({ watch_room: piece, seeing: true }, () => {
                                                    this.props.floors_actions.fetch_comments(this.state.watch_room.IdPiece);
                                                });
                                            }} >
                                                <i className="fas fa-eye white font-10 align-self-center"></i>
                                                <span className="white font-10 align-self-center ms-1 poppins">Ver</span>
                                            </div>

                                            {/* STAFF ASSIGNED TO THIS PIECE */}
                                            {(piece.Staff || []).length > 0 && <span className={`ms-auto ${_.find(piece.Staff, s => s.IdStaff === user.IdStaff) ? 'green' : 'white'} font-10 align-self-center poppins`}><i className="icon-lb_staff font-10 me-1"></i>{(piece.Staff || []).length}</span>}

                                            {/* SELECTED */}
                                            <i className={`ms-auto ${_.find(this.state.rooms_selected, r => r.IdPiece === piece.IdPiece) ? 'fas fa-circle green' : 'far fa-circle white'} font-10 align-self-center ms-1`}></i>
                                        </div>

                                        {/* LABEL */}
                                        <div className="d-flex w-100 justify-content-center" style={{ height: 80 }}>
                                            <span className="white font-10 align-self-center mx-2 poppins">{piece.Label}</span>
                                        </div>

                                        {/* STATUS */}
                                        <div className="d-flex w-100">
                                            {status.map((s, index) => {
                                                if (piece.Status & Math.pow(2, s.id - 1)) {
                                                    return (<i key={index} className="me-1 fas fa-circle font-10 align-self-center" title={s.name} style={{ color: s.color }}></i>)
                                                } else return null
                                            })}
                                            {this.state.pieces_taken && this.state.pieces_taken.indexOf(piece.IdPiece) !== -1 
                                            && <i className="me-1 fas fa-circle font-10 align-self-center" title={OCUPADA.name} style={{ color: OCUPADA.color }}></i>}
                                            <span className="white font-10 align-self-center ms-1 poppins">Estados</span>
                                        </div>
                                    </figure>)}
                            </div>}
                        </section>)
                    })}
                </div>
            </div>
        </section >
    }
    renderCommon = () => {
        return (
            <div className="">
                {this.renderHeader()}
                {this.renderAccordion()}
                <div style={{ height: 200 }} />
            </div>
        )
    }


    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>

                    <Menu {...this.state} {...this.props} />

                    <Animated animationInDuration={300} animationOutDuration={300} isVisible={rooms_selected.length ? true : false} animateOnMount={false} animationIn="slideInUp" animationOut="slideOutDown" style={{ position: 'fixed', bottom: 0, left: 100, right: 0 }} className="p-4 d-flex justify-content-center">
                        <div className="black-input p-4 d-flex" style={{ backgroundColor: '#15191E' }}>
                            <div className="align-self-center">
                                <div className="d-flex">
                                    <span className="font-8 gray">Seleccionados:</span>
                                    <span className="font-8 ms-2 blue">{_.chain(rooms_selected)
                                        .map(r => r.Label).value().join(', ')}</span>
                                </div>
                                <div className="d-flex my-2">
                                    <div className="bg-gray-light w-100 align-self-center" style={{ height: 1 }}></div>
                                    <span className="gray font-8 w-100 align-self-center text-center">Cambiador de estados</span>
                                    <div className="bg-gray-light w-100 align-self-center" style={{ height: 1 }}></div>
                                </div>
                                <div className="d-flex justify-content-evenly align-items-start">
                                    {_.chain(status).reject(s => s.macro).value().map((s, i) => <div onClick={() => this.onBounce(i)} key={i} className={`w-100 d-flex flex-column justify-content-center text-center ${s.bounce ? 'bounce' : ''}`}>
                                        <button className={`btn btn-lg btn-${s.selected ? 'blue white' : 'gray-light black'} align-self-center`} style={{ borderRadius: 5 }}><i className={`${s.icon}`} /></button>
                                        <span className="font-8 white poppins mt-1 gray align-self-center">{s.name}</span>
                                    </div>)}
                                </div>
                            </div>
                            <div className="d-flex flex-column align-self-center ms-4">
                                <button onClick={() => this.setState({ assigning: true, selected_staff: null })} type="button" className="btn btn-lg btn-gray-light black w-100 poppins font-10">
                                    Asignar <i className="icon-lb_user" />
                                </button>
                                <button onClick={() => {
                                    this.setState({ watch_room: _.first(rooms_selected), seeing: true }, () => {
                                        this.props.floors_actions.fetch_comments(this.state.watch_room.IdPiece);
                                    });
                                }} type="button" className="btn btn-lg btn-black gray w-100 mt-2 poppins font-10">
                                    Ver <i className="fas fa-eye" />
                                </button>
                            </div>
                        </div>

                    </Animated>

                    {/* Assign */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.assigning ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center line1">Asignar tareas</h3>
                                <h6 className="blue text-center poppins font-10 line1">Habitaciones: {_.chain(rooms_selected)
                                    .map(r => r.Label).value().join(', ')}</h6>
                                <hr className="w-50 mx-auto" />
                                <div className="d-flex flex-row">
                                    <span className="white mt-2 me-2">Tareas:</span>
                                    {_.chain(status).reject(s => s.macro).filter(s => s.selected).value().map((s, i) => <div key={i} className={`d-flex align-self-start flex-column justify-content-center text-center`} style={{ width: 70 }}>
                                        <div className={`btn btn-lg btn-${false ? 'blue white' : 'gray-light black'} align-self-center`} style={{ borderRadius: 5 }}><i className={`${s.icon}`} /></div>
                                        <span className="font-8 white poppins mt-1 gray align-self-center">{s.name}</span>
                                    </div>)}
                                </div>
                                <div className="w-100 mt-4">
                                    <label className="white poppins font-12">Encargar a <i className="blue">*</i></label>
                                    <div style={{ width: 400 }} className={`${false ? 'error-input' : ''} d-flex mt-2`}>
                                        <ReactSearchAutocomplete
                                            className="w-100 white"
                                            styling={{
                                                height: "44px",
                                                border: "2px solid #343f4b",
                                                borderRadius: "12px",
                                                backgroundColor: "#0e1115",
                                                boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                                                hoverBackgroundColor: "#343F4B",
                                                color: "white",
                                                fontSize: "16px",
                                                iconColor: "#9a9a9a",
                                                placeholderColor: "white",
                                                clearIconMargin: '3px 14px 0 0',
                                                searchIconMargin: '0 0 0 16px'
                                            }}
                                            items={this.state.staffs || []}
                                            onSearch={() => { }}
                                            onHover={() => { }}
                                            onSelect={({ id, name }) => {
                                                this.setState({ selected_staff: _.find(this.props.staffs, s => s.IdStaff === id) });
                                            }}
                                            onFocus={() => { }}
                                            autoFocus
                                            formatResult={(item) => {
                                                return (
                                                    <div>
                                                        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
                                                    </div>
                                                )
                                            }}
                                        />                                        
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ assigning: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.onAssignStaff} type="button" className={`btn btn-lg btn-${this.state.selected_staff ? 'blue' : 'gray-2'} white ms-3 align-self-center`} >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    {/* Ver */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.seeing ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 align-self-center" style={{ overflow: 'hidden' }}>
                                <See {...this.props} room={this.state.watch_room} toggle={() => this.setState({ seeing: !this.state.seeing })} />
                            </div>
                        </div>
                    </Animated>


                </section>
            </Layout>
        )
    }
}

export default Housekeep;
