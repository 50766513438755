import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* fetch_bookings() {
    yield* takeEvery("BOOKINGS/FETCH", function* (action) {
        try {
            const result = yield call(({ id, filters }) => {
                if (filters) {
                    return request.post(`${config.apiEndpoint}/api/v4/properties/bookings/${id}`)
                        .send({ filters })
                        .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                        .then(data => (JSON.parse(data.text)));
                }
                else
                    return request.get(`${config.apiEndpoint}/api/v4/properties/bookings/${id}`)
                        .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                        .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { bookings } = result;
                yield put({ type: 'BOOKINGS/SET', bookings });
            } else {
                yield put({
                    type: 'BOOKINGS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'BOOKINGS/FETCH_FAILED',
                e
            });
        }
    });
}

//BOOKINGS/FILTERED/FETCH
export function* fetch_booking() {
    yield* takeEvery("BOOKING/FETCH", function* (action) {
        try {
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/bookings/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { book } = result;
                yield put({ type: 'BOOK/SET', book });
            } else {
                yield put({
                    type: 'BOOKING/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'BOOKING/FETCH_FAILED',
                e
            });
        }
    });
}

//
export function* assign_piece_to_book() {
    yield* takeEvery("BOOKING/ASSIGN/PIECE", function* (action) {
        try {
            const result = yield call(({ IdBookRoom, IdPiece }) => {
                return request.post(`${config.apiEndpoint}/api/v4/bookings/assign_piece`)
                    .send({ IdBookRoom, IdPiece })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'BOOKING/FETCH', id: action.IdBook });
                yield put({ type: 'BOOKING/RANGE/FETCH', IdProperty: action.IdProperty, CheckIn: action.CheckIn, CheckOut: action.CheckOut });
            } else {
                yield put({
                    type: 'BOOKING/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'BOOKING/FETCH_FAILED',
                e
            });
        }
    });
}

export function* free_up_pieces_from_book() {
    yield* takeEvery("BOOKING/FREE_UP/PIECES", function* (action) {
        try {
            const result = yield call(({ IdBook }) => {                
                return request.post(`${config.apiEndpoint}/api/v4/bookings/free_up_pieces`)
                    .send({ IdBook })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                yield put({ type: 'BOOKING/FETCH', id: action.IdBook });
                yield put({ type: 'BOOKING/RANGE/FETCH', IdProperty: action.IdProperty, CheckIn: action.CheckIn, CheckOut: action.CheckOut });
            } else {
                yield put({
                    type: 'BOOKING/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'BOOKING/FETCH_FAILED',
                e
            });
        }
    });
}

//export function fetch_day({ IdProperty, Day }) { return { type: 'BOOKING/DAY/FETCH', IdProperty, Day } }
export function* fetch_day() {
    yield* takeEvery("BOOKING/DAY/FETCH", function* (action) {
        try {
            const result = yield call(({ IdProperty, Day }) => {
                return request.post(`${config.apiEndpoint}/api/v4/logic/occupancy/day/${IdProperty}`)
                    .send({ Day })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { bookings } = result;
                yield put({ type: 'BOOKINGS/SET', bookings });
            } else {
                yield put({
                    type: 'BOOKINGS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'BOOKINGS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_range() {
    yield* takeEvery("BOOKING/RANGE/FETCH", function* (action) {
        try {
            const result = yield call(({ IdProperty, CheckIn, CheckOut }) => {
                return request.post(`${config.apiEndpoint}/api/v4/logic/occupancy/range/${IdProperty}`)
                    .send({ CheckIn, CheckOut })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { bookings } = result;
                yield put({ type: 'BOOKINGS/SET', bookings });
            } else {
                yield put({
                    type: 'BOOKINGS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'BOOKINGS/FETCH_FAILED',
                e
            });
        }
    });
}
