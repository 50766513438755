import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';


export function* fetch_countries() {
    yield* takeEvery("COUNTRIES/FETCH", function* (action) {
        try {
            const result = yield call(() => {                
                return request.get(`${config.apiEndpoint}/api/v4/countries/new`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { countries } = result;
                yield put({ type: 'COUNTRIES/SET', countries });
            } else {
                yield put({
                    type: 'COUNTRIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'COUNTRIES/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_countries_all() {
    yield* takeEvery("COUNTRY_CODES/FETCH", function* (action) {
        try {
            const result = yield call(() => {                
                return request.get(`${config.apiEndpoint}/api/v4/countries/full`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { country_codes } = result;                
                yield put({ type: 'COUNTRY_CODES/SET', country_codes });
            } else {
                yield put({
                    type: 'COUNTRIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'COUNTRIES/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_nationalities() {
    yield* takeEvery("NATIONALITIES/FETCH", function* (action) {
        try {
            const result = yield call(() => {                
                return request.get(`${config.apiEndpoint}/api/v4/countries/nationalities`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { nationalities } = result;                
                yield put({ type: 'NATIONALITIES/SET', nationalities });
            } else {
                yield put({
                    type: 'NATIONALITIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'NATIONALITIES/FETCH_FAILED',
                e
            });
        }
    });
}