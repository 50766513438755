import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';


export function* fetch_floors() {
    yield* takeEvery("FLOORS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/properties/floors/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { floors } = result;
                yield put({ type: 'FLOORS/SET', floors });
            } else {
                yield put({
                    type: 'FLOORS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'FLOORS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_room() {
    yield* takeEvery("ROOM/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/rooms/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { room } = result;
                yield put({ type: 'ROOM/SET', room });
            } else {
                yield put({
                    type: 'ROOM/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'ROOM/FETCH_FAILED',
                e
            });
        }
    });
}

export function* archive_room() {
    yield* takeEvery("ROOM/ARCHIVE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/rooms/archive/${id}`)
                    .send({})
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { IdProperty } = action
                yield put({ type: 'ROOMS/FETCH', id: IdProperty });
            } else {
                yield put({
                    type: 'ROOM/ARCHIVE/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'ROOM/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}

export function* toggle_room() {
    yield* takeEvery("ROOM/TOGGLE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id, IsActive }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/rooms/toggle/${id}`)
                    .send({ IsActive })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'ROOM/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}

export function* update_piece_status() {
    yield* takeEvery("PIECE/STATUS/UPDATE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ piece }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/floors/piece/status/${piece.IdPiece}`)
                    .send({ Status: piece.Status, MissingObjects: piece.MissingObjects })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'ROOM/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}

export function* assign_piece_staff() {
    yield* takeEvery("PIECE/STAFF/ASSIGN", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ IdPiece, IdStaff, IdProperty }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/floors/piece/staff/assign`)
                    .send({ IdPiece, IdStaff, IdProperty })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({ type: 'FLOORS/FETCH', id: action.IdProperty });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'PIECE/STAFF/ASSIGN_FAILED',
                e
            });
        }
    });
}

export function* unassign_piece_staff() {
    yield* takeEvery("PIECE/STAFF/UNASSIGN", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ IdPiece, IdStaff }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/floors/piece/staff/unassign`)
                    .send({ IdPiece, IdStaff })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({ type: 'FLOORS/FETCH', id: action.IdProperty });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'PIECE/STAFF/ASSIGN_FAILED',
                e
            });
        }
    });
}

export function* fetch_piece_comments() {
    yield* takeEvery("PIECE/COMMENTS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/pieces/comments/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            yield put({ type: 'LOADING/SET', loading: false });
            if (result && result.success) {
                const { comments } = result;
                yield put({ type: 'COMMENTS/SET', comments });
            } else {
                yield put({
                    type: 'ROOM/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'PIECE/STAFF/ASSIGN_FAILED',
                e
            });
        }
    });
}

export function* add_piece_comments() {
    yield* takeEvery("PIECE/COMMENT/ADD", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ IdPiece, Comment }) => {
                return request.post(`${config.apiEndpoint}/api/v4/pieces/comment/${IdPiece}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({ Comment })
                    .then(data => (JSON.parse(data.text)));
            }, action);
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({ type: 'PIECE/COMMENTS/FETCH', id: action.IdPiece });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'PIECE/STAFF/ASSIGN_FAILED',
                e
            });
        }
    });
}