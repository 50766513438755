function room_Reducer(state = [], action) {
    switch (action.type) {
        case 'ROOM/SET':            
            return action.room;
        case 'ROOM/CLEAR':
            return null;
        default:
            return state;
    }
}

export default room_Reducer;
