import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import Loguito from './loguito';
import fakeAuth from '../../fakeAuth';

class Menu extends React.Component {
    state = {
        menu_toggled: false
    }

    componentWillMount() {
        this.setState({
            menu_toggled: this.props.menu_toggled,
            path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/'),
            IDP: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')) : null
        });
    }

    // TODO: this items are based on the logged user privileges
    render() {
        const { hide } = this.props, { IDP } = this.state;
        let user = fakeAuth.getUser();

        return <section>

            <Animated animateOnMount={false} animationInDuration={400} animationOutDuration={400} animationIn="slideInLeft" animationOut="slideOutLeft" isVisible={true || this.state.menu_toggled} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, width: 100, }} className="w3-hide-small bg-gray-2">
                <div className="w-100 d-flex flex-column p-2">
                    <Link to="/dashboard" className={`mt-3 w-100 rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <Loguito className="font-40 align-self-center ms-2" />
                    </Link>
                    <Link title="Dashboard" to={IDP ? `/dashboard/${IDP.IdProperty}` : '/properties'} className={`mt-4 w-100 rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_dashboard font-20 align-self-center ms-2 ${this.state.path.indexOf('dashboard') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>
                    <Link title="Reservas" to={IDP ? `/bookings/${IDP.IdProperty}` : '/properties'} className={`w-100 rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_reservaciones font-14 align-self-center ms-2 ${this.state.path.indexOf('bookings') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>
                    <Link title="Calendario" to={IDP ? `/frontdesk/${IDP.IdProperty}` : '/properties'} className={`w-100 rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`far fa-calendar font-14 align-self-center ms-2 ${this.state.path.indexOf('frontdesk') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>
                    {!user.IdProperty && <Link title="Planes" to={IDP ? `/plans/${IDP.IdProperty}` : '/properties'} className={`w-100 rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_planes font-12 align-self-center ms-2 ${this.state.path.indexOf('plans') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>}
                    {!user.IdProperty && <Link title="Habitaciones" to={IDP ? `/rooms/${IDP.IdProperty}` : '/properties'} className={`w-100  rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_rooms font-10 align-self-center ms-2 ${this.state.path.indexOf('rooms') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>}
                    <Link title="Huespedes" to={IDP ? `/guests/${IDP.IdProperty}` : '/properties'} className={`w-100  rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_huespedes font-10 align-self-center ms-2 ${(this.state.path.indexOf('guests') !== -1 || this.state.path.indexOf('guest') !== -1) ? 'blue' : 'left-menu-link'}`} />
                    </Link>
                    {!user.IdProperty && <Link title="Reportes" to={IDP ? `/reports/${IDP.IdProperty}` : '/reports'} className={`w-100  rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_reportes font-10 align-self-center ms-2 ${this.state.path.indexOf('reports') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>}
                    <Link to={IDP ? `/coorps/${IDP.IdProperty}` : '/properties'} className={`w-100  rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`far fa-star font-12 align-self-center ms-2 ${this.state.path.indexOf('coorps') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>
                    {!user.IdProperty && <Link to={IDP ? `/staff/${IDP.IdProperty}` : '/properties'} className={`w-100  rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_staff font-14 align-self-center ms-2 ${this.state.path.indexOf('staff') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>}
                    <Link title="Housekeep" to={IDP ? `/housekeep/${IDP.IdProperty}` : '/properties'} className={`w-100  rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_housekeep font-12 align-self-center ms-2 ${this.state.path.indexOf('housekeep') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>
                    {!user.IdProperty && <Link title="Floormap" to={IDP ? `/floormap/${IDP.IdProperty}` : '/properties'} className={`w-100 rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`fas fa-map font-12 align-self-center ms-2 ${this.state.path.indexOf('floormap') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>}
                    <Link title="Settings" to={IDP ? `/settings/${IDP.IdProperty}` : '/properties'} className={`w-100  rounded d-flex flex-row justify-content-center pointer`} style={{ height: 50 }}>
                        <i className={`icon-lb_settings font-12 align-self-center ms-2 ${this.state.path.indexOf('settings') !== -1 ? 'blue' : 'left-menu-link'}`} />
                    </Link>
                </div>
            </Animated>
        </section>
    }
}

export default Menu;