import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';
import OffersList from './partials/offers';
import PlansList from './partials/plans';

class Plans extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 1,
        rooms_selected: [],
        status: config.room_status,
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }

    // Lifecycle
    componentWillMount() {
        const { id } = this.props.params;
        if (id) {
            this.props.offers_actions && this.props.offers_actions.fetch(id);
            this.props.plans_actions && this.props.plans_actions.fetch(id);
        }
    }
    componentWillReceiveProps = nextProps => {
        const { plans, offers } = nextProps;
        this.setState({ plans, offers });
    }
    //   

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_planes blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Planes y Ofertas</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            <div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input className="w-100 p-3 white" type="text" name="email" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} />
                </div>
            </div>
            <div className="w-100 align-self-center d-flex">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }

    renderCommon = () => {
        const { offers, plans } = this.state, { id } = this.props.params;
        return (
            <div className="">
                {this.renderHeader()}
                <Animated animationIn="fadeInRight" animateOnMount={false} animationInDuration={300} isVisible={plans ? true : false}>
                    <div className="w-100 d-flex mt-4">
                        <span className="white ms-2 font-20 poppins align-self-center">Planes</span>
                        <Link to={`/plans/add/${id}`} className="btn btn-lg btn-blue white px-4 ms-4 align-self-center d-flex" >
                            Nuevo plan <i className="fas fa-plus-circle align-self-center font-10 ms-3" />
                        </Link>
                    </div>
                    <PlansList {...this.props} />
                </Animated>
                <Animated animationIn="fadeInRight" animateOnMount={false} animationInDuration={300} isVisible={offers ? true : false}>
                    <div className="w-100 d-flex mt-4">
                        <span className="white ms-2 font-20 poppins align-self-center">Ofertas</span>
                        <Link to={`/offers/add/${id}`} className="btn btn-lg btn-blue white px-4 ms-4 align-self-center d-flex" >
                            Nueva oferta <i className="fas fa-plus-circle align-self-center font-10 ms-3" />
                        </Link>
                    </div>
                    <OffersList {...this.props} />
                </Animated>
                <div style={{ height: 200 }} />
            </div >
        )
    }

    render() {
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>

                    <Menu {...this.state} {...this.props} />



                </section>
            </Layout>
        )
    }
}

export default Plans;
