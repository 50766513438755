import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';


export function* fetch_coorps() {
    yield* takeEvery("COORPS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id, letter = 'A' }) => {
                return request.get(`${config.apiEndpoint}/api/v4/properties/coorps/${id}/${letter}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { coorps } = result;                
                yield put({ type: 'COORPS/SET', coorps });
            } else {
                yield put({
                    type: 'COORPS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'COORPS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_all_coorps() {
    yield* takeEvery("COORPS/FETCH/ALL", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/coorps/all/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                const { coorps } = result;                                
                yield put({ type: 'COORPS/SET', coorps });
            } else {
                yield put({
                    type: 'COORPS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'COORPS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_coorp() {
    yield* takeEvery("COORP/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id, letter = 'A' }) => {
                return request.get(`${config.apiEndpoint}/api/v4/coorps/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { coorp } = result;                
                yield put({ type: 'COORP/SET', coorp });
            } else {
                yield put({
                    type: 'COORPS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'COORPS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_guest() {
    yield* takeEvery("GUEST/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {                
                return request.get(`${config.apiEndpoint}/api/v4/guests/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                const { guest } = result;                
                yield put({ type: 'GUEST/SET', guest });
            } else {
                yield put({
                    type: 'GUEST/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'GUEST/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_guest_bookings() {
    yield* takeEvery("GUEST/BOOKINGS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {                
                return request.get(`${config.apiEndpoint}/api/v4/guests/bookings/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                const { bookings } = result;                
                yield put({ type: 'BOOKINGS/SET', bookings });
            } else {
                yield put({
                    type: 'GUEST/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'GUEST/FETCH_FAILED',
                e
            });
        }
    });
}



export function* archive_staff() {
    yield* takeEvery("STAFF/ARCHIVE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/staff/archive/${id}`)
                    .send({})
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { IdProperty } = action
                yield put({ type: 'STAFFS/FETCH', id: IdProperty });
            } else {
                yield put({
                    type: 'STAFF/ARCHIVE/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'STAFF/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}

export function* toggle_staff() {
    yield* takeEvery("STAFF/TOGGLE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id, IsActive }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/staff/toggle/${id}`)
                    .send({ IsActive })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);            
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'STAFF/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}
