import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import Layout from '../layouts/claire';
import { generateRandomId, getBase64 } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import BlackOverlay from '../partials/black_overlay';
import fakeAuth from '../../fakeAuth';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LineLoader from '../partials/line_loader';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../partials/custom_date_picker';
import moment from 'moment';
import request from 'superagent';
import config from '../../config';

class AddOffer extends React.Component {

    state = {
        page: 1,
        toggle: true,
        menu_toggled: false,
        validations: {},
        comodities: config.room_comodities,
        editorMode: 1, // [1: es, 2: en]
        editorStage: 1, // [1: description, 2: rates, 3: terms]
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        period: {
            WeekDays: 0,
            SalesCommission: {},
            RemainingInventory: {},
            MinOccupancy: {},
            MaxOccupancy: {},
            BlockedAllowance: 0,
            CloseAtArrival: 0,
            CloseAtDeparture: 0,
            MinDaysStay: {},
        },
        room: {
            BedTypes: [{
                id: generateRandomId(10)
            }],
            IdProperty: this.props.params.id,
            Comodities: [],
            Name: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
            Description: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
        },
        offer: {
            IdProperty: this.props.params.id,
            Name: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
            Description: {
                en: { Value: '', IdLanguage: 1 },
                es: { Value: '', IdLanguage: 2 },
            },
        },
        offer_types: config.offer_types,
        week_day_flags: config.week_day_flags,
    }

    // lifecycle
    componentWillMount() {
        const { params } = this.props;
        this.setState({ bootstrapped: true }, () => { this.onLoadEditorState(1, 1); });

        // Fetch rooms and plans
        const { id } = this.props.params;
        if (id) {
            this.props.rooms_actions && this.props.rooms_actions.fetch(id);
            this.props.plans_actions && this.props.plans_actions.fetch(id);
        }
    }
    componentWillReceiveProps = nextProps => {
        const { plans, rooms } = nextProps;
        this.setState({ plans, rooms });
    }
    componentWillUnmount = () => {
        //this.props.properties_actions && this.props.properties_actions.clean();
    }
    //

    onLoadEditorState = (mode, stage) => {
        const { offer } = this.state;
        let html = '';
        switch (mode) {
            case 1: // Description
                html = stage === 1 ? offer.Description.es.Value : offer.Description.en.Value; break;
            default: break;
        }
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            this.setState({
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)),
                bootstrapped: true,
                editorMode: mode,
                editorStage: stage,
            });
        }
    }
    onEditorStateChange = (editorState) => this.setState({ editorState }, () => {
        const { offer, editorMode, editorStage } = this.state;
        switch (editorMode) {
            case 1: // Description
                offer.Description[editorStage === 1 ? 'es' : 'en'].Value = draftToHtml(convertToRaw(editorState.getCurrentContent())); break;
            default: break;
        }
    });
    onNameChange = () => {
        const { editorStage, offer } = this.state;
        offer.Name[editorStage === 1 ? 'es' : 'en'].Value = this.txtRoomName.value;
        this.setState({ offer });
    }

    // Saving    
    doSaveOffer = () => {
        const { offer, rooms, plans } = this.state;
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
            }, () => {
                // Call API
                request[offer.IdOffer ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/offers${offer.IdOffer ? `/${offer.Idoffer}` : ''}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({
                        PeriodName: this.txtPeriodName.value,
                        Amount: parseFloat(this.txtAmount.value),
                        MinDays: parseInt(this.txtMinDays.value, 10),
                        Start: moment(offer.Start).utc().format(),
                        End: moment(offer.End).utc().format(),
                        IdProperty: this.props.params.id,
                        PeriodName: this.txtPeriodName.value,
                        Rooms: _.chain(rooms).filter(r => r.selected).map(u => u.IdRoom).value(),
                        Plans: _.chain(plans).filter(r => r.selected).map(u => u.IdPlan).value(),
                        ..._.pick(offer, 'Description', 'Name', 'OfferType')
                    })
                    .then((data) => {
                        if (data.body.success) {
                            this.props.router.goBack();
                        } else {
                            // TODO: show server error?
                            this.setState({ microTransac: false });
                        }
                    }, (error) => {
                        this.setState({ microTransac: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });
        }
    }

    validate = () => {
        this.setState({ validations: {} }, () => {
            const { validations, offer } = this.state;

            if (!offer.Name.es.Value) {
                validations.txtRoomName = 'Por favor, introduce (al menos) el nombre en Español de la oferta';
                this.txtRoomNameWrapper.scrollIntoView({ behavior: 'smooth' });
                this.setState({ validations });
                return;
            }
            if (!this.txtPeriodName.value) {
                validations.txtPeriodName = 'Por favor, introduce el nombre del período';
                this.txtPeriodNameWrapper.scrollIntoView({ behavior: 'smooth' });
                this.setState({ validations });
                return;
            }
            if (!offer.OfferType) {
                validations.txtOfferType = 'Por favor, seleccione una opción';
                this.txtOfferTypeWrapper.scrollIntoView({ behavior: 'smooth' });
                this.setState({ validations });
                return;
            }
            if (_.isNaN(parseInt(this.txtAmount.value, 10))) {
                validations.txtAmount = 'Por favor introduzca un número válido aqui';
                this.txtAmountWrapper.scrollIntoView({ behavior: 'smooth' });
                this.setState({ validations });
                return;
            }
            if (_.isNaN(parseInt(this.txtMinDays.value, 10))) {
                validations.txtMinDays = 'Por favor introduzca un número válido aqui';
                this.txtMinDaysWrapper.scrollIntoView({ behavior: 'smooth' });
                this.setState({ validations });
                return;
            }           

            this.setState({ confirm: true });

        });
    }
    //

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_planes blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Nueva oferta</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
                <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>
            </div>
            <TopRightMenu {...this.props} />
        </div>
    }
    renderPlans = () => {
        const { validations, period, offer, offer_types } = this.state, { WeekDays } = period, { rooms, plans } = this.state;
        return <div className="w-100 mx-auto mt-4">
            <div className="d-flex">
                <label className="white poppins font-12 align-self-center">Aplicar oferta a plan</label>
                {plans && plans.length > 0 && <span onClick={() => {
                    let flag = _.find(plans, r => !r.selected) ? true : false;
                    plans.forEach(r => r.selected = flag);
                    this.setState({ plans });
                }} className="blue font-10 px-3 pointer ms-auto poppins align-self-center">{_.find(plans, r => !r.selected) ? 'Todos' : 'Ninguno'}</span>}
            </div>
            {plans && plans.map((plan, i) => <div key={plan.IdPlan} className={`black-input d-flex mt-2`}>
                <span className="p-3 align-self-center white">{plan.GivenName}</span>
                <i className={`fas font-20 align-self-center fa-toggle-off mx-auto me-3 white`} />
            </div>)}
            {plans && plans.length === 0 && <div className="bg-gray-2 gray-light rounded mt-2">
                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                    <h3 className="align-self-center gray text-center poppins">Por lo visto no tienes<br />planes creados.</h3>
                </div>
            </div>}
        </div>
    }
    renderRooms = () => {
        const { validations, period, offer, offer_types } = this.state, { WeekDays } = period, { rooms, plans } = this.state;
        return <div className="w-100 mx-auto mt-4">
            <div className="d-flex">
                <label className="white poppins font-12 align-self-center">Aplicar oferta a habitación</label>
                {rooms && rooms.length > 0 && <span onClick={() => {
                    let flag = _.find(rooms, r => !r.selected) ? true : false;
                    rooms.forEach(r => r.selected = flag);
                    this.setState({ rooms });
                }} className="blue font-10 px-3 pointer ms-auto poppins align-self-center">{_.find(rooms, r => !r.selected) ? 'Todas' : 'Ninguna'}</span>}
            </div>
            {rooms && rooms.map((room, i) => <div key={room.IdRoom} className={`black-input d-flex mt-2`}>
                <span className="p-3 align-self-center white">{room.GivenName}</span>
                <i onClick={() => {
                    const { rooms } = this.state;
                    rooms[i].selected = !rooms[i].selected;
                    this.setState({ rooms });
                }} className={`fas font-20 align-self-center fa-toggle-${room.selected ? 'on green' : 'off white'} mx-auto me-3`} />
            </div>)}
            {rooms && rooms.length === 0 && <div className="bg-gray-2 gray-light rounded mt-2">
                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                    <h3 className="align-self-center gray text-center poppins">Por lo visto no tienes<br />habitaciones creadas.</h3>
                </div>
            </div>}
        </div>
    }
    renderAppliances = () => {
        const { validations, period, offer, offer_types } = this.state, { WeekDays } = period, { rooms, plans } = this.state;
        return <section>
            {/* Appliances */}
            <div className="d-flex w3-content">
                {/* Rooms */}
                {this.renderRooms()}
                <div className="p-1" />
                {/* Plans */}
                {this.renderPlans()}
            </div>
        </section>
    }
    renderPeriod = () => {
        const { validations, period, offer, offer_types } = this.state, { WeekDays } = period;
        return <section>
            <h4 className="white poppins mt-4">Períodos y tipo de oferta</h4>
            <hr />

            {/* Periodo */}
            <div className="d-flex w3-content">
                {/* Periodo */}
                <div className="w-100 mx-auto mt-4" ref={(i) => this.txtPeriodNameWrapper = i}>
                    <label className="white poppins font-12">Nombre del periodo <i className="blue">*</i></label>
                    <div id="txtPeriodName" className={`black-input ${validations.txtPeriodName ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Agregar valor" ref={(input) => this.txtPeriodName = input} defaultValue={period.Name} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtPeriodName ? true : false} target={`txtPeriodName`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtPeriodName = !validations.txtPeriodName;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtPeriodName}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
            {/* Dates */}
            <div className="d-flex w3-content">
                {/* Start */}
                <div className="w-100 mx-auto mt-4">
                    <label className="white poppins font-12">Inicio <i className="blue">*</i></label>
                    <div id="txtPeriodStart">
                        <DatePicker highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" />} selected={moment(offer.Start).utc()} showYearDropdown scrollableYearDropdown
                            yearDropdownItemNumber={15} onChange={date => {
                                const { offer } = this.state;
                                offer.Start = moment(date).utc();
                                this.setState({ offer });
                            }} className="white font-12"
                        />
                    </div>
                    <Popover placement="right" isOpen={validations.txtPeriodStart ? true : false} target={`txtPeriodStart`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtPeriodStart = !validations.txtPeriodStart;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtPeriodStart}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="p-1" />
                {/* End */}
                <div className="w-100 mx-auto mt-4">
                    <label className="white poppins font-12">Fin <i className="blue">*</i></label>
                    <div id="txtPeriodEnd">
                        <DatePicker highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" />} selected={moment(offer.End).utc()} showYearDropdown scrollableYearDropdown
                            yearDropdownItemNumber={15} onChange={date => {
                                const { offer } = this.state;
                                offer.End = moment(date).utc();
                                this.setState({ offer });
                            }} className={`${validations.txtPeriodEnd ? 'error-input' : ''} white font-12`}
                        />
                    </div>
                    <Popover placement="right" isOpen={validations.txtPeriodEnd ? true : false} target={`txtPeriodEnd`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtPeriodEnd = !validations.txtPeriodEnd;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtPeriodEnd}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>

            <h4 className="text-center mt-4 white">Tipo de oferta</h4>

            {/* Types */}
            <div className="d-flex w3-content">
                {/* Offer Type */}
                <div className="w-100 mt-4" ref={i => this.txtOfferTypeWrapper = i}>
                    <label className="white poppins font-12">Calcular por <i className="blue">*</i></label>
                    <div id="txtOfferType" className={`black-input ${validations.txtOfferType ? 'error-input' : ''} d-flex mt-2`}>
                        <Dropdown isOpen={this.state.pickOfferType} toggle={() => this.setState({ pickOfferType: !this.state.pickOfferType })} className="w-100">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 no-border p-3 white bg-transparent d-flex">
                                <span className="align-self-center">{(_.find(offer_types, t => parseInt(t.id, 10) === offer.OfferType) || { name: 'Seleccione' }).name}</span>
                                <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {offer_types.map((c, i) => <DropdownItem key={i} onClick={() => {
                                    const { offer } = this.state;
                                    offer.OfferType = parseInt(c.id, 10);
                                    this.setState({ offer });
                                    const { validations } = this.state;
                                    validations.txtOfferType = false;
                                    this.setState({ validations });
                                }}>{c.name}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <Popover placement="right" isOpen={validations.txtOfferType ? true : false} target={`txtOfferType`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtOfferType = !validations.txtOfferType;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtOfferType}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="p-1" />
                {/* Amount */}
                <div className="w-100 mx-auto mt-4" ref={i => this.txtAmountWrapper = i}>
                    <label className="white poppins font-12">Monto <i className="blue">*</i></label>
                    <div id="txtAmount" className={`black-input ${validations.txtAmount ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Agregar valor" ref={(input) => this.txtAmount = input} defaultValue={offer.Amount} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtAmount ? true : false} target={`txtAmount`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtAmount = !validations.txtAmount;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtAmount}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <div className="p-1" />
                {/* Min Days */}
                <div className="w-100 mx-auto mt-4" ref={i => this.txtMinDaysWrapper = i}>
                    <label className="white poppins font-12">Noches mínimas <i className="blue">*</i></label>
                    <div id="txtMinDays" className={`black-input ${validations.txtMinDays ? 'error-input' : ''} d-flex mt-2`}>
                        <input className="w-100 p-3 white" type="text" name="name" placeholder="Agregar valor" ref={(input) => this.txtMinDays = input} defaultValue={offer.MinDays} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtMinDays ? true : false} target={`txtMinDays`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtMinDays = !validations.txtMinDays;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtMinDays}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
        </section>
    }
    renderBasic = () => {
        const { editorState, editorStage, editorMode, validations, offer } = this.state;
        return <section className="mt-4">
            <h4 className="white poppins mt-4">Información general</h4>
            <hr />
            <div className="w3-content">
                {/* Room Name */}
                <div className="w-100 mt-4" ref={(input) => this.txtRoomNameWrapper = input}>
                    <div className="d-flex">
                        <label className="white poppins font-12">Nombre <i className="blue">*</i></label>
                        <div className="d-flex flex-row ms-auto">
                            <button onClick={() => this.setState({ editorStage: 1 }, () => this.onLoadEditorState(editorMode, 1))} className={`ms-auto btn btn-sm btn-link  ${editorStage === 1 ? 'blue' : 'gray'}`}>ES</button>
                            <button onClick={() => this.setState({ editorStage: 2 }, () => this.onLoadEditorState(editorMode, 2))} className={`btn btn-sm btn-link ${editorStage === 2 ? 'blue' : 'gray'}`}>EN</button>
                        </div>
                    </div>
                    <div id="txtRoomName" className={`black-input ${validations.txtRoomName ? 'error-input' : ''} d-flex mt-2`}>
                        {editorStage === 1 && <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre de la oferta en español" ref={(input) => this.txtRoomName = input} defaultValue={offer.Name.es.Value} onChange={this.onNameChange} />}
                        {editorStage === 2 && <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre de la oferta en ingles" ref={(input) => this.txtRoomName = input} defaultValue={offer.Name.en.Value} onChange={this.onNameChange} />}
                    </div>
                    <Popover placement="right" isOpen={validations.txtRoomName ? true : false} target={`txtRoomName`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtRoomName = !validations.txtRoomName;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtRoomName}</span>
                        </PopoverBody>
                    </Popover>
                </div>
                <label className="white poppins font-12 mt-4 mb-2">Descripción <i className="blue">*</i></label>
                <Editor
                    wrapperClassName="demo-wrapper"
                    editorState={editorState}
                    editorClassName="demo-editor px-4"
                    onEditorStateChange={this.onEditorStateChange}
                />
            </div>
        </section>
    }
    renderCommon = () => {
        return (
            <div>
                {this.renderHeader()}
                {this.state.bootstrapped && <section>

                    {/* Room details */}
                    {this.state.page === 1 && <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.toggle} animationOut="fadeOutRight" animationIn="fadeInRight" className="mt-4">
                        {this.renderBasic()}
                        {this.renderPeriod()}
                        {this.renderAppliances()}
                        {/* Buttons */}
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white align-self-center" >
                                <i className="icon-corner-down-left white font-12" />
                            </button>
                            <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className="far fa-save white font-12" />
                            </button>
                        </div>
                    </Animated>}

                </section>
                }
                <div style={{ height: 100 }} />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Nueva oferta</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de crear esta nueva oferta?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doSaveOffer} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default AddOffer;
