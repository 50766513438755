import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router';
import Layout from '../layouts/claire';
import { generateRandomId, getBase64, getReadableFileSizeString } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import BlackOverlay from '../partials/black_overlay';
import moment from 'moment';
import fakeAuth from '../../fakeAuth';
import _, { slice } from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LineLoader from '../partials/line_loader';
import MaskedInput from 'react-text-mask';
import request from 'superagent';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../partials/custom_date_picker';
import config from '../../config';

const baseStyle = {
    flex: 1,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 10,
    borderColor: '#343f4b',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onDrop && props.onDrop(file);
            props.drop && props.drop(file);
        });

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'image/*', onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container" style={{ position: 'relative' }}>

            <div {...getRootProps({ style })} className="pointer bg-gray-dark">
                {/*<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    className=" d-flex flex-row justify-content-center w-100 h-100">
                    <div style={{ width: 420, height: 420, borderRadius: 210, backgroundColor: '#e5e5e5', opacity: 0.3 }} className="align-self-center" />
    </div>*/}
                <input {...getInputProps()} />
                <div style={{ height: 50 }} />
                <i className="fas fa-upload white font-60" />
                <div style={{ height: 20 }} />
                <p className="text-center font-18">Arrastrar y soltar archivos, o <span className="blue">buscar</span></p>
                <p className="text-center font-14 gray">Soporta: JPEG, PNG. • Max: 2Mb c/u.</p>
                {/*<div style={{ border: '3px solid #2cad3c', borderRadius: 20, width: 200 }} className="p-2 text-center mt-4"><span className="green">UPLOAD VIDEOS</span></div>*/}
                <div style={{ height: 50 }} />
            </div>

        </div>
    );
}

class AddBooking extends React.Component {
    state = {
        mode: parseInt((localStorage.getItem('lb.rt') || 1), 10),
        files: [],
        validations: {},
        menu_toggled: false,
        filters: {
            CheckIn: moment().utc()
        },
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        bootstrapped: false,
        sources: [{ id: 1, value: 'Source 1' }, { id: 2, value: 'Source 2' }],
        staff: {
            IdProperty: this.props.params.id,
            PhoneCountryCode: '+58',
            CellphoneCountryCode: '+58',
        },
        source: 'WEB',
        cart: []
    }

    // lifecycle
    componentWillMount() {
        const { params } = this.props;
        this.props.form_actions.fetch_countries();
    }
    componentWillReceiveProps = nextProps => {

    }
    componentWillUnmount = () => {
        //this.props.properties_actions && this.props.properties_actions.clean();
    }
    //

    onLogo = e => {
        const { staff } = this.state, { } = this.props;
        getBase64(e.target.files[0]).then(data => {
            staff.LogoUrl = data;
            this.setState({ staff, doUpdateLogo: true });
        });
    }
    onSaveBook = () => {
        // validate
        if (this.state.microTransac) return;
        const { cart } = this.state;
        if (!cart.length) {
            alert('Please add at least one room');
            return;
        }
        this.setState({ saving: true, microTransac: true }, () => {

            let current_array = 'items';
            switch (this.state.mode) {
                case 1: current_array = 'items'; break;
                case 2: current_array = 'WALKIN'; break;
                case 3: current_array = 'COORP'; break;
                case 4: current_array = 'NOCHE_ROMANTICA'; break;
                default: break;
            }

            // TODO: the idea here is to send all 
            // the cart to the API, and generate a booking ID,
            // then redirect to the 'booking' page, for them to complete guest information (or delete it if is not compeltes?)
            // Call API
            request.post(`${config.apiEndpoint}/api/v4/bookings/${this.props.params.id}`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .send({
                    IdAccount: fakeAuth.getUser().IdAccount,
                    Cart: this.state.cart,
                    RateHeader: {
                        id: this.state.mode,
                        name: current_array
                    },
                    IsGroup: this.state.group ? 1 : 0,
                    Source: this.state.source,
                    CheckIn: moment(this.state.CheckIn).utc().format(),
                    CheckOut: moment(this.state.CheckOut).utc().format(),
                })
                .then((data) => {

                    if (data.body.success) {
                        let ID = data.body.IdBook;
                        this.props.router.push(`/booking/${ID}`);
                    } else
                        this.setState({ microTransac: false });

                }, (error) => {
                    this.setState({ microTransac: false, error: 'Something wrong' });
                    //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                });
        });
    }
    onDrop = (file) => {

        // application/pdf -> image/png -> image/jpeg        
        const { files } = this.state;
        files.push({ file, eye: false });
        this.setState({ files });

        /*getBase64(file).then(data => {
            request.post(`${config.apiEndpoint}/api/v4/rooms/image/we`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .send({ data })
                .then();
        });*/
    }
    onDeleteImage = (IdAlbum) => {
        request.delete(`${config.apiEndpoint}/api/v4/staff/image/${IdAlbum}`)
            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
            .send({}).then(() => { });
    }

    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    onSearch = () => {
        const { CheckIn, CheckOut, validations, mode } = this.state;
        let current_array = 'items';
        switch (mode) {
            case 1: current_array = 'items'; break;
            case 2: current_array = 'WALKIN'; break;
            case 3: current_array = 'COORP'; break;
            default: break;
        }

        this.setState({ validations: {}, cart: [] }, () => {
            const { validations, property } = this.state;

            let Nights = this.txtNights.value ? (parseInt(this.txtNights.value, 10) || 1) : 1;

            if (!CheckIn) {
                validations.txtCheckIn = 'Por favor, selecciona la fecha de check-in';
                this.setState({ validations });
                return;
            }

            this.setState({ microTransac: true, CheckOut: moment(CheckIn).add(Nights, 'days') }, () => {
                request.post(`${config.apiEndpoint}/api/v4/logic/availability/${this.props.params.id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({ CheckIn, CheckOut: this.state.CheckOut })
                    .then((data) => {
                        if (data.body.success) {
                            this.setState({ rooms: data.body.rooms, microTransac: false, bootstrapped: true }, () => {
                                this.state.rooms.forEach((room, index) => {
                                    request.post(`${config.apiEndpoint}/api/v4/logic/rates/${room.IdRoom}`)
                                        .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                                        .send({ CheckIn, CheckOut: this.state.CheckOut })
                                        .then((data) => {
                                            const { rooms } = this.state;
                                            rooms[index].RATE = data.body.rates;
                                            rooms[index].MIN_RATE = 0;
                                            rooms[index].RATE.forEach(rate => {
                                                if (rate.DAY_RATE) {
                                                    rooms[index].MIN_RATE += parseFloat((rate.DAY_RATE[current_array] || rate.DAY_RATE.items)[0].Adults);
                                                }
                                            });
                                            this.setState({ rooms });
                                        });
                                });
                            });
                        } else {
                            // TODO: show server error?
                            this.setState({ microTransac: false });
                        }
                    }, (error) => {
                        this.setState({ microTransac: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });

        });
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_reservaciones white font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Nueva reserva</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
                <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>
            </div>
            <div className="w-75">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderFilters = () => {
        const { filters, sources, validations, mode } = this.state;
        return <Animated animationIn="fadeInRight" className="w-100 d-flex flex-wrap mt-4" style={{ zIndex: 200, position: 'relative' }}>

            {/* Start */}
            <div style={{ width: 150 }}>
                <label className="white poppins font-12">Check-in</label>
                <div id="txtCheckIn">
                    <DatePicker highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" hint="Check-In" />} selected={this.state.CheckIn} showYearDropdown scrollableYearDropdown
                        yearDropdownItemNumber={15} onChange={date => {
                            this.setState({ CheckIn: moment(date).utc() });
                        }} className={`${validations.txtCheckIn ? 'error-input' : ''} white font-12`}
                    />
                    <Popover placement="right" isOpen={validations.txtCheckIn ? true : false} target={`txtCheckIn`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtCheckIn = !validations.txtCheckIn;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCheckIn}</span>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
            <div className="p-1" />
            {/* Nights */}
            <div style={{ width: 150 }}>
                <label className="white poppins font-12">Noches</label>
                <div id="txtNights" className={`black-input d-flex mt-2`}>
                    <input className="w-100 p-3 white" type="text" name="nights" placeholder="Noches" ref={(input) => this.txtNights = input} />
                </div>
            </div>
            <div className="p-1" />
            <button onClick={this.onSearch} className="btn btn-lg btn-blue align-self-end d-flex">
                <i className={`fas fa-${this.state.microTransac ? 'clock fa-spin' : 'search'} white font-18 my-2 align-self-center`} />
            </button>
            <button onClick={() => this.setState({ group: !this.state.group })} className="btn btn-lg btn-link align-self-end d-flex">
                <i className={`far fa-${this.state.group ? 'check-square blue' : 'square gray'} font-18 align-self-center my-2`} />
                <span className="ms-2 align-self-center white font-12">Empresarial | Grupal</span>
            </button>

            {/* Source */}
            <div className="ms-auto" style={{ width: 200 }}>
                <label className="white poppins font-12">Fuente</label>
                <div id="txtType" className={`black-input  d-flex mt-2`}>
                    <Dropdown isOpen={this.state.pickSource} toggle={() => this.setState({ pickSource: !this.state.pickSource })} className="w-100">
                        <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                            <span className="align-self-center">{(_.find(config.book_sources, t => t.name === this.state.source)).name}</span>
                            <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                            {config.book_sources.map((c, i) => <DropdownItem key={i} onClick={() => {
                                this.setState({ source: c.name });
                            }}>{c.name}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            {/* Rate type */}
            <div className="ms-2" style={{ width: 200 }}>
                <label className="white poppins font-12">Tarifa</label>
                <div id="txtType" className={`black-input  d-flex mt-2`}>
                    <Dropdown isOpen={this.state.pickType} toggle={() => this.setState({ pickType: !this.state.pickType })} className="w-100">
                        <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                            <span className="align-self-center">{(_.find(config.rate_types, t => t.id === mode)).name}</span>
                            <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                            {config.rate_types.map((c, i) => <DropdownItem key={i} onClick={() => {
                                this.setState({ mode: c.id }, () => {
                                    localStorage.setItem('lb.rt', c.id);
                                    this.onSearch();
                                });
                            }}>{c.name}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        </Animated>
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Tipo <i className={`icon-lb_sort ${sort === 'Name' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('Name')} /></th>
                <th className="bg-gray-2 white">Desde <i className={`icon-lb_sort ${sort === 'IdBooking' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('IdBooking')} /></th>
                <th className="bg-gray-2 white">Disp. <i className={`icon-lb_sort ${sort === 'Rooms' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('Rooms')} /></th>
                <th className="bg-gray-2 white text-center">Adultos </th>
                <th className="bg-gray-2 white text-center">Niños   </th>
                <th className="bg-gray-2 white text-center">Infantes</th>
                <th className="bg-gray-2 white text-center">Cama<br />extra</th>
                <th className="bg-gray-2 white text-center">Cantidad</th>
                <th className="bg-gray-2 white text-right"></th>
            </tr>
        </thead>
    }
    renderAvailability = () => {
        const { bootstrapped, rooms, mode } = this.state;
        let current_array = 'items';
        switch (mode) {
            case 1: current_array = 'items'; break;
            case 2: current_array = 'WALKIN'; break;
            case 3: current_array = 'COORP'; break;
            default: break;
        }

        return <Animated animationInDelay={200} animationIn="fadeInRight" className="mt-4" style={{ zIndex: 100, position: 'relative' }}>
            <hr />
            <h4 className="white poppins"><i className="far fa-calendar blue me-2" />Disponibilidad</h4>
            <div className="w-100 bg-gray-2 px-3 py-2 rounded d-flex">
                <table className="w-100 table m-0 poppins bg-gray-2 white">
                    {this.renderTH()}
                    {bootstrapped && rooms && rooms.length > 0 && <tbody>
                        {rooms.map((room, i) => <tr key={room.IdRoom}>
                            <td className="bg-gray-2 white">{room.GivenName}</td>
                            <td className={`bg-gray-2 ${room.MIN_RATE ? 'white' : 'orange'}`}>{room.RATE ? `USD ${room.MIN_RATE}` : 'Calculating...'}</td>
                            <td className={`bg-gray-2 ${room.BOOKABLE ? 'white' : 'red'}`}>{room.BOOKABLE}/{room.Availability}</td>
                            <td className="bg-gray-2 white">
                                <div className={`black-input mx-auto d-flex`} style={{ minWidth: 80, maxWidth: 80 }}>
                                    <input onChange={e => {
                                        const { rooms } = this.state;
                                        rooms[i].SELECTED_ADULTS = parseInt(e.target.value, 10) || 0;
                                        this.setState({ rooms });
                                    }} max={room.MaxAdults} min={0} className="px-3 py-1 white w-100" type="number" placeholder="0" /><span className={`align-self-center ${room.SELECTED_ADULTS && room.SELECTED_ADULTS > room.MaxAdults ? 'red' : 'gray'} font-8 me-1`}>/ {room.MaxAdults}</span>
                                </div>
                            </td>
                            <td className="bg-gray-2 white">
                                <div className={`black-input mx-auto d-flex`} style={{ minWidth: 80, maxWidth: 80 }}>
                                    <input onChange={e => {
                                        const { rooms } = this.state;
                                        rooms[i].SELECTED_CHILDREN = parseInt(e.target.value, 10) || 0;
                                        this.setState({ rooms });
                                    }} max={room.MaxChildren} min={0} className="px-3 py-1 white w-100"
                                        type="number" placeholder="0" /><span className={`align-self-center ${room.SELECTED_CHILDREN && room.SELECTED_CHILDREN > room.MaxChildren ? 'red' : 'gray'} font-8 me-1`}>/ {room.MaxChildren}</span>
                                </div>
                            </td>
                            <td className="bg-gray-2 white">
                                <div className={`black-input mx-auto d-flex`} style={{ minWidth: 80, maxWidth: 80 }}>
                                    <input onChange={e => {
                                        const { rooms } = this.state;
                                        rooms[i].SELECTED_CRIBS = parseInt(e.target.value, 10) || 0;
                                        this.setState({ rooms });
                                    }} max={room.MaxCribs} min={0} className="px-3 py-1 white w-100" type="number" placeholder="0" /><span className={`align-self-center ${room.SELECTED_CRIBS && room.SELECTED_CRIBS > room.MaxCribs ? 'red' : 'gray'} font-8 me-1`}>/ {room.MaxCribs}</span>
                                </div>
                            </td>
                            <td className="bg-gray-2 white text-center">
                                {room.ExtraBed && <button onClick={() => {
                                    const { rooms } = this.state;
                                    rooms[i].EXTRA_BED_SELECTED = !rooms[i].EXTRA_BED_SELECTED;
                                    this.setState({ rooms });
                                }} className="btn btn-link"><i className={`far fa-${room.EXTRA_BED_SELECTED ? `check-square blue` : `square gray`} font-16`} /></button>}
                            </td>
                            <td className="bg-gray-2 white">
                                <div className={`black-input mx-auto d-flex`} style={{ minWidth: 80, maxWidth: 80 }}>
                                    <input onChange={e => {
                                        const { rooms } = this.state;
                                        rooms[i].SELECTED_QUANTITY = parseInt(e.target.value, 10) || 0;
                                        this.setState({ rooms });
                                    }} max={room.BOOKABLE} min={0} className="px-3 py-1 white w-100" type="number" placeholder="0" />
                                </div>
                            </td>
                            {/* && (room.SELECTED_ADULTS + room.SELECTED_CHILDREN + room.SELECTED_CRIBS) <= room.MaxOccupancy */}
                            <td className="bg-gray-2 white font-8 text-right">
                                {(_.chain([
                                    room.SELECTED_ADULTS, room.SELECTED_CHILDREN, room.SELECTED_CRIBS
                                ]).compact().flatten().reduce((a, b) => a + b, 0).value()) > room.MaxOccupancy && <span className="font-8 red">Ocupación máxima<br />excedida ({room.MaxOccupancy})</span>}
                                {room.BOOKABLE > 0 && room.MIN_RATE >= 0 && (_.chain([
                                    room.SELECTED_ADULTS, room.SELECTED_CHILDREN, room.SELECTED_CRIBS
                                ]).compact().flatten().reduce((a, b) => a + b, 0).value()) <= room.MaxOccupancy && <button onClick={() => {
                                    const { cart, rooms } = this.state;
                                    let room_to_add = { ...rooms[i] };

                                    // RULE: at least one (1) adult per room
                                    if (!room_to_add.SELECTED_ADULTS) room_to_add.SELECTED_ADULTS = 1;
                                    //

                                    let ADULT_RATE = 0, CHILDREN_RATE = 0, CRIBS_RATE = 0;


                                    // Adding up adults                                        
                                    if (room_to_add.SELECTED_ADULTS) {
                                        ADULT_RATE = _.chain(room_to_add.RATE)
                                            .map(day => {
                                                return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_ADULTS) || { Adults: 0 }).Adults);
                                            })
                                            .reduce((a, b) => a + b, 0)
                                            .value();
                                    }
                                    //

                                    // Adding up children                                        
                                    if (room_to_add.SELECTED_CHILDREN) {
                                        CHILDREN_RATE = _.chain(room_to_add.RATE)
                                            .map(day => {
                                                return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_CHILDREN) || { Children: 0 }).Children);
                                            })
                                            .reduce((a, b) => a + b, 0)
                                            .value();
                                    }
                                    //

                                    // Adding up cribs                                        
                                    if (room_to_add.SELECTED_CRIBS) {
                                        CRIBS_RATE = _.chain(room_to_add.RATE)
                                            .map(day => {
                                                return parseFloat((_.find((day.DAY_RATE[current_array] || day.DAY_RATE.items), i => i.Amount === room_to_add.SELECTED_CRIBS) || { Cribs: 0 }).Cribs);
                                            })
                                            .reduce((a, b) => a + b, 0)
                                            .value();
                                    }
                                    //


                                    room_to_add.ADULT_RATE = ADULT_RATE; // * room_to_add.SELECTED_QUANTITY;
                                    room_to_add.CHILDREN_RATE = CHILDREN_RATE; // * room_to_add.SELECTED_QUANTITY;
                                    room_to_add.CRIBS_RATE = CRIBS_RATE; // * room_to_add.SELECTED_QUANTITY;
                                    room_to_add.CALCULATED_RATE = ADULT_RATE + CHILDREN_RATE + CRIBS_RATE;

                                    for (let j = 1; j <= parseInt({ ...room_to_add }.SELECTED_QUANTITY || 1, 10); j++) {
                                        cart.push({ ...room_to_add, SELECTED_QUANTITY: 1, local_id: generateRandomId(5) });
                                    }
                                    this.setState({ cart });

                                }} className="btn btn-blue btn-sm white">Agregar</button>}
                            </td>
                        </tr>)}
                    </tbody>}
                    {!bootstrapped && <tbody>
                        <tr>
                            <td colSpan={9} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">Filtra tu búsqueda para<br />ver la disponibilidad.</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>}
                    {bootstrapped && rooms && rooms.length === 0 && <tbody>
                        <tr>
                            <td colSpan={9} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">Lo sentimos, no encontramos habitaciones disponibles para las fechas seleccionadas.</h3>
                                </div>
                            </td>
                        </tr></tbody>}
                </table>
            </div>
        </Animated>
    }
    renderCart = () => {
        const { cart, CheckIn, CheckOut } = this.state;
        return <Animated animationInDelay={200} animationIn="fadeInRight" className="mt-4" style={{ zIndex: 100, position: 'relative' }}>
            <hr />
            <h4 className="white poppins"><i className="icon-lb_rooms blue me-2" />Habitaciones agregadas</h4>
            <div className="w-100 bg-gray-3 ps-2 pb-2 rounded d-flex flex-wrap">

                {cart.map((item, i) => <div key={item.local_id} className={`${item.CALCULATED_RATE ? 'bg-black' : 'bg-red white'} py-2 px-3 me-2 mt-2 rounded`} style={{ minWidth: 320, maxWidth: 440 }}>
                    <div className="d-flex">
                        <span className={`poppins toBold font-12 ${item.CALCULATED_RATE ? 'blue' : 'white'} align-self-center`}>x{item.SELECTED_QUANTITY || 1} {item.GivenName} - {item.CALCULATED_RATE ? `USD ${item.CALCULATED_RATE}` : 'TARIFA INVÁLIDA'}</span>
                        <button onClick={() => {
                            const { cart } = this.state;
                            this.setState({ cart: [...cart.slice(0, i), ...cart.slice(i + 1)] });
                        }} className="btn btn-sm btn-link ms-auto text-right"><i className="fas fa-times white font-12 align-self-center" /></button>
                    </div>
                    <div>
                        <span className="poppins font-10 white">{moment(CheckIn).format('DD/MM/YYYY')} • {moment(CheckOut).format('DD/MM/YYYY')}</span>
                    </div>
                    <div>
                        <span className="poppins font-8 white">{item.SELECTED_ADULTS} Adulto(s), {item.SELECTED_CHILDREN || 0} Niño(s), {item.SELECTED_CRIBS || 0} Infante(s){item.EXTRA_BED_SELECTED ? ', C/e' : ''}</span>
                    </div>
                </div>)}

            </div>
            <div className="w-50 p-0 rounded mx-auto mt-4 d-flex" style={{ overflow: 'hidden' }}>
                <div className="w-100 d-flex flex-column bg-gray-3 py-2 m-0">
                    <span className="white align-self-center">Sub-total</span>
                    <span className="white align-self-center">USD {_.chain(cart)
                        .map(i => i.CALCULATED_RATE)
                        .reduce((a, b) => a + b, 0).value()}</span>
                </div>
                {/*<div className="w-100 d-flex flex-column bg-gray-3 py-2 m-0">
                    <span className="white align-self-center">Impuestos</span>
                    <span className="white align-self-center">?</span>
                </div>*/}
                <div className="w-100 d-flex flex-column bg-gray-3 py-2 m-0">
                    <span className="white align-self-center">Promocion</span>
                    <span className="white align-self-center">USD 0</span>
                </div>
                <div className="w-100 d-flex flex-column bg-gray-2 py-2 m-0">
                    <span className="white align-self-center">Sub-total</span>
                    <span className="white align-self-center">USD {_.chain(cart)
                        .map(i => i.CALCULATED_RATE)
                        .reduce((a, b) => a + b, 0).value()}</span>
                </div>
            </div>
        </Animated>
    }
    renderCommon = () => {
        const { cart } = this.state;
        return (
            <div>
                {this.renderHeader()}
                <section className='w3-container'>
                    <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.toggle} animationOut="fadeOutRight" animationIn="fadeInRight" className="mt-4">
                        {this.renderFilters()}
                        {this.renderAvailability()}
                        {cart.length > 0 && this.renderCart()}
                        {/* Buttons */}
                        {cart.length > 0 && !_.find(cart, item => !item.CALCULATED_RATE) && <div className="d-flex justify-content-center mt-4">
                            <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white align-self-center" >
                                <i className="icon-corner-down-left white font-12" />
                            </button>
                            <button onClick={this.onSaveBook} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center d-flex" >
                                <span className="align-self-center">{`Detalles ${this.state.group ? 'del grupo | empresa' : 'del huesped'}`}</span>
                                {!this.state.saving && <i className="fas fa-book white font-12 align-self-center ms-2" />}
                                {this.state.saving && <i className="fas fa-cog fa-spin white font-12 align-self-center ms-2" />}
                            </button>
                        </div>}
                    </Animated>
                </section>
                <div style={{ height: 100 }} />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                </section>
            </Layout>
        )
    }
}

export default AddBooking;
