const config = {
    apiEndpoint: process.env.REACT_APP_API_HOST,
    apiKey: process.env.REACT_APP_API_KEY,
    palette: {
        green: '#2cad3c',
        blue: '#008db5',
        yellow: '#fdee21',
        purple: '#a713a2'
    },
    shifts: [
        { id: 1, value: 'Diurno' },
        { id: 2, value: 'Nocturno' },
        { id: 3, value: 'Mixto' }
    ],
    job_titles: [
        { id: 1, value: 'Am@ de Llaves' },
        { id: 2, value: 'Supervisor' },
        { id: 3, value: 'Camarer@' },
        { id: 4, value: 'Lencer@' },
        { id: 5, value: 'Aseador' }
    ],
    room_status: [
        { id: 1, name: 'Limpia', icon: 'icon-lb_housekeep', color: '#00C6FE', pow: 1 },
        { id: 2, name: 'Sucia', icon: 'icon-lb_housekeep', color: '#B4B878', pow: 2 },
        { id: 3, name: 'Disponible', icon: 'icon-lb_housekeep', color: '#05F300', macro: true, pow: 4 },
        { id: 4, name: 'Ocupada', icon: 'icon-lb_housekeep', color: '#FE001D', macro: true, pow: 8 },
        { id: 5, name: 'Reservada', icon: 'icon-lb_housekeep', color: '#1771FF', macro: true, pow: 16 },
        { id: 6, name: 'Reparar', icon: 'icon-lb_housekeep', color: '#FDF403', pow: 32 },
        { id: 7, name: 'DNR', icon: 'icon-lb_housekeep', color: '#FF640D', pow: 64 },
        { id: 8, name: 'Inspección', icon: 'icon-lb_housekeep', color: '#FF1AC7', pow: 128 },
        { id: 9, name: 'Estadia noche', icon: 'icon-lb_housekeep', color: '#848ECE', pow: 256 },
        { id: 10, name: 'Uso de casa', icon: 'icon-lb_housekeep', color: '#BEE7FE', pow: 512 },
        { id: 11, name: 'Check-out', icon: 'icon-lb_housekeep', color: '#6E44FF', pow: 1024 },
        { id: 12, name: 'No disponible', icon: 'icon-lb_housekeep', color: '#991A00', pow: 2048 },
    ],
    booking_status: [
        { id: 1, name: 'Por Confirmar', color_class: 'w3-yellow black' },
        { id: 2, name: 'Confirmado', color_class: 'w3-blue white' },
        { id: 3, name: 'Checked-in', color_class: 'w3-green white' },
        { id: 4, name: 'Checked-out', color_class: 'w3-orange white' },
        { id: 5, name: 'No Show', color_class: 'w3-red white' },
        { id: 6, name: 'Anulado', color_class: 'w3-pink white' },
    ],
    rate_types: [
        { id: 1, name: 'RACK' },
        { id: 2, name: 'WALK-IN' },
        { id: 3, name: 'CORPORATIVA' },
        { id: 4, name: 'NOCHE-ROMANTICA' },
    ],
    book_sources: [
        { id: 1, name: 'WEB' },
        { id: 2, name: 'OTRO' }
    ],
    payment_types: [
        { id: 1, name: 'TDC Nacional' },
        { id: 2, name: 'TDC Internacional' },
        { id: 3, name: 'Transferencia Banco Nacional' },
        { id: 4, name: 'Transferencia Banco Internacional' },
        { id: 5, name: 'Efectivo | Cash' },
        { id: 6, name: 'Efectivo | USD' },
        { id: 7, name: 'Zelle' },
        { id: 8, name: 'Débito Nacional' },
        { id: 9, name: 'Pago Movil' },
        { id: 10, name: 'Crypto' },

    ],
    offer_types: [
        { id: 1, name: 'Por porcentaje', icon: '%' },
        { id: 2, name: 'Por cierre', icon: '' },
    ],
    room_objects: [
        { id: 1, name: 'Pantalla plana TV' },
        { id: 2, name: 'Control de TV' },
        { id: 4, name: 'Control de A/C' },
        { id: 8, name: 'Secador de cabello' },
        { id: 16, name: 'Toallas' },
        { id: 32, name: 'Batas de baño' },
        { id: 64, name: 'Mini-nevera' },
        { id: 128, name: 'Otros' },
    ],
    week_day_flags: {
        mon: 1, tue: 2, wed: 4, thu: 8, fri: 16, sat: 32, sun: 64
    },
    regime_types: {
        solo_alojamiento: 1,
        bandb: 2,
        media_pension: 4,
        pension_completa: 8,
        todo_incluido: 16,
        servicios: 32
    },
    comodities: [
        "Wi-Fi gratuito",
        "Piscina",
        "Gimnasio",
        "Restaurante",
        "Servicio de habitaciones",
        "Aire acondicionado",
        "Spa",
        "Estacionamiento",
        "Servicio de conserjería",
        "Centro de negocios",
        "Desayuno buffet",
        "TV por cable",
        "Lavandería",
        "Caja de seguridad en la habitación",
        "Bar en el hotel",
        "Salas de reuniones",
        "Transporte al aeropuerto",
        "Recepción 24 horas",
        "Mascotas permitidas",
        "Acceso para personas con movilidad reducida",
        "Cafetera",
    ],
    room_comodities: [
        "Pantalla plana TV",
        "Canales por cable",
        "Control de TV",
        "Mini-bar",
        "Jacuzzi",
        "Acepta mascotas",
        "Vista panorámica",
        "Piscina y sauna",
        "Elevador privado",
        "Set de sábanas cómodas",
        "Set de toallas y batas de baño",
        "Secador de pelo",
        "Aire acondicionado",
        "Bóveda de seguridad",
        "Champú y jabón de cortesía"
    ],
    timezones: [
        { id: 1, name: 'America/New_York', description: 'Eastern Time', utcOffset: -5 },
        { id: 3, name: 'America/Chicago', description: 'Central Time', utcOffset: -6 },
        { id: 5, name: 'America/Denver', description: 'Mountain Time', utcOffset: -7 },
        { id: 7, name: 'America/Los_Angeles', description: 'Pacific Time', utcOffset: -8 },
        { id: 8, name: 'America/Vancouver', description: 'Pacific Time (Canada)', utcOffset: -8 },
        { id: 9, name: 'America/Anchorage', description: 'Alaska Time', utcOffset: -9 },
        { id: 10, name: 'America/Adak', description: 'Hawaii-Aleutian Time', utcOffset: -10 },
        { id: 11, name: 'America/Mexico_City', description: 'Central Standard Time (Mexico)', utcOffset: -6 },
        { id: 12, name: 'Europe/London', description: 'Greenwich Mean Time (GMT)', utcOffset: 0 },
        { id: 15, name: 'Europe/Madrid', description: 'Central European Time (CET)', utcOffset: 1 },
        { id: 16, name: 'Europe/Athens', description: 'Eastern European Time (EET)', utcOffset: 2 },
        { id: 18, name: 'Europe/Moscow', description: 'Moscow Standard Time (MSK)', utcOffset: 3 },
        // Add more time zones as needed
    ],
}

export default config;
