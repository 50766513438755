import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import moment from 'moment';
import BlackOverlay from '../partials/black_overlay';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';

class Reports extends React.Component {
    state = {
        menu_toggled: false,
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }
    // Lifecycle

    //

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_reportes blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Reportes</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            <div className="w-100">
                {/*<div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input className="w-100 p-3 white" type="text" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} onChange={e => this.setState({ search: e.target.value })} />
                </div>*/}
            </div>
            <div className="w-100 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderCommon = () => {
        return (
            <div className="">
                {this.renderHeader()}
                {/* MAIN */}
                <div className="d-flex flex-column justify-content-center centered-message">

                    <ul className="mx-auto  align-self-center d-flex flex-wrap mt-4 justify-content-center" >

                        <li className="reports-tile bg-gray-2" style={{ borderRadius: 6, overflow: 'hidden' }}>
                            <div className="w-100 h-100">
                                <Link to={`/reports/occupation/${this.props.params.id}`} className="white align-self-center justify-content-center d-flex flex-column w-100 h-100">
                                    <i className="fas fa-percent align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                                    <span className=" align-self-center mt-2 line1 text-center poppins">Ocupación y<br />Saldos</span>
                                </Link>
                            </div>
                        </li>

                        {/*<li className="reports-tile bg-gray-2" style={{ borderRadius: 6, overflow: 'hidden' }}>
                            <div className="w-100 h-100">
                                <Link to={`/reports/transactions/${this.props.params.id}`} className="white align-self-center justify-content-center d-flex flex-column w-100 h-100">
                                    <i className="fas fa-dollar align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                                    <span className=" align-self-center mt-2 line1 text-center poppins">Movimientos<br />Financieros</span>
                                </Link>
                            </div>
                        </li>*/}

                        <li className="reports-tile bg-gray-2" style={{ borderRadius: 6, overflow: 'hidden' }}>
                            <div className="w-100 h-100">
                                <Link to={`/reports/forecast/${this.props.params.id}`} className="white align-self-center justify-content-center d-flex flex-column w-100 h-100">
                                    <i className="fas fa-cloud align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                                    <span className=" align-self-center mt-2 line1 text-center poppins">Forecast</span>
                                </Link>
                            </div>
                        </li>

                    </ul>

                </div>
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                </section>
            </Layout>
        )
    }
}

export default Reports;
