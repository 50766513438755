import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import BlackOverlay from '../partials/black_overlay';
import config from '../../config';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';

class Rooms extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 1,
        rooms_selected: [],
        status: config.room_status,
        sort: 'GivenName',
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }

    // Lifecycle
    componentWillMount() {
        const { id } = this.props.params;
        if (id) {
            this.props.rooms_actions && this.props.rooms_actions.fetch(id);
        }
    }
    componentWillReceiveProps = nextProps => {
        const { rooms } = nextProps;
        this.setState({ rooms });
    }
    //

    onToggle = (IdRoom) => {
        const { rooms } = this.state;
        let index = _.findIndex(rooms, r => r.IdRoom === IdRoom);
        rooms[index].IsActive = !rooms[index].IsActive;
        this.props.rooms_actions && this.props.rooms_actions.toggle(IdRoom, rooms[index].IsActive);
        this.setState({ rooms });
    }
    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    doDelete = () => {
        const { id } = this.props.params;
        this.props.rooms_actions && this.props.rooms_actions.archive(this.state.delete_id, id);
        this.setState({ delete_id: null });
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex">
                <i className="icon-lb_rooms blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Habitaciones</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            <div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input onChange={() => this.setState({ search: this.txtSearch.value })} className="w-100 p-3 white" type="text" name="email" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} />
                </div>
            </div>
            <div className="w-100 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Tipo <i className={`icon-lb_sort ${sort === 'GivenName' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('GivenName')} /></th>
                <th className="bg-gray-2 white">Moneda</th>
                <th className="bg-gray-2 white">Periodos <i className={`icon-lb_sort ${sort === 'TotalPeriods' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('TotalPeriods')} /></th>
                <th className="bg-gray-2 white">Inventarios <i className={`icon-lb_sort ${sort === 'Availability' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('Availability')} /></th>
                <th className="bg-gray-2 white text-center">Estado</th>
                <th className="bg-gray-2 white text-right">Accion</th>
            </tr>
        </thead>
    }
    renderTable = () => {
        const { rooms, sort, up } = this.state;
        return <div className="mt-4 d-flex">
            <Animated animationIn='fadeInRight' isVisible={rooms ? true : false} animationInDuration={300}
                animateOnMount={false} className="w-100 bg-gray-2 px-3 py-2 rounded d-flex">
                <table className="w-100 table m-0 poppins bg-gray-2 white">
                    {this.renderTH()}
                    {rooms && rooms.length > 0 && <tbody>
                        {_.chain(rooms)
                            .filter(r => {
                                if (this.state.search) return `${r.GivenName}`.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
                                return true;
                            })
                            .sortBy(sort)
                            .tap(arr => {
                                if (up) arr.reverse();
                            })
                            .value().map((room, index) => <tr key={room.IdRoom}>
                                <td className="bg-gray-2 white">{room.GivenName}</td>
                                <td className="bg-gray-2 white">{room.CurrencyCode || 'Bs.F'}</td>
                                <td className="bg-gray-2 white poppins"><Link to={`/periods/${room.IdRoom}`}>{room.TotalPeriods || 0}</Link></td>
                                <td className="bg-gray-2 white">{room.Availability}</td>
                                <td className="bg-gray-2 white text-center"><i onClick={() => this.onToggle(room.IdRoom)} className={`fas fa-toggle-${room.IsActive ? 'on green' : 'off white'} font-18`} /></td>
                                <td className="bg-gray-2 white">
                                    <div className="d-flex justify-content-end">
                                        <Link to={`/rooms/edit/${room.IdRoom}`}><i className="fas fa-edit ms-3 font-16 pointer blue" /></Link>
                                        <Link to={`/periods/${room.IdRoom}`}><i className="fas fa-clock ms-3 font-16 pointer gray" /></Link>
                                        <i className="fas fa-copy ms-3 font-16 pointer gray" />
                                        <i className="fas fa-trash ms-3 font-16 pointer gray" onClick={() => this.setState({ delete_id: room.IdRoom })} />
                                    </div>
                                </td>
                            </tr>)}
                    </tbody>}
                    {rooms && rooms.length === 0 && <tbody>
                        <tr>
                            <td colSpan={6} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">Por lo visto no tienes habitaciones creadas.<br />Haz click sobre el botón de "Nueva Habitación" para empezar.</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>}
                    {/*<tfoot>
                        <tr>
                            <td colSpan={6} className="text-center bg-gray-2">
                                <span className="w3-badge me-1 poppins font-12">1</span>
                                <span className="w3-badge poppins font-12">2</span>
                            </td>
                        </tr>
                    </tfoot>*/}
                </table>
            </Animated>
        </div>
    }
    renderCommon = () => {
        const { id } = this.props.params;
        return (
            <div className="">
                {this.renderHeader()}
                <div className="w-100 d-flex mt-4">
                    <span className="white ms-2 font-20 poppins align-self-center">Habitaciones</span>
                    {id && <Link to={`/rooms/add/${id}`} className="btn btn-lg btn-blue white px-4 ms-2 align-self-center d-flex" >
                        Nueva Habitación <i className="fas fa-plus-circle align-self-center font-10 ms-3" />
                    </Link>}
                </div>
                {this.renderTable()}
                <div style={{ height: 200 }} />
            </div>
        )
    }

    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Dlete Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.delete_id ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Eliminar habitación</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de eliminar esta habitación?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ delete_id: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doDelete} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default Rooms;
