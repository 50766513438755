function rates_Reducer(state = [], action) {
    switch (action.type) {
        case 'RATES/SET':            
            return action.rates;
        case 'RATES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default rates_Reducer;
