import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router';
import Layout from '../layouts/claire';
import { generateRandomId, getBase64, getReadableFileSizeString } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import BlackOverlay from '../partials/black_overlay';
import moment from 'moment';
import fakeAuth from '../../fakeAuth';
import _, { slice } from 'lodash';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LineLoader from '../partials/line_loader';
import MaskedInput from 'react-text-mask';
import request from 'superagent';
import config from '../../config';
import BookingsTable from '../bookings/partials/table';
import Coorp from '../bookings/partials/coorp';

class EditGuest extends React.Component {
    state = {
        files: [],
        validations: {},
        menu_toggled: false,
        guest: {
            IdProperty: this.props.params.id,
            PhoneCountryCode: '+58',
            CellphoneCountryCode: '+58',
        },
        shifts: config.shifts,
    }

    // lifecycle
    componentWillMount() {
        const { params } = this.props, { id } = params;
        if (params.id) {
            this.props.form_actions.fetch_countries();
            this.props.form_actions.fetch_country_codes();
            this.props.form_actions.fetch_nationalities();
            this.props.coorps_actions && this.props.coorps_actions.fetch_all(fakeAuth.getUser().IdAccount);
            this.props.guests_actions && this.props.guests_actions.fetch_one(id);
            this.props.guests_actions && this.props.guests_actions.fetch_bookings(id);
        } else {
            this.props.router.goBack();
        }
    }
    componentWillReceiveProps = nextProps => {
        const { guest, bookings } = nextProps;
        if (guest && guest.IdGuest) this.setState({
            bootstrapped: true,
            guest,
            bookings
        });

    }
    componentWillUnmount = () => {
        this.props.booking_actions && this.props.booking_actions.clear();
        this.props.guests_actions && this.props.guests_actions.clear();
    }
    //

    onLogo = e => {
        const { guest } = this.state, { } = this.props;
        getBase64(e.target.files[0]).then(data => {
            guest.LogoUrl = data;
            this.setState({ guest, doUpdateLogo: true });
        });
    }

    doSave = () => {
        const { guest } = this.state;
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
            }, () => {

                // CALL API                
                request.patch(`${config.apiEndpoint}/api/v4/guests${guest.IdGuest ? `/${guest.IdGuest}` : ''}`).set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({
                        IdGuest: guest.IdGuest,
                        IdAccount: fakeAuth.getUser().IdAccount,
                        FirstName: this.txtFirstName.value,
                        LastName: this.txtLastName.value,
                        Email: this.txtEmail.value,
                        Phone: this.txtPhone.value,
                        Cellphone: this.txtCellphone.value,
                        DateOfBirth: ReactDOM.findDOMNode(this.txtDOB).value,
                        IdOrPassport: this.txtIdOrPassport.value,
                        Address: this.txtAddress.value,
                        CarPlate: this.txtPlate.value,
                        Profession: this.txtProfession.value,
                        PhoneCountryCode: this.txtPhoneCountryCode.value || '+58',
                        CellPhoneCountryCode: this.txtCellPhoneCountryCode.value || '+58',
                        Logo: this.state.doUpdateLogo ? guest.LogoUrl : null,
                        Company: '', // this.txtCompany.value,
                        ..._.pick(guest, 'IdCountry', 'IdState', 'State', 'IdCity', 'City',
                            'IdNationality', 'JobTitle', 'IdCoorp', 'Nationality')
                    })
                    .then((data) => {
                        if (data.body.success) {
                            this.props.router.goBack();
                        }
                    }, (error) => {
                        this.setState({ microTransact: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });
        }
    }
    validate = (e) => {
        e && e.preventDefault && e.preventDefault();
        this.setState({ validations: {} }, () => {
            try {
                const { validations, guest } = this.state;

                if (!this.txtFirstName.value) {
                    validations.txtFirstName = 'Por favor, introduce el nombre del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                if (!this.txtLastName.value) {
                    validations.txtLastName = 'Por favor, introduce el apellido del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                if (!this.txtEmail.value) {
                    validations.txtEmail = 'Por favor, introduce el correo electrónico del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                if (ReactDOM.findDOMNode(this.txtDOB).value && !moment(ReactDOM.findDOMNode(this.txtDOB).value, 'DD/MM/YYYY').isValid()) {
                    validations.txtDOB = 'Por favor, introduzca una fecha valida siguiendo el formato DD/MM/YYYY';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                if (!this.txtIdOrPassport.value) {
                    validations.txtIdOrPassport = 'Por favor, introduce el documento de identificación del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                if (!guest.IdCountry) {
                    validations.txtCountry = 'Por favor, selecciona el pais de residencia del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                if (!guest.IdState) {
                    validations.txtState = 'Por favor, selecciona el estado de residencia del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                if (!guest.IdCity) {
                    validations.txtCity = 'Por favor, introduce la ciudad de residencia del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                if (!this.txtAddress.value) {
                    validations.txtAddress = 'Por favor, introduce la direccion del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }
                /*if (!guest.IdNationality) {
                    validations.txtNationality = 'Por favor, selecciona la nacionalidad del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }*/
                if (!guest.Nationality) {
                    validations.txtNationality = 'Por favor, selecciona la nacionalidad del huesped';
                    this.setState({ validations }, () => { this.txtFirstNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                    return;
                }

                this.setState({ confirm: true, validations });
            } catch (e) { console.error({ e }); }

        });
    }

    onCountry = c => {
        const { guest } = this.state;
        guest.IdCountry = parseInt(c.id, 10);
        this.setState({ guest }, () => {
            this.props.fnUpdate && this.props.fnUpdate(this.state);
        });
        const { validations } = this.state;
        validations.txtCountry = false;
        this.setState({ validations });
    }
    onState = c => {
        const { guest } = this.state;
        guest.IdState = parseInt(c.id, 10);
        guest.State = c.name;
        this.setState({ guest }, () => {
            this.props.fnUpdate && this.props.fnUpdate(this.state);
        });
        const { validations } = this.state;
        validations.txtState = false;
        this.setState({ validations });
    }
    onCity = c => {
        const { guest } = this.state;
        guest.IdCity = parseInt(c.id, 10);
        guest.City = c.name;
        this.setState({ guest }, () => {
            this.props.fnUpdate && this.props.fnUpdate(this.state);
        });
        const { validations } = this.state;
        validations.txtCity = false;
        this.setState({ validations });
    }
    onNationality = c => {
        const { guest } = this.state;
        //guest.IdNationality = parseInt(c.id, 10);
        guest.Nationality = c;
        this.setState({ guest }, () => {
            this.props.fnUpdate && this.props.fnUpdate(this.state);
        });
        const { validations } = this.state;
        validations.txtNationality = false;
        this.setState({ validations });
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_user blue font-40 align-self-center" />
                <span className="white ms-2 font-20 poppins align-self-center">Editar huesped</span>
                <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>
            </div>
            <div className="w-75">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }

    renderBasic = () => {
        const { validations, guest, timezones } = this.state, { nationalities, countries, country_codes } = this.props;

        // Country / State / City
        let selected_country = null, selected_state = null, selected_city = null;
        if (countries && countries.length && guest.IdCountry) {
            selected_country = _.find(countries, c => parseInt(c.id, 10) === guest.IdCountry);
            if (selected_country && guest.IdState) {
                selected_state = _.find(selected_country.states, s => parseInt(s.id, 10) === guest.IdState);
                if (selected_state && guest.IdCity) {
                    selected_city = _.find(selected_state.cities, c => parseInt(c.id, 10) === guest.IdCity);
                }
            }
        }

        return <section style={{ position: 'relative', zIndex: 200 }}>
            <Animated animationIn="fadeIn" className="w3-content d-flex">
                {/* Avatar */}
                <div className="me-4 mt-4" style={{ position: 'relative', zIndex: 200 }}>
                    <input type="file" id="fi" onChange={this.onLogo} className="hide" ref={i => this.avatarFU = i} />
                    <label htmlFor="fi" className="align-self-center">
                        {(guest.LogoUrl) && <div className="cover pointer bg-gray-2 mx-auto d-flex justify-content-center" style={{
                            width: 200, height: 200, borderRadius: '50%', border: '1px solid black',
                            background: `url(${guest.LogoUrl}) no-repeat center center white`,
                        }} />}
                        {!(guest.LogoUrl) && <div style={{ width: 200, height: 200, borderRadius: '50%', }} className="bg-gray-2 pointer mx-auto d-flex justify-content-center">
                            <i className="fas fa-camera font-60 gray align-self-center" />
                        </div>}
                    </label>
                </div>
                {/* Rest */}
                <div style={{ borderLeft: '2px solid #343f4b' }} className="ps-3">
                    <div className="d-flex w3-content" ref={input => this.txtFirstNameWrapper = input}>
                        {/* Id Or Passport */}
                        <div className="w-100 mt-4">
                            <div className="d-flex">
                                <label className="white poppins font-12">Cédula | Pasaporte <i className="blue">*</i></label>
                                {this.state.searching && <i className="fas fa-cog fa-spin ms-auto white font-12 align-self-center me-2" />}
                            </div>
                            <div id="txtIdOrPassport" className={`black-input ${validations.txtIdOrPassport ? 'error-input' : ''} d-flex mt-2`}>
                                <input onChange={e => {
                                    const { guest } = this.state;
                                    guest.IdOrPassport = e.target.value;
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} className="w-100 p-3 white" type="text" name="name" placeholder="Cédula o pasaporte" ref={(input) => this.txtIdOrPassport = input} defaultValue={guest.IdOrPassport} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtIdOrPassport ? true : false} target={`txtIdOrPassport`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtIdOrPassport = !validations.txtIdOrPassport;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtIdOrPassport}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* First Name */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Nombre <i className="blue">*</i></label>
                            <div id="txtFirstName" className={`black-input ${validations.txtFirstName ? 'error-input' : ''} d-flex mt-2`}>
                                <input onChange={e => {
                                    const { guest } = this.state;
                                    guest.FirstName = e.target.value;
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} className="w-100 p-3 white" type="text" name="name" placeholder="Nombre del huesped" ref={(input) => this.txtFirstName = input} defaultValue={guest.FirstName} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtFirstName ? true : false} target={`txtFirstName`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtFirstName = !validations.txtFirstName;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtFirstName}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Last Name */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Apellido <i className="blue">*</i></label>
                            <div id="txtLastName" className={`black-input ${validations.txtLastName ? 'error-input' : ''} d-flex mt-2`}>
                                <input onChange={e => {
                                    const { guest } = this.state;
                                    guest.LastName = e.target.value;
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} className="w-100 p-3 white" type="text" name="name" placeholder="Apellido del huesped" ref={(input) => this.txtLastName = input} defaultValue={guest.LastName} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtLastName ? true : false} target={`txtLastName`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtLastName = !validations.txtLastName;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtLastName}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                    </div>
                    <div className="d-flex w3-content">
                        {/* Phone */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Teléfono</label>
                            <div className="black-input d-flex mt-2">
                                <input onChange={e => {
                                    const { guest } = this.state;
                                    guest.PhoneCountryCode = e.target.value;
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} style={{ width: 70 }} className="p-3 white" type="text" name="phone" placeholder="+" maxLength={4} ref={(input) => this.txtPhoneCountryCode = input} defaultValue={guest.PhoneCountryCode || '+58'} />
                                <i className="fas fa-minus font-16 white align-self-center px-1 " />
                                <input onChange={e => {
                                    const { guest } = this.state;
                                    guest.Phone = e.target.value;
                                    //guest.PhoneCountryCode = '+58';
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} className="w-100 p-3 white" type="text" name="phone" placeholder="Teléfono" ref={(input) => this.txtPhone = input} defaultValue={guest.Phone} />
                            </div>
                        </div>
                        <div className="px-1" />
                        {/* Cellphone */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Celular</label>
                            <div className="black-input d-flex mt-2">
                                <input onChange={e => {
                                    const { guest } = this.state;
                                    guest.CellPhoneCountryCode = e.target.value;
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} style={{ width: 70 }} className="p-3 white" type="text" name="phone" placeholder="+" maxLength={4} ref={(input) => this.txtCellPhoneCountryCode = input} defaultValue={guest.CellPhoneCountryCode || '+58'} />
                                <i className="fas fa-minus font-16 white align-self-center px-1 " />
                                <input onChange={e => {
                                    const { guest } = this.state;
                                    guest.CellPhone = e.target.value;
                                    guest.CellPhoneCountryCode = '+58';
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} className="w-100 p-3 white" type="text" name="phone" placeholder="Celular" ref={(input) => this.txtCellphone = input} defaultValue={guest.Cellphone} />
                            </div>
                        </div>
                        <div className="px-1" />
                        {/* DOB */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Fecha de nacimiento <i className="blue">*</i></label>
                            <div id="txtDOB" className={`black-input d-flex mt-2`}>
                                <i className="fas fa-calendar font-16 white align-self-center ps-3" />
                                <MaskedInput guide={true} ref={(i) => this.txtDOB = i} className="w-100 p-3 white" placeholder="DD/MM/YYYY" defaultValue={guest.DateOfBirth ? moment(guest.DateOfBirth).utc().format('DD/MM/YYYY') : null} mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex w3-content">
                        {/* Country */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Pais <i className="blue">*</i></label>
                            <div id="txtCountry" className={`black-input ${validations.txtCountry ? 'error-input' : ''} d-flex mt-2`}>
                                <Dropdown isOpen={this.state.pickCountry} toggle={() => this.setState({ pickCountry: !this.state.pickCountry })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{(_.find(countries, t => parseInt(t.id, 10) === guest.IdCountry) || { name: 'Pais' }).name}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100" style={{ maxHeight: 400, overflowY: 'auto' }}>
                                        {countries && _.chain(countries).sortBy('name').value().map((c, i) => <DropdownItem key={i} onClick={() => this.onCountry(c)}>
                                            {`${c.emoji} ${c.name}`}
                                        </DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Popover placement="right" isOpen={validations.txtCountry ? true : false} target={`txtCountry`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtCountry = !validations.txtCountry;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCountry}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        {/* State */}
                        {selected_country && <div className="px-1" />}
                        {selected_country && <div className="w-100 mt-4">
                            <label className="white poppins font-12">Estado <i className="blue">*</i></label>
                            <div id="txtState" className={`black-input ${validations.txtState ? 'error-input' : ''} d-flex mt-2`}>
                                <Dropdown isOpen={this.state.pickState} toggle={() => this.setState({ pickState: !this.state.pickState })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{(_.find(selected_country.states, t => parseInt(t.id, 10) === guest.IdState) || { name: 'Estado' }).name}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100" style={{ maxHeight: 400, overflowY: 'auto' }}>
                                        {selected_country && selected_country.states.map((c, i) => <DropdownItem key={i} onClick={() => this.onState(c)}>
                                            {`${c.name}`}
                                        </DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                                {/*<input onChange={e => {
                                    const { guest } = this.state;
                                    guest.State = e.target.value;
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} className="w-100 p-3 white" type="text" name="name" placeholder="Estado"
                                    ref={(input) => this.txtState = input} defaultValue={guest.State} />*/}
                            </div>
                            <Popover placement="right" isOpen={validations.txtState ? true : false} target={`txtState`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtState = !validations.txtState;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtState}</span>
                                </PopoverBody>
                            </Popover>
                        </div>}
                        {/* City */}
                        {selected_state && <div className="px-1" />}
                        {selected_state && <div className="w-100 mt-4">
                            <label className="white poppins font-12">Ciudad <i className="blue">*</i></label>
                            <div id="txtCity" className={`black-input ${validations.txtCity ? 'error-input' : ''} d-flex mt-2`}>
                                <Dropdown isOpen={this.state.pickCity} toggle={() => this.setState({ pickCity: !this.state.pickCity })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{(_.find(selected_state.cities, t => parseInt(t.id, 10) === guest.IdCity) || { name: 'Ciudad' }).name}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100" style={{ maxHeight: 400, overflowY: 'auto' }}>
                                        {selected_state && _.chain(selected_state.cities).sortBy('name').value().map((c, i) => <DropdownItem key={i} onClick={() => this.onCity(c)}>
                                            {`${c.name}`}
                                        </DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Popover placement="right" isOpen={validations.txtCity ? true : false} target={`txtCity`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtCity = !validations.txtCity;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCity}</span>
                                </PopoverBody>
                            </Popover>
                        </div>}
                    </div>
                    <div className="d-flex w3-content">
                        {/* Email */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Correo electrónico <i className="blue">*</i></label>
                            <div id="txtEmail" className={`black-input ${validations.txtEmail ? 'error-input' : ''} d-flex mt-2`}>
                                <i className="fas fa-at font-16 white align-self-center ps-3" />
                                <input onChange={e => {
                                    const { guest } = this.state;
                                    guest.EmailAddress = e.target.value;
                                    this.setState({ guest }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} className="w-100 p-3 white" type="text" name="name" placeholder="Correo del huesped" ref={(input) => this.txtEmail = input} defaultValue={guest.Email} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtEmail ? true : false} target={`txtEmail`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtEmail = !validations.txtEmail;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtEmail}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Address */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Dirección <i className="blue">*</i></label>
                            <div id="txtAddress" className={`black-input d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Direccion" ref={(input) => this.txtAddress = input} defaultValue={guest.Address} />
                            </div>
                        </div>
                        <div className="px-1" />
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Placa vehicular</label>
                            <div className="black-input d-flex mt-2">
                                <input className="w-100 p-3 white" type="text" name="plate" placeholder="Placa vehicular" ref={(input) => this.txtPlate = input} defaultValue={guest.CarPlate} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex w3-content">





                        {/* Nationality */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Nacionalidad <i className="blue">*</i></label>
                            <div id="txtNationality" className={`black-input ${validations.txtNationality ? 'error-input' : ''} d-flex mt-2`}>
                                <Dropdown isOpen={this.state.pickNationality} toggle={() => this.setState({ pickNationality: !this.state.pickNationality })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{_.find(nationalities, t => t === guest.Nationality) || 'Nacionalidad'}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100" style={{ maxHeight: 400, overflowY: 'auto' }}>
                                        {nationalities && _.chain(nationalities).value().map((c, i) => <DropdownItem key={i} onClick={() => this.onNationality(c)}>
                                            {`${c}`}
                                        </DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Popover placement="right" isOpen={validations.txtNationality ? true : false} target={`txtNationality`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtNationality = !validations.txtNationality;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtNationality}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />





                        {/* Job Title */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Profesión</label>
                            <div className="black-input d-flex mt-2">
                                <input className="w-100 p-3 white" type="text" name="profession" placeholder="Profesión" ref={(input) => this.txtProfession = input} defaultValue={guest.Profession} />
                            </div>
                        </div>
                        <div className="px-1" />
                        {/* Empresa | Agencia */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12 d-flex">Empresa | Agencia <i className="align-self-center pointer fas ms-auto fa-plus-circle blue" onClick={() => this.setState({ create_coorp: true })} /></label>
                            <div className="black-input d-flex mt-2">

                                <Dropdown isOpen={this.state.pickAgency} toggle={() => this.setState({ pickAgency: !this.state.pickAgency })} className="w-100">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                        <span className="align-self-center">{(_.find(this.props.coorps, t => t.IdCoorp === guest.IdCoorp) || { Name: 'Agencia' }).Name}</span>
                                        <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100" style={{ maxHeight: 400, overflowY: 'auto' }}>
                                        {this.props.coorps && _.chain(this.props.coorps)
                                            .sortBy('Name')
                                            .value().map((c, i) => <DropdownItem key={i} onClick={() => {
                                                const { guest } = this.state;
                                                guest.IdCoorp = c.IdCoorp;
                                                this.setState({ guest }, () => {
                                                    this.props.fnUpdate && this.props.fnUpdate(this.state);
                                                });
                                            }}>{c.Name}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </Animated>
            <Animated animationIn='fadeIn' animationInDelay={300}>
                {/* Buttons */}
                <div className="d-flex justify-content-center mt-4">
                    <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white align-self-center" >
                        <i className="icon-corner-down-left white font-12" />
                    </button>
                    {!this.state.microTransact && <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center d-flex" >
                        <span className="align-self-center">Guardar cambios</span><i className="fas fa-chevron-right white font-12 ms-2 align-self-center" />
                    </button>}
                    {this.state.microTransact && <button type="button" className="btn btn-lg btn-blue white ms-3 align-self-center d-flex" >
                        <span className="align-self-center">Guardando...</span><i className="fas fa-cog fa-spin white font-12 ms-2 align-self-center" />
                    </button>}
                </div>
            </Animated>
        </section>
    }
    renderBookings = () => {
        return <section>
            <div className="w-100 d-flex ">
                <i className="icon-lb_reservaciones blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Reservas</span>
                </div>
            </div>
            <BookingsTable {...this.state} />
        </section>
    }

    renderCommon = () => {

        return (
            <div>
                {this.renderHeader()}
                <section>
                    {this.state.bootstrapped && <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.toggle} animationOut="fadeOutRight" animationIn="fadeInRight" className="mt-4">
                        {this.renderBasic()}
                        <hr />
                        {this.renderBookings()}
                    </Animated>}
                </section>
                <div style={{ height: 100 }} />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Guardar cambios</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de esta operación?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doSave} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    {/* ADD A QUICK AGENCY */}
                    <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.create_coorp ? true : false} animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                        <BlackOverlay />
                        <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, left: 100, right: 0, overflowY: 'auto' }} className="bg-gray-2">
                            <div className="d-flex mx-4 mt-4">
                                <span className="poppins white font-16">Nueva agencia</span>
                                <button onClick={() => this.setState({ create_coorp: false })} className="btn btn-link rounded ms-auto"><i className="fas fa-times-circle gray font-18" /></button>
                            </div>
                            <Coorp {...this.props} fn={(IdCoorp) => {
                                // TODO: assign the id to the user
                                // refresh everything                                
                                this.setState({ create_coorp: false });
                                const { guest } = this.state;
                                guest.IdCoorp = IdCoorp;
                                this.setState({ guest });
                            }} toggle={() => this.setState({ create_coorp: false })} />
                        </div>
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default EditGuest;
