import React from 'react';
import fakeAuth from '../../fakeAuth';
import moment from 'moment';
import _ from 'lodash';
import { formatPrice, padWithZeros, stripHTMLTags } from '../../helpers';

class PrintableExample extends React.Component {

    state = {
        tab: 1,
        menu_toggled: false,
        toggle: 1,
        sort: 'Name',
        filters: {
            CheckIn: moment().utc()
        },
        validations: {
            guest: false,
        },
        book: {
            IdStatus: 1,
            Guest: {
                FirstName: '',
                LastName: ''
            }
        },
        sources: [{ id: 1, value: 'Source 1' }, { id: 2, value: 'Source 2' }],
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        IdProperty: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).IdProperty : null,
    }

    // Lifecycle
    componentWillMount() {
        this.props.form_actions.fetch_countries();
        this.props.form_actions.fetch_country_codes();
        this.props.form_actions.fetch_nationalities();
        const { params } = this.props, { id } = params;
        if (id) {
            this.setup();
            this.props.floors_actions && this.props.floors_actions.fetch(this.state.IdProperty);
            this.props.coorps_actions && this.props.coorps_actions.fetch_all(fakeAuth.getUser().IdAccount);
            this.props.properties_actions && this.props.properties_actions.fetch_one(this.state.IdProperty);
        } else this.props.router.goBack();
    }
    componentWillReceiveProps = nextProps => {
        const { book, bookings, booking_actions, property } = nextProps, { id } = this.props.params;
        if (book && book.IdBook) this.setState({ book }, () => {
            // NEW: here we need to get the 'occupancy of pieces of the day the reservation starts (or today if that day has already passed). 
            // Then we use that occupancy to rule out pieces for assigning due to -well- they are taken            
            if (!this.state.bootstrapped) {
                this.setState({ bootstrapped: true }, () => {                    
                    setTimeout(() => window && window.print(), 1000);
                });
            }
        });
    }
    //

    setup = () => {
        const { params } = this.props, { id } = params;
        this.props.booking_actions && this.props.booking_actions.fetch_one(id);
        this.props.accounting_actions && this.props.accounting_actions.fetch(id);
        this.props.accounting_actions && this.props.accounting_actions.fetch_payment_methods(id);
    }

    componentDidMount = () => {
        //
    }
    render() {

        const { book } = this.state, { accounting, property } = this.props;
        let SUB_TOTAL = _.chain(accounting).reject(a => a.IsTaxFree).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value(),
            TAXES = SUB_TOTAL * (property ? (property.Tax || 0) : 0) / 100,
            OTHER_TAXES = 0, //_.chain(accounting).filter(a => a.IsTaxFree).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value(),
            NIGHTS = Math.abs(moment(book.CheckOut).utc().diff(moment(book.CheckIn).utc(), 'days')) + 1,
            PROMO = 0;

        return <section className="bg-white w3-container m-4">
            {book && book.IdBook && <section className="w3-content">
                <div className="d-flex mt-4">
                    <div className="w-25">
                        <img style={{ maxHeight: 70 }} src={`${book.LogoUrl}?full=80`} />
                    </div>
                    <div className="w-50 text-center align-self-center">
                        <h3 className="align-self-center">Comprobante de Reservación</h3>
                    </div>
                    <div className="w-25 text-right align-self-center">
                        <h1 className="align-self-center">{padWithZeros(book.PNR)}</h1>
                    </div>
                </div>
                <section className="font-8">
                    <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Check-In</span>
                            <span>{moment(book.CheckIn).format('DD/MM/YYYY')}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Noches</span>
                            <span>{NIGHTS}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Check-Out</span>
                            <span>{moment(book.CheckOut).format('DD/MM/YYYY')}</span>
                        </figure>
                        {book.IsGroup !== 1 && <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Cedula|Pasaporte</span>
                            <span>{book.Guest.IdOrPassport}</span>
                        </figure>}
                        {book.IsGroup === 1 && <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>ID | RIF</span>
                            <span>{book.Coorp.ID}</span>
                        </figure>}
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Contrato</span>
                            <span>-</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Reservado Por</span>
                            <span>-</span>
                        </figure>
                    </div>
                    {book.IsGroup !== 1 && <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Nombre</span>
                            <span>{book.Guest.FirstName}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Apellido</span>
                            <span>{book.Guest.LastName}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Empresa</span>
                            <span>{book.Guest.Coorp || '-'}</span>
                        </figure>
                    </div>}
                    {book.IsGroup === 1 && <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Empresa</span>
                            <span>{book.Coorp.LegalName || '-'}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Contacto</span>
                            <span>{`${book.Coorp.FirstName} ${book.Coorp.LastName}`}</span>
                        </figure>                        
                    </div>}
                    {book.IsGroup !== 1 && <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Dirección</span>
                            <span>{book.Address}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Teléfono</span>
                            <span>{`${book.Guest.CellPhoneCountryCode}-${book.Guest.Cellphone}`}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Email</span>
                            <span>{book.Guest.Email}</span>
                        </figure>
                    </div>}
                    {book.IsGroup === 1 && <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Teléfono</span>
                            <span>{`${book.Coorp.PhoneCountryCode}-${book.Coorp.Phone}`}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Email</span>
                            <span>{book.Coorp.Email}</span>
                        </figure>
                    </div>}
                    <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Linea</span>
                            <span>-</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Vuelo</span>
                            <span>-</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Origen</span>
                            <span>{(book.Guest || { Origin: '-' }).Origin}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Hora</span>
                            <span>{(book.Guest || { EstimatedArrivalTime: '-' }).EstimatedArrivalTime}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>PAX - Child</span>
                            <span>{`${_.chain(book.Rooms).map(u => u.Adults).reduce((a, b) => a + b, 0).value()} - ${_.chain(book.Rooms).map(u => u.Children + u.Cribs).reduce((a, b) => a + b, 0).value()}`}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Por Noche</span>
                            <span>{formatPrice(SUB_TOTAL / NIGHTS * 100)}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Estadia</span>
                            <span>{formatPrice(SUB_TOTAL * 100)}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Impuesto</span>
                            <span>{formatPrice((TAXES + OTHER_TAXES) * 100)}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Total General</span>
                            <span>{formatPrice((SUB_TOTAL + TAXES + PROMO) * 100)}</span>
                        </figure>
                    </div>
                    <div className="w-100 text-center align-self-center">
                        <h3 className="align-self-center">Detalle de la reserva</h3>
                    </div>
                    <div className="d-flex mt-2 font-8">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Tipo</span>
                            {book.Rooms.map((r, i) => <span key={i}>{r.GivenName}</span>)}
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Tarifa</span>
                            {book.Rooms.map((r, i) => <span key={i}>{book.RateHeaderName}</span>)}
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Cntd.</span>
                            {book.Rooms.map((r, i) => <span key={i}>1</span>)}
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Pax</span>
                            {book.Rooms.map((r, i) => <span key={i}>{r.Adults}</span>)}
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Chld</span>
                            {book.Rooms.map((r, i) => <span key={i}>{r.Children + r.Cribs}</span>)}
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Total</span>
                            {book.Rooms.map((r, i) => <span key={i}>{formatPrice(r.RateTotal / NIGHTS * 100)}</span>)}
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Global</span>
                            {book.Rooms.map((r, i) => <span key={i}>{formatPrice(r.RateTotal * 100)}</span>)}
                        </figure>
                    </div>
                    <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0" style={{ minHeight: 100 }}>
                            <span>Observaciones</span>
                            <span>{stripHTMLTags(book.Notes)}</span>
                        </figure>
                    </div>
                    <div className="mt-2" />
                </section>
            </section>}
        </section>
    }
}
export default PrintableExample;