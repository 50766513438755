import React from 'react';
import { Link } from 'react-router';
import fakeAuth from '../../fakeAuth';

class NavBar extends React.Component {

    // Lifecycle
    componentWillMount() {
        this.setState({
            user: fakeAuth.getUser()
        });
    }

    logout = () => {
        const { router } = this.props;
        fakeAuth.signout(() => {
            this.props.form.logout && this.props.form.logout();
            router.push('/login');
        });
    }

    render() {
        const { onToggleMenu } = this.props, { user } = this.state;
        return (
            <div className="d-flex flex-column pb-4 menu pt-2 bg-whiteish h-100" style={{ zIndex: 10 }}>
                <div className="p-4 d-flex flex-row w-100">
                    <i className="fas blue fa-times font-30 pointer " onClick={onToggleMenu} />
                    <Link to="/family" className="ms-auto align-self-center"><img alt="" src={user.UserImage} className="smallPic" /></Link>
                </div>
                <Link to="/dashboard" className="bg-white rounded d-flex flex-row justify-content-start mx-2" style={{ height: 50 }}>
                    <i className="icon-home font-10 jeff_blue align-self-center ms-2" /><span className="syncopate font-8 jeff_blue align-self-center text-center ms-2">HOME</span>
                </Link>
                <Link to="/network" className="bg-white rounded d-flex flex-row justify-content-start mt-2 mx-2" style={{ height: 50 }}>
                    <i className="icon-share-2 font-10 jeff_blue align-self-center ms-2" /><span className="syncopate font-8 jeff_blue align-self-center text-center ms-2">MY NETWORK</span>
                </Link>
                <Link to="/scouting" className="bg-white rounded d-flex flex-row justify-content-start mt-2 mx-2" style={{ height: 50 }}>
                    <i className="icon-sliders font-10 jeff_blue align-self-center ms-2" /><span className="syncopate font-8 jeff_blue align-self-center text-center ms-2">SCOUTING</span>
                </Link>
                <Link to="/recruits" className="bg-white rounded d-flex flex-row justify-content-start mt-2 mx-2" style={{ height: 50 }}>
                    <i className="icon-search font-10 jeff_blue align-self-center ms-2" /><span className="syncopate font-8 jeff_blue align-self-center text-center ms-2">RECRUITS</span>
                </Link>
                <Link to="/family" className="bg-white rounded d-flex flex-row justify-content-start mt-2 mx-2" style={{ height: 50 }}>
                    <i className="icon-users font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">PROFILE</span>
                </Link>
                <a href="https://www.thesqwad.com/referral/10" target="_blank" className={`bg-white jeff_blue rounded d-flex flex-row justify-content-start mt-2 mx-2`} style={{ height: 50 }}>
                    <i className="icon-share font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">SHARE APPS</span>
                </a>
                {/*<Link to="/psfa" className={`rounded d-flex flex-row justify-content-start mt-2 mx-2`} style={{ height: 50, backgroundColor: '#28153D' }}>
                            <img src="/images/logos/psfa.png" className="h-100 ms-2" />
                            <span className="syncopate font-8 align-self-center text-center ms-2 white">PSFA</span>
        </Link>*/}
                <a onClick={this.logout} rel="noopener noreferrer" className={`syncopate w3-bar-item w3-button w3-padding-large block text-left green font-20`}>LOGOUT</a>


            </div>
        );
    }
}

export default NavBar;
