import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { padWithZeros } from '../../helpers';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import momentPlugin from '@fullcalendar/moment'
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../partials/custom_date_picker';
import moment from 'moment';
import _ from 'lodash';
import BookingsTable from '../bookings/partials/table';

class Bookings extends React.Component {


    state = {
        menu_toggled: false,
        toggle: 1,
        sort: 'Name',
        filters: localStorage.getItem('lb.bookings.filters') ? {
            CheckIn: JSON.parse(localStorage.getItem('lb.bookings.filters')).CheckIn ? moment(JSON.parse(localStorage.getItem('lb.bookings.filters')).CheckIn).utc() : null,
            CheckOut: JSON.parse(localStorage.getItem('lb.bookings.filters')).CheckOut ? moment(JSON.parse(localStorage.getItem('lb.bookings.filters')).CheckOut).utc() : null,
            Code: JSON.parse(localStorage.getItem('lb.bookings.filters')).Code || null
        } : {
            CheckIn: moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').utc(),
        },
        sources: [{ id: 1, value: 'WEB' }, { id: 2, value: 'WALK-IN' }],
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }

    // Lifecycle
    componentWillMount() {
        const { params, booking_actions } = this.props, { id } = params, { filters } = this.state;
        if (id) {
            this.onFetch();
        } else this.props.router.push('/properties');
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ bookings: nextProps.bookings, bootstraped: nextProps.bookings ? true : false });
    }
    //

    onFetch = () => {
        const { id } = this.props.params, { filters } = this.state;
        if (id) {
            let hotel = JSON.parse(localStorage.getItem('lb.ip')) || { CheckIn: '15:00', CheckOut: '13:00' };
            this.props.booking_actions && this.props.booking_actions.fetch_filtered(id, {
                ...filters,
                CheckInHour: hotel.CheckIn,
                CheckOutHour: hotel.CheckOut
            });
            localStorage.setItem('lb.bookings.filters', JSON.stringify({
                CheckIn: filters.CheckIn ? filters.CheckIn.format('YYYY-MM-DD') : null,
                CheckOut: filters.CheckOut ? filters.CheckOut.format('YYYY-MM-DD') : null,
                Code: filters.Code
            }));
        }
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_reservaciones blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Reservas</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>

            {/*<div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input className="w-100 p-3 white" type="text" name="email" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} />
                </div>
    </div>*/}
            <div className="w-100 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }

    renderFilters = () => {
        const { filters, sources } = this.state;
        const { id } = this.props.params;
        return (
            <section>
                {/* FILTERS */}
                {this.state.bootstraped && <section>
                    <Animated animationIn="fadeInRight" animationInDelay={200} animationInDuration={200} className="w-100 d-flex flex-wrap mt-4" style={{ zIndex: 200, position: 'relative' }}>

                        {/* Start */}
                        <div style={{ width: 150 }}>
                            <label className="white poppins font-12">Check-In</label>
                            <div id="txtPeriodStart">
                                <DatePicker selected={this.state.filters.CheckIn} highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" hint="Check-In" />} showYearDropdown scrollableYearDropdown maxDate={this.state.filters.CheckOut}
                                    yearDropdownItemNumber={15} onChange={date => {
                                        const { filters } = this.state;
                                        filters.CheckIn = moment(date).utc();
                                        this.setState({ filters });
                                    }} className={`white font-12 ${filters.CheckIn ? 'dirty' : ''}`}
                                />
                            </div>
                        </div>
                        <div className="p-1" />
                        {/* End */}
                        <div style={{ width: 150 }}>
                            <label className="white poppins font-12">Check-Out</label>
                            <div id="txtPeriodEnd">
                                <DatePicker selected={this.state.filters.CheckOut} highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" hint="Check-Out" clear={() => {
                                    const { filters } = this.state;
                                    filters.CheckOut = null;
                                    this.setState({ filters });
                                }} />} showYearDropdown scrollableYearDropdown minDate={this.state.filters.CheckIn}
                                    yearDropdownItemNumber={15} onChange={date => {
                                        const { filters } = this.state;
                                        filters.CheckOut = moment(date).utc();
                                        this.setState({ filters });
                                    }} className={`white font-12 ${filters.CheckOut ? 'dirty' : ''}`}
                                />
                            </div>
                        </div>
                        <div className="p-1" />
                        {/* Code */}
                        <div style={{ width: 400 }}>
                            <label className="white poppins font-12">Búsqueda</label>
                            <div id="txtCode" className={`black-input d-flex mt-2 ${filters.Code ? 'dirty' : ''}`}>
                                <input defaultValue={this.state.filters.Code} className="w-100 p-3 white" type="text" name="name" placeholder="Ej: Código de reserva, Nombre.." ref={(input) => this.txtCode = input} onChange={(e) => {
                                    const { filters } = this.state;
                                    filters.Code = e.target.value;                                    
                                    this.setState({ filters });
                                }} />
                            </div>
                        </div>
                        <div className="p-1" />
                        <button onClick={this.onFetch} className="btn btn-lg btn-link white align-self-end d-flex bordered">
                            <i className="fas fa-search blue font-18 my-2 align-self-center" />
                        </button>
                        <div className="p-1" />
                        <Link to={`/booking/add/${this.props.params.id}`} className="ms-auto btn btn-lg btn-blue white align-self-end d-flex">
                            <i className="fas fa-plus-circle white font-18 my-2 align-self-center" />
                            <span className="align-self-center ms-2">Nueva Reserva</span>
                        </Link>
                    </Animated>
                </section>}
            </section>
        );
    }
    renderTable = () => {
        return <BookingsTable {...this.state} />
    }

    renderCommon = () => {
        const { filters, sources } = this.state;
        const { id } = this.props.params
        return (
            <div className="">
                {this.renderHeader()}
                <div className="w-100 d-flex mt-4">
                    <Link to={`/frontdesk/${id}`} className="btn btn-link d-flex">
                        <i className="far fa-calendar gray font-18 align-self-center" />
                        <span className="gray ms-3 font-16 poppins align-self-center">Calendario</span>
                    </Link>
                    <div className="btn btn-link d-flex">
                        <i className="fas fa-list fa-square-check blue font-18 align-self-center" />
                        <span className="white ms-3 font-16 poppins align-self-center">Listado de reservas</span>
                    </div>
                </div>
                {this.renderFilters()}
                {this.renderTable()}
                <div style={{ height: 200 }} />
            </div>
        )
    }

    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {this.state.microTransac && <LineLoader />}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}
                    <Menu {...this.state} {...this.props} />
                </section>
            </Layout>
        )
    }
}

export default Bookings;
