import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';


export function* fetch_periods() {
    yield* takeEvery("PERIODS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/rooms/periods/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { periods, room } = result;
                yield put({ type: 'PERIODS/SET', periods });
                yield put({ type: 'ROOM/SET', room });
            } else {
                yield put({
                    type: 'PERIODS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'PERIODS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_period() {
    yield* takeEvery("PERIOD/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/periods/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { period } = result;                
                yield put({ type: 'PERIOD/SET', period });
            } else {
                yield put({
                    type: 'PERIOD/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'PERIOD/FETCH_FAILED',
                e
            });
        }
    });
}

export function* archive_period() {
    yield* takeEvery("PERIOD/ARCHIVE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/periods/archive/${id}`)
                    .send({})
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { IdRoom } = action
                yield put({ type: 'PERIODS/FETCH', id: IdRoom });
            } else {
                yield put({
                    type: 'PERIOD/ARCHIVE/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'PERIOD/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}

export function* toggle_period() {
    yield* takeEvery("PERIOD/TOGGLE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id, IsActive }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/periods/toggle/${id}`)
                    .send({ IsActive })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'PERIOD/TOGGLE/FETCH_FAILED',
                e
            });
        }
    });
}
