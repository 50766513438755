import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import config from '../../config';
import BookingsTable from '../bookings/partials/table';
import moment from 'moment';
import WidgetOccupancy from './partials/widget_occupancy';
import WidgetTotalSales from './partials/widget_total_sales';
import WidgetVS from './partials/widget_vs';

class Home extends React.Component {
    state = {
        menu_toggled: false,
        filters: {},
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }
    componentWillMount() {
        const { params, booking_actions } = this.props, { id } = params;
        if (id) {
            let hotel = JSON.parse(localStorage.getItem('lb.ip')) || { CheckIn: '15:00', CheckOut: '13:00' };
            this.props.booking_actions && this.props.booking_actions.fetch_filtered(id, {
                CheckIn: moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').utc(),
                CheckInHour: hotel.CheckIn,
            });
            //booking_actions && booking_actions.fetch(id);
        } else {
            let user = fakeAuth.getUser();
            if (user.IdProperty && user.IdStaff) {
                localStorage.setItem('lb.ip', JSON.stringify({
                    IdProperty: user.IdProperty,
                    Name: user.PropertyName,
                    CheckIn: user.PropertyCheckIn,
                    CheckOut: user.PropertyCheckOut
                }));

                // TODO: Here we need to evaluate the user's permissions, to seee
                // where do we move it                
                this.props.router.push(`/dashboard/${user.IdProperty}`);
            } else this.props.router.push('/properties');
        }
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ bookings: nextProps.bookings, bootstraped: true });
    }
    renderHeader = () => {
        const { id } = this.props.params
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_dashboard blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Vista General</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            <div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input className="w-100 p-3 white" type="text" name="email" placeholder="Buscar en Legendbeds"
                        ref={(input) => this.txtSearch = input} onChange={(e) => {
                            const { filters } = this.state;
                            filters.Master = e.target.value;
                            this.setState({ filters });
                        }} />
                </div>
            </div>
            {/*<div className="w-100 d-flex justify-content-center ">
                <Link to={`/frontdesk/${id}`} className="w-100 d-flex flex-column align-self-center">
                    <i className="icon-lb_calendar font-30 gray align-self-center" />
                    <span className="poppins align-self-center gray font-8">Calendario</span>
                </Link>
                <div className="w-100 d-flex flex-column align-self-center">
                    <i className="icon-lb_tune font-30 gray align-self-center" />
                    <span className="poppins align-self-center gray font-8">Widgets</span>
                </div>
                <div className="w-100 d-flex flex-column align-self-center">
                    <i className="icon-lb_cog font-30 gray align-self-center" />
                    <span className="poppins align-self-center gray font-8">Opciones</span>
                </div>
    </div>*/}
            <div className="w-100 d-flex">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderWidgets = () => {
        return <div className="mt-4 d-flex">
            <WidgetOccupancy {...this.props} />
            <div className="p-2" />
            <WidgetTotalSales {...this.props} />
            <div className="p-2" />
            <WidgetVS {...this.props} />
        </div>
    }
    renderTable = () => {
        return <BookingsTable {...this.state} />
    }
    renderCommon = () => {
        return (
            <div className="">
                {this.renderHeader()}
                {this.state.bootstraped && <section>
                    {this.renderWidgets()}
                    {this.renderTable()}
                    <div style={{ height: 100 }} />
                </section>}
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                </section>
            </Layout>
        )
    }
}

export default Home;
