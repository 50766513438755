import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

// import the roots
import rootReducer from './redux-sagas/reducers/index';
import rootSaga from './redux-sagas/sagas/root';

const sagaMiddleware = createSagaMiddleware()

const defaultState = {
    book: null,
    //bookings: null,
    user: null,
    properties: null,
    property: null,
    periods: null,
    period: null,
    countries: null,
    country_codes: null,
    rooms: null,
    room: null,
    rates: null,
    offers: null,
    offer: null,
    plans: null,
    plan: null,
    staff: null,
    staffs: null,
    floors: null,
    comments: null,
    accounting: null,
    auxiliar_accountings: null,
    payment_methods: null,
    occupancy_summary: null,
    guests: null,
    guest: null,
    coorps: null,
    coorp: null,
    nationalities: null,
};

// TODO: understand this ?
const enhancers = compose(
    applyMiddleware(sagaMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f
);
const store = createStore(
    rootReducer,
    defaultState,
    enhancers
);
//

sagaMiddleware.run(rootSaga)

export default store;
