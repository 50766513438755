import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { generateRandomId, preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import BlackOverlay from '../partials/black_overlay';
import _ from 'lodash';
import request from 'superagent';

class Floormap extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 1,
        floors: [],
        rooms_selected: [],
        status: config.room_status,
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }
    componentWillMount() {
        const { params } = this.props;
        let user = fakeAuth.getUser();
        if (user.IdProperty) this.props.router.goBack();
        else {
            this.props.rooms_actions && this.props.rooms_actions.fetch(params.id);
            this.props.properties_actions && this.props.properties_actions.fetch_one(params.id);
            this.props.floors_actions && this.props.floors_actions.fetch(params.id);
        }
    }
    componentWillReceiveProps = nextProps => {
        const { floors = [] } = nextProps;
        this.setState({ floors });
    }

    onAddFloor = () => {
        // TODO: call api
        const { floors = [] } = this.state;
        floors.push({ TemporalId: generateRandomId(10), Number: '#', Pieces: [], });
        this.setState({ floors, toggle: _.last(floors).IdFloor, dirty: true });
    }
    onSaveChanges = () => {
        // TODO: call a massive save api?
        const { floors } = this.state;

        // Call API
        this.setState({ microTransac: true }, () => {
            request.post(`${config.apiEndpoint}/api/v4/floors/${this.props.params.id}`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .send(floors)
                .then((data) => {
                    if (data.body.success) {
                        this.props.floors_actions && this.props.floors_actions.fetch(this.props.params.id);
                        this.setState({ dirty: false, confirm: false, microTransac: false });
                    } else
                        this.setState({ microTransac: false });

                }, (error) => {
                    this.setState({ microTransac: false, error: 'Something wrong' });
                    //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                });
        });
    }
    onArchiveFloor = () => {
        const { archive, floors } = this.state;
        this.setState({ archive: null, floors: _.reject(floors, f => f.IdFloor === archive) });
        // CALL API
        request.patch(`${config.apiEndpoint}/api/v4/floors/archive/${archive}`)
            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
            .send({})
            .end();
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="fas fa-map blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Floormap</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
                {this.state.dirty === true && <button onClick={() => this.setState({ confirm: true })} className="btn btn-lg btn-green white px-4 align-self-center d-flex ms-4" >
                    Guardar cambios
                </button>}
            </div>
            <div className="d-flex align-self-center w-100">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderControls = () => {
        const { status, floors } = this.state, { property } = this.props;
        return <div className="px-4 py-3 d-flex" style={{ background: '#15191E', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
            <div className="d-flex w-100">
                <i className="ms-4 white fas font-20 fa-chevron-right align-self-center" />
                <div className="ms-4 bg-blue d-flex flex-row justify-content-center" style={{ width: 60, height: 60, borderRadius: 30, border: '6px solid black' }}>
                    <span className="white font-10 poppins align-self-center">{(floors || []).length}/{(property || { TotalFloors: '?' }).TotalFloors}</span>
                </div>
                <div className="d-flex flex-column ms-2 align-self-center">
                    <span className="poppins white font-8">Pisos</span>
                    <span className="poppins white font-8">Sugeridos</span>
                </div>
            </div>
            <div className="bg-gray-2 black-input d-flex w-100">
                <i className="fas fa-search font-16 white align-self-center ps-3" />
                <input className="w-100 p-3 white" type="text" placeholder="Buscar pieza" ref={(input) => this.txtSearch = input} onChange={e => this.setState({ search: e.target.value })} />
            </div>
            <div className="d-flex w-100">
                <button onClick={this.onAddFloor} className="btn btn-lg btn-blue white px-4 ms-auto align-self-center d-flex" >
                    Nuevo Piso <i className="fas fa-plus-circle align-self-center font-10 ms-3" />
                </button>
            </div>
        </div>
    }
    renderAccordion = () => {
        const { toggle, floors } = this.state, { rooms } = this.props;

        return <section>
            <div className="bg-gray-2 d-flex black-input mb-3 mt-4" >
                <div className="w-100">
                    {this.renderControls()}
                    {floors && floors.length > 0 && floors.map((floor, floor_index) => {
                        return (
                            <section key={floor.IdFloor || floor.TemporalId}>
                                {/* FLOOR HEADER */}
                                <div className="px-4 py-3 d-flex pointer" /*onClick={() => this.setState({ toggle: floor.IdFloor })}*/>
                                    <span className="poppins white align-self-center">Piso</span>
                                    <div className="black-input mx-2 align-self-center">
                                        <input className="w-100 p-3 white poppins" type="text" placeholder="#"
                                            style={{ maxWidth: 80 }} defaultValue={floor.Label} onChange={({ target }) => {
                                                const { floors } = this.state;
                                                floors[floor_index].Label = target.value;
                                                this.setState({ floors, dirty: true });
                                            }} />
                                    </div>
                                    <button onClick={() => {
                                        const { floors } = this.state;
                                        floors[floor_index].Pieces.push({ TemporalId: generateRandomId(10) });
                                        this.setState({ floors, dirty: true });
                                    }} className="btn btn-lg btn-link bordered white px-4 ms-auto align-self-center d-flex" >
                                        Nueva pieza <i className="fas fa-plus-circle align-self-center font-10 ms-3" />
                                    </button>
                                    {floor.IdFloor && <i className={`ms-3 me-2 gray fas fa-archive align-self-center pointer`} onClick={() => {
                                        this.setState({ archive: floor.IdFloor })
                                    }} />}
                                    {!floor.IdFloor && <i className={`ms-3 me-2 gray fas fa-times align-self-center pointer`} onClick={() => {
                                        const { floors } = this.state;
                                        this.setState({ floors: [...floors.slice(0, floor_index), ...floors.slice(floor_index + 1)] });
                                    }} />}
                                </div>
                                <div className="bg-black p-4 d-flex flex-wrap justify-content-center">
                                    {/* ROOMS */}
                                    {floor.Pieces.map((piece, b) => {
                                        if (!this.state.search || (this.state.search &&
                                            floors[floor_index].Pieces[b].Label &&
                                            floors[floor_index].Pieces[b].Label.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)) {
                                            return <figure className="bg-gray-2 d-flex flex-column black-input m-1 p-2" style={{ width: 235 }} key={piece.IdPiece || piece.TemporalId} onClick={() => this.setState({ rooms_selected: [{}] })} >
                                                <div className="d-flex w-100">
                                                    <i onClick={() => {
                                                        const { floors } = this.state;
                                                        floors[floor_index].Pieces = [
                                                            ...floors[floor_index].Pieces.slice(0, b),
                                                            ...floors[floor_index].Pieces.slice(b + 1)
                                                        ];
                                                        // TODO: if the piece has an IdPiece, we call API to Archive it
                                                        this.setState({ floors });
                                                    }} className="fas ms-auto pointer fa-times white font-10 align-self-center ms-1"></i>
                                                </div>
                                                <div className="d-flex flex-column w-100 justify-content-center" style={{ height: 'auto' }}>
                                                    <div className="black-input mx-2 align-self-center">
                                                        <input className="w-100 p-3 white poppins" type="text" placeholder="Nombre" defaultValue={piece.Label} onChange={({ target }) => {
                                                            const { floors } = this.state;
                                                            if (floors[floor_index].Pieces[b]) {
                                                                floors[floor_index].Pieces[b].Label = target.value;
                                                                this.setState({ floors, dirty: true });
                                                            }
                                                        }} />
                                                    </div>
                                                    <div className={`black-input d-flex mt-2 mx-2 mb-2`}>
                                                        <Dropdown isOpen={piece.SELECTING} toggle={() => {
                                                            const { floors } = this.state;
                                                            if (floors[floor_index].Pieces[b]) {
                                                                floors[floor_index].Pieces[b].SELECTING = !floors[floor_index].Pieces[b].SELECTING;
                                                                this.setState({ floors });
                                                            }
                                                        }} className="w-100">
                                                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 bg-transparent d-flex">
                                                                <span className={`align-self-center poppins ${piece.IdRoom ? 'white' : 'gray'}`}>{(_.find(rooms, t => t.IdRoom === piece.IdRoom) || { GivenName: 'Tipo' }).GivenName}</span>
                                                                <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="w-100">
                                                                {rooms && rooms.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                                    const { floors } = this.state;
                                                                    if (floors[floor_index].Pieces[b]) {
                                                                        //floors[floor_index].Pieces[b].SELECTING = false;
                                                                        floors[floor_index].Pieces[b].IdRoom = c.IdRoom;
                                                                        this.setState({ floors, dirty: true });
                                                                    }
                                                                }}>{c.GivenName}</DropdownItem>)}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </figure>
                                        } else return null;
                                    })}

                                    {/* Add Room */}
                                    {/*<figure onClick={() => {
                                        const { floors } = this.state;
                                        floors[floor_index].Pieces.push({ TemporalId: generateRandomId(10) });
                                        this.setState({ floors, dirty: true });
                                    }} className="btn-blue d-flex justify-content-center flex-column black-input m-1 p-2" style={{ width: 235 }}>
                                        <div className="d-flex w-100 justify-content-center align-self-center" style={{ height: 80 }}>
                                            <span className="white align-self-center mx-2 poppins">AGREGAR PIEZA <i className="fas fa-plus-circle align-self-center font-10 ms-3" /></span>
                                        </div>
                                </figure>*/}

                                </div>
                            </section>)
                    })}

                    <div className="px-4 py-3 d-flex" style={{ background: '#15191E', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} />
                    </div>
                </div>
        </section >
    }
    renderCommon = () => {
        return (
            <div className="">
                {this.renderHeader()}
                {this.renderAccordion()}
                <div style={{ height: 200 }} />
            </div>
        )
    }

    onBounce = (i) => {
        const { status } = this.state;
        _.chain(status).reject(s => s.macro).value()[i].bounce = true;
        _.chain(status).reject(s => s.macro).value()[i].selected = !_.chain(status).reject(s => s.macro).value()[i].selected;
        this.setState({ status }, () => {
            setTimeout(() => {
                _.chain(status).reject(s => s.macro).value()[i].bounce = false;
                this.setState({ status });
            }, 1000);
        })
    }

    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">{this.props.params.id ? 'Editar' : 'Nueva'} floormap</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de guardar estos cambios?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.onSaveChanges} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.archive ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Archivar piso</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de archivar este piso? Esta operación no se puede revertir</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ archive: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.onArchiveFloor} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    <Animated animationInDuration={300} animationOutDuration={300} isVisible={false} animateOnMount={false} animationIn="slideInUp" animationOut="slideOutDown" style={{ position: 'fixed', bottom: 0, left: 100, right: 0 }} className="p-4 d-flex justify-content-center">
                        <div className="black-input p-4 d-flex" style={{ backgroundColor: '#15191E' }}>
                            <div className="align-self-center">
                                <div className="d-flex">
                                    <span className="font-8 gray">Seleccionados:</span>
                                    <span className="font-8 ms-2 blue">#101, #102, #103</span>
                                </div>
                                <div className="d-flex my-2">
                                    <div className="bg-gray-light w-100 align-self-center" style={{ height: 1 }}></div>
                                    <span className="gray font-8 w-100 align-self-center text-center">Cambiador de estados</span>
                                    <div className="bg-gray-light w-100 align-self-center" style={{ height: 1 }}></div>
                                </div>
                                <div className="d-flex justify-content-evenly  align-items-start">
                                    {_.chain(status).reject(s => s.macro).value().map((s, i) => <div onClick={() => this.onBounce(i)} key={i} className={`w-100 d-flex flex-column justify-content-center text-center ${s.bounce ? 'bounce' : ''}`}>
                                        <button className={`btn btn-lg btn-${s.selected ? 'blue white' : 'gray-light black'} align-self-center`} style={{ borderRadius: 5 }}><i className={`${s.icon}`} /></button>
                                        <span className="font-8 white poppins mt-1 gray align-self-center">{s.name}</span>
                                    </div>)}
                                </div>
                            </div>
                            <div className="d-flex flex-column align-self-center ms-4">
                                <button type="button" className="btn btn-lg btn-gray-light black w-100 poppins font-10">
                                    Asignar <i className="icon-lb_user" />
                                </button>
                                <button type="button" className="btn btn-lg btn-black gray w-100 mt-2 poppins font-10">
                                    Comentarios <i className="fas fa-comments" />
                                </button>
                            </div>
                        </div>

                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default Floormap;
