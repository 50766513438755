import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';


export function* fetch_rooms() {
    yield* takeEvery("ROOMS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/properties/rooms/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { rooms } = result;
                yield put({ type: 'ROOMS/SET', rooms });
            } else {
                yield put({
                    type: 'ROOMS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'ROOMS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* fetch_room() {
    yield* takeEvery("ROOM/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v4/rooms/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { room } = result;                
                yield put({ type: 'ROOM/SET', room });
            } else {
                yield put({
                    type: 'ROOM/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'ROOM/FETCH_FAILED',
                e
            });
        }
    });
}

export function* archive_room() {
    yield* takeEvery("ROOM/ARCHIVE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/rooms/archive/${id}`)
                    .send({})
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { IdProperty } = action
                yield put({ type: 'ROOMS/FETCH', id: IdProperty });
            } else {
                yield put({
                    type: 'ROOM/ARCHIVE/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'ROOM/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}

export function* toggle_room() {
    yield* takeEvery("ROOM/TOGGLE", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id, IsActive }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/rooms/toggle/${id}`)
                    .send({ IsActive })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);            
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({ type: 'LOADING/SET', loading: false });
            yield put({
                type: 'ROOM/ARCHIVE/FETCH_FAILED',
                e
            });
        }
    });
}
