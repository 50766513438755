function reducer(state = [], action) {
    switch (action.type) {
        case 'COUNTRY_CODES/SET':            
            return action.country_codes;
        case 'COUNTRY_CODES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;
