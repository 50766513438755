import React from 'react';
import { Link } from 'react-router';
import fakeAuth from '../../fakeAuth';

class NavBar extends React.Component {

    state = {
        user: null
    }

    // Lifecycle
    componentWillMount() {
        this.setState({
            user: fakeAuth.getUser()
        });
    }


    logout = () => {
        const { router } = this.props;
        fakeAuth.signout(() => {
            this.props.form.logout && this.props.form.logout();
            router.push('/login');
        });
    }

    render() {
        const { className, nbs, form } = this.props, { user } = this.state;

        return (
            <section id="top" className="ms-auto w3-hide-small">
                <div className="d-flex flex-row h-100 toBold syncopate" style={{ fontSize: '13px' }}>
                    <Link to="/dashboard" className={`ms-4 align-self-center font-10 ${className} ${nbs === 'managers' ? 'white' : 'white'}`}>HOME</Link>
                    <a onClick={this.logout} className={`font-10 ms-4 orange align-self-center me-4 pointer`}>LOGOUT</a>
                    <Link to="/family" className="me-4 align-self-center"><img alt="" src={user.UserImage} className="smallPic" /></Link>
                </div>
            </section>
        );
    }
}

export default NavBar;