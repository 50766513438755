function comments_Reducer(state = [], action) {
    switch (action.type) {
        case 'COMMENTS/SET':            
            return action.comments;
        case 'COUNTRIES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default comments_Reducer;
