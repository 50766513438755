export function login(args) {
    return {
        type: "AUTH/LOGIN",
        token: args.token,
        isAdmin: args.isAdmin
    }
}
export function logout() { return { type: "AUTH/LOGOUT", } }
export function setTitle(args) { return { type: 'TITLE/SET', args } }
export function updateTitle(args) { return { type: 'TITLE/UPDATE', args } }
export function setMicro(micro) { return { type: 'MICRO/SET', micro } }
export function putReferal(referal) { return { type: 'REFERAL/SET', referal } }
export function clearReferal() { return { type: 'REFERAL/CLEAR' } }
export function set(form) { return { type: 'FORM/SET', form } }
export function fetchCommunities() { return { type: 'COMMUNITIES/FETCH' } }
export function fetch_countries() { return { type: 'COUNTRIES/FETCH' } }
export function fetch_country_codes() { return { type: 'COUNTRY_CODES/FETCH' } }
export function fetch_nationalities() { return { type: 'NATIONALITIES/FETCH' } }