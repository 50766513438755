import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { getReadableFileSizeString, getBase64 } from '../../../helpers';
import { Popover, PopoverBody, } from 'reactstrap';
import _, { slice } from 'lodash';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import request from 'superagent';
import config from '../../../config';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';



class Notes extends React.Component {
    state = {

    }

    /*
    files: _.chain(staff.Attachments || []).map((file, i) => {
                return { file: { name: file.Name || `File #${i + 1}`, IdAlbum: file.IdAlbum, URL: file.URL, size: file.FileSize || file.Size, type: file.MimeType }, eye: false }
            }).value()
    */
    componentWillMount = () => {
        const { book } = this.props;
        this.setState({ bootstrapped: true }, () => { this.onLoadEditorState(1, 1); });
    }
    componentWillReceiveProps = nextProps => {
        const { book } = nextProps;
        this.setState({

        });
    }

    onLoadEditorState = (mode, stage) => {
        const { book } = this.props;
        let html = book.Notes || '';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            this.setState({
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)),
                bootstrapped: true,
                editorMode: mode,
                editorStage: stage,
            });
        }
    }
    onEditorStateChange = (editorState) => this.setState({ editorState }, () => {
        const { editorMode, editorStage } = this.state, { book } = this.props;
        book.Notes = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    });

    validate = () => {
        const { book } = this.props;
        this.setState({ microTransact: true }, () => {
            // CALL API                
            request.patch(`${config.apiEndpoint}/api/v4/bookings/notes/${book.IdBook}`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .send({
                    ..._.pick(book, 'Notes')
                })
                .then((data) => {
                    if (data.body.success) {
                        this.setState({ microTransact: false }, () => {
                            this.props.fnUpdate && this.props.fnUpdate(this.state);
                            this.props.fnDone && this.props.fnDone();
                        });
                    }
                }, (error) => {
                    this.setState({ microTransact: false, error: 'Something wrong' });
                    //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                });
        });
    }

    render() {
        const { editorState, editorStage, editorMode, } = this.state;
        return <section>

            <section className="mt-2">
                <div className="w3-content">
                    <Editor
                        wrapperClassName="demo-wrapper"
                        editorState={editorState}
                        editorClassName="demo-editor px-4"
                        onEditorStateChange={this.onEditorStateChange}
                    />

                    {/* Buttons */}
                    <div className="d-flex justify-content-center mt-4">
                        {!this.state.microTransact && <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center d-flex" >
                            <span className="align-self-center">Guardar cambios</span><i className="fas fa-chevron-right white font-12 ms-2 align-self-center" />
                        </button>}
                        {this.state.microTransact && <button type="button" className="btn btn-lg btn-blue white ms-3 align-self-center d-flex" >
                            <span className="align-self-center">Guardando...</span><i className="fas fa-cog fa-spin white font-12 ms-2 align-self-center" />
                        </button>}
                    </div>
                </div>
            </section>

        </section>
    }
}

export default Notes;