import React from 'react';
import Layout from '../layouts/base.dark';
import { Link } from 'react-router';
import { humanize_user } from '../../helpers';
import BlackOverlay from '../partials/black_overlay';
import { Animated } from 'react-animated-css';;
import fakeAuth from '../../fakeAuth';
import request from 'superagent';
import config from '../../config';
import Logo from '../partials/logo';
import { Popover, PopoverBody } from 'reactstrap';

class Recover extends React.Component {
    state = {
        redirectToReferrer: false,
        mode: 1,
        invalid: false,
        selectedBg: null,
        message: null,
        email: null,
        toggle: true,
        code: ''
    }

    componentWillMount() {
        const { params } = this.props, { referal } = params;
        if (referal) this.props.putReferal(referal);
        if (fakeAuth.isAuthenticated()) {
            this.props.router.push('/dashboard');
        } else {
            // Clear some things            
            this.setState({ email: localStorage.getItem('email') });
        }
    }

    doLogin = (e) => {
        e && e.preventDefault && e.preventDefault();
        fakeAuth.authenticate(() => {
            this.props.form_actions.login({
                token: '41231231', // user.Token,
                isAdmin: true, //user.IsLeagueAdmin
            });
            this.props.router.push('/dashboard');

        }, '41231231',
            true, { IdUser: 1, FirstName: 'Freddy', LastName: 'Torres', Token: 'kljdhashljka' })
        return;
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
                email: this.txtEmail.value
            }, () => {

                // Save the email on the localstorage for future usage
                localStorage.setItem('email', this.txtEmail.value);

                // Call API
                request.post(`${config.apiEndpoint}/api/v6/membership/login`)
                    .send({
                        email: this.txtEmail.value,
                        pass: this.txtPassword.value
                    })
                    .then((data) => {
                        if (data.body.success) {

                            // Decrypt User Info
                            data.body.family.forEach(humanize_user);


                            this.setState({ toggle: false }, () => {
                                setTimeout(() => {

                                    if (data.body.family.length > 1) {

                                        this.setState({
                                            mode: 2,
                                            microTransac: false,
                                            users: data.body.family,
                                        }, () => {
                                            this.setState({ toggle: true });
                                        });
                                    } else {
                                        this.setState({ users: data.body.family }, () => {
                                            this.selectUser(_.first(data.body.family));
                                        });
                                    }
                                }, 400)
                            });

                        } else
                            this.setState({ microTransac: false, invalid: true });

                    }, (error) => {
                        this.setState({
                            microTransac: false,
                            invalid: true,
                            message: 'Invalid Email or Password. Please check.', //body.error.message
                        });
                        this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });
        }
    }

    selectUser = user => {
        if (user.Token) {
            this.setState({ toggle: false }, () => {
                fakeAuth.authenticate(() => {
                    this.props.form_actions.login({
                        token: user.Token,
                        isAdmin: user.IsLeagueAdmin
                    });
                    setTimeout(() => { this.props.router.push('/dashboard'); }, 500);
                }, user.Token, user.IsLeagueAdmin, user, this.state.users)
            });
        }
    }

    onValidateEmail = () => {
        if (this.txtEmail.value) {
            this.setState({ toggle: false, wrong_email: false }, () => {
                setTimeout(() => {
                    this.setState({ toggle: true, mode: 2 });
                }, 500);
            });
        } else this.setState({ wrong_email: true });
    }

    render() {
        const { mode, microTransac, email, toggle, code } = this.state;
        return (
            <Layout>
                <section style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: `url(images/backgrounds/waves.png) no-repeat center bottom #1771ff` }}>
                    <div className="h-100 w-100 d-flex justify-content-center" style={{ position: 'relative' }}>                        

                        {mode === 1 && <Animated animateOnMount={true} isVisible={(toggle && mode === 1) ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight"
                            animationOutDuration={400} animationInDelay={400} animationInDuration={400} style={{ width: 500, zIndex: 1, borderRadius: 6 }}
                            className=" align-self-center d-flex px-4" >
                            <div className="w-100 d-flex flex-row px-4" id="form_container">
                                <form key="login-form" className='align-self-center w-100 d-flex flex-column'>
                                    <Logo className="font-80 align-self-center mt-4" />
                                    <div className="black-input bg-gray-2 p-4 align-self-center mt-4">
                                        <h3 className="white poppins text-center">Has olvidado tu contraseña</h3>
                                        <hr className="w-50 mx-auto" />
                                        <h6 className="white text-center poppins">Ingresa tu dirección de correo electrónico afiliado a legendbeds para enviarte un enlace y restablecer la contraseña.</h6>
                                        <div className="w-100 mt-4">
                                            <div className={`black-input ${this.state.wrong_email ? 'error-input' : ''} d-flex mt-2`}>
                                                <i className="fas fa-user font-16 white align-self-center ps-3" />
                                                <input className="w-100 p-3 white poppins" defaultValue={email} type="text" name="email" placeholder="Correo electrónico" ref={(input) => this.txtEmail = input} />
                                            </div>
                                        </div>
                                        <section>
                                            <button type="button" className="btn btn-lg btn-blue white w-100 mt-4" onClick={this.onValidateEmail}>
                                                {!microTransac && <span className="poppins">Recuperar</span>}
                                                {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                            </button>
                                            <Link to="/login" className="btn btn-lg btn-link white w-100 font-10">
                                                <span className="poppins">Volver al inicio de sesión</span>
                                            </Link>
                                        </section>
                                    </div>
                                    <div style={{ height: 70 }} />
                                    <span className="white font-8 mx-auto poppins">©Legendbeds 2023. Powered by Legendsoft. Todos los derechos reservados</span>
                                </form>

                                <Popover placement="right" isOpen={this.state.wrong_email} target={`form_container`} toggle={() => this.setState({ wrong_email: !this.state.wrong_email })} className="bg-gray-2">
                                    <PopoverBody className="d-flex flex-row justify-content-center">
                                        <i className="fas fa-times red font-8 align-self-center" />
                                        <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>Lo sentimos, no pudimos encontrar una cuenta con esa dirección de correo electrónico. Asegúrate de escribirlo correctamente.</span>
                                    </PopoverBody>
                                </Popover>
                            </div>
                        </Animated>}

                        {mode === 2 && <Animated animateOnMount={true} isVisible={(toggle && mode === 2) ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight"
                            animationOutDuration={400} animationInDelay={400} animationInDuration={400} style={{ width: 500, zIndex: 1, borderRadius: 6 }}
                            className=" align-self-center d-flex px-4" >

                            <div className="w-100 d-flex flex-row px-4" id="form_container">
                                <form key="login-form" className='align-self-center w-100 d-flex flex-column'>
                                    <Logo className="font-80 align-self-center mt-4" />
                                    <div className="black-input bg-gray-2 p-4 align-self-center mt-4">
                                        <h3 className="white poppins text-center">Has olvidado tu contraseña</h3>
                                        <hr className="w-50 mx-auto" />
                                        <h6 className="white text-center poppins">En breve le enviaremos el enlace a su correo electrónico para restrablecer la contraseña.</h6>
                                        <section>
                                            <Link to="/login" className="btn btn-lg btn-blue white w-100 mt-4">
                                                <span className="poppins">Volver al inicio de sesión</span>
                                            </Link>
                                        </section>
                                    </div>
                                    <div style={{ height: 70 }} />
                                    <span className="white font-8 mx-auto poppins">©Legendbeds 2023. Powered by Legendsoft. Todos los derechos reservados</span>
                                </form>
                            </div>

                        </Animated>}

                    </div>
                </section>
            </Layout >
        )
    }
}

export default Recover;
