import React from 'react';
import { Animated } from 'react-animated-css';
import moment from 'moment';

class WidgetOccupancy extends React.Component {
    state = {
        Flag: 'day',
        occupancy_summary: null
    }
    componentWillMount = () => {
        this.onFetch();
    }
    componentWillReceiveProps = nextProps => {
        const { occupancy_summary } = nextProps;
        this.setState({ occupancy_summary: occupancy_summary || this.state.occupancy_summary });
    }

    onFetch = () => {
        const { id } = this.props.params;
        //this.props.logic_actions && this.props.logic_actions.clear_occupancy_summary();
        this.props.logic_actions && this.props.logic_actions.fetch_occupancy({ IdProperty: id, Flag: this.state.Flag });
    }

    render() {
        const { occupancy_summary } = this.state, { Flag } = this.state;        
        return <Animated animationIn='fadeInRight' animationInDelay={0} className="w-100 bg-gray-2 px-3 py-2 rounded">
            <div className="d-flex">
                <span className="poppins font-12 white align-self-center">Ocupación</span>
                {!occupancy_summary && <span className="poppins font-16 gray ms-auto align-self-center"><i className="fas fa-cog fa-spin" /></span>}
                {occupancy_summary && <span className="ms-auto align-self-center"><span className="font-8 gray poppins">{`(${occupancy_summary.total_rooms_occupied}/${occupancy_summary.total_rooms})`}</span> <span className="poppins font-16 blue align-self-center">{Math.round(occupancy_summary.occupancy_percentage)}%</span></span>}
            </div>
            <div className="d-flex mt-2">
                <span onClick={() => { this.setState({ Flag: 'day' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'day' ? 'blue' : 'white'} align-self-center`}>Dia</span>
                <span onClick={() => { this.setState({ Flag: 'week' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'week' ? 'blue' : 'white'} white align-self-center ms-2`}>Semana</span>
                <span onClick={() => { this.setState({ Flag: 'month' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'month' ? 'blue' : 'white'} white align-self-center ms-2`}>Mes</span>
                {/*<span className="pointer poppins font-8 white align-self-center ms-2">Año</span>*/}
                <i className="icon-download font-16 transparent ms-auto align-self-center" />
            </div>
        </Animated>
    }
}

export default WidgetOccupancy;