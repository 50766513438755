export function fetch(id) { return { type: 'FLOORS/FETCH', id } }
export function fetch_one(id) { return { type: 'PLAN/FETCH', id } }
export function archive(id, IdProperty) { return { type: 'PLAN/ARCHIVE', id, IdProperty } }
export function toggle(id, IsActive) { return { type: 'PLAN/TOGGLE', id, IsActive } }
export function clean() { return { type: 'PLAN/CLEAR' } }
export function update_piece_status(piece) { return { type: 'PIECE/STATUS/UPDATE', piece } }

// Piece comments
export function fetch_comments(id) { return { type: 'PIECE/COMMENTS/FETCH', id }}
export function add_comment({ IdPiece, Comment }) { return { type: 'PIECE/COMMENT/ADD', IdPiece, Comment }}

// Staff assignments
export function assign_staff({ IdPiece, IdStaff, IdProperty }) { return { type: 'PIECE/STAFF/ASSIGN', IdPiece, IdStaff, IdProperty } }
export function unassign_staff({ IdPiece, IdStaff, IdProperty }) { return { type: 'PIECE/STAFF/UNASSIGN', IdPiece, IdStaff, IdProperty } }