import React from 'react';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import config from '../../../config';

class Rates extends React.Component {

    state = {
        mode: 1,
        day: 'Mon',
        people: [1, 2, 3, 4, 5, 6],
        rate: {
            IsAll: 0,
            Mon: {
                items: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                WALKIN: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                COORP: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                LOVE: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ]
            },
            Tue: {
                items: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                WALKIN: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                COORP: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                LOVE: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ]
            },
            Wed: {
                items: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                WALKIN: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                COORP: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                LOVE: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ]
            },
            Thu: {
                items: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                WALKIN: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                COORP: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                LOVE: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ]
            },
            Fri: {
                items: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                WALKIN: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                COORP: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                LOVE: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ]
            },
            Sat: {
                items: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                WALKIN: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                COORP: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                LOVE: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ]
            },
            Sun: {
                items: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                WALKIN: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                COORP: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                LOVE: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ]
            },
            All: {
                items: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                WALKIN: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                COORP: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ],
                LOVE: [
                    { Amount: 1, Adults: null, Children: null, Cribs: null },
                    { Amount: 2, Adults: null, Children: null, Cribs: null },
                    { Amount: 3, Adults: null, Children: null, Cribs: null },
                    { Amount: 4, Adults: null, Children: null, Cribs: null },
                    { Amount: 5, Adults: null, Children: null, Cribs: null },
                    { Amount: 6, Adults: null, Children: null, Cribs: null },
                    { Amount: 7, Adults: null, Children: null, Cribs: null },
                    { Amount: 8, Adults: null, Children: null, Cribs: null },
                    { Amount: 9, Adults: null, Children: null, Cribs: null },
                    { Amount: 10, Adults: null, Children: null, Cribs: null }
                ]
            }
        }
    }
    componentWillMount() {
        const { period } = this.props;
        if (period.Rate && period.Rate.IdRate) this.setState({ rate: period.Rate });
    }

    render() {
        const { day, rate, mode } = this.state;        

        // HOT FIX: try to fucking complete the rate for those that have things missing
        ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'All'].forEach(day => {
            if (!rate[day].WALKIN) rate[day].WALKIN = { ...rate[day].items };
            if (!rate[day].COORP) rate[day].COORP = { ...rate[day].items };
            if (!rate[day].LOVE) rate[day].LOVE = { ...rate[day].items };
        });
        //

        let current_array = 'items';
        switch (mode) {
            case 1: current_array = 'items'; break;
            case 2: current_array = 'WALKIN'; break;
            case 3: current_array = 'COORP'; break;
            case 4: current_array = 'LOVE'; break;
            default: break;
        }
        return <section>
            {/* Room rates */}
            <div className="w3-content">
                <h5 className="white poppins mt-4 text-center">Tarifas de la habitación</h5>
            </div>

            <div className="w3-content">
                {/* Shift */}
                <div className="mt-4 mx-auto w-75">
                    <label className="white poppins font-12">Tipo</label>
                    <div id="txtType" className={`black-input  d-flex mt-2`}>
                        <Dropdown isOpen={this.state.pickType} toggle={() => this.setState({ pickType: !this.state.pickType })} className="w-100">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                <span className="align-self-center">{(_.find(config.rate_types, t => t.id === mode)).name}</span>
                                <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {config.rate_types.map((c, i) => <DropdownItem key={i} onClick={() => {
                                    this.setState({ mode: c.id }, this.forceUpdate);
                                }}>{c.name}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                {/* WEEK Days */}
                <div className="w-75 d-flex mx-auto mt-2">
                    <button onClick={() => this.setState({ day: 'Mon' }, this.forceUpdate)} className={`btn btn-link w-100 ${(this.state.day === 'Mon' || rate.IsAll) ? 'blue' : 'gray'}`}>LUN</button>
                    <span>|</span>
                    <button onClick={() => this.setState({ day: 'Tue' }, this.forceUpdate)} className={`btn btn-link w-100 ${(this.state.day === 'Tue' || rate.IsAll) ? 'blue' : 'gray'}`}>MAR</button>
                    <span>|</span>
                    <button onClick={() => this.setState({ day: 'Wed' }, this.forceUpdate)} className={`btn btn-link w-100 ${(this.state.day === 'Wed' || rate.IsAll) ? 'blue' : 'gray'}`}>MIE</button>
                    <span>|</span>
                    <button onClick={() => this.setState({ day: 'Thu' }, this.forceUpdate)} className={`btn btn-link w-100 ${(this.state.day === 'Thu' || rate.IsAll) ? 'blue' : 'gray'}`}>JUE</button>
                    <span>|</span>
                    <button onClick={() => this.setState({ day: 'Fri' }, this.forceUpdate)} className={`btn btn-link w-100 ${(this.state.day === 'Fri' || rate.IsAll) ? 'blue' : 'gray'}`}>FRI</button>
                    <span>|</span>
                    <button onClick={() => this.setState({ day: 'Sat' }, this.forceUpdate)} className={`btn btn-link w-100 ${(this.state.day === 'Sat' || rate.IsAll) ? 'blue' : 'gray'}`}>SAB</button>
                    <span>|</span>
                    <button onClick={() => this.setState({ day: 'Sun' }, this.forceUpdate)} className={`btn btn-link w-100 ${(this.state.day === 'Sun' || rate.IsAll) ? 'blue' : 'gray'}`}>DOM</button>
                </div>
                {/* ALL Days */}
                <div className="d-flex justify-content-center">
                    <button onClick={() => {
                        const { rate } = this.state;
                        rate.IsAll = !rate.IsAll;
                        this.setState({ rate }, this.forceUpdate);
                    }} className={`btn btn-link d-flex ${(rate.IsAll) ? 'white' : 'gray'}`}><i className={`far ${rate.IsAll ? 'fa-check-square blue' : 'fa-square gray'} font-12 align-self-center`} /><span className="align-self-center ms-2 font-10 text-left poppins">Aplicar mismas tarifas a todos los días</span></button>
                </div>
                {/* Tabla */}
                <div className="mt-3 w-50 mx-auto bg-gray-2 px-0 py-0 rounded no-overflow d-flex">
                    <table className="w-100 table m-0 poppins bg-gray-2 white">
                        <thead>
                            <tr>
                                <th className="bg-gray-2 white p-4">Precio por<br />persona</th>
                                <th className="bg-gray-3 white text-center">Adultos</th>
                                <th className="bg-gray-3 white text-center">Niños</th>
                                <th className="bg-gray-3 white text-center">Infantes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.people.map((i, index) => {
                                if (!rate.IsAll) {
                                    let col = _.find(rate[day][current_array], item => item.Amount === i) || {};
                                    return (<tr style={{ borderStyle: 'hidden' }} key={index}>
                                        <td className="bg-gray-2 px-4 whiteish font-30 text-center">{i}</td>
                                        <td className="bg-gray-3">
                                            <div className={`black-input`} style={{ maxWidth: 150 }}>
                                                <input key={`ad_${day}`} value={col.Adults || ''} onChange={e => {
                                                    rate[day][current_array][index].Adults = e.target.value;
                                                    this.setState({ rate });
                                                }} className="p-3 white debug w-100 text-center" type="text" placeholder="" />
                                            </div>
                                        </td>
                                        <td className="bg-gray-3">
                                            <div className={`black-input`} style={{ maxWidth: 150 }}>
                                                <input key={`ch_${day}`} value={col.Children || ''} onChange={e => {
                                                    rate[day][current_array][index].Children = e.target.value;
                                                    this.setState({ rate });
                                                }} className="p-3 white debug w-100 text-center" type="text" placeholder="" />
                                            </div>
                                        </td>
                                        <td className="bg-gray-3">
                                            <div className={`black-input`} style={{ maxWidth: 150 }}>
                                                <input key={`cr_${day}`} value={col.Cribs || ''} onChange={e => {
                                                    rate[day][current_array][index].Cribs = e.target.value;
                                                    this.setState({ rate });
                                                }} className="p-3 white debug w-100 text-center" type="text" placeholder="" />
                                            </div>
                                        </td>
                                    </tr>)
                                }
                                else {
                                    let col = _.find(rate.All[current_array], item => item.Amount === i) || {};
                                    return (<tr style={{ borderStyle: 'hidden' }} key={index}>
                                        <td className="bg-gray-2 px-4 whiteish font-30 text-center">{i}</td>
                                        <td className="bg-gray-3">
                                            <div className={`black-input`} style={{ maxWidth: 150 }}>
                                                <input key={`all_ad_${day}`} value={col.Adults || ''} onChange={e => {
                                                    rate.All[current_array][index].Adults = e.target.value;
                                                    this.setState({ rate });
                                                }} className="p-3 white debug w-100 text-center" type="text" placeholder="" />
                                            </div>
                                        </td>
                                        <td className="bg-gray-3">
                                            <div className={`black-input`} style={{ maxWidth: 150 }}>
                                                <input key={`all_ch_${day}`} value={col.Children || ''} onChange={e => {
                                                    rate.All[current_array][index].Children = e.target.value;
                                                    this.setState({ rate });
                                                }} className="p-3 white debug w-100 text-center" type="text" placeholder="" />
                                            </div>
                                        </td>
                                        <td className="bg-gray-3">
                                            <div className={`black-input`} style={{ maxWidth: 150 }}>
                                                <input key={`all_cr_${day}`} value={col.Cribs || ''} onChange={e => {
                                                    rate.All[current_array][index].Cribs = e.target.value;
                                                    this.setState({ rate });
                                                }} className="p-3 white debug w-100 text-center" type="text" placeholder="" />
                                            </div>
                                        </td>
                                    </tr>)
                                }
                            }
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="w-50 mx-auto">
                    <button onClick={() => {
                        const { rate, people } = this.state;
                        _.values(rate).forEach(day => {
                            if (day && day[current_array])
                                day[current_array].push({ Amount: (day[current_array].length + 1), Adults: null, Children: null, Cribs: null });
                        });
                        people.push(people.length + 1);
                        this.setState({ rate, people });
                    }} type="button" className="btn btn-lg btn-blue white mx-auto mt-3 w-100">
                        <i className="fas fa-plus white font-12" /> Agregar personas
                    </button>
                </div>
            </div>
        </section >
    }
}

export default Rates;