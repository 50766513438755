function staffs_Reducer(state = [], action) {
    switch (action.type) {
        case 'STAFFS/SET':            
            return action.staffs;
        case 'STAFFS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default staffs_Reducer;
