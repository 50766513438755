import React from 'react';
import config from '../../../config';
import _ from 'lodash';

class Pieces extends React.Component {
    state = {
        toggle: 0,
        rooms_selected: [],
        assigned_tasks: [],
        floors: [],
        status: config.room_status,
        pieces_taken: null
    }
    //{ id: 4, name: 'Ocupada', icon: 'icon-lb_housekeep', color: '#FE001D', macro: true },

    componentWillReceiveProps = nextProps => {
        const { bookings, room } = nextProps;                
        if (bookings) {
            this.setState({
                pieces_taken: _.chain(bookings)
                    .map(b => b.Rooms || [])
                    .flattenDeep()
                    .filter(r => r.IdPiece)
                    .map(p => p.IdPiece)
                    .value()
            });
        }
    }
    render() {
        const { floors } = this.props, { status, toggle, pieces_taken } = this.state;        
        return <section>
            <div className="bg-gray-2 d-flex black-input mb-3 mt-4 mx-4" style={{ overflow: 'hidden' }}>
                <div className="w-100 debug">

                    {floors && floors.length > 0 && _.sortBy(floors, 'Label').map((floor, floor_index) => {
                        return (<section key={floor.IdFloor || floor.TemporalId}>

                            {/* FLOOR HEADER */}
                            <div className="px-4 py-3 d-flex pointer" onClick={() => this.setState({ toggle: floor_index }, () => {
                                // NEW: if we change floors, we clean up the selection and the status
                                const { status } = this.state;
                                status.forEach(s => s.selected = false);
                                this.setState({ status, rooms_selected: [] });
                            })}>
                                <span className="poppins white align-self-center">Piso {floor.Label}</span>
                                {/*<i className={`ms-2 blue fas fa-chevron-${toggle === floor_index ? 'down' : 'right'} align-self-center`} />*/}
                            </div>
                            {/* PIECES */}
                            {pieces_taken && <div className="bg-black p-4 d-flex flex-wrap justify-content-center">

                                {_.chain(floor.Pieces)
                                    .filter((piece, i) => {                                        
                                        if (this.state.filter_assigned_tasks) {
                                            return _.find(piece.Staff, s => s.IdStaff === user.IdStaff) ? true : false
                                        }
                                        return true;
                                    })
                                    .value().map((piece, b) => {

                                        // If the piece is already taken for the day, we mark it as 
                                        //'Ocupada (4)', so It cannot be selected. If not, we set it 
                                        // to 'Disponible (3)' 
                                        const { pieces_taken, status } = this.state, { room } = this.props;
                                        let current_status = piece.Status + 0;
                                        let ocupada = _.find(status, s => s.id === 4);
                                        if (_.find(pieces_taken, p => p === piece.IdPiece) && !(current_status & ocupada.pow)) {
                                            current_status += ocupada.pow;
                                        }
                                        //

                                        return <figure className={`pointer bg-gray-2 d-flex flex-column black-input m-1 p-2 ${_.find(this.state.rooms_selected, r => r.IdPiece === piece.IdPiece) ? 'white-border' : ''}`} style={{ width: 200, opacity: ((current_status & ocupada.pow) || ((room && piece.IdRoom !== room.IdRoom))) ? 0.3 : 1 }} key={b} onClick={() => {
                                            // HERE
                                            if ((current_status & ocupada.pow) || ((room && piece.IdRoom !== room.IdRoom))) {
                                                // TODO: alert that is taken
                                                alert('Habitacion no disponible para esta reserva');
                                            } else
                                                this.props.fn && this.props.fn(piece);
                                        }}>
                                            <div className="d-flex w-100">

                                                {/* VER */}
                                                <div className="d-flex" onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.setState({ watch_room: piece, seeing: true }, () => {
                                                        this.props.floors_actions.fetch_comments(this.state.watch_room.IdPiece);
                                                    });
                                                }} >
                                                    <i className="fas fa-eye white font-10 align-self-center"></i>
                                                    <span className="white font-10 align-self-center ms-1 poppins">Ver</span>
                                                </div>

                                                {/* SELECTED */}
                                                <i className={`ms-auto ${_.find(this.state.rooms_selected, r => r.IdPiece === piece.IdPiece) ? 'fas fa-circle green' : 'far fa-circle white'} font-10 align-self-center ms-1`}></i>
                                            </div>

                                            {/* LABEL */}
                                            <div className="d-flex w-100 justify-content-center" style={{ height: 30 }}>
                                                <span className="white font-10 align-self-center mx-2 poppins">{piece.Label}</span>
                                            </div>

                                            {/* STATUS */}
                                            <div className="d-flex w-100">
                                                {status.map((s, index) => {
                                                    if (current_status & s.pow) {
                                                        return (<i key={index} className="me-1 fas fa-circle font-10 align-self-center" title={s.name} style={{ color: s.color }}></i>)
                                                    } else return null
                                                })}
                                                <span className="white font-10 align-self-center ms-1 poppins">Estados</span>
                                            </div>
                                        </figure>
                                    })}
                            </div>}
                        </section>)
                    })}
                </div>
            </div>
        </section>
    }
}

export default Pieces;