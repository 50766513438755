import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import moment from 'moment';
import BlackOverlay from '../partials/black_overlay';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';

class Coorps extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 1,
        rooms_selected: [],
        status: config.room_status,
        sort: 'CreationDate',
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }
    // Lifecycle
    componentWillMount() {
        const { id, letter } = this.props.params;
        if (id) {
            this.setState({ letter }, () => {
                this.props.properties_actions && this.props.properties_actions.fetch_coorps(id, letter);
            });
        }
    }
    componentWillReceiveProps = nextProps => {
        const { coorps, params } = nextProps, { letter = 'A' } = params, { id } = this.props.params;
        if (letter !== this.state.letter) {
            this.setState({ letter }, () => {
                this.props.properties_actions && this.props.properties_actions.fetch_coorps(id, letter);
            });
        }
        else
            this.setState({ coorps });
    }
    //

    onToggle = (IdStaff) => {
        const { guests } = this.state;
        let index = _.findIndex(guests, r => r.IdStaff === IdStaff);
        if (index === -1) return;
        guests[index].IsActive = !guests[index].IsActive;
        this.props.guests_actions && this.props.guests_actions.toggle(IdStaff, guests[index].IsActive);
        this.setState({ guests });
    }
    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="far fa-star blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Coorps. | Agencias</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            <div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input className="w-100 p-3 white" type="text" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} onChange={e => this.setState({ search: e.target.value })} />
                </div>
            </div>
            <div className="w-100 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Nombre <i className={`icon-lb_sort ${sort === 'FirstName' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('FirstName')} /></th>
                <th className="bg-gray-2 white">Contacto</th>
                <th className="bg-gray-2 white text-right">Accion</th>
            </tr>
        </thead>
    }
    renderTable = () => {
        const { coorps, sort, up } = this.state;
        let filtered_coorp = _.chain(coorps)
            .filter(r => {
                if (this.txtSearch.value) return `${r.FirstName}${r.LastName}${r.Name}${r.LegalName}${r.ID}`.toLowerCase().indexOf(this.txtSearch.value.toLowerCase()) !== -1;
                return true;
            }).value();
        return <div className="mt-4 d-flex">
            <Animated animationIn='fadeInRight' isVisible={coorps ? true : false} animationInDuration={300}
                animateOnMount={false} className="w-100 bg-gray-2 px-3 py-2 rounded d-flex">
                <table className="w-100 table m-0 poppins bg-gray-2 white">
                    {this.renderTH()}
                    {coorps && filtered_coorp.length > 0 && <tbody>
                        {_.chain(filtered_coorp)
                            .sortBy(sort)
                            .tap(arr => {
                                if (up) arr.reverse();
                            })
                            .value().map((coorp, index) => <tr key={coorp.IdGuest}>
                                <td className="bg-gray-2 white">
                                    <div className="d-flex">
                                        <div className="cover" style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid #1771FF`, background: `url(${coorp.LogoUrl}) no-repeat center center` }}></div>
                                        <div className="d-flex flex-column">
                                            <span className="ms-2 align-self-start white font-12">{`${coorp.Name}`}</span>
                                            <span className="ms-2 align-self-start gray font-10">{`${_.compact([coorp.LegalName]).join(' | ')}`}</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="bg-gray-2 font-10 white">
                                    <span className="white">{`${coorp.FirstName} ${coorp.LastName}`}</span>
                                    <br />
                                    <span className="blue">{coorp.Email}</span>
                                    <br />
                                    {`${coorp.PhoneCountryCode} ${coorp.Phone}`}
                                </td>
                                <td className="bg-gray-2 white">
                                    <div className="d-flex justify-content-end">
                                        <Link to={`/coorps/edit/${coorp.IdCoorp}`}><i className="fas fa-edit ms-3 font-16 pointer blue" /></Link>
                                        {/*<i className="fas fa-trash ms-3 font-16 pointer gray" onClick={() => this.setState({ delete_id: coorp.IdGuest })} />*/}
                                    </div>
                                </td>
                            </tr>)}
                    </tbody>}
                    {coorps && filtered_coorp.length === 0 && <tbody>
                        <tr>
                            <td colSpan={6} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">Por lo visto no tienes coorporaciones | agencias en esta cuenta<br />{this.state.search ? ` bajo estos parámetros de búsqueda` : ''}.</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>}
                </table>
            </Animated>
        </div>
    }
    renderCommon = () => {
        const { letter = 'A', id } = this.props.params;
        return (
            <div className="">
                {this.renderHeader()}
                <div className="w-100 d-flex mt-4">
                    {id && <Link to={`/coorps/add/${id}`} className="btn btn-lg btn-blue white px-4 ms-2 align-self-center d-flex" >
                        Nueva Cuenta <i className="fas fa-plus-circle align-self-center font-10 ms-3" />
                    </Link>}
                </div>
                <div className="w-100 d-flex justify-content-center mt-4 white">
                    <Link className={`btn btn-link  ${letter === 'A' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/A`}>A</Link>
                    <Link className={`btn btn-link  ${letter === 'B' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/B`}>B</Link>
                    <Link className={`btn btn-link  ${letter === 'C' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/C`}>C</Link>
                    <Link className={`btn btn-link  ${letter === 'D' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/D`}>D</Link>
                    <Link className={`btn btn-link  ${letter === 'E' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/E`}>E</Link>
                    <Link className={`btn btn-link  ${letter === 'F' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/F`}>F</Link>
                    <Link className={`btn btn-link  ${letter === 'G' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/G`}>G</Link>
                    <Link className={`btn btn-link  ${letter === 'H' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/H`}>H</Link>
                    <Link className={`btn btn-link  ${letter === 'I' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/I`}>I</Link>
                    <Link className={`btn btn-link  ${letter === 'J' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/J`}>J</Link>
                    <Link className={`btn btn-link  ${letter === 'K' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/K`}>K</Link>
                    <Link className={`btn btn-link  ${letter === 'L' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/L`}>L</Link>
                    <Link className={`btn btn-link  ${letter === 'M' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/M`}>M</Link>
                    <Link className={`btn btn-link  ${letter === 'N' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/N`}>N</Link>
                    <Link className={`btn btn-link  ${letter === 'O' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/O`}>O</Link>
                    <Link className={`btn btn-link  ${letter === 'P' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/P`}>P</Link>
                    <Link className={`btn btn-link  ${letter === 'Q' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/Q`}>Q</Link>
                    <Link className={`btn btn-link  ${letter === 'R' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/R`}>R</Link>
                    <Link className={`btn btn-link  ${letter === 'S' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/S`}>S</Link>
                    <Link className={`btn btn-link  ${letter === 'T' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/T`}>T</Link>
                    <Link className={`btn btn-link  ${letter === 'U' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/U`}>U</Link>
                    <Link className={`btn btn-link  ${letter === 'V' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/V`}>V</Link>
                    <Link className={`btn btn-link  ${letter === 'W' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/W`}>W</Link>
                    <Link className={`btn btn-link  ${letter === 'X' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/X`}>X</Link>
                    <Link className={`btn btn-link  ${letter === 'Y' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/Y`}>Y</Link>
                    <Link className={`btn btn-link  ${letter === 'Z' ? 'blue' : 'gray'}`} to={`/coorps/${this.props.params.id}/Z`}>Z</Link>
                </div>
                {this.renderTable()}
                <div style={{ height: 200 }} />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Delete Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.delete_id ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Eliminar cuenta coorporativa / agencia</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de esta operación?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ delete_id: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doDelete} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>
                </section>
            </Layout>
        )
    }
}

export default Coorps;
