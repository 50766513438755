import React from 'react';
import { generateRandomId, getBase64, getReadableFileSizeString } from '../../../helpers';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import BlackOverlay from '../../partials/black_overlay';
import fakeAuth from '../../../fakeAuth';
import _, { slice } from 'lodash';
import request from 'superagent';
import { Animated } from 'react-animated-css';
import config from '../../../config';

class CoorpForm extends React.Component {
    state = {
        validations: {},
        menu_toggled: false,
        coorp: {
            IdProperty: this.props.params.id,
            PhoneCountryCode: '+58',
        },
    }

    componentWillMount = () => {
        const { book } = this.props;
        if (book.Coorp) this.setState({ coorp: book.Coorp });
    }
    componentWillReceiveProps = nextProps => {
        const { book } = nextProps;
        if (book.Coorp) this.setState({ coorp: book.Coorp });
    }

    onLogo = e => {
        const { coorp } = this.state, { } = this.props;
        getBase64(e.target.files[0]).then(data => {
            coorp.LogoUrl = data;
            this.setState({ coorp, doUpdateLogo: true });
        });
    }

    onSearchCoorp = () => {
        const c = this.txtID.value;
        if (c) {
            this.setState({ searching: true }, () => {
                request.post(`${config.apiEndpoint}/api/v4/coorps/fetch`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({ SearchText: this.txtID.value, IdAccount: fakeAuth.getUser().IdAccount })
                    .then((data) => {
                        this.setState({ previous_client: data.body.coorp });
                        this.setState({ searching: false });
                    }, (error) => {
                        this.setState({ searching: false });
                    });
            })
        }
    }
    onOldCoorp = () => {
        // TODO: assign such client to the reservation
        this.setState({ microTransac: true }, () => {
            request.post(`${config.apiEndpoint}/api/v4/bookings/assign_coorp`)
                .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                .send({
                    ..._.pick(this.state.previous_client, 'IdCoorp', 'LegalName'),
                    IdBook: this.props.params.id
                })
                .then((data) => {
                    if (data.body.success) {
                        this.setState({ coorp: this.state.previous_client, microTransact: false, previous_client: null }, () => {
                            this.props.fnUpdate && this.props.fnUpdate(this.state);
                            this.props.fnDone && this.props.fnDone();
                        });
                    }
                }, (error) => {
                    this.setState({ microTransact: false, error: 'Something wrong' });
                    //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                });
        });
    }

    doSave = () => {
        const { coorp } = this.state;
        const { microTransac } = this.state;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
            }, () => {

                // Call API
                request[coorp.IdCoorp ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/coorps${coorp.IdCoorp ? `/${coorp.IdCoorp}` : ''}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({
                        Name: this.txtName.value,
                        LegalName: this.txtLegalName.value,
                        ID: this.txtID.value,
                        FirstName: this.txtFirstName.value,
                        LastName: this.txtLastName.value,
                        Email: this.txtEmail.value,
                        Phone: this.txtPhone.value,
                        IdAccount: fakeAuth.getUser().IdAccount,
                        Logo: this.state.doUpdateLogo ? coorp.LogoUrl : null,
                        ..._.pick(coorp, 'IdProperty', 'PhoneCountryCode', 'IdLogo')
                    })
                    .then((data) => {
                        if (data.body.success && data.body.IdCoorp) {
                            this.props.coorps_actions && this.props.coorps_actions.fetch_all(fakeAuth.getUser().IdAccount);

                            // Assign this
                            const { coorp } = this.state;
                            coorp.LegalName = this.txtLegalName.value;
                            coorp.IdCoorp = data.body.IdCoorp;
                            this.setState({ previous_client: coorp, confirm: false }, this.onOldCoorp);
                        } else
                            this.setState({ microTransac: false });

                    }, (error) => {
                        this.setState({ microTransac: false, error: 'Something wrong' });
                    });

            });
        }
    }
    validate = (e) => {
        e && e.preventDefault && e.preventDefault();
        this.setState({ validations: {} }, () => {
            const { validations, staff } = this.state;

            if (!this.txtName.value) {
                validations.txtName = 'Por favor, introduce el nombre de la coorporación | agencia';
                this.setState({ validations }, () => { this.txtNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtLegalName.value) {
                validations.txtLegalName = 'Por favor, introduce el nombre legal de la coorporación | agencia';
                this.setState({ validations }, () => { this.txtNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtID.value) {
                validations.txtID = 'Por favor, introduce el ID | RIF de la coorporación | agencia';
                this.setState({ validations }, () => { this.txtNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }

            if (!this.txtFirstName.value) {
                validations.txtFirstName = 'Por favor, introduce el nombre del contacto de la coorporación | agencia';
                this.setState({ validations }, () => { this.txtNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtLastName.value) {
                validations.txtLastName = 'Por favor, introduce el apellido del contacto de la coorporación | agencia';
                this.setState({ validations }, () => { this.txtNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtEmail.value) {
                validations.txtEmail = 'Por favor, introduce el correo electrónico del empleado';
                this.setState({ validations }, () => { this.txtNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }

            this.setState({ confirm: true, validations });

        });
    }
    render() {
        const { validations, coorp, } = this.state, { } = this.props;        

        return <section className="mt-4 px-4">
            <div className="w3-content d-flex">
                {/* Avatar */}
                <div className="me-4 mt-4">
                    <input type="file" id="fi" onChange={this.onLogo} className="hide" ref={i => this.avatarFU = i} />
                    <label htmlFor="fi" className="align-self-center">
                        {(coorp.LogoUrl) && <div className="cover pointer bg-gray-2 mx-auto d-flex justify-content-center" style={{
                            width: 200, height: 200, borderRadius: '50%', border: '2px solid #343f4b',
                            background: `url(${coorp.LogoUrl}) no-repeat center center white`,
                        }} />}
                        {!(coorp.LogoUrl) && <div style={{ width: 200, height: 200, borderRadius: '50%', border: '2px solid #343f4b' }} className="bg-gray-2 pointer mx-auto d-flex justify-content-center">
                            <i className="fas fa-camera font-60 gray align-self-center" />
                        </div>}
                    </label>
                </div>
                {/* Rest */}
                <div>

                    <div className="d-flex w3-content">
                        {/* Id Or RIF */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">ID | RIF <i className="blue">*</i></label>
                            <div id="txtID" className={`black-input ${validations.txtID ? 'error-input' : ''} d-flex mt-2`}>
                                <input onChange={e => {
                                    const { coorp } = this.state;
                                    coorp.ID = e.target.value;
                                    this.setState({ coorp }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} onBlur={() => {
                                    this.onSearchCoorp();
                                }} className="w-100 p-3 white" type="text" name="name" placeholder="ID | RIF" ref={(input) => this.txtID = input} defaultValue={coorp.ID} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtID ? true : false} target={`txtID`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtID = !validations.txtID;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtID}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Name */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Nombre Contacto <i className="blue">*</i></label>
                            <div id="txtFirstName" className={`black-input ${validations.txtFirstName ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre" ref={(input) => this.txtFirstName = input} defaultValue={coorp.FirstName} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtFirstName ? true : false} target={`txtFirstName`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtFirstName = !validations.txtFirstName;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtFirstName}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Last Name */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Apellido Contacto <i className="blue">*</i></label>
                            <div id="txtLastName" className={`black-input ${validations.txtLastName ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Apellido" ref={(input) => this.txtLastName = input} defaultValue={coorp.LastName} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtLastName ? true : false} target={`txtLastName`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtLastName = !validations.txtLastName;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtLastName}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                    </div>

                    <div className="d-flex w3-content" ref={input => this.txtNameWrapper = input}>
                        {/* Name */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Nombre <i className="blue">*</i></label>
                            <div id="txtName" className={`black-input ${validations.txtName ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre"
                                    ref={(input) => this.txtName = input} defaultValue={coorp.Name} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtName ? true : false} target={`txtName`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtName = !validations.txtName;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtName}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <div className="px-1" />
                        {/* Legal Name */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Nombre Legal <i className="blue">*</i></label>
                            <div id="txtLegalName" className={`black-input ${validations.txtLegalName ? 'error-input' : ''} d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Nombre legal" ref={(input) => this.txtLegalName = input} defaultValue={coorp.LegalName} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtLegalName ? true : false} target={`txtLegalName`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtLegalName = !validations.txtLegalName;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtLegalName}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                    </div>

                    <div className="d-flex w3-content">
                        {/* Phone */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Teléfono</label>
                            <div className="black-input d-flex mt-2">
                                <input onChange={e => {
                                    const { coorp } = this.state;
                                    guest.PhoneCountryCode = e.target.value;
                                    this.setState({ coorp }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} style={{ width: 70 }} className="p-3 white" type="text" name="phone" placeholder="+" maxLength={4} ref={(input) => this.txtPhoneCountryCode = input} defaultValue={coorp.PhoneCountryCode || '+58'} />
                                <i className="fas fa-minus font-16 white align-self-center px-1 " />
                                <input onChange={e => {
                                    const { coorp } = this.state;
                                    coorp.Phone = e.target.value;
                                    this.setState({ coorp }, () => {
                                        this.props.fnUpdate && this.props.fnUpdate(this.state);
                                    });
                                }} className="w-100 p-3 white" type="text" name="phone" placeholder="Teléfono" ref={(input) => this.txtPhone = input} defaultValue={coorp.Phone} />
                            </div>
                        </div>
                        <div className="px-1" />
                        {/* Email */}
                        <div className="w-100 mt-4">
                            <label className="white poppins font-12">Correo electrónico <i className="blue">*</i></label>
                            <div id="txtEmail" className={`black-input ${validations.txtEmail ? 'error-input' : ''} d-flex mt-2`}>
                                <i className="fas fa-at font-16 white align-self-center ps-3" />
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Correo electrónico" ref={(input) => this.txtEmail = input} defaultValue={coorp.Email} />
                            </div>
                            <Popover placement="right" isOpen={validations.txtEmail ? true : false} target={`txtEmail`} toggle={() => {
                                const { validations } = this.state;
                                validations.txtEmail = !validations.txtEmail;
                                this.setState({ validations });
                            }} className="bg-gray-2">
                                <PopoverBody className="d-flex flex-row justify-content-center">
                                    <i className="fas fa-times red font-8 align-self-center" />
                                    <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtEmail}</span>
                                </PopoverBody>
                            </Popover>
                        </div>
                    </div>
                </div>
            </div>

            {/* Buttons */}
            <div className="d-flex justify-content-center mt-4">
                <button onClick={() => this.props.toggle && this.props.toggle()} type="button" className="btn btn-lg btn-blue white align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>
                <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="far fa-save white font-12" />
                </button>
            </div>

            {/* Confirm */}
            <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                <BlackOverlay />
                <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                    <div className="black-input bg-gray-2 p-4 align-self-center">
                        <h3 className="white poppins text-center">Confirmar operación</h3>
                        <h6 className="blue text-center poppins">{this.state.coorp.IdCoorp ? 'Editar' : 'Nueva'} agencia | coorporación</h6>
                        <hr className="w-50 mx-auto" />
                        <h6 className="white text-center poppins">¿Está seguro de ejecutar esta operación?</h6>
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                <i className="fas fa-times white font-12" />
                            </button>
                            <button onClick={this.doSave} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className="fas fa-check white font-12" />
                            </button>
                        </div>
                    </div>
                </div>
            </Animated>

            {/* PREVIOUS GUEST FOUND */}
            <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.previous_client ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                <BlackOverlay />
                {this.state.previous_client && <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                    <div className="black-input bg-gray-2 p-4 align-self-center">
                        <h3 className="white poppins text-center">Cliente recurrente</h3>
                        <h6 className="red text-center poppins">{`${this.state.previous_client.LegalName}`}</h6>
                        <hr className="w-50 mx-auto" />
                        <h6 className="white text-center poppins">Encontramos un registro previo de Empresa | Agencia con ID '{this.state.previous_client.ID}'<br />¿Desea asociar esta reserva a dicha empresa | agencia?</h6>
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={() => this.setState({ previous_client: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                <i className="fas fa-times white font-12" />
                            </button>
                            <button onClick={this.onOldCoorp} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className="fas fa-check white font-12" />
                            </button>
                        </div>
                    </div>
                </div>}
            </Animated>
        </section>
    }
}

export default CoorpForm;