import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import moment from 'moment';
import BlackOverlay from '../partials/black_overlay';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';

class Transactions extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 1,
        rooms_selected: [],
        status: config.room_status,
        sort: 'CreationDate',
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }
    // Lifecycle
    componentWillMount() {
        const { id, letter } = this.props.params;
        if (id) {
            this.setState({ letter }, () => {
                this.props.properties_actions && this.props.properties_actions.fetch_guests(id, letter);
            });
        }
    }
    componentWillReceiveProps = nextProps => {
        const { guests, params } = nextProps, { letter = 'A' } = params, { id } = this.props.params;
        if (letter !== this.state.letter) {
            this.setState({ letter }, () => {
                this.props.properties_actions && this.props.properties_actions.fetch_guests(id, letter);
            });
        }
        else
            this.setState({ guests });
    }
    //

    onToggle = (IdStaff) => {
        const { guests } = this.state;
        let index = _.findIndex(guests, r => r.IdStaff === IdStaff);
        if (index === -1) return;
        guests[index].IsActive = !guests[index].IsActive;
        this.props.guests_actions && this.props.guests_actions.toggle(IdStaff, guests[index].IsActive);
        this.setState({ guests });
    }
    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    doDelete = () => {
        const { id } = this.props.params;
        this.props.guests_actions && this.props.guests_actions.archive(this.state.delete_id, id);
        this.setState({ delete_id: null });
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_reportes blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Reportes - Transacciones</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            {/*<div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input className="w-100 p-3 white" type="text" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} onChange={e => this.setState({ search: e.target.value })} />
                </div>
            </div>*/}
            <div className="w-100 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderCommon = () => {
        return (
            <div className="">
                {this.renderHeader()}
                <div className="d-flex justify-content-center mt-4">
                    <Link to={`/reports/occupation/${this.props.params.id}`} className="btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-percent font-20 whiteish" /></Link>
                    {/*<Link to={`/reports/transactions/${this.props.params.id}`} className="ms-2 btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-dollar font-20 blue" /></Link>*/}
                    <Link to={`/reports/forecast/${this.props.params.id}`} className="ms-2 btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-cloud font-20 whiteish" /></Link>
                </div>
                <div style={{ position: "absolute", top: '12em', bottom: 0, left: 30, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">

                    {this.state.bootstrapped === 1 && <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', minWidth: 300, margin: 'auto' }}>

                    </section>}
                </div>
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Delete Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.delete_id ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Eliminar huesped</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de eliminar este empleado?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ delete_id: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doDelete} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>
                </section>
            </Layout>
        )
    }
}

export default Transactions;
