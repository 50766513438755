import _ from 'lodash';
import React from 'react';
import config from '../../../config';
import fakeAuth from '../../../fakeAuth';
import moment from 'moment';

class See extends React.Component {
    state = {
        room: {},
        floor: {},
        status: [...config.room_status],
        tab: 1,
        room_objects: [...config.room_objects],
        substract_status: 0
    }
    componentWillReceiveProps = nextProps => {
        const { room, floors, comments, bookings } = nextProps;
        if (room) {
            this.setState({ room, floor: _.find(floors, f => f.IdFloor === room.IdFloor) });
            if (this.state.tab === 3 && this.messagesEnd) {
                this.messagesEnd.scrollIntoView({ behavior: "smooth" });
            }

            // Find the person that's in this room according to the bookings
            let BOOK = _.chain(bookings).find(b => {
                return _.find(b.Rooms, r => r.IdPiece === room.IdPiece) ? true : false;
            }).value();
            this.setState({ BOOK });
        }
    }

    onFinishTask = () => {
        const { room } = this.state, user = fakeAuth.getUser(), { id } = this.props.params;
        room.Status -= this.state.substract_status;
        if (room.Status <= 0) room.Status = 1; // Limpia por defecto
        this.props.floors_actions && this.props.floors_actions.update_piece_status(room);
        this.props.floors_actions && this.props.floors_actions.unassign_staff({
            IdPiece: room.IdPiece,
            IdStaff: user.IdStaff,
            IdProperty: id
        });
        this.props.toggle();
    }

    onChat = e => {
        e.preventDefault();
        e.stopPropagation();
        const { room } = this.state;
        this.props.floors_actions && this.props.floors_actions.add_comment({
            IdPiece: room.IdPiece,
            Comment: this.txtChat.value
        });
        this.txtChat.value = '';
    }


    render() {
        const { room, floor, status, tab, BOOK } = this.state, user = fakeAuth.getUser();
        return <section style={{ width: 600 }}>
            {room && <section>
                {/* TABS */}
                <div className="d-flex bg-gray-3">
                    <button onClick={() => this.setState({ tab: 1 })} className={`poppins font-14 btn btn-link ${tab === 1 ? 'blue' : 'white'}`}><i className="fas fa-info-circle"></i> Info</button>
                    <button onClick={() => this.setState({ tab: 2 })} className={`poppins font-14 btn btn-link ${tab === 2 ? 'blue' : 'white'}`}><i className="fas fa-layer-group"></i> Inventario {room.MissingObjects > 0 && <i className="fas fa-circle red align-self-center font-8"></i>}</button>
                    <button onClick={() => this.setState({ tab: 3 }, () => {
                        this.messagesEnd.scrollIntoView({});
                    })} className={`poppins font-14 btn btn-link ${tab === 3 ? 'blue' : 'white'}`}><i className="fas fa-comment"></i> Comentarios</button>
                    <button onClick={this.props.toggle} className="font-14 btn btn-link ms-auto"><i className="far fa-times-circle gray" /></button>
                </div>

                <hr className="p-0 mx-0 mb-1 mt-0" />
                {/* INFO */}
                {tab === 1 && <section className="p-3">
                    <div className="d-flex">
                        <span className="white font-20 align-self-center">Habitación</span>
                        <span className="blue ms-2 font-20 align-self-center">#{room.Label}</span>
                    </div>
                    <div className="d-flex">
                        <span className="white font-10 align-self-center">Ubicación</span>
                        <span className="blue ms-2 font-10 align-self-center">Piso {floor.Label}</span>
                        <span className="gray ms-2 font-20 align-self-center">|</span>
                        <span className="white ms-2 font-10 align-self-center me-1">Estatus</span>
                        {status.map((s, index) => {
                            if (room.Status & Math.pow(2, s.id - 1)) {
                                return (<i key={index} className="ms-1 fas fa-circle font-10 align-self-center" title={s.name} style={{ color: s.color }}></i>)
                            } else return null
                        })}
                    </div>
                    <hr className="p-0 mx-0 my-1" />
                    <span className="white font-14 my-2 block">Ocupante</span>
                    <hr className="p-0 mx-0 my-1" />
                    {BOOK && <div className="d-flex flex-wrap pb-3">
                        <div className="w-50 pt-3"><span className="white">Nombre:</span><span className="ms-1 blue">{BOOK.GivenName}</span></div>
                        <div className="w-50 pt-3"><span className="white">Registro:</span><span className="ms-1 blue">{_.last(BOOK.IdBook.split('-'))}</span></div>
                        {!user.IdStaff && <section className="d-flex flex-wrap w-100">
                            <div className="w-50 pt-3"><span className="white">Check-In:</span><span className="ms-1 blue">{moment(BOOK.CheckIn).utc().locale('es').format('MMM Do YYYY')}</span></div>
                            <div className="w-50 pt-3"><span className="white">Check-Out:</span><span className="ms-1 blue">{moment(BOOK.CheckOut).utc().locale('es').format('MMM Do YYYY')}</span></div>
                            {/*<div className="w-50 pt-3"><span className="white">Tasa:</span><span className="ms-1 blue">$100</span></div>
                            <div className="w-50 pt-3"><span className="white">Saldo:</span><span className="ms-1 blue">$800</span></div>*/}
                        </section>}
                    </div>}
                    {!BOOK && <div className="bg-gray-2 gray-light">
                        <div className="d-flex justify-content-center" style={{ height: 200 }}>
                            <h3 className="align-self-center gray text-center poppins">Por lo visto esta pieza no se encuentra ocupada hoy.</h3>
                        </div>
                    </div>}
                    <hr className="p-0 mx-0 my-1" />
                    <span className="white font-14 my-2 block">Tareas</span>
                    <div className="d-flex flex-row">
                        {status.map((s, i) => {
                            if (room && room.Status & Math.pow(2, s.id - 1)) {
                                return (<div onClick={() => {
                                    const { substract_status } = this.state;
                                    if (this.state.substract_status & Math.pow(2, s.id - 1)) this.setState({ substract_status: substract_status - Math.pow(2, s.id - 1) });
                                    else this.setState({ substract_status: substract_status + Math.pow(2, s.id - 1) });
                                }} key={i} className={`d-flex align-self-start flex-column justify-content-center text-center`} style={{ width: 70 }}>
                                    <div className={`btn btn-lg btn-${this.state.substract_status & Math.pow(2, s.id - 1) ? 'blue white' : 'gray-light black'} align-self-center`} style={{ borderRadius: 5 }}><i className={`${s.icon}`} /></div>
                                    <span className="font-8 white poppins mt-1 gray align-self-center">{s.name}</span>
                                </div>)
                            } else return null
                        })}
                    </div>
                </section>}
                {/* INVENTAIRO */}
                {tab === 2 && <section className="p-3">
                    <div className="d-flex">
                        <span className="white font-20 align-self-center">Inventario</span>
                        <span className="blue ms-2 font-20 align-self-center">#{room.Label}</span>
                    </div>
                    <div className="d-flex mb-3">
                        <span className="white font-10 align-self-center">Lista de equipos de la habitación</span>
                    </div>
                    <hr className="p-0 mx-0 my-1" />
                    <div className="d-flex my-2">
                        <span className="white">Objeto</span>
                        <span className="ms-auto white">Falta</span>
                    </div>
                    <hr className="p-0 mx-0 my-1" />
                    {this.state.room_objects && this.state.room_objects.map((ro, i) => <div key={i} className="d-flex my-2 py-1" style={{ borderBottom: `1px solid #222` }}>
                        <span className="white align-self-center">{ro.name}</span>
                        <button onClick={() => {
                            const { room } = this.state;
                            if (room.MissingObjects & ro.id) room.MissingObjects -= ro.id;
                            else room.MissingObjects += ro.id;
                            this.setState({ room });
                            //TODO: call api?
                            this.props.floors_actions && this.props.floors_actions.update_piece_status(room);
                        }} className="ms-auto btn btn-link align-self-center">
                            <i className={`far ${(room.MissingObjects & ro.id) ? 'fa-check-square red' : 'fa-square white'}`} />
                        </button>
                    </div>)}
                </section>}
                {/* COMMENTARIOS */}
                {tab === 3 && <section>
                    <form>
                        <div className="d-flex flex-column" style={{ height: 500, overflowY: 'scroll' }}>
                            {this.props.comments && _.sortBy(this.props.comments, 'CreateDate').map((comment, index) => {
                                if (comment.IdUser === user.IdUser) {
                                    return <div key={index} className="d-flex flex-row p-2">
                                        <div title={`${comment.FirstName} ${comment.LastName}`} style={{ width: 60, height: 60, borderRadius: 30 }} className="bg-gray-3 d-flex justify-content-center"><span className="whiteish align-self-center font-40">{comment.FirstName.substring(0, 1)}</span></div>
                                        <div style={{ borderRadius: 5 }} className="w-75 p-3 bg-gray-3 ms-3 font-14 d-flex flex-column">
                                            <span className="whiteish font-14 line1">{comment.Comment}</span>
                                            <span className="gray font-8 mt-1">{moment(comment.CreateDate).format('MMM Do YYYY @ HH:mmA')}</span>
                                        </div>
                                    </div>
                                } else {
                                    return <div key={index} className="d-flex flex-row p-2 justify-content-end">
                                        <div style={{ borderRadius: 5 }} className="w-75 p-3 bg-gray-3 me-3 font-14 d-flex flex-column">
                                            <span className="whiteish font-14 line1">{comment.Comment}</span>
                                            <span className="gray font-8 mt-1">{moment(comment.CreateDate).format('MMM Do YYYY @ HH:mmA')}</span>
                                        </div>
                                        <div title={`${comment.FirstName} ${comment.LastName}`} style={{ width: 60, height: 60, borderRadius: 30 }} className="bg-gray-3 d-flex justify-content-center"><span className="whiteish align-self-center font-40">{comment.FirstName.substring(0, 1)}</span></div>
                                    </div>
                                }
                            })}
                            <div ref={(el) => { this.messagesEnd = el; }} />
                        </div>
                        <div className="p-2">
                            <div id="txtChat" className={`black-input d-flex mt-2`}>
                                <input className="w-100 p-3 white" type="text" name="name" placeholder="Comentario" ref={(input) => this.txtChat = input} />
                                <button onClick={this.onChat} type="submit" className="btn btn-link white ms-auto">Enviar</button>
                            </div>
                        </div>
                    </form>
                </section>}

                <hr className="p-0 mx-0 my-1" />
                <div className="d-flex flex-row justify-content-center py-3">
                    <button onClick={this.props.toggle} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                        <i className="fas fa-times white font-12" />
                    </button>
                    <button onClick={this.onFinishTask} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                        <i className="fas fa-check white font-12 me-2" />Terminar tarea
                    </button>
                </div>

            </section>}
        </section>
    }
}

export default See;