import React from 'react';
import moment from 'moment';
import { detectCreditCardType } from '../../../helpers';

class PaymentMethods extends React.Component {
    state = {
        sort: 'CreateDate',
    }
    componentWillMount = () => {
        const { id } = this.props.params;
        //this.props.accounting_actions && this.props.accounting_actions.fetch(id);
    }

    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Tarjeta</th>
                <th className="bg-gray-2 white">Nombre</th>
                <th className="bg-gray-2 white">Fecha Exp.</th>
                <th className="bg-gray-2 white">CVV</th>
            </tr>
        </thead>
    }
    render() {
        const { payment_methods } = this.props, { sort, up } = this.state;
        return <section>
            {payment_methods && <table className="w-100 table m-0 poppins bg-gray-2 white">
                {this.renderTH()}
                {payment_methods.length > 0 && <tbody>
                    {_.chain(payment_methods)
                        .value().map((method) => {
                            return (<tr key={method.IdBookPaymentMethod}>
                                <td className="bg-gray-2 white"><i className={`${detectCreditCardType(`${method.Method.Number}`).FA} me-2`} />{method.Method.Number}</td>
                                <td className="bg-gray-2 white">{method.Method.Name}</td>
                                <td className="bg-gray-2 white">{method.Method.ExpDate}</td>
                                <td className="bg-gray-2 white">{method.Method.CVV}</td>
                            </tr>)
                        })}
                </tbody>}
                {payment_methods.length === 0 && <tbody>
                    <tr>
                        <td colSpan={4} className="bg-gray-2 gray-light">
                            <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                <h3 className="align-self-center gray text-center poppins">No se han recolectado métodos de pago para esta reserva</h3>
                            </div>
                        </td>
                    </tr>
                </tbody>}
            </table>}
        </section>
    }
}

export default PaymentMethods;