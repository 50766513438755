import React from 'react';
import ReactDOM from 'react-dom';
import { Animated } from 'react-animated-css';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _, { slice } from 'lodash';
import fakeAuth from '../../../fakeAuth';
import MaskedInput from 'react-text-mask';
import request from 'superagent';
import config from '../../../config';
import { detectCreditCardType } from '../../../helpers';

class PaymentMethodForm extends React.Component {
    state = {
        mode: 1,
        validations: {},
        transaction: {},
        IdProperty: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).IdProperty : null,
    }

    validate = (e) => {
        e && e.preventDefault && e.preventDefault();
        if (this.state.microTransact) return;
        this.setState({ validations: {} }, () => {
            const { validations } = this.state;

            const { id } = this.props.params, { IdProperty } = this.state;

            if (!this.txtNumber.value) {
                validations.txtNumber = 'Por favor, introduce el número de tarjeta';
                this.setState({ validations }, () => { this.txtNumberWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtName.value) {
                validations.txtName = 'Por favor, introduce el nombre que aparece en la tarjeta';
                this.setState({ validations }, () => { this.txtNameWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }            
            if (!ReactDOM.findDOMNode(this.txtExpDate).value) {
                validations.txtExpDate = 'Por favor, introduzca la fecha de expiración de la tarjeta';
                this.setState({ validations }, () => { this.txtExpDateWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtCVV.value) {
                validations.txtCVV = 'Por favor, introduce el código CVV que aparece en la parte posterior de la tarjeta';
                this.setState({ validations }, () => { this.txtCVVWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }

            this.setState({ microTransact: true }, () => {
                // CALL API                
                request.post(`${config.apiEndpoint}/api/v4/accounting/payment_method`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({
                        IdBook: id,
                        IdProperty,
                        Number: this.txtNumber.value,
                        Name: this.txtName.value,
                        ExpDate: ReactDOM.findDOMNode(this.txtExpDate).value,
                        CVV: this.txtCVV.value
                    })
                    .then((data) => {
                        if (data.body.success) {
                            // CALL API to refresh payments
                            this.props.accounting_actions && this.props.accounting_actions.fetch_payment_methods(this.props.params.id);
                            this.props.toggle && this.props.toggle();
                        }
                    }, (error) => {
                        this.setState({ microTransact: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });

        });
    }

    render() {
        const { mode, validations, transaction } = this.state;
        return <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
            <div className="black-input bg-gray-2 p-4 align-self-center" style={{ width: 600 }}>
                <h3 className="white poppins text-center">Agregar método de pago</h3>
                <hr className="w-50 mx-auto" />
                {/* NUMBER */}
                <div className="w-100 mt-4" ref={i => this.txtNumberWrapper = i}>
                    <label className="white poppins font-12">Número <i className="blue">*</i></label>
                    <div id="txtNumber" className={`black-input ${validations.txtNumber ? 'error-input' : ''} d-flex mt-2`}>
                        <i className={`${detectCreditCardType(this.state.number || '').FA} mx-3 align-self-center whiteish font-20`} />
                        <input onChange={e => {
                            this.setState({ number: e.target.value });
                        }} maxLength={16} className="w-100 p-3 white" type="text" name="number" placeholder="Número de tarjeta" ref={(input) => this.txtNumber = input} defaultValue={transaction.Number} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtNumber ? true : false} target={`txtNumber`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtNumber = !validations.txtNumber;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtNumber}</span>
                        </PopoverBody>
                    </Popover>
                </div>


                {/* NAME */}
                <div className="w-100 mt-4" ref={i => this.txtNameWrapper = i}>
                    <label className="white poppins font-12">Nombre en la tarjeta <i className="blue">*</i></label>
                    <div id="txtName" className={`black-input ${validations.txtName ? 'error-input' : ''} d-flex mt-2`}>
                        <input onChange={e => {

                        }} maxLength={16} className="w-100 p-3 white" type="text" name="name" placeholder="Nombre en la tarjeta" ref={(input) => this.txtName = input} defaultValue={transaction.Number} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtName ? true : false} target={`txtName`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtName = !validations.txtName;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtName}</span>
                        </PopoverBody>
                    </Popover>
                </div>

                <div className='d-flex flex-row'>

                    {/* EXP DATE */}
                    <div className="w-100 mt-4" ref={i => this.txtExpDateWrapper = i}>
                        <label className="white poppins font-12">Fecha de expiración <i className="blue">*</i></label>
                        <div id="txtDOB" className={`black-input ${validations.txtExpDate ? 'error-input' : ''} d-flex mt-2`}>
                            <i className="fas fa-calendar font-16 white align-self-center ps-3" />
                            <MaskedInput guide={true} ref={(i) => this.txtExpDate = i} className="w-100 p-3 white" placeholder="MM/YY" defaultValue={transaction.ExpDate} mask={[/\d/, /\d/, '/', /\d/, /\d/]} />
                        </div>
                        {/*<Popover placement="right" isOpen={validations.txtExpDate ? true : false} target={`txtExpDate`} toggle={() => {
                            const { validations } = this.state;
                            validations.txtExpDate = !validations.txtExpDate;
                            this.setState({ validations });
                        }} className="bg-gray-2">
                            <PopoverBody className="d-flex flex-row justify-content-center">
                                <i className="fas fa-times red font-8 align-self-center" />
                                <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtExpDate}</span>
                            </PopoverBody>
                    </Popover>*/}
                    </div>


                    {/* CVV */}
                    <div className="w-75 mt-4" ref={i => this.txtCVVWrapper = i}>
                        <label className="white poppins font-12">CVV <i className="blue">*</i></label>
                        <div id="txtCVV" className={`black-input ${validations.txtCVV ? 'error-input' : ''} d-flex mt-2`}>
                            <input onChange={e => {

                            }} className="w-100 p-3 white" maxLength={3} type="text" name="confirmation" placeholder="CVV" ref={(input) => this.txtCVV = input} defaultValue={transaction.Confirmation} />
                        </div>
                        <Popover placement="right" isOpen={validations.txtCVV ? true : false} target={`txtCVV`} toggle={() => {
                            const { validations } = this.state;
                            validations.txtCVV = !validations.txtCVV;
                            this.setState({ validations });
                        }} className="bg-gray-2">
                            <PopoverBody className="d-flex flex-row justify-content-center">
                                <i className="fas fa-times red font-8 align-self-center" />
                                <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtCVV}</span>
                            </PopoverBody>
                        </Popover>
                    </div>
                </div>

                {/* BUTTONS */}
                <div className="d-flex justify-content-center mt-4">
                    <button onClick={this.props.toggle} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                        <i className="fas fa-times white font-12" />
                    </button>
                    <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                        {!this.state.microTransact && <i className="fas fa-check white font-12" />}
                        {this.state.microTransact && <i className="fas fa-cog fa-spin white font-12" />}
                    </button>
                </div>
            </div>
        </div>
    }

}

export default PaymentMethodForm;