import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import config from '../../../config';
import { Animated } from 'react-animated-css';
import BlackOverlay from '../../partials/black_overlay';

class OffersList extends React.Component {
    state = {
        offers: null,
        sort: 'Start',
        offer_types: config.offer_types
    }
    componentWillMount() {
        const { offers } = this.props;
        this.setState({ offers });
    }
    componentWillReceiveProps = nextProps => {
        const { offers } = nextProps;
        this.setState({ offers });
    }
    onToggle = (IdOffer) => {
        const { offers } = this.state;
        let index = _.findIndex(offers, r => r.IdOffer === IdOffer);
        offers[index].IsActive = !offers[index].IsActive;
        this.props.offers_actions && this.props.offers_actions.toggle(IdOffer, offers[index].IsActive);
        this.setState({ offers });
    }
    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    doDelete = () => {
        const { delete_id } = this.state, { id } = this.props.params;
        this.props.offers_actions && this.props.offers_actions.archive(this.state.delete_id, id);
        this.setState({ delete_id: null });
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Nombre <i className={`icon-lb_sort ${sort === 'GivenName' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('GivenName')} /></th>
                <th className="bg-gray-2 white">Inicio <i className={`icon-lb_sort ${sort === 'Start' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('Start')} /></th>
                <th className="bg-gray-2 white">Fin <i className={`icon-lb_sort ${sort === 'End' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('End')} /></th>
                <th className="bg-gray-2 white">Tipo <i className={`icon-lb_sort ${sort === 'OfferType' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('OfferType')} /></th>
                <th className="bg-gray-2 white text-center">Estado</th>
                <th className="bg-gray-2 white text-right">Accion</th>
            </tr>
        </thead>

    }
    render() {
        const { offers, sort, up } = this.state;
        return <div className="mt-4 d-flex">
            <div className="w-100 bg-gray-2 px-3 py-2 rounded d-flex">
                <table className="w-100 table m-0 poppins bg-gray-2 white">
                    {this.renderTH()}
                    {offers && offers.length > 0 && <tbody>
                        {_.chain(offers)
                            .filter(r => {
                                if (this.props.search) return `${r.GivenName}`.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;
                                return true;
                            })
                            .sortBy(sort)
                            .tap(arr => {
                                if (up) arr.reverse();
                            })
                            .value().map((offer, index) => <tr key={offer.IdOffer}>
                                <td className="bg-gray-2 white">{offer.GivenName}</td>
                                <td className="bg-gray-2 white">{moment(offer.Start).utc().locale('es').format('MMM Do YYYY')}</td>
                                <td className="bg-gray-2 white">{moment(offer.End).utc().locale('es').format('MMM Do YYYY')}</td>
                                <td className="bg-gray-2 white">{(_.find(this.state.offer_types, o => o.id === offer.OfferType) || {}).name} ({offer.Amount}{(_.find(this.state.offer_types, o => o.id === offer.OfferType) || {}).icon})</td>
                                <td className="bg-gray-2 white text-center"><i onClick={() => this.onToggle(offer.IdOffer)} className={`fas fa-toggle-${offer.IsActive ? 'on green' : 'off white'} font-18`} /></td>
                                <td className="bg-gray-2 white">
                                    <div className="d-flex justify-content-end">
                                        <Link to={`/offers/edit/${offer.IdOffer}`}><i className="fas fa-edit ms-3 font-16 pointer blue" /></Link>
                                        <i className="fas fa-copy ms-3 font-16 pointer gray" />
                                        <i className="fas fa-trash ms-3 font-16 pointer gray" onClick={() => this.setState({ delete_id: offer.IdOffer })} />
                                    </div>
                                </td>
                            </tr>)}
                    </tbody>}
                    {offers && offers.length === 0 && <tbody>
                        <tr>
                            <td colSpan={6} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">Por lo visto no tienes ofertas creadas.<br />Haz click sobre el botón de 'Nueva Oferta' para empezar.</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>}
                    {/* Pager */}
                    {offers && offers.length > 10 && <tfoot>
                        <tr>
                            <td colSpan={6} className="text-center bg-gray-2">
                                <span className="w3-badge me-1 poppins font-12">1</span>
                                <span className="w3-badge poppins font-12">2</span>
                            </td>
                        </tr>
                    </tfoot>}
                </table>
            </div>

            {/* Dlete Confirm */}
            <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.delete_id ? true : false}>
                <BlackOverlay />
                <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                    <div className="black-input bg-gray-2 p-4 align-self-center">
                        <h3 className="white poppins text-center">Confirmar operación</h3>
                        <h6 className="blue text-center poppins">Eliminar oferta</h6>
                        <hr className="w-50 mx-auto" />
                        <h6 className="white text-center poppins">¿Está seguro de eliminar esta oferta?</h6>
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={() => this.setState({ delete_id: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                <i className="fas fa-times white font-12" />
                            </button>
                            <button onClick={this.doDelete} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className="fas fa-check white font-12" />
                            </button>
                        </div>
                    </div>
                </div>
            </Animated>
        </div>
    }
}

export default OffersList;
