import React from 'react';
import { Animated } from 'react-animated-css';
import moment from 'moment';
import { formatPrice } from '../../../helpers';
import BlackOverlay from '../../partials/black_overlay'
import request from 'superagent';
import config from '../../../config';
import _ from 'lodash';
import TransactionForm from './transaction_form';

class RoomsResume extends React.Component {
    state = {
        book: this.props.book,
        sort: 'CreateDate',
    }
    componentWillReceiveProps = nextProps => {
        const { book } = nextProps;
        this.setState({ book });
    }

    renderBook = () => {
        const { book } = this.state, { Rooms } = book, { auxiliar_accountings } = this.props;
        return <Animated animationIn="fadeIn" className="d-flex flex-column w-100 rounded bg-gray-3" style={{ zIndex: 100, position: 'relative' }}>
            <div className="px-3">
                <table className="w-100 table m-0 poppins bg-gray-3 white">
                    <thead>
                        <tr>
                            <th className="bg-gray-3 white">Tipo</th>
                            <th className="bg-gray-3 white">Pieza</th>
                            <th className="bg-gray-3 white">Check-in • Check-out</th>
                            <th className="bg-gray-3 white text-center">Ocupantes</th>
                            <th className="bg-gray-3 white text-center">Cuenta Auxiliar</th>
                            <th className="bg-gray-3 white text-right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Rooms.map((room, i) => <tr key={`${i}_${room.IdRoom}`}>

                            <td className="bg-gray-3 white font-10">{room.GivenName} <i className="fas fa-edit ms-2 pointer blue" onClick={() => this.props.onChange(room, i)} /></td>

                            <td className="bg-gray-3 white font-10"><button onClick={() => this.props.onPiece(room, i)} className={`btn btn-link ${room.PieceLabel ? 'blue' : 'red underlined'}`}>{room.PieceLabel || `TBD`}</button></td>

                            <td className="bg-gray-3 white font-10">{moment(book.CheckIn).format('DD/MM/YYYY')} • {moment(book.CheckOut).format('DD/MM/YYYY')} • ({Math.abs(moment(book.CheckOut).utc().diff(moment(book.CheckIn).utc(), 'days')) + 1} Noches)</td>

                            <td className="bg-gray-3 white font-10 text-center">
                                {room.DISTRIBUTION && room.DISTRIBUTION.map((d, i) => <i key={i} onClick={() => this.setState({ assigning_companion: room })} className={`align-self-center icon-lb_user font-${d.ADULT ? 20 : 12} ${(_.chain([book.Guest || {}, ...book.Companions]).filter(u => u.IdBookRoom === room.IdBookRoom).value().length > i) ? 'blue' : 'gray'} pointer`} />)}
                            </td>

                            <td className="bg-gray-3 white font-10 text-center">
                                <i onClick={() => this.setState({ assigning_auxiliar_account: room })} className={`align-self-center fas fa-file-invoice-dollar font-20  ${(_.chain(auxiliar_accountings).values().flatten().compact().filter(u => u.IdBookRoom === room.IdBookRoom).value().length > 0) ? 'blue' : 'gray'} pointer`} />
                            </td>

                            <td className="bg-gray-3 white font-10 text-right">{formatPrice(room.RateTotal * 100)}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </Animated>
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Fecha <i className={`icon-lb_sort ${sort === 'CreateDate' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('CreateDate')} /></th>
                <th className="bg-gray-2 white">Concepto</th>
                <th className="bg-gray-2 white">Tipo</th>
                <th className="bg-gray-2 white">Crédito</th>
                <th className="bg-gray-2 white">Débito</th>
                <th className="bg-gray-2 white">Balance</th>
                <th className="bg-gray-2 white">Confirmación</th>
            </tr>
        </thead>
    }
    render() {
        const { book } = this.state, { assigning_companion, assigning_auxiliar_account, sort, up } = this.state,
            { auxiliar_accountings, property } = this.props;
        const { payment_types } = config;

        let accounting = _.chain(auxiliar_accountings).values().flatten().compact()
            .filter(u => {
                if (assigning_auxiliar_account) return u.IdBookRoom === assigning_auxiliar_account.IdBookRoom;
                else return false;
            })
            .value();

        let SUB_TOTAL = _.chain(accounting).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value(),
            TAXES = _.chain(accounting).reject(a => a.IsTaxFree).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value() * (property ? (property.Tax || 0) : 0) / 100,
            BALANCE = (SUB_TOTAL + TAXES) - _.chain(accounting).map(a => parseFloat(a.Credit)).reduce((a, b) => a + b, 0).value(),
            PROMO = 0;

        return <section>
            {this.renderBook()}

            {/* ASSIGN PIECES TO THE ROOMS OF THIS RESERVATION */}
            <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.assigning_companion ? true : false} animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                <BlackOverlay />
                <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, right: 500, left: 0, overflowY: 'auto' }} onClick={() => this.setState({ assigning_companion: false })}></div>
                <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, width: 500, right: 0, overflowY: 'auto' }} className="bg-gray-2">
                    <div className="d-flex mx-4 mt-4">
                        <span className="poppins white font-12">Asigne una acompañate a la habitación</span>
                        <button onClick={() => this.setState({ assigning_companion: false })} className="btn btn-link rounded ms-auto"><i className="fas fa-times-circle gray font-18" /></button>
                    </div>
                    <div>
                        {_.chain([book.Guest || null, ...book.Companions]).flatten().compact().value().map((user, i) => <figure onClick={() => {
                            const { assigning_companion, book } = this.state;
                            let target = _.find([book.Guest || {}, ...book.Companions], s => s.IdGuest === user.IdGuest);
                            if (target) {
                                if (target.IdBookRoom !== assigning_companion.IdBookRoom) target.IdBookRoom = assigning_companion.IdBookRoom;
                                else target.IdBookRoom = null;
                                // CALL API
                                request.post(`${config.apiEndpoint}/api/v4/bookings/put_guest_on_room`)
                                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                                    .send(_.pick(target, 'IdBookRoom', 'IdBookGuest'))
                                    .then(this.props.fnUpdate);
                            }
                            this.setState({ book });
                        }} key={i} className="tile d-flex pointer">
                            <div className="cover align-self-center" style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid #1771FF`, background: `url(${user.LogoUrl}) no-repeat center center` }}></div>
                            <span className="white align-self-center ms-3">{`${user.FirstName} ${user.LastName}`}</span>
                            {assigning_companion && <i className={`${user.IdBookRoom === assigning_companion.IdBookRoom ? 'fas fa-check' : 'far fa-circle'} white font-16 ms-auto align-self-center`} />}
                        </figure>)}
                    </div>
                </div>
            </Animated>

            {/* ASSIGN PIECES TO THE ROOMS OF THIS RESERVATION */}
            <Animated animationInDuration={200} animationOutDuration={200} isVisible={this.state.assigning_auxiliar_account ? true : false} animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                <BlackOverlay />
                <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, width: 200, left: 0, overflowY: 'auto' }} onClick={() => this.setState({ assigning_auxiliar_account: false })}></div>
                <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, left: 200, right: 0, overflowY: 'auto', borderLeft: '1px solid gray' }} className="bg-gray-2">
                    <div className="d-flex mx-4 mt-4">
                        <span className="poppins white font-12 align-self-center">Cuenta auxiliar {assigning_auxiliar_account ? assigning_auxiliar_account.PieceLabel : ''}</span>
                        <button className="btn btn-blue btn-sm white font-10 poppins ms-2 align-self-center" onClick={() => this.setState({ assigning_charge: true })}><i className="fas fa-plus" />Agregar Cargo | Pago</button>
                        <button onClick={() => this.setState({ assigning_auxiliar_account: false })} className="btn btn-link rounded ms-auto"><i className="fas fa-times-circle gray font-18" /></button>
                    </div>
                    <div className="px-4">
                        <table className="table poppins bg-gray-2 white">
                            {this.renderTH()}
                            {accounting.length > 0 && <tbody>
                                {_.chain(accounting)
                                    .sortBy(sort)
                                    .tap(arr => {
                                        if (up) arr.reverse();
                                    })
                                    .value().map((accounting) => {

                                        accounting.PaymentType = parseFloat(accounting.Credit) ? (_.find(payment_types, pt => pt.id === accounting.IdPaymentType) || _.first(payment_types)).name : '-';

                                        /*let class_name = (_.find(booking_status, t => parseInt(t.id, 10) === booking.IdStatus) || { color_class: 'Invalid' }).color_class;*/
                                        return (<tr key={accounting.IdBookAccounting}>
                                            <td className="bg-gray-2 white">{moment(accounting.CreateDate).format('MMM Do YYYY @ HH:mmA')}</td>
                                            <td className="bg-gray-2 white">{accounting.Reason}</td>
                                            <td className="bg-gray-2 white">{accounting.PaymentType}</td>
                                            <td className="bg-gray-2 white">{formatPrice(parseFloat(accounting.Credit) * 100)}</td>
                                            <td className="bg-gray-2 white">{formatPrice(parseFloat(accounting.Debit) * 100)}</td>
                                            <td className="bg-gray-2 white">{formatPrice(parseFloat(accounting.Balance) * 100)}</td>
                                            <td className="bg-gray-2 white">{accounting.Confirmation}</td>
                                        </tr>)
                                    })}
                                <tr>
                                    <td className="bg-gray-2 white"></td>
                                    <td className="bg-gray-2 white">Impuestos ({property ? property.Tax : '0'}%)</td>
                                    <td className="bg-gray-2 white"></td>
                                    <td className="bg-gray-2 white"></td>
                                    <td className="bg-gray-2 white">{formatPrice(TAXES * 100)}</td>
                                    <td className="bg-gray-2 white">{formatPrice(BALANCE * 100)}</td>
                                    <td className="bg-gray-2 white"></td>
                                </tr>
                            </tbody>}
                            {accounting.length === 0 && <tbody>
                                <tr>
                                    <td colSpan={7} className="bg-gray-2 gray-light">
                                        <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                            <h3 className="align-self-center gray text-center poppins">No se han creado transacciones adicionales para esta habitación</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>}
                        </table>
                    </div>
                </div>
            </Animated>

            {/* ADD CHARGE */}
            <Animated animationInDuration={200} animationOutDuration={200}
                isVisible={this.state.assigning_charge ? true : false} animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
                <BlackOverlay />
                {this.state.assigning_charge && this.state.assigning_auxiliar_account && <TransactionForm {...this.props} toggle={() => this.setState({ assigning_charge: false })} auxiliar={assigning_auxiliar_account} />}
            </Animated>
        </section>
    }
}

export default RoomsResume;