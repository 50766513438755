import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import momentPlugin from '@fullcalendar/moment'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline' // a plugin!
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment';
import _ from 'lodash';

require("moment/min/locales.min");

function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </>
    )
}

class Frontdesk extends React.Component {

    calendarComponentRef = React.createRef()

    state = {
        defaultDate: moment(),
        start: moment(),
        end: moment().add(1, 'day'),
        menu_toggled: false,
        toggle: 1,
        rooms_selected: [],
        floors: [],
        calendar_events: [],
        status: config.room_status,
        timeline_length: localStorage.getItem('lb.calendar.mode') || 'resourceTimelineDay',
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null
    }

    // Lifecycle
    componentWillMount() {
        momentPlugin.locale = esLocale;
        const { params, booking_actions } = this.props;
        let user = fakeAuth.getUser();
        if (user.IdProperty) this.props.router.goBack();
        else {
            this.props.properties_actions && this.props.properties_actions.fetch_one(params.id);
            this.props.floors_actions && this.props.floors_actions.fetch(params.id);
            this.onFetch();
        }

    }
    componentWillReceiveProps = nextProps => {
        const { floors = [], bookings } = nextProps;
        this.setState({
            floors, bookings,
            calendar_events: _.chain(bookings || [])
                .map(b => b.Rooms)
                .flattenDeep()
                .compact()
                .map(b => {
                    let parent_book = _.find(bookings, o => o.IdBook === b.IdBook);

                    return {
                        id: b.IdBookRoom,
                        resourceId: b.IdPiece,
                        title: `${_.last(b.IdBook.split('-'))} - ${parent_book.GivenName}`,
                        start: moment(b.CheckIn).utc().format(),
                        end: moment(b.CheckOut).utc().format(),
                        classNames: _.find(config.booking_status, bs => bs.id === parent_book.IdStatus).color_class.split(' '),
                        extendedProps: { book: { IdBook: parent_book.IdBook } }
                    }
                }).value()
        });
    }
    //

    onFetch = () => {
        this.props.booking_actions && this.props.booking_actions.fetch_range({
            IdProperty: this.props.params.id,
            CheckIn: moment(this.state.start).format('YYYY-MM-DD'),
            CheckOut: moment(this.state.end).format('YYYY-MM-DD')
        });
    }

    renderHeader = () => {
        const { id } = this.props.params
        return <div className="d-flex">
            {/*<div className="d-flex ">
                <i className="icon-lb_frontdesk blue font-40 align-self-center" />
                <span className="white ms-3 font-20 poppins align-self-center">Frontdesk</span>
    </div>*/}
            <div className="w-100 d-flex ">
                <i className="icon-lb_reservaciones blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Reservas</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            <div className="w-100 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderTopControls = () => {

        return <div className="d-flex">

            {/* FILTERS */}
            <button className="btn bg-gray-dark">
                <i className="far fa-calendar gray font-12 align-self-center" />
                {this.state.start && <span className="gray ms-3 font-12 poppins align-self-center">{this.state.start.format('DD/MM/YYYY')}</span>}
            </button>
            <button className="btn bg-gray-dark ms-2">
                <i className="far fa-calendar gray font-12 align-self-center" />
                {this.state.end && <span className="gray ms-3 font-12 poppins align-self-center">{this.state.end.format('DD/MM/YYYY')}</span>}
            </button>
            {/*<button className="btn bg-gray-dark ms-2">
                <i className="fas fa-filter gray font-12 align-self-center" />
                <i className="fas ms-3 fa-chevron-down gray font-12 align-self-center" />
    </button>*/}
            <button onClick={this.onFetch} className="btn btn-blue circle ms-2 justify-content-center">
                <i className="fas fa-search white font-12 align-self-center" />
            </button>

            {/* NEXT AND PREV */}
            <button className="btn bg-gray-dark ms-auto" onClick={() => {
                this.calendarComponentRef.current.getApi().prev();
            }}>
                <i className="fas fa-chevron-left white font-12 align-self-center" />
            </button>
            <button className="btn bg-gray-dark" onClick={() => {
                this.calendarComponentRef.current.getApi().next();
            }}>
                <i className="fas fa-chevron-right white font-12 align-self-center" />
            </button>
            {this.state.start && <span className="font-16 white poppins ms-3 align-self-center">{this.state.start.locale('es').format(this.state.timeline_length === 'resourceTimelineDay' ? 'MMM Do YYYY' : 'MMM YYYY').toLocaleUpperCase()}</span>}

            {/* TIMELINE LENGTH */}
            <button className="btn bg-gray-dark ms-auto justify-content-center" onClick={() => this.setState({ timeline_length: 'resourceTimelineDay' }, () => {
                localStorage.setItem('lb.calendar.mode', 'resourceTimelineDay')
                let calendarApi = this.calendarComponentRef.current.getApi()
                calendarApi.changeView(this.state.timeline_length);
            })}>
                <span className={`${this.state.timeline_length === 'resourceTimelineDay' ? 'blue' : 'gray'} font-12 poppins align-self-center`}>Día</span>
            </button>
            <button className="btn bg-gray-dark justify-content-center" onClick={() => this.setState({ timeline_length: 'resourceTimelineWeek' }, () => {
                localStorage.setItem('lb.calendar.mode', 'resourceTimelineWeek')
                let calendarApi = this.calendarComponentRef.current.getApi()
                calendarApi.changeView(this.state.timeline_length);
            })}>
                <span className={`${this.state.timeline_length === 'resourceTimelineWeek' ? 'blue' : 'gray'} font-12 poppins align-self-center`}>Semana</span>
            </button>
            <button className="btn bg-gray-dark justify-content-center" onClick={() => this.setState({ timeline_length: 'resourceTimelineMonth' }, () => {
                localStorage.setItem('lb.calendar.mode', 'resourceTimelineMonth')
                let calendarApi = this.calendarComponentRef.current.getApi()
                calendarApi.changeView(this.state.timeline_length);
            })}>
                <span className={`${this.state.timeline_length === 'resourceTimelineMonth' ? 'blue' : 'gray'} font-12 poppins align-self-center`}>Mes</span>
            </button>
        </div>
    }
    renderTable = () => {

        let slotMinWidth = 100;
        switch (this.state.timeline_length) {
            case 'resourceTimelineDay': slotMinWidth = 50; break;
            case 'resourceTimelineWeek': slotMinWidth = 180; break;
            case 'resourceTimelineMonth': slotMinWidth = 180; break;
            default: break;
        }

        const { floors } = this.state;
        let resources = [];
        /*
        resources={[
                            { id: 'a', floor: '1', title: 'Auditorium A', },
                            { id: 'b', floor: '1', title: 'Auditorium B', occupancy: 40 },
                            { id: 'c', floor: '1', title: 'Auditorium C', occupancy: 40 },
                            { id: 'd', floor: '1', title: 'Auditorium D', occupancy: 40 },
                            { id: 'e', floor: '1', title: 'Auditorium E', occupancy: 40 },
                            
                        ]}
                        */
        _.sortBy(floors, 'Label').forEach(floor => {
            _.sortBy(floor.Pieces, 'Label').forEach(piece => {
                resources.push({
                    id: piece.IdPiece,
                    floor: floor.Label,
                    title: piece.Label,
                    occupancy: 0, // TODO: ?
                })
            });
        });

        return <div className="mt-4">
            <div className="bg-gray-2 p-3 rounded">
                {this.renderTopControls()}
                <div className="white poppins">
                    <FullCalendar
                        initialView={this.state.timeline_length}
                        schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                        plugins={[resourceTimelinePlugin, interactionPlugin, momentPlugin]}
                        headerToolbar={{ left: '', right: '' }}
                        ref={this.calendarComponentRef}
                        contentHeight={700} // 'auto'
                        slotMinTime="10:00:00"
                        locale={esLocale}

                        resourceAreaWidth={350}
                        resourceAreaHeaderContent="Habitaciones"

                        resourceAreaHeaderClassNames={'bg-gray-dark'}
                        resourceGroupLabelClassNames={''}
                        resourceGroupLaneClassNames={''}
                        resourceLabelClassNames={''}
                        slotLabelClassNames={'bg-gray-dark'}

                        datesSet={(args) => {
                            // This is in order to update the UI top controls based on the current calendar render
                            this.setState({ start: moment(args.startStr), end: moment(args.endStr) }, () => {
                                this.props.booking_actions && this.props.booking_actions.fetch_range({
                                    IdProperty: this.props.params.id,
                                    CheckIn: moment(this.state.start).format('YYYY-MM-DD'),
                                    CheckOut: moment(this.state.end).format('YYYY-MM-DD')
                                });
                            });
                        }}

                        views={{
                            month: {
                                dayHeaderFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
                                slotLabelFormat: (date) => {
                                    return moment(date.date).locale('es').format('dddd Do').toLocaleUpperCase();
                                }
                            },
                            week: {
                                dayHeaderFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
                            },
                        }}

                        slotMinWidth={slotMinWidth}
                        editable={false}
                        resourceAreaColumns={[
                            {
                                group: true,
                                field: 'floor',
                                headerContent: 'Piso'
                            },
                            {
                                field: 'title',
                                headerContent: 'Pieza'
                            },
                        ]}
                        resources={resources}
                        //events="https://fullcalendar.io/api/demo-feeds/events.json?single-day&for-resource-timeline"
                        events={this.state.calendar_events || []}

                        eventClick={(info) => {
                            //alert('Event: ' + info.event.title);
                            /*alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                            alert('View: ' + info.view.type);*/

                            // change the border color just for fun
                            info.el.style.borderColor = 'red';
                            this.props.router.push(`booking/${info.event.extendedProps.book.IdBook}`);
                        }}
                    />
                </div>
            </div>
        </div>
    }
    renderCommon = () => {
        const { id } = this.props.params
        return (
            <div>
                {this.renderHeader()}
                <div className="w-100 d-flex mt-4">
                    <button className="btn btn-link d-flex">
                        <i className="far fa-calendar blue font-18 align-self-center" />
                        <span className="white ms-3 font-16 poppins align-self-center">Calendario</span>
                    </button>
                    <Link to={`/bookings/${id}`} className="btn btn-link d-flex">
                        <i className="fas fa-list fa-square-check gray font-18 align-self-center" />
                        <span className="gray ms-3 font-16 poppins align-self-center">Listado de reservas</span>
                    </Link>
                </div>
                {this.renderTable()}
                <div style={{ height: 200 }} />
            </div>
        )
    }

    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {this.state.microTransac && <LineLoader />}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}
                    <Menu {...this.state} {...this.props} />
                </section>
            </Layout>
        )
    }
}

export default Frontdesk;
