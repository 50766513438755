import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { padWithZeros, } from '../../helpers';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import moment from 'moment';
import BlackOverlay from '../partials/black_overlay';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class Occupation extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 1,
        rooms_selected: [],
        floors: [],
        status: config.room_status,
        sort: 'CreationDate',
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        filters: {
            CheckIn: moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').utc(),
            CheckOut: moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').add(1, 'days').utc(),
            IdRoom: null
        },
    }

    // Lifecycle
    componentWillMount() {
        const { params, } = this.props;
        let user = fakeAuth.getUser();
        if (user.IdProperty) this.props.router.goBack();
        else {
            this.props.properties_actions && this.props.properties_actions.fetch_one(params.id);
            this.props.floors_actions && this.props.floors_actions.fetch(params.id);
            this.props.rooms_actions && this.props.rooms_actions.fetch(params.id);
            this.onFetch();
        }
    }
    componentWillReceiveProps = nextProps => {
        const { floors = [], bookings, rooms } = nextProps;

        // resources is basically the list of actual pieces
        let resources = [];
        if (rooms) {            

            let all_booked_rooms = _.chain(bookings).each(b => {
                b.Rooms.forEach(room => {
                    _.extend(room, _.pick(b, 'PNR', 'GivenName', 'Guests', 'Accounting'))
                });
            }).map(b => b.Rooms)
                .flatten()
                .compact()
                .value();

            _.sortBy(floors, 'Label').forEach(floor => {
                _.sortBy(floor.Pieces, 'Label').forEach((piece, i) => {
                    resources.push({
                        id: piece.IdPiece,
                        floor: floor.Label,
                        title: piece.Label,
                        name: (_.find(rooms, r => r.IdRoom === piece.IdRoom) || {}).GivenName,
                        IdRoom: (_.find(rooms, r => r.IdRoom === piece.IdRoom) || {}).IdRoom,
                        BOOK: _.find(all_booked_rooms, br => br.IdPiece === piece.IdPiece),
                        occupancy: 0, // TODO: ?
                    })
                });
            });

            rooms.forEach(r => {
                r.TOTAL = _.filter(resources, x => x.IdRoom === r.IdRoom).length
            });

            this.setState({
                floors,
                bookings,
                all_booked_rooms,
                resources,
                rooms: [{
                    GivenName: `Todas`,
                    TOTAL: resources.length,
                    IdRoom: null
                }, ...rooms],
                bootstrapped: bookings ? true : false
            });
        }


    }
    //

    doDelete = () => {
        const { id } = this.props.params;
        this.props.guests_actions && this.props.guests_actions.archive(this.state.delete_id, id);
        this.setState({ delete_id: null });
    }

    onFetch = () => {
        this.props.booking_actions && this.props.booking_actions.fetch_range({
            IdProperty: this.props.params.id,
            CheckIn: moment(this.state.filters.CheckIn).format('YYYY-MM-DD'),
            CheckOut: moment(this.state.filters.CheckOut).format('YYYY-MM-DD')
        });
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_reportes blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Reportes - Ocupación</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            {/*<div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input className="w-100 p-3 white" type="text" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} onChange={e => this.setState({ search: e.target.value })} />
                </div>
            </div>*/}
            <div className="w-100 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderFilters = () => {
        const { filters } = this.state;

        return <Animated animationIn="fadeInRight" animationInDelay={200} animationInDuration={200} className="w3-content d-flex justify-content-center mt-4" style={{ zIndex: 200, position: 'relative' }}>

            <div className="bg-gray-2 black-input d-flex" style={{ width: 400 }}>
                <i className="fas fa-search font-16 white align-self-center ps-3" />
                <input className="w-100 p-3 white" type="text" placeholder="Buscar" ref={(input) => this.txtSearch = input} onChange={e => this.setState({ search: e.target.value })} />
            </div>
            {this.state.bootstrapped && this.state.all_booked_rooms && this.state.all_booked_rooms.length > 0 && <ReactHTMLTableToExcel id="test-table-xls-button" className="ms-auto btn btn-lg btn-link white align-self-end d-flex bordered" table="table-to-xls" filename={`Ocupacion`}
                sheet="Admins" buttonText="Exportar" />}

        </Animated>
    }
    renderTable = () => {
        const { resources, all_booked_rooms, bookings } = this.state;

        return <table className="table font-10" id="table-to-xls">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Loc</th>
                    <th>Tipo</th>
                    <th>Nombre(s)</th>
                    <th>Apellido(s)</th>
                    <th>ID</th>
                    <th>In</th>
                    <th>Hora</th>
                    <th>Out</th>
                    <th>Tarifa</th>
                    <th>Rate</th>
                    <th>Saldo</th>
                    <th>F.Pago</th>
                    <th>Empresa</th>
                </tr>
            </thead>
            <tbody>
                {resources && _.chain(resources)
                    .filter(r => r.BOOK)
                    .filter(r => {
                        // TODO: txtSearch filtert
                        if (!this.txtSearch.value) return true;
                        return `${JSON.stringify(r.BOOK)}`.toLowerCase().indexOf(this.txtSearch.value.toLowerCase()) !== -1;
                    })                    
                    .value().map((r, index) => {

                        // Some calculations needed here                        
                        let main_guest = _.find(r.BOOK.Guests, g => (g.IdBookRoom === r.id || !g.IsCompanion)) || {};

                        return <tr key={index}>
                            <td>{r.title}</td>
                            <td>{padWithZeros(r.BOOK.PNR)}</td>
                            <td>{r.name}</td>
                            <td>{main_guest.FirstName}</td>
                            <td>{main_guest.LastName}</td>
                            <td>{main_guest.IdOrPassport}</td>
                            <td>{moment(r.BOOK.CheckIn).format('DD/MM/YYYY')}</td>
                            <td>{moment(r.BOOK.CheckIn).format('HH:mm')}</td>
                            <td>{moment(r.BOOK.CheckOut).format('DD/MM/YYYY')}</td>
                            <td>{Math.abs(moment(r.BOOK.CheckOut).diff(moment(r.BOOK.CheckIn), 'days')) + 1}</td>
                            <td>{ /* not sure how to calculate this */}</td>
                            <td>{(_.last(r.BOOK.Accounting).Balance)}</td>
                            <td>-</td>
                            <td>{main_guest.Coorp}</td>
                        </tr>
                    })}
            </tbody>
        </table>
    }
    renderCommon = () => {
        return (
            <div className="">
                {this.renderHeader()}
                <div className="d-flex justify-content-center mt-4">
                    <Link to={`/reports/occupation/${this.props.params.id}`} className="btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-percent font-20 blue" /></Link>
                    {/*<Link to={`/reports/transactions/${this.props.params.id}`} className="ms-2 btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-dollar font-20 whiteish" /></Link>*/}
                    <Link to={`/reports/forecast/${this.props.params.id}`} className="ms-2 btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-cloud font-20 whiteish" /></Link>
                </div>
                <div style={{ position: "absolute", top: '12em', bottom: 0, left: 100, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">

                    {this.renderFilters()}

                    {/* Sheet */}
                    {this.state.resources && <Animated animationIn="fadeInUp" animationInDuration={200} className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', minWidth: 300, margin: 'auto' }}>
                        {this.renderTable()}
                    </Animated>}
                </div>
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Delete Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.delete_id ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Eliminar huesped</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de eliminar este empleado?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ delete_id: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doDelete} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>
                </section>
            </Layout>
        )
    }
}

export default Occupation;
