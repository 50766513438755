import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import BlackOverlay from '../partials/black_overlay';
import config from '../../config';
import request from 'superagent';
import moment from 'moment';
import Menu from '../partials/menu';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';

class Periods extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 1,
        status: config.room_status,
        sort: 'CreationDate'
    }
    componentWillMount() {
        const { id } = this.props.params;
        if (id) {
            this.props.periods_actions && this.props.periods_actions.fetch(id);
        } else {
            this.props.router.goBack();
        }
    }
    componentWillReceiveProps = nextProps => {
        const { periods, room } = nextProps;
        this.setState({ periods, room });
    }

    onToggle = (IdPeriod) => {
        const { periods } = this.state;
        let index = _.findIndex(periods, r => r.IdPeriod === IdPeriod);
        periods[index].IsActive = !periods[index].IsActive;
        this.props.periods_actions && this.props.periods_actions.toggle(IdPeriod, periods[index].IsActive);
        this.setState({ periods });
    }
    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    doDelete = () => {
        const { id } = this.props.params;
        this.props.periods_actions && this.props.periods_actions.archive(this.state.delete_id, id);
        this.setState({ delete_id: null });
    }
    doCopyPeriod = () => {
        const { confirm_copy } = this.state, { id } = this.props.params;

        // Call API
        request.post(`${config.apiEndpoint}/api/v4/periods`)
            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
            .send({
                ..._.omit(confirm_copy, 'IdPeriod'),
                Name: `${confirm_copy.Name} (copy)`
            }).then((data) => {
                this.props.periods_actions && this.props.periods_actions.fetch(id);
            }, (error) => {
                this.setState({ microTransac: false, error: 'Something wrong' });
                //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
            });

        this.setState({ confirm_copy: false });
    }

    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="far fa-clock blue font-40 align-self-center" />
                <span className="white ms-3 font-20 poppins align-self-center">Periodos</span>
            </div>
            <div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input onChange={() => this.setState({ search: this.txtSearch.value })} className="w-100 p-3 white" type="text" name="email" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} />
                </div>
            </div>
            <div className="w-100">
            <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Periodo <i className={`icon-lb_sort ${sort === 'Name' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('Name')} /></th>
                <th className="bg-gray-2 white">Inicio <i className={`icon-lb_sort ${sort === 'Start' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('Start')} /></th>
                <th className="bg-gray-2 white">Fin <i className={`icon-lb_sort ${sort === 'End' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('End')} /></th>
                <th className="bg-gray-2 white">Fecha de creación <i className={`icon-lb_sort ${sort === 'CreationDate' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('CreationDate')} /></th>
                <th className="bg-gray-2 white text-center">Estado</th>
                <th className="bg-gray-2 white text-right">Accion</th>
            </tr>
        </thead>
    }
    renderTable = () => {
        const { periods, sort, up, room } = this.state;
        return <div className="mt-4 d-flex">
            <Animated isVisible={periods ? true : false} animateOnMount={false} animationInDuration={300} animationIn='fadeInRight' className="w-100 bg-gray-2 px-3 py-2 rounded d-flex">
                <table className="w-100 table m-0 poppins bg-gray-2 white">
                    {this.renderTH()}
                    {periods && periods.length > 0 && <tbody>
                        {_.chain(periods)
                            .filter(r => {
                                if (this.state.search) return `${r.GivenName}`.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
                                return true;
                            })
                            .sortBy(sort)
                            .tap(arr => {
                                if (up) arr.reverse();
                            })
                            .value().map((period, index) => <tr key={period.IdPeriod}>
                                <td className="bg-gray-2 white">{period.Name}</td>
                                <td className="bg-gray-2 white">{moment(period.Start).locale('es').utc().format('MMMM Do YYYY')}</td>
                                <td className="bg-gray-2 white">{moment(period.End).locale('es').utc().format('MMMM Do YYYY')}</td>
                                <td className="bg-gray-2 white">{moment(period.CreationDate).locale('es').format('MMMM Do YYYY')}</td>
                                <td className="bg-gray-2 white text-center"><i onClick={() => this.onToggle(period.IdPeriod)} className={`fas fa-toggle-${period.IsActive ? 'on green' : 'off white'} font-18`} /></td>
                                <td className="bg-gray-2 white">
                                    <div className="d-flex justify-content-end">
                                        <Link to={`/periods/edit/${period.IdPeriod}`}><i className="fas fa-edit ms-3 font-16 pointer blue" /></Link>
                                        <i className="fas fa-copy ms-3 font-16 pointer gray" onClick={() => {
                                            this.setState({ confirm_copy: period });
                                        }} />
                                        <i className="fas fa-trash ms-3 font-16 pointer gray" onClick={() => this.setState({ delete_id: period.IdPeriod })} />
                                    </div>
                                </td>
                            </tr>)}
                    </tbody>}
                    {periods && periods.length === 0 && <tbody>
                        <tr>
                            <td colSpan={6} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">Por lo visto la habitación {(room || {}).GivenName} no tiene periodos creados.<br />Haz click sobre el botón de "Nuevo Periodo" para empezar.</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>}
                    {/*<tfoot>
                        <tr>
                            <td colSpan={6} className="text-center bg-gray-2">
                                <span className="w3-badge me-1 poppins font-12">1</span>
                                <span className="w3-badge poppins font-12">2</span>
                            </td>
                        </tr>
                    </tfoot>*/}
                </table>
            </Animated>
        </div>
    }
    renderCommon = () => {
        const { id } = this.props.params, { room } = this.props;
        return (
            <div className="">
                {this.renderHeader()}
                <div className="w-100 d-flex mt-4">
                    {room && <span className="white ms-2 font-20 poppins align-self-center">{room.GivenName}</span>}
                    {id && <Link to={`/periods/add/${id}`} className="btn btn-lg btn-blue white px-4 ms-4 align-self-center d-flex" >
                        Nuevo Periodo <i className="fas fa-plus-circle align-self-center font-10 ms-3" />
                    </Link>}
                    <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                        <i className="icon-corner-down-left white font-12" />
                    </button>
                </div>
                {this.renderTable()}
                <div style={{ height: 200 }} />
            </div>
        )
    }

    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Delete Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.delete_id ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Eliminar periodo</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de eliminar este periodo?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ delete_id: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doDelete} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                    {/* Copy Period */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm_copy ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Copiar período</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de crear una copia de este período?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ confirm_copy: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doCopyPeriod} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>

                </section>
            </Layout>
        )
    }
}

export default Periods;
