function accounting_Reducer(state = [], action) {
    switch (action.type) {
        case 'ACCOUNTING/SET':
            return action.accounting;
        case 'ACCOUNTING/CLEAR':
            return null;
        case 'BOOK/CLEAR':
            return null;
        default:
            return state;
    }
}

export default accounting_Reducer;
