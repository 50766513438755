import React from 'react';

class Base extends React.Component {
    render() {
        return <section>
            {React.cloneElement(this.props.children, this.props)}
        </section>
    }
}

export default Base;