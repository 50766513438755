import React from 'react';

const LineLoader = (props) => {

    return (
        <div className="cssload-container">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        );

};

export default LineLoader;