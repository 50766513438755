import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

// Get the accounting of a book
export function* fetch_accounting() {
    yield* takeEvery("ACCOUNTING/FETCH", function* (action) {
        try {
            const result = yield call(({ id }) => {                
                return request.get(`${config.apiEndpoint}/api/v4/accounting/book/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { accounting, auxiliar_accountings } = result;                
                yield put({ type: 'ACCOUNTING/SET', accounting });
                yield put({ type: 'ACCOUNTING/AUXILIAR/SET', auxiliar_accountings });
            } else {
                yield put({
                    type: 'ACCOUNTING/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ACCOUNTING/FETCH_FAILED',
                e
            });
        }
    });
}

// Get the payment methods of a book
export function* fetch_payment_methods() {
    yield* takeEvery("PAYMENT_METHODS/FETCH", function* (action) {
        try {
            const result = yield call(({ id }) => {                     
                return request.get(`${config.apiEndpoint}/api/v4/accounting/payment_methods/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { payment_methods } = result;                
                yield put({ type: 'PAYMENT_METHODS/SET', payment_methods });
            } else {
                yield put({
                    type: 'PAYMENT_METHODS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'PAYMENT_METHODS/FETCH_FAILED',
                e
            });
        }
    });
}


export function* fetch_booking() {
    yield* takeEvery("BOOKING/FETCH", function* (action) {
        try {
            const result = yield call(({ id }) => {                
                return request.get(`${config.apiEndpoint}/api/v4/bookings/${id}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { book } = result;
                yield put({ type: 'BOOK/SET', book });
            } else {
                yield put({
                    type: 'BOOKING/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'BOOKING/FETCH_FAILED',
                e
            });
        }
    });
}


