import React from 'react';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Jumbotron } from 'reactstrap';
import moment from 'moment';
import _, { slice } from 'lodash';
import request from 'superagent';
import config from '../../../config';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../partials/custom_date_picker';
import { Animated } from 'react-animated-css';
import BlackOverlay from '../../partials/black_overlay';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import momentPlugin from '@fullcalendar/moment'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline' // a plugin!
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import esLocale from '@fullcalendar/core/locales/es';
import fakeAuth from '../../../fakeAuth';


class ChangeDates extends React.Component {

    calendarComponentRef = React.createRef()

    state = {
        mode: parseInt((localStorage.getItem('lb.rt') || 1), 10),
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        bootstrapped: false,
        timeline_length: 'resourceTimelineMonth',
        defaultDate: moment(this.props.book.CheckIn),
        start: moment(this.props.book.CheckIn),
        end: moment(this.props.book.CheckOut),
        IdBook: this.props.params.id,
        has_pieces: false
    }

    componentWillMount() {
        momentPlugin.locale = esLocale;
        const { book } = this.props;
        if (_.find(book.Rooms, r => r.IdPiece)) this.setState({ has_pieces: _.find(book.Rooms, r => r.IdPiece).IdPiece });
        this.props.properties_actions && this.props.properties_actions.fetch_one(book.IdProperty);
        this.props.floors_actions && this.props.floors_actions.fetch(book.IdProperty);
        this.onFetch();
    }
    componentWillReceiveProps = nextProps => {
        const { floors = [], bookings } = nextProps;

        if (bookings) {
            let calendar_events = _.chain(bookings || [])
                .reject(b => [5, 6].indexOf(b.IdStatus) !== -1)                
                .map(b => b.Rooms)
                .flattenDeep()
                .compact()
                .filter(b => {
                    if (this.state.has_pieces) return b.IdPiece === this.state.has_pieces;
                    return b.IdBook === this.state.IdBook; //false;
                })
                .map(b => {
                    let parent_book = _.find(bookings, o => o.IdBook === b.IdBook);
                    return {
                        overlap: false,
                        id: b.IdBookRoom,
                        title: `${b.IdBook.substring(0, 6)} - ${b.PieceLabel || 'Pieza no asignada'} - ${parent_book.GivenName || 'Huesped no asignado'}`,
                        start: moment(b.CheckIn).format(),
                        end: moment(b.CheckOut).add(-1, 'days').format(),
                        editable: b.IdBook === this.state.IdBook && parent_book.IdStatus !== 3,
                        durationEditable: b.IdBook === this.state.IdBook,
                        classNames: b.IdBook === this.state.IdBook ? _.find(config.booking_status, bs => bs.id === parent_book.IdStatus).color_class.split(' ') : 'w3-gray white',
                        extendedProps: { book: b.IdBook === this.state.IdBook ? { IdBook: parent_book.IdBook } : null }
                    }
                }).value();

            this.setState({
                floors,
                bookings,
                calendar_events
            });
        }
    }

    onFetch = () => {
        const { book } = this.props;
        this.props.booking_actions && this.props.booking_actions.fetch_range({
            IdProperty: book.IdProperty,
            CheckIn: moment(book.CheckIn).format('YYYY-MM-DD'),
            CheckOut: moment(book.CheckOut).add(1, 'month').format('YYYY-MM-DD')
        });
    }
    onSave = () => this.setState({ confirm: true });

    doChange = () => {
        this.setState({ modifying: true });
        const { changing_dates, book } = this.props, { mode, start, end } = this.state;

        // Call API
        request.patch(`${config.apiEndpoint}/api/v4/bookings/dates/${book.IdBook}`)
            .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
            .send({
                CheckIn: moment(start).utc().format(),
                CheckOut: moment(end).utc().format(),
                IdProperty: book.IdProperty
            })
            .then((data) => {
                this.props.onDone && this.props.onDone();
            }, (error) => {
                this.setState({ microTransac: false, error: 'Something wrong' });
                //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
            });
    }

    onAddNight = (add) => {
        const { calendar_events } = this.state, event = _.find(calendar_events, e => e.extendedProps.book);
        if (event) {
            event.end = moment(event.end).add(add, 'days').format();
            this.setState({
                calendar_events,                
                end: moment(this.state.end).add(add, 'days'),
                dirty: true
            }, () => {
                //if (this.state.has_pieces)
                    this.calendarComponentRef.current.getApi().getEventById(event.id).moveEnd({ days: add });
            });
        }
    }

    onEventDrop = ({ event, revert, newResource, }) => {
        const { calendar_events } = this.state;
        _.extend(_.find(calendar_events, function (e) {
            return (e.id === event.id);
        }), _.pick(event, 'start', 'end', 'resourceId'));
        this.setState({
            dirty: true,
            calendar_events,
            start: moment(event.start),
            end: moment(event.end).add(1, 'day')
        });
    }

    renderTopControls = () => {
        const { book } = this.props, { has_pieces, dirty } = this.state;

        return <div className="d-flex">

            {/* FILTERS */}
            {/*!has_pieces && <div style={{ width: 150 }}>
                <label className="white poppins font-12">Check-in</label>
                <div id="txtCheckIn">
                    <DatePicker highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" hint="Check-In" />} selected={this.state.start} showYearDropdown scrollableYearDropdown
                        yearDropdownItemNumber={15} onChange={date => {
                            this.setState({ start: moment(date).utc() });
                        }} className={`white font-12`}
                    />
                </div>
            </div>*/}

            {true && <button className="btn bg-gray-dark">
                <i className="far fa-calendar gray font-12 align-self-center" />
                {book && <span className="gray ms-3 font-12 poppins align-self-center">{`${moment(this.state.start).utc().format('MMM Do YYYY')}`}</span>}
            </button>}

            {/* NEXT AND PREV */}
            <button className="btn bg-gray-dark ms-2" onClick={() => this.onAddNight(-1)}>
                <i className="fas fa-chevron-left white font-12 align-self-center" />
            </button>
            {book && <span className="font-16 white poppins mx-3 align-self-center">{`${1 + moment(this.state.end).utc().diff(moment(this.state.start).utc(), 'days')} Noches`}</span>}
            <button className="btn bg-gray-dark" onClick={() => this.onAddNight(1)}>
                <i className="fas fa-chevron-right white font-12 align-self-center" />
            </button>

            {/* DIRTY */}
            {dirty && <button onClick={this.onSave} className="btn btn-blue ms-2 white" style={{ borderRadius: 20 }}>Guardar Cambios</button>}

            {/* NEXT AND PREV */}
            {true && <button className="btn bg-gray-dark ms-auto" onClick={() => {
                this.calendarComponentRef.current.getApi().prev();
            }}>
                <i className="fas fa-chevron-left white font-12 align-self-center" />
            </button>}
            {true && <button className="btn bg-gray-dark" onClick={() => {
                this.calendarComponentRef.current.getApi().next();
            }}>
                <i className="fas fa-chevron-right white font-12 align-self-center" />
            </button>}
            {this.state.start && <span className="font-16 white poppins ms-3 align-self-center">{this.state.start.locale('es').format(this.state.timeline_length === 'resourceTimelineDay' ? 'MMM Do YYYY' : 'MMM YYYY').toLocaleUpperCase()}</span>}
        </div>
    }

    render() {
        const { changing_dates, book } = this.props, { has_pieces, confirm } = this.state;

        return <div style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, left: 100, right: 0, overflowY: 'auto' }} className="bg-gray-2">
            {changing_dates && book && <section>
                <div className="d-flex mx-4 mt-4">
                    <span className="poppins white font-16">Modifique las fechas de la reserva</span>
                    <button onClick={this.props.onCancel} className="btn btn-link rounded ms-auto"><i className="fas fa-times-circle gray font-18" /></button>
                </div>
                <hr />
                <div className='px-4'>
                    {this.renderTopControls()}
                    <div className="white poppins">

                        {!has_pieces && <div className="bg-gray-2 gray-light">
                            <div className="d-flex justify-content-center" style={{ height: 100 }}>
                                <h3 className="align-self-center gray text-center poppins">Esta reserva aun no tiene piezas asignadas</h3>
                            </div>
                        </div>}
                        {true && !confirm && <FullCalendar initialView={'dayGridYear'}
                            schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                            plugins={[interactionPlugin, momentPlugin, dayGridPlugin]}
                            headerToolbar={{ left: '', right: '' }}
                            ref={this.calendarComponentRef}
                            locale={esLocale}
                            overlap={false}
                            //height={400} // 'auto'
                            //slotMinTime="10:00:00"                           

                            initialDate={this.state.defaultDate.format('YYYY-MM-DD')}
                            slotLabelClassNames={'bg-gray-dark'}

                            /*datesSet={(args) => {
                                // This is in order to update the UI top controls based on the current calendar render
                                this.setState({ start: moment(args.startStr), end: moment(args.endStr) }, () => {
                                    this.props.booking_actions && this.props.booking_actions.fetch_range({
                                        IdProperty: this.props.book.IdProperty,
                                        CheckIn: moment(this.props.book.CheckIn).format('YYYY-MM-DD'),
                                        CheckOut: moment(this.props.book.CheckOut).add(1, 'month').format('YYYY-MM-DD')
                                    });
                                });
                            }}*/

                            /*views={{
                                month: {
                                    dayHeaderFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
                                    slotLabelFormat: (date) => {
                                        return moment(date.date).locale('es').format('dddd Do').toLocaleUpperCase();
                                    }
                                },
                                week: {
                                    dayHeaderFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
                                },
                            }}*/

                            editable={true}
                            events={this.state.calendar_events || []}
                            eventClick={(info) => {
                                //alert('Event: ' + info.event.title);
                                /*alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                                alert('View: ' + info.view.type);*/

                                // change the border color just for fun
                                /*info.el.style.borderColor = 'red';
                                this.props.router.push(`booking/${info.event.extendedProps.book.IdBook}`);*/
                            }}
                            eventDrop={this.onEventDrop}
                        />}
                    </div>
                </div>
            </section>}

            {/* Confirm */}
            <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.confirm ? true : false}>
                <BlackOverlay />
                <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1000 }}>
                    <div className="black-input bg-gray-2 p-4 align-self-center">
                        <h3 className="white poppins text-center">Confirmar operación</h3>
                        <h6 className="red text-center poppins">Modioficar Reserva</h6>
                        <hr className="w-50 mx-auto" />
                        <h6 className="white text-center poppins" style={{ maxWidth: 400 }}>¿Está seguro de modificar esta reserva? Las tarifas seran re-calculadas. Esta operación no se puede revertir</h6>
                        <div className="d-flex justify-content-center mt-4">
                            {!this.state.modifying && <button onClick={() => this.setState({ confirm: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                <i className="fas fa-times white font-12" />
                            </button>}
                            <button onClick={this.doChange} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                <i className={`fas fa-${this.state.modifying ? 'cog fa-spin' : 'check'} white font-12`} />
                            </button>
                        </div>
                    </div>
                </div>
            </Animated>
        </div>
    }
}

export default ChangeDates;