import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* fetch_logic_occupancy() {
    yield* takeEvery("LOGIC/OCCUPANCY/FETCH", function* (action) {
        try {
            const result = yield call(({ IdProperty, Flag }) => {
                return request.post(`${config.apiEndpoint}/api/v4/logic/occupancy/summary/${IdProperty}`)
                    .send({ Flag })
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { occupancy_summary } = result;
                yield put({ type: 'OCCUPANCY/SUMMARY/SET', occupancy_summary });
            } else {
                yield put({
                    type: 'OCCUPANCY/SUMMARY/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'OCCUPANCY/SUMMARY/FETCH_FAILED',
                e
            });
        }
    });
}
