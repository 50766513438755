function plans_Reducer(state = [], action) {
    switch (action.type) {
        case 'PLANS/SET':            
            return action.plans;
        case 'PLANS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default plans_Reducer;
