import React from 'react';
import moment from 'moment';
import config from '../../../config';
import { formatPrice } from '../../../helpers';

class Accounting extends React.Component {
    state = {
        sort: 'CreateDate',
    }
    componentWillMount = () => {
        const { id } = this.props.params;
        //this.props.accounting_actions && this.props.accounting_actions.fetch(id);
    }

    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Fecha <i className={`icon-lb_sort ${sort === 'CreateDate' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('CreateDate')} /></th>
                <th className="bg-gray-2 white">Concepto</th>
                <th className="bg-gray-2 white">Tipo</th>
                <th className="bg-gray-2 white">Crédito</th>
                <th className="bg-gray-2 white">Débito</th>
                <th className="bg-gray-2 white">Balance</th>
                <th className="bg-gray-2 white">Confirmación</th>
            </tr>
        </thead>
    }
    render() {
        const { accounting, auxiliar_accountings } = this.props, { sort, up } = this.state;
        const { payment_types } = config;        
        const { property } = this.props;

        let SUB_TOTAL = _.chain(accounting).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value(),
            TAXES = _.chain(accounting).reject(a => a.IsTaxFree).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value() * (property ? (property.Tax || 0) : 0) / 100,
            BALANCE = (SUB_TOTAL + TAXES) - _.chain(accounting).map(a => parseFloat(a.Credit)).reduce((a, b) => a + b, 0).value(),
            PROMO = 0;

        return <section>
            {accounting && <section>
                {/*<section className="d-flex flex-row mb-2">
                    <h5 className="white">Cuenta Maestra</h5>
                    <button className="btn btn-blue btn-sm white font-10 poppins ms-2" onClick={() => this.setState({ assigning_charge: true })}><i className="fas fa-plus" />Agregar Entrada</button>
                </section>*/}
                <table className="w-100 table m-0 poppins bg-gray-2 white">
                    {this.renderTH()}
                    {accounting.length > 0 && <tbody>
                        {_.chain(accounting)
                            .sortBy(sort)
                            .tap(arr => {
                                if (up) arr.reverse();
                            })
                            .value().map((accounting) => {

                                accounting.PaymentType = parseFloat(accounting.Credit) ? (_.find(payment_types, pt => pt.id === accounting.IdPaymentType) || _.first(payment_types)).name : '-';

                                /*let class_name = (_.find(booking_status, t => parseInt(t.id, 10) === booking.IdStatus) || { color_class: 'Invalid' }).color_class;*/
                                return (<tr key={accounting.IdBookAccounting}>
                                    <td className="bg-gray-2 white">{moment(accounting.CreateDate).format('MMM Do YYYY @ HH:mmA')}</td>
                                    <td className="bg-gray-2 white">{accounting.Reason}</td>
                                    <td className="bg-gray-2 white">{accounting.PaymentType}</td>
                                    <td className="bg-gray-2 white">{formatPrice(parseFloat(accounting.Credit) * 100)}</td>
                                    <td className="bg-gray-2 white">{formatPrice(parseFloat(accounting.Debit) * 100)}</td>
                                    <td className="bg-gray-2 white">{formatPrice(parseFloat(accounting.Balance) * 100)}</td>
                                    <td className="bg-gray-2 white">{accounting.Confirmation}</td>
                                </tr>)
                            })}
                        <tr>
                            <td className="bg-gray-2 white"></td>
                            <td className="bg-gray-2 white">Impuestos ({property.Tax}%)</td>
                            <td className="bg-gray-2 white"></td>
                            <td className="bg-gray-2 white"></td>
                            <td className="bg-gray-2 white">{formatPrice(TAXES * 100)}</td>
                            <td className="bg-gray-2 white">{formatPrice(BALANCE * 100)}</td>
                            <td className="bg-gray-2 white"></td>
                        </tr>
                    </tbody>}
                    {accounting.length === 0 && <tbody>
                        <tr>
                            <td colSpan={6} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">No se han creado transacciones para esta reserva</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>}
                </table>
            </section>}
        </section>
    }
}

export default Accounting;