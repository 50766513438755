import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import config from '../../../config';
import moment from 'moment';
import { padWithZeros } from '../../../helpers';

class BookingsTable extends React.Component {
    state = {
        sort: 'CheckIn',
    }
    componentWillMount() {
        const { bookings } = this.props;
        this.setState({ bookings });
    }
    componentWillReceiveProps = nextProps => {
        const { bookings } = nextProps;
        this.setState({ bookings });
    }
    onSort = (s) => {
        const { sort } = this.state;
        if (s === sort) this.setState({ up: !this.state.up });
        else this.setState({ up: false, sort: s });
    }
    renderTH = () => {
        const { sort } = this.state;
        return <thead>
            <tr>
                <th className="bg-gray-2 white">Cliente <i className={`icon-lb_sort ${sort === 'Name' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('GivenName')} /></th>
                <th className="bg-gray-2 white">#.Reserva <i className={`icon-lb_sort ${sort === 'IdBook' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('IdBook')} /></th>
                <th className="bg-gray-2 white">Check-In • Out <i className={`icon-lb_sort ${sort === 'CheckIn' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('CheckIn')} /></th>
                <th className="bg-gray-2 white text-center">Estado <i className={`icon-lb_sort ${sort === 'IdStatus' ? 'blue' : 'gray'}`}
                    onClick={() => this.onSort('IdStatus')} /></th>
                <th className="bg-gray-2 white text-right">Accion</th>
            </tr>
        </thead>
    }
    render() {

        const { bookings, sort, up } = this.state, { filters } = this.props;
        const { booking_status } = config;
        let filtered_bookings = _.chain(bookings)
            .filter(booking => {
                let valid = true;
                if (filters && (filters.Code || filters.Master)) {
                    if (`${booking.IdBook} ${booking.GivenName} ${padWithZeros(booking.PNR)}`.toLowerCase().indexOf((filters.Code || filters.Master).toLowerCase()) === -1) valid = false;
                };
                return valid;
            }).value();

        return <div className="mt-4 d-flex">
            <Animated animationIn="fadeInRight" className="w-100 bg-gray-2 px-3 py-2 rounded d-flex">
                {bookings && <table className="w-100 table m-0 poppins bg-gray-2 white">
                    {this.renderTH()}
                    {filtered_bookings.length > 0 && <tbody>
                        {_.chain(filtered_bookings)
                            .sortBy(sort)
                            .tap(arr => {
                                if (up) arr.reverse();
                            })
                            .value().map((booking, index) => {
                                let class_name = (_.find(booking_status, t => parseInt(t.id, 10) === booking.IdStatus) || { color_class: 'Invalid' }).color_class;
                                return (<tr key={booking.IdBook}>
                                    <td className="bg-gray-2 white">{index + 1}. {booking.GivenName}</td>
                                    {/*<td className="bg-gray-2 white">{`${booking.IdBook}`.substring(36 - 12)}</td>*/}
                                    <td className="bg-gray-2 white">{`${padWithZeros(booking.PNR)}`}</td>
                                    <td className="bg-gray-2 white font-10">{`${moment(booking.CheckIn).format('MMM Do YYYY')} • ${moment(booking.CheckOut).format('MMM Do YYYY')}`}</td>
                                    <td className="bg-gray-2 white font-8 text-center"><span className={`w3-tag ${class_name} rounded`}>{(_.find(booking_status, t => parseInt(t.id, 10) === booking.IdStatus) || { name: 'Invalid' }).name}</span></td>
                                    <td className="bg-gray-2 white text-center">
                                        <div className="d-flex justify-content-end">
                                            <Link to={`/booking/${booking.IdBook}`} className="d-flex"><i className="fas fa-eye blue ms-3 font-16 pointer blue me-2 align-self-center" /><span className="align-self-center ms-0">Ver</span></Link>
                                        </div>
                                    </td>
                                </tr>)
                            })}
                    </tbody>}
                    {filtered_bookings.length === 0 && <tbody>
                        <tr>
                            <td colSpan={5} className="bg-gray-2 gray-light">
                                <div className="d-flex justify-content-center" style={{ height: 200 }}>
                                    <h3 className="align-self-center gray text-center poppins">No se encontraron reservas con los parámetros de búsqueda.</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>}
                    {/*<tfoot>
                        <tr>
                            <td colSpan={5} className="text-center bg-gray-2">
                                <span className="w3-badge me-1 poppins font-12">1</span>
                                <span className="w3-badge poppins font-12">2</span>
                            </td>
                        </tr>
                    </tfoot>*/}
                </table>}
            </Animated>
        </div>

    }
}

export default BookingsTable;