import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import accounting from './accounting';
import auxiliar_accountings from './auxiliar_accountings';
import book from './book';
import bookings from './bookings';
import comments from './comments';
import floors from './floors';
import user from './user';
import properties from './properties';
import property from './property';
import periods from './periods';
import period from './period';
import rates from './rates';
import rooms from './rooms';
import room from './room';
import offers from './offers';
import offer from './offer';
import occupancy_summary from './occupancy_summary';
import payment_methods from './payment_methods';
import plans from './plans';
import plan from './plan';
import countries from './countries';
import country_codes from './country_codes';
import staffs from './staffs';
import staff from './staff';
import guests from './guests';
import guest from './guest';
import coorps from './coorps';
import coorp from './coorp';
import nationalities from './nationalities';


import auth from './auth';

const rootReducer = combineReducers({
    routing: routerReducer,
    auth,
    book,
    bookings,
    user,
    properties, 
    property,
    periods,
    period,
    countries,
    country_codes,
    offers,
    offer,
    plans,
    plan,
    rooms,
    room,
    rates,
    staffs,
    staff,
    floors,
    comments,
    accounting,
    auxiliar_accountings,
    payment_methods,
    occupancy_summary,
    guests,
    guest,
    coorps,
    coorp,
    nationalities
});

export default rootReducer;
