
import { fork, } from 'redux-saga/effects';

// Sagas
import {
  family_members,
} from './membership'

import { fetch_booking, fetch_bookings, assign_piece_to_book, free_up_pieces_from_book, fetch_day, fetch_range } from './bookings';
import { fetch_properties, fetch_property } from './properties';
import { fetch_countries, fetch_countries_all, fetch_nationalities } from './countries';
import { fetch_room, fetch_rooms, archive_room, toggle_room } from './rooms';
import { fetch_periods, fetch_period, toggle_period, archive_period } from './periods';
import { fetch_offers, toggle_offer, archive_offer, fetch_offer } from './offers';
import { fetch_plans, fetch_plan, toggle_plan, archive_plan } from './plans';
import { fetch_staff, fetch_staffs, toggle_staff, archive_staff } from './staffs';
import { fetch_floors, update_piece_status, assign_piece_staff, 
  unassign_piece_staff, fetch_piece_comments, add_piece_comments } from './floors';
import { fetch_accounting, fetch_payment_methods } from './accounting';
import { fetch_logic_occupancy } from './logic';
import { fetch_guests, fetch_guest, fetch_guest_bookings } from './guests';
import { fetch_coorps, fetch_coorp, fetch_all_coorps } from './coorps';

export default function* rootSaga() {
  yield [
    // Membership
    fork(family_members),

    // Properties
    fork(fetch_properties),
    fork(fetch_property),

    // Rooms
    fork(fetch_rooms),
    fork(fetch_room),
    fork(archive_room),
    fork(toggle_room),

    // Periods
    fork(fetch_periods),
    fork(fetch_period),
    fork(toggle_period),
    fork(archive_period),

    // Offers
    fork(fetch_offers),
    fork(toggle_offer),
    fork(archive_offer),
    fork(fetch_offer),

    // Plans
    fork(fetch_plans),
    fork(fetch_plan),
    fork(toggle_plan),
    fork(archive_plan),
    
    // Staffs
    fork(fetch_staffs),
    fork(fetch_staff),
    fork(toggle_staff),
    fork(archive_staff),

    // Countries
    fork(fetch_countries),
    fork(fetch_countries_all),
    fork(fetch_nationalities),

    // Bookings
    fork(fetch_booking),
    fork(fetch_bookings),
    fork(assign_piece_to_book),
    fork(free_up_pieces_from_book),
    fork(fetch_day),
    fork(fetch_range),

    // Floors
    fork(fetch_floors),
    fork(update_piece_status),
    fork(assign_piece_staff),
    fork(unassign_piece_staff),
    fork(fetch_piece_comments),
    fork(add_piece_comments),

    // Accounting
    fork(fetch_accounting),
    fork(fetch_payment_methods),

    // Logic
    fork(fetch_logic_occupancy),

    // Guests
    fork(fetch_guests),
    fork(fetch_guest),
    fork(fetch_guest_bookings),

    // Coorps
    fork(fetch_coorps),
    fork(fetch_coorp),
    fork(fetch_all_coorps),
  ]
}