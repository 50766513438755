function nationalities_Reducer(state = [], action) {
    switch (action.type) {
        case 'NATIONALITIES/SET':
            return action.nationalities;
        case 'NATIONALITIES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default nationalities_Reducer;
