import React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import fakeAuth from '../../fakeAuth';
import { Link } from 'react-router';

class TopRightMenu extends React.Component {
    state = {
        hotel: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')) : null
    }
    logout = () => {
        const { router } = this.props;
        fakeAuth.signout(() => {
            this.props.form_actions.logout && this.props.form_actions.logout();
            router.push('/login');
        });
    }
    render() {
        let user = fakeAuth.getUser();
        return <div className="w-auto ms-auto d-flex align-self-center">
            <div className="ms-auto align-self-center bg-gray-dark d-flex justify-content-center" style={{ width: 40, height: 40, borderRadius: 20, border: '1px solid gray' }}>
                <i className="fas fa-bell align-self-center whiteish font-14" />
            </div>
            <div className="ms-2 align-self-center cover" style={{ width: 40, height: 40, borderRadius: 20, border: '1px solid gray', background: `url(${user.LogoUrl}) no-repeat center center` }}></div>
            <button onClick={() => this.setState({ user_menu: !this.state.user_menu })} className="poppins d-flex btn btn-link align-self-center white font-10 ms-2" id="btn-user">{user.FirstName} {user.LastName.substring(0, 1)}. <i className="fa fa-chevron-down align-self-center ms-1" /></button>
            <Popover placement="bottom" isOpen={this.state.user_menu} target={`btn-user`} toggle={() => this.setState({ user_menu: !this.state.user_menu })} className="bg-gray-2">
                <PopoverBody className="d-flex flex-column justify-content-center">
                    <button className="btn btn-link btn-sm white text-left"><i className="icon-lb_user me-2" />Mi Cuenta</button>
                    {!user.IdProperty && <Link to={this.state.hotel ? `/staff/${this.state.hotel.IdProperty}` : '/properties'} className="btn btn-link btn-sm white text-left"><i className="icon-lb_staff font-8 me-2" />Staff</Link>}
                    {!user.IdProperty && <Link to="/properties" className="btn btn-link btn-sm white text-left"><i className="icon-lb_suitcase me-2" />Propiedades</Link>}
                    <button className="btn btn-link btn-sm white text-left"><i className="icon-lb_cog me-2" />Ajustes</button>
                    <button className="btn btn-link btn-sm white text-left" onClick={this.logout}><i className="icon-log-out me-2" />Cerrar Sesión</button>
                </PopoverBody>
            </Popover>
        </div>
    }
}

export default TopRightMenu;