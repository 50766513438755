import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Footer from '../footer.claire';
import Logo from '../logo';
import Burger from '../burger.claire';
import { Animated } from 'react-animated-css';
import { Helmet } from "react-helmet";
import Socials from '../socials_claire';
import Contact from '../contact';
import Form from '../form';

class One extends React.Component {
    state = {
        navBarTransparent: false,
        menuOpen: false
    }
    componentDidMount = () => { window.scrollTo(0, 0); }
    onEnterViewport = () => this.setState({ navBarTransparent: true });
    onExitViewport = () => this.setState({ navBarTransparent: false });
    onToggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

    render() {
        const { navBarTransparent, menuOpen } = this.state, { nbs, title = 'legendbeds', router, className } = this.props;

        return <section className={`${className}`}>
            <Helmet>
                <title>{title}</title>
                <body className={`${className}`}></body>
            </Helmet>

            {/* Navbars */}

            {/* Desktop */}
            {/*<div className={`w3-top navi-bar cover w3-hide-small bg-dark-blue ${navBarTransparent ? '' : ''}`}
                style={{ zIndex: 3 }}>
                <div className={`w3-bar d-flex flex-row font-11 m-auto`} style={{ height: 70, maxWidth: 1200 }}>
                    <Logo className="ms-4" who={who} />
                    <Socials router={router} className="white" nbs={nbs} onToggleMenu={this.onToggleMenu} form={this.props.form_actions} />
                </div>
    </div>*/}

            {/* Mobile */}
            <div className={`w3-top navi-bar cover w3-hide-medium w3-hide-large bg-dark-blue ${navBarTransparent ? '' : ''}`}
                style={{ zIndex: 3 }}>
                <div className={`w3-bar d-flex flex-row font-11 m-auto w3-mobile`} style={{ height: 70, zIndex: 2, maxWidth: 1200 }}>
                    <span className="w-50 align-self-center fas fa-bars white font-30 ms-4" onClick={() => this.onToggleMenu()} style={{ marginTop: 3 }} />
                    {/*<Logo className="ms-auto me-3" who={who} />*/}
                </div>
            </div>            

            {/* /Navbars */}

            {/* Mobile Menu */}
            <Animated animateOnMount={false} isVisible={menuOpen} animationIn="fadeInLeft" animationInDuration={300} animationOutDuration={300} animationOut="fadeOutLeft" className={`w3-sidebar w3-bar-block w3-card w3-center bg-white w-75`}
                style={{ left: 0, zIndex: 4 }}>
                {/*<Burger nbs={nbs} router={router} onToggleMenu={this.onToggleMenu} form={this.props.form_actions} />*/}
            </Animated>
            {/* /Mobile Menu */}

            {/* Body */}
            {React.cloneElement(this.props.children, this.props)}
            {/* /Body */}

            {/*<ScrollTrigger onEnter={this.onEnterViewport} onExit={this.onExitViewport} triggerOnLoad={true}
                style={{ position: 'absolute', top: 150, height: 50 }} className="" throttleScroll={0} />*/}

            {/*<Footer />*/}

        </section>
    }
}

export default One;