import React from 'react';
import { Animated } from 'react-animated-css';
import moment from 'moment';

class WidgetTotalSales extends React.Component {
    state = {
        Flag: 'day',
        CI: moment().add(-1, 'day').utc(),
        CO: moment().add(1, 'day').utc()
    }
    componentWillMount = () => {
        this.onFetch();
    }
    componentWillReceiveProps = nextProps => {
        const { bookings } = nextProps;
    }

    onFetch = () => {
        const { id } = this.props.params, { Flag } = this.state;
        let CI = moment().add(-1, 'day').utc(),
            CO = moment().add(1, 'day').utc();
        switch (Flag) {
            case 'week': CI = moment().startOf('isoWeek').add(-1, 'day').utc();
                CO = moment(CI).add(8, 'days'); break;
            case 'month': CI = moment().startOf('month').add(-1, 'day').utc();
                CO = moment().add(1, 'months').startOf('month').utc(); break;
            default: break;
        }
        this.setState({ CI, CO });
        this.props.logic_actions && this.props.logic_actions.clear_occupancy_summary();
        this.props.booking_actions && this.props.booking_actions.fetch_range({
            IdProperty: id,
            CheckIn: moment(CI).format('YYYY-MM-DD'),
            CheckOut: moment(CO).format('YYYY-MM-DD')
        });
    }

    render() {
        const { Flag, CI, CO } = this.state, { bookings } = this.props;

        let total = 0;
        if (bookings) {
            let accountings = _.chain(bookings).map(b => b.Accounting)
                .compact().flatten()
                .filter(a => moment(a.CreateDate).isBetween(CI, CO))
                .value();
            total = _.chain(accountings).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value()
        }
        return <Animated animationIn='fadeInRight' animationInDelay={400} className="w-100 bg-gray-2 px-3 py-2 rounded">
            <div className="d-flex">
                <span className="poppins font-12 white align-self-center">Ventas totales</span>
                {!bookings && <span className="poppins font-16 gray ms-auto align-self-center"><i className="fas fa-cog fa-spin" /></span>}
                {bookings && <span className="poppins font-16 blue ms-auto align-self-center">{`USD ${total.toFixed(2)}`}</span>}
            </div>
            <div className="d-flex mt-2">
                <span onClick={() => { this.setState({ Flag: 'day' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'day' ? 'blue' : 'white'} align-self-center`}>Dia</span>
                <span onClick={() => { this.setState({ Flag: 'week' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'week' ? 'blue' : 'white'} white align-self-center ms-2`}>Semana</span>
                <span onClick={() => { this.setState({ Flag: 'month' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'month' ? 'blue' : 'white'} white align-self-center ms-2`}>Mes</span>
                {/*<span className="font-8 gray">{`${CI.format('MMM Do YYYY')} - ${CO.format('MMM Do YYYY')}`}</span>*/}
                {/*<span className="pointer poppins font-8 white align-self-center ms-2">Año</span>*/}
                <span className="ms-auto align-self-center gray font-10">+ Impuestos</span>
                {/*<i className="icon-download font-16 transparent ms-auto align-self-center" />*/}
            </div>
        </Animated>
    }
}

export default WidgetTotalSales;